import React from 'react';

const ProjectQaWeldingInspectionsAccessRestrictionsIndex = React.lazy(() => import("./index"))
const NewProjectQaWeldingInspectionsAccessRestrictions = React.lazy(() => import("./new"))
const EditProjectQaWeldingInspectionsAccessRestrictions = React.lazy(() => import("./edit"))

export default[
	{path:'/project/qa/welding_inspections/access_restrictions/:id/edit',component: EditProjectQaWeldingInspectionsAccessRestrictions,name:'Edit ProjectQaWeldingInspectionsAccessRestriction'},
	{path:'/project/qa/welding_inspections/access_restrictions/new',component: NewProjectQaWeldingInspectionsAccessRestrictions,name:'New ProjectQaWeldingInspectionsAccessRestriction'},  
	{path:'/project/qa/welding_inspections/access_restrictions',component: ProjectQaWeldingInspectionsAccessRestrictionsIndex,name:'ProjectQaWeldingInspectionsAccessRestrictions'}, 
]

// import ProjectQaWeldingInspectionsAccessRestrictionsRoutes from "modules/project/qa/welding_inspections/access_restrictions/web/routes"
// routes = _.concat(routes,ProjectQaWeldingInspectionsAccessRestrictionsRoutes)
