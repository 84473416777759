import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardHeader, CardBody } from "reactstrap";
import classnames from "classnames";

const PaintCodeDetailsIndex = React.lazy(() => import("modules/paint/code_details/web/index"))
const PaintProductsIndex = React.lazy(() => import("modules/paint/products/web/index"))
const PaintProductCategoriesIndex = React.lazy(() => import("modules/paint/product_categories/web/index"))
const PaintTreatmentGradesIndex = React.lazy(() => import("modules/paint/treatment_grades/web/index"))
const PaintLocationsIndex = React.lazy(() => import("modules/paint/locations/web/index"))
const PaintSurfacePrepsIndex = React.lazy(() => import("modules/paint/surface_preps/web/index"))


import qs from "query-string";

const PaintSettingsIndex = (props) => {
  const { location, history } = props;
  const query = qs.parse(location.search);
  const { tab } = query;

  const [activeTab, setActiveTab] = useState(tab ? tab : "products");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Card>
      <CardHeader>Paint Settings</CardHeader>
      <CardBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "products" })}
              onClick={() => {
                toggle("products");
                history.push(`/paint/settings?tab=products`);
              }}
            >
              Products
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "product_categories" })}
              onClick={() => {
                toggle("product_categories");
                history.push(`/paint/settings?tab=product_categories`);
              }}
            >
              Product Categories
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "treatment_grades" })}
              onClick={() => {
                toggle("treatment_grades");
                history.push(`/paint/settings?tab=treatment_grades`);
              }}
            >
              Treatment Grades
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "locations" })}
              onClick={() => {
                toggle("locations");
                history.push(`/paint/settings?tab=locations`);
              }}
            >
              Locations
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "surface_preps" })}
              onClick={() => {
                toggle("surface_preps");
                history.push(`/paint/settings?tab=surface_preps`);
              }}
            >
              Surface Preps
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "code_details" })}
              onClick={() => {
                toggle("code_details");
                history.push(`/paint/settings?tab=code_details`);
              }}
            >
              Code Details
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="products">
            <PaintProductsIndex {...props} />
          </TabPane>
          <TabPane tabId="product_categories">
            <PaintProductCategoriesIndex {...props} />
          </TabPane>
          <TabPane tabId="treatment_grades">
            <PaintTreatmentGradesIndex {...props} />
          </TabPane>
          <TabPane tabId="locations">
            <PaintLocationsIndex {...props} />
          </TabPane>
          <TabPane tabId="surface_preps">
            <PaintSurfacePrepsIndex {...props} />
          </TabPane>
          <TabPane tabId="code_details">
            <PaintCodeDetailsIndex {...props} />
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  );
};

export default PaintSettingsIndex;
