import axios from "axios";

const prepareData = (data) => {return {project_qa_welding_inspections_fillet_size:data}}

// Plural Actions
export const GET_PROJECT_QA_WELDING_INSPECTIONS_FILLET_SIZES = "get_project_qa_welding_inspections_fillet_sizes";
export const getProjectQaWeldingInspectionsFilletSizes = () => {
	const url = `/project/qa/welding_inspections/fillet_sizes.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_WELDING_INSPECTIONS_FILLET_SIZES,
		payload: request
	};
};


// Singular Actions
export const CREATE_PROJECT_QA_WELDING_INSPECTIONS_FILLET_SIZE = "create_project_qa_welding_inspections_fillet_size";
export const createProjectQaWeldingInspectionsFilletSize = (data) => {
	const url = `/project/qa/welding_inspections/fillet_sizes.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PROJECT_QA_WELDING_INSPECTIONS_FILLET_SIZE,
		payload: request
	};
};

export const GET_PROJECT_QA_WELDING_INSPECTIONS_FILLET_SIZE = "get_project_qa_welding_inspections_fillet_size";
export const getProjectQaWeldingInspectionsFilletSize = (id) => {
	const url = `/project/qa/welding_inspections/fillet_sizes/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_WELDING_INSPECTIONS_FILLET_SIZE,
		payload: request
	};
};

export const UPDATE_PROJECT_QA_WELDING_INSPECTIONS_FILLET_SIZE = "update_project_qa_welding_inspections_fillet_size";
export const updateProjectQaWeldingInspectionsFilletSize = (id,data) => {
	const url = `/project/qa/welding_inspections/fillet_sizes/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PROJECT_QA_WELDING_INSPECTIONS_FILLET_SIZE,
		payload: request
	};
};

export const DESTROY_PROJECT_QA_WELDING_INSPECTIONS_FILLET_SIZE = "destroy_project_qa_welding_inspections_fillet_size";
export const destroyProjectQaWeldingInspectionsFilletSize = (id) => {
	const url = `/project/qa/welding_inspections/fillet_sizes/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PROJECT_QA_WELDING_INSPECTIONS_FILLET_SIZE,
		payload: request
	};
};
