import React from 'react';

const EmployeeTasksIndex = React.lazy(() => import("./index"))
const NewEmployeeTasks = React.lazy(() => import("./new"))
const EditEmployeeTasks = React.lazy(() => import("./edit"))

export default[
	{path:'/employee/:employee_id/tasks/:id/edit',component: EditEmployeeTasks,name:'Edit EmployeeTask'},
	{path:'/employee/:employee_id/tasks/new',component: NewEmployeeTasks,name:'New EmployeeTask'},  
	// {path:'/employee/:employee_id/tasks',component: EmployeeTasksIndex,name:'EmployeeTasks'}, 
]

// import EmployeeTasksRoutes from "modules/employee/tasks/web/routes"
// routes = _.concat(routes,EmployeeTasksRoutes)
