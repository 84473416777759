import { GET_CUSTOM_FORMS, GET_CUSTOM_FORM, CREATE_CUSTOM_FORM, UPDATE_CUSTOM_FORM, DESTROY_CUSTOM_FORM } from "./actions";

export function CustomFormsReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_CUSTOM_FORMS}_FULFILLED`: {
      let newState = _.cloneDeep(state);
      return _.merge(newState, _.mapKeys(action.payload.data, "id"));
    }
    case `${GET_CUSTOM_FORM}_FULFILLED`:
    case `${CREATE_CUSTOM_FORM}_FULFILLED`:
    case `${UPDATE_CUSTOM_FORM}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_CUSTOM_FORM}_FULFILLED`: {
      let newState = _.clone(state);
      const deletedItem = action.payload.data;
      const id = _.get(deletedItem, "id");
      newState = _.filter(newState, (i) => i.id != id);
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { CustomFormsReducer } from "modules/custom_forms/reducer"

// custom_forms:CustomFormsReducer,
