export function PendingReducer(state = 0, action) {
  const split = action.type.split("_");
  const status = split.slice(-1)[0];

  if (action.meta?.disableSpinner) {
    return state;
  } else {
    switch (status) {
      case "PENDING":
        return state + 1;
      case "FULFILLED":
        return state - 1;
      case "REJECTED":
        return state - 1;
      default:
        return state;
    }
  }
}
