import React, { Fragment, useState, useRef } from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import { Row, Col, Label, FormGroup, Collapse, Button, CardBody, Card, CardHeader } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";

import BasicModal from "modules/5_view_helpers/web/BasicModal";
import "./AccordionArray.scss";
import { useEffect } from "react";

export default function AccordionArray(props) {
  const {
    fields,
    stackedLabels,
    columns,
    titleField,
    label,
    meta: { error },
    addProps,
    filterBy,
  } = props;

  const removeItem = (i) => {
    const thisField = fields.value[i];

    if (thisField?.id) {
      fields.update(i, { id: thisField.id, _destroy: "1" });
    } else {
      fields.remove(i);
    }
  };

  return (
    <Fragment>
      <Row md={12}>
        <Col>
          <Button
            disabled={_.isPresent(error)}
            onClick={() => {
              if (!_.isPresent(error)) {
                fields.push({ defaultOpen: true, ...addProps });
              }
            }}
          >
            Add {label}
          </Button>
        </Col>
      </Row>
      <hr />
      {fields.map((field, i) => {
        const thisField = fields.value[i];
        const markedForDestruction = _.get(thisField, "_destroy");
        const showItem = _.isEmpty(filterBy) || _.isEqual(thisField?.fieldName, filterBy);
        if (!markedForDestruction && showItem) {
          return (
            <ItemShow
              removeItem={() => {
                removeItem(i);
              }}
              stackedLabels={stackedLabels}
              field={field}
              thisField={thisField}
              columns={columns}
              titleField={titleField}
              focus={true}
              label={label}
              key={i}
              thisFieldErrors={error ? error[i] : false}
            />
          );
        }
      })}
    </Fragment>
  );
}

AccordionArray.defaultProps = {
  addProps: {},
};

const ItemShow = (props) => {
  const { removeItem, stackedLabels, field, thisField, columns, titleField, label, thisFieldErrors, closeAll } = props;

  const [itemOpen, setItemOpen] = useState(thisField.defaultOpen ? true : false);
  const toggle = () => setItemOpen(!itemOpen);

  const actionItemRef = useRef(null);

  useEffect(() => {
    actionItemRef.current.scrollIntoView();
  }, [focus]);

  return (
    <Fragment>
      <Row md={12}>
        <Col md={12}>
          <Card style={thisFieldErrors ? { outline: "2px solid red", backgroundColor: "rgb(195 0 0 / 5%)" } : {}}>
            <CardHeader
              style={thisFieldErrors ? { backgroundColor: "rgb(195 0 0 / 5%)", userSelect: "none" } : { userSelect: "none" }}
              className="accordian-header"
            >
              <Col md={1} className="pt-1 pr-0 pl-0" onClick={thisFieldErrors ? null : toggle} style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={itemOpen ? faAngleUp : faAngleDown} size="lg" />
              </Col>
              <Col md={10} onClick={thisFieldErrors ? null : toggle} style={{ cursor: "pointer" }} className="header-title pl-0">
                <div>{thisField[titleField]}</div>
              </Col>
              <Col md={1}>
                <BasicModal
                  buttonLabel={<FontAwesomeIcon icon={faTimes} />}
                  buttonColor="danger"
                  successButtonLabel="Yes"
                  onSuccessClick={() => {
                    removeItem();
                  }}
                  title={`Delete ${label}`}
                >
                  Are you sure you want to delete this {label}?
                </BasicModal>
              </Col>
            </CardHeader>

            <Collapse isOpen={itemOpen}>
              <CardBody>
                {stackedLabels ? (
                  <Fragment>
                    {_.map(columns, (column, i) => {
                      return (
                        <FormGroup key={i}>
                          <Label style={{ fontSize: "10pt", marginBottom: "4px" }} for={`${field}.${column.name}`}>
                            {column.label}
                          </Label>
                          {column.array && (
                            <FieldArray
                              name={`${field}.${column.name}`}
                              {..._.omit(column, ["name", "label", "fieldWidth", "labelWidth", "maxLength"])}
                            />
                          )}
                          {!column.array && (
                            <Field name={`${field}.${column.name}`} {..._.omit(column, ["name", "label", "fieldWidth", "labelWidth", "maxLength"])} />
                          )}
                        </FormGroup>
                      );
                    })}
                  </Fragment>
                ) : (
                  <FormGroup row>
                    {_.map(columns, (column, i) => {
                      return (
                        <Fragment key={i}>
                          <Label
                            md={column.labelWidth ? column.labelWidth : "4"}
                            style={{ fontSize: "10pt", marginBottom: "4px" }}
                            for={`${field}.${column.name}`}
                          >
                            {column.label}
                          </Label>
                          <Col md={column.fieldWidth ? column.fieldWidth : "8"} className="mb-2">
                            {column.array && (
                              <FieldArray
                                name={`${field}.${column.name}`}
                                {..._.omit(column, ["name", "label", "fieldWidth", "labelWidth", "maxLength"])}
                              />
                            )}
                            {!column.array && (
                              <Field
                                name={`${field}.${column.name}`}
                                {..._.omit(column, ["name", "label", "fieldWidth", "labelWidth", "maxLength"])}
                              />
                            )}
                          </Col>
                        </Fragment>
                      );
                    })}
                  </FormGroup>
                )}
                <hr />
                <Button onClick={thisFieldErrors ? null : toggle} color="primary" disabled={_.isPresent(thisFieldErrors)} size="lg" block>
                  <div ref={actionItemRef}>Done</div>
                </Button>
              </CardBody>
            </Collapse>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
