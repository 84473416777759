import TrainingCourseRoutes from "modules/training/courses/web/routes";
import TrainingCompanyRoutes from "modules/training/companies/web/routes";
import TrainingCourseCategoryRoutes from "modules/training/course_categories/web/routes";
import TrainingBookingRoutes from "modules/training/bookings/web/routes";
import TrainingDashboardRoutes from "modules/training/dashboard/web/routes";
import TrainingNzqaNumbersRoutes from "modules/training/nzqa_numbers/web/routes";

let routes = [];
// Training routes
routes = _.concat(routes, TrainingCourseRoutes);
routes = _.concat(routes, TrainingCompanyRoutes);
routes = _.concat(routes, TrainingCourseCategoryRoutes);
routes = _.concat(routes, TrainingBookingRoutes);
routes = _.concat(routes, TrainingDashboardRoutes);
routes = _.concat(routes, TrainingNzqaNumbersRoutes);

export default routes;
