import axios from "axios";
import jsonFormData from "json-form-data";

const prepareData = (data) => {
  return jsonFormData(
    { training_course: data },
    { showLeafArrayIndexes: true }
  );
};

// Plural Actions
export const GET_TRAINING_COURSES = "get_training_courses";
export const getTrainingCourses = () => {
  const url = "/training/courses.json";
  const request = axios.get(url);
  return {
    type: GET_TRAINING_COURSES,
    payload: request,
  };
};

// Singular Actions
export const CREATE_TRAINING_COURSE = "create_training_course";
export const createTrainingCourse = (data) => {
  const url = "/training/courses.json";
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_TRAINING_COURSE,
    payload: request,
  };
};

export const GET_TRAINING_COURSE = "get_training_course";
export const getTrainingCourse = (id) => {
  const url = `/training/courses/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_TRAINING_COURSE,
    payload: request,
  };
};

export const UPDATE_TRAINING_COURSE = "update_training_course";
export const updateTrainingCourse = (id, data) => {
  const url = `/training/courses/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_TRAINING_COURSE,
    payload: request,
  };
};

export const DESTROY_TRAINING_COURSE = "destroy_training_course";
export const destroyTrainingCourse = (id) => {
  const url = `/training/courses/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_TRAINING_COURSE,
    payload: request,
    meta: { id: id },
  };
};
