import axios from "axios";

const prepareData = (data) => {
  return { hireages_external: data };
};

// Plural Actions
export const GET_HIREAGES_EXTERNALS = "get_hireages_externals";
export const getHireagesExternals = () => {
  const url = "/hireages/externals.json";
  const request = axios.get(url);
  return {
    type: GET_HIREAGES_EXTERNALS,
    payload: request,
  };
};

// Singular Actions
export const CREATE_HIREAGES_EXTERNAL = "create_hireages_external";
export const createHireagesExternal = (data) => {
  const url = "/hireages/externals.json";
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_HIREAGES_EXTERNAL,
    payload: request,
  };
};

export const GET_HIREAGES_EXTERNAL = "get_hireages_external";
export const getHireagesExternal = (id) => {
  const url = `/hireages/externals/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_HIREAGES_EXTERNAL,
    payload: request,
  };
};

export const UPDATE_HIREAGES_EXTERNAL = "update_hireages_external";
export const updateHireagesExternal = (id, data) => {
  const url = `/hireages/externals/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_HIREAGES_EXTERNAL,
    payload: request,
  };
};

export const DESTROY_HIREAGES_EXTERNAL = "destroy_hireages_external";
export const destroyHireagesExternal = (id) => {
  const url = `/hireages/externals/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_HIREAGES_EXTERNAL,
    payload: request,
    meta: { id: id },
  };
};
