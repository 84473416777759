import {
  GET_PROJECTS,
  GET_PROJECT,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  DESTROY_PROJECT,
} from "./actions";

export function ProjectsReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_PROJECTS}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    case `${GET_PROJECT}_FULFILLED`:
    case `${CREATE_PROJECT}_FULFILLED`:
    case `${UPDATE_PROJECT}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_PROJECT}_FULFILLED`: {
      let newState = _.clone(state);
      const {
        meta: { id },
      } = action;
      _.unset(newState, id);
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { ProjectsReducer } from "modules/projects/reducer"

// projects:ProjectsReducer,
