import React from 'react';

const ProjectQaWeldingInspectionsViewingConditionsIndex = React.lazy(() => import("./index"))
const NewProjectQaWeldingInspectionsViewingConditions = React.lazy(() => import("./new"))
const EditProjectQaWeldingInspectionsViewingConditions = React.lazy(() => import("./edit"))

export default[
	{path:'/project/qa/welding_inspections/viewing_conditions/:id/edit',component: EditProjectQaWeldingInspectionsViewingConditions,name:'Edit ProjectQaWeldingInspectionsViewingCondition'},
	{path:'/project/qa/welding_inspections/viewing_conditions/new',component: NewProjectQaWeldingInspectionsViewingConditions,name:'New ProjectQaWeldingInspectionsViewingCondition'},  
	{path:'/project/qa/welding_inspections/viewing_conditions',component: ProjectQaWeldingInspectionsViewingConditionsIndex,name:'ProjectQaWeldingInspectionsViewingConditions'}, 
]

// import ProjectQaWeldingInspectionsViewingConditionsRoutes from "modules/project/qa/welding_inspections/viewing_conditions/web/routes"
// routes = _.concat(routes,ProjectQaWeldingInspectionsViewingConditionsRoutes)
