import {
  GET_CUSTOM_FORM_RECORDS,
  GET_CUSTOM_FORM_RECORD,
  CREATE_CUSTOM_FORM_RECORD,
  UPDATE_CUSTOM_FORM_RECORD,
  DESTROY_CUSTOM_FORM_RECORD,
} from "./actions";

export function CustomFormRecordsReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_CUSTOM_FORM_RECORDS}_FULFILLED`: {
      let newState = _.cloneDeep(state);
      return _.merge(newState, _.mapKeys(action.payload.data, "id"));
    }
    case `${GET_CUSTOM_FORM_RECORD}_FULFILLED`:
    case `${CREATE_CUSTOM_FORM_RECORD}_FULFILLED`:
    case `${UPDATE_CUSTOM_FORM_RECORD}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_CUSTOM_FORM_RECORD}_FULFILLED`: {
      let newState = _.clone(state);
      const deletedItem = action.payload.data;
      const id = _.get(deletedItem, "id");
      newState = _.filter(newState, (i) => i.id != id);
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { CustomFormRecordsReducer } from "modules/custom_form/records/reducer"

// custom_form_records:CustomFormRecordsReducer,
