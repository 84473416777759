import React, { useMemo, useRef, Fragment } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import "./ReactDropzone.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-regular-svg-icons";

import { f7, PhotoBrowser } from "framework7-react";
// import Compressor from "compressorjs";
import imageCompression from "browser-image-compression";
import jsonFormData from "json-form-data";
import { BarLoader } from "react-spinners";
import { connect } from "react-redux";
import { addReactDropzoneFileUploading, removeReactDropzoneFileUploading } from "../ReactDropzone/actions";

const imageExt = ["png", "jpg", "jpeg", "gif", "bmp"];

function mapStateToProps(state) {
  return {
    react_dropzone_status: state.react_dropzone_status,
  };
}

function ReactDropzone(props) {
  const {
    placeholder,
    filesAccepted,
    fields,
    attachmentName,
    maxSizeMB,
    addProps,
    filterBy,
    field_name,
    imageOnly,
    disabled,
    maxSize,
    maxFiles,
    uploadNow,
    meta: { error },
  } = props;
  const photoBrowserRef = useRef(null);

  const filtered_values = filterBy
    ? _.filter(fields.value, (file) => file[filterBy] == field_name && !file._destroy)
    : _.filter(fields.value, (file) => !file._destroy);

  const removeFile = (i) => {
    const { dialog } = f7;
    const thisField = fields.value[i];

    dialog.confirm("Are you sure you want to remove this file?", () => {
      if (thisField?.id) {
        fields.update(i, { id: thisField.id, _destroy: "1" });
      } else {
        fields.remove(i);
      }
    });
  };

  const addFile = (fileIo, fileAddedIndex) => {
    let fileObject = {
      name: fileIo.name,
      size: fileIo.size,
      type: fileIo.type,
      url: URL.createObjectURL(fileIo),
      ...addProps,
    };

    if (uploadNow) {
      // Calculate the index of where the file will be added into fields
      // If there are multiple files being uploaded, the index will be the length of the fields + the index of the file being added
      const fileIndex = fields.value?.length + fileAddedIndex || 0 + fileAddedIndex;
      fields.push({ uploading: true, ...fileObject });
      props.addReactDropzoneFileUploading();

      // Upload the fileAccepted
      const data = jsonFormData({ attachment: fileIo }, { showLeafArrayIndexes: true });
      axios.post("/attachments/create", data).then((response) => {
        // Once the file is uploaded, update the file object and remove the loading and set uploaded to true
        fileObject.blob_signed_id = response.data.blob_signed_id;
        fields.update(fileIndex, { uploading: false, uploaded: true, ...fileObject });
        props.removeReactDropzoneFileUploading();
      });
    } else {
      fileObject[attachmentName] = fileIo;
      fields.push(fileObject);
    }
  };

  const onDrop = (acceptedFiles) => {
    _.each(acceptedFiles, (fileObject, attachmentIndex) => {
      const options = {
        maxSizeMB: maxSizeMB,
        useWebWorker: true,
      };
      try {
        if (fileObject.size >= 1000000) {
          f7.dialog.preloader("Compressing");
          imageCompression(fileObject, options)
            .then((compressedBlob) => {
              const compressedFile = new File([compressedBlob], compressedBlob.name, { type: compressedBlob.type, lastModified: Date.now() });
              addFile(compressedFile, attachmentIndex); // add the new compressed file
              f7.dialog.close();
            })
            .catch(() => {
              addFile(fileObject, attachmentIndex);
              f7.dialog.close();
            });
        } else {
          addFile(fileObject, attachmentIndex);
        }
      } catch (error) {
        addFile(fileObject, attachmentIndex);
        f7.dialog.close();
      }
    });
  };

  const onDropRejected = (errorArray) => {
    _.each(errorArray, (errorObject) => {
      _.each(errorObject.errors, (e) => {
        if (e.message == "File type must be image/*") {
          toastr.error(`${errorObject.file.name} File must be an image`);
        } else {
          toastr.error(`${errorObject.file.name} ${e.message}`);
        }
      });
    });
  };

  const validator = (newFile) => {
    if (_.some(filtered_values, (file) => newFile.name == file.name && newFile.size == file.size)) {
      return {
        code: "duplicate-file",
        message: `File already exists`,
      };
    }
  };

  const photos = useMemo(() => {
    let images = _.filter(filtered_values, (file, i) => {
      if (!file._destroy && file.name) {
        let ext = file.name.split(".").pop().toLowerCase();
        const isImage = _.includes(imageExt, ext);
        return isImage;
      }
    });

    return _.map(images, (image) => {
      if (image.attachment) {
        const url = URL.createObjectURL(image[attachmentName]);
        return { ...image, path: image.name, url: url };
      } else {
        return image;
      }
    });
  }, [fields]);

  // Dropzone
  return (
    <Fragment>
      <Dropzone
        onDrop={onDrop}
        disabled={disabled}
        accept={imageOnly ? "image/*" : filesAccepted}
        maxSize={maxSize}
        maxFiles={maxFiles}
        validator={validator}
        onDropRejected={onDropRejected}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              {_.isEmpty(filtered_values) && <p className="placeholder-text">{placeholder}</p>}
              <aside className="thumbsContainer">
                {fields.map((field, i) => {
                  const thisField = fields.value[i];
                  const markedForDestruction = _.get(thisField, "_destroy");
                  if (field_name && filterBy && thisField[filterBy] != field_name) return null; // if field_name and filterBy are present, only show files that match
                  if (!markedForDestruction) {
                    return (
                      <FilePreview
                        file={thisField}
                        key={i}
                        i={i}
                        removeFile={() => {
                          removeFile(i);
                        }}
                        photos={photos}
                        openPhotoBrowser={() => {
                          const photo = _.findIndex(photos, (p) => p.name == thisField.name);
                          photoBrowserRef.current.open(photo);
                        }}
                      />
                    );
                  }
                })}
              </aside>
            </div>
          </section>
        )}
      </Dropzone>
      <PhotoBrowser photos={photos} type="popup" ref={photoBrowserRef} />
      {error && <div style={{ color: "red" }}>{error}</div>}
    </Fragment>
  );
}

ReactDropzone.defaultProps = {
  placeholder: "Tap here to attach files",
  maxSizeMB: 1,
  attachmentName: "attachment",
};

export default connect(mapStateToProps, { addReactDropzoneFileUploading, removeReactDropzoneFileUploading })(ReactDropzone);

const FilePreview = (props) => {
  let { file, removeFile, photos } = props;

  // Find file extension for preview image
  let ext = file?.name?.split(".").pop().toLowerCase();

  const displayFileSize = file.size < 1000000 ? `${(file.size / 1000).toFixed(2)} kB` : `${(file.size / 1000000).toFixed(2)} MB`;
  const isImage = _.includes(imageExt, ext);

  const findPhoto = _.find(photos, (photo) => photo.name == file.name);
  const blobUrl = findPhoto?.url;
  const style = file?.uploaded ? { borderColor: "green", borderWidth: "2px" } : {};

  const handleImageClick = (e) => {
    e.stopPropagation();
    props.openPhotoBrowser();
  };

  return (
    <div className="preview-container">
      <button
        type="button"
        className="remove-file"
        // e.stopPropagation to prevent dropzone opening
        onClick={(e) => {
          e.stopPropagation();
          removeFile(file);
        }}
      />

      {file.uploading && <BarLoader loading={true} />}
      {!file.uploading && (
        <>
          {isImage ? (
            // Preview for images
            <a href={blobUrl} target="_blank">
              <div className="thumb" onClick={handleImageClick} style={style}>
                <div className="thumbInner">
                  <img src={blobUrl} className="img" />
                </div>
              </div>
            </a>
          ) : (
            // Preview for non-images
            <div className="thumb" onClick={handleImageClick} style={{ cursor: "default", ...style }}>
              <div className="thumbInnerNoImg">
                <FontAwesomeIcon icon={faFile} size="2x" />
              </div>
            </div>
          )}
        </>
      )}
      <div
        className="file-name"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <a href={blobUrl} target="_blank">
          {file.name}
        </a>
      </div>
      <div className="file-size">{displayFileSize}</div>
    </div>
  );
};
