import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Alert } from "reactstrap";

function mapStateToProps(state) {
	return { 
		errors: state.errors 
	};
}

const ErrorsDisplay = (props) => {
	const { errors } = props

	if (_.isEmpty(errors)) {
		return null
	}
	else{
		return(
			<Alert color="danger">
				<b>The following errors prevented further action</b>
				{_.map(errors, (error,i) => (
					<li key={i}>{error}</li>
				))}
			</Alert>
		)		
	}

};

export default connect(mapStateToProps, {})(ErrorsDisplay);
