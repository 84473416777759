import React, { Fragment } from "react";
import { Label, FormGroup } from "reactstrap";

import { SingleDatePicker } from "react-dates";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./SingleDateField.scss";

class SingleDateField extends React.Component {
  constructor(props) {
    super(props);

    this.state = { focused: false };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleFocusChange = this.handleFocusChange.bind(this);
  }

  handleDateChange(date, e) {
    const {
      input: { value, onChange, onBlur, name },
    } = this.props;

    if (date) {
      onChange(date.format("YYYY-MM-DD"));
    } else {
      onChange("");
    }
  }

  handleFocusChange({ focused }) {
    this.setState({ focused });
  }

  render() {
    const {
      input: { value, onChange, onBlur, name },
      meta: { touched, error, warning },
      label,
      type,
      placeholder,
      readonly,
      disabled,
      showClearDate,
      bgColor,
      enablePreviousDates,
      minDate,
    } = this.props;
    let date = value ? moment(value) : null;

    let isOutsideRange;

    if (minDate) {
      isOutsideRange = minDate ? (day) => day.isBefore(minDate) : undefined;
    } else {
      isOutsideRange = enablePreviousDates ? () => false : undefined;
    }

    return (
      <div>
        <Label>{label}</Label>
        {label && <br />}
        <SingleDatePicker
          date={date} // momentPropTypes.momentObj or null
          onDateChange={this.handleDateChange} // PropTypes.func.isRequired
          focused={this.state.focused} // PropTypes.bool
          onFocusChange={this.handleFocusChange} // PropTypes.func.isRequired
          id={name} // PropTypes.string.isRequired,
          firstDayOfWeek={1}
          displayFormat="DD/MM/YYYY"
          disabled={disabled}
          readOnly={readonly}
          isOutsideRange={isOutsideRange}
          showClearDate={showClearDate}
          placeholder={placeholder}
        />
        {touched &&
          ((error && (
            <div className="text-danger">
              {label ? label : name} {error}
            </div>
          )) ||
            (warning && <div className="text-warning">{warning}</div>))}
      </div>
    );
  }
}

SingleDateField.defaultProps = {
  readonly: false,
  showClearDate: false,
};

export default SingleDateField;
