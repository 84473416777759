import axios from "axios";

const prepareData = (data) => {
  return { custom_form: data };
};

// Plural Actions
export const GET_CUSTOM_FORMS = "get_custom_forms";
export const getCustomForms = (show_index) => {
  let url = `/custom_forms.json`;
  if (show_index) {
    url += "?show_index=true";
  }
  const request = axios.get(url);
  return {
    type: GET_CUSTOM_FORMS,
    payload: request,
  };
};

// Singular Actions
export const CREATE_CUSTOM_FORM = "create_custom_form";
export const createCustomForm = (data) => {
  const url = `/custom_forms.json`;
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_CUSTOM_FORM,
    payload: request,
  };
};

export const GET_CUSTOM_FORM = "get_custom_form";
export const getCustomForm = (id,show_children) => {
  let url = `/custom_forms/${id}.json`;
  if (show_children) {
    url += "?show_children";
  }
  const request = axios.get(url);
  return {
    type: GET_CUSTOM_FORM,
    payload: request,
  };
};

export const UPDATE_CUSTOM_FORM = "update_custom_form";
export const updateCustomForm = (id, data) => {
  const url = `/custom_forms/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_CUSTOM_FORM,
    payload: request,
  };
};

export const DESTROY_CUSTOM_FORM = "destroy_custom_form";
export const destroyCustomForm = (id) => {
  const url = `/custom_forms/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_CUSTOM_FORM,
    payload: request,
  };
};
