import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { SmartSelect, InputField, required } from "modules/4_final_form/mobile";

import { Field } from "react-final-form";

import { getProjectAreas } from "modules/project/areas/actions";

import { BlockTitle, Block, List, ListItem, Button } from "framework7-react";

function mapStateToProps(state) {
  return {
    project_areas: state.project_areas,
  };
}

const Area = (props) => {
  const {
    values: { id },
    project_areas,
    project_id,
  } = props;

  useEffect(() => {
    props.getProjectAreas(project_id);
  }, []);

  const number = 0;

  const showProjectAreas = _.filter(project_areas, (pa) => pa.id == id || !pa.has_qa_site_site_installation);

  const onNextClick = () => {
    const { errors, handleSubmit, toggleTab } = props;

    if (_.isNotEmpty(errors)) {
      handleSubmit();
    } else {
      toggleTab(number + 1);
    }
  };

  return (
    <Block>
      <BlockTitle>
        <h5>Area Covered By this report</h5>
      </BlockTitle>

      <List>
        <Field
          slot="list"
          label="Area"
          component={SmartSelect}
          placeholder="Select Area"
          name="project_area_id"
          options={showProjectAreas}
          validate={required}
        />

        <div slot="list" className="grid grid-cols-4">
          <div>
            <ListItem title="Level" />
          </div>
          <div>
            <Field component={InputField} name="level_from" placeholder="From" />
          </div>
          <div>
            <ListItem title="To" />
          </div>
          <div>
            <Field component={InputField} name="level_to" placeholder="To" />
          </div>
        </div>

        <div slot="list" className="grid grid-cols-4">
          <div>
            <ListItem title="Grid" />
          </div>
          <div>
            <Field component={InputField} name="grid_from" placeholder="From" />
          </div>
          <div>
            <ListItem title="To" />
          </div>
          <div>
            <Field component={InputField} name="grid_to" placeholder="To" />
          </div>
        </div>

        <Field slot="list" component={InputField} label="Other Area Details" name="other_area_name" placeholder="Enter here..." type="textarea" />
      </List>
    </Block>
  );
};

export default connect(mapStateToProps, { getProjectAreas })(Area);
