import axios from "axios";

const prepareData = (data) => {return {project_qa_welding_inspections_welding_process:data}}

// Plural Actions
export const GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCESSES = "get_project_qa_welding_inspections_welding_processes";
export const getProjectQaWeldingInspectionsWeldingProcesses = () => {
	const url = `/project/qa/welding_inspections/welding_processes.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCESSES,
		payload: request
	};
};


// Singular Actions
export const CREATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCESS = "create_project_qa_welding_inspections_welding_process";
export const createProjectQaWeldingInspectionsWeldingProcess = (data) => {
	const url = `/project/qa/welding_inspections/welding_processes.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCESS,
		payload: request
	};
};

export const GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCESS = "get_project_qa_welding_inspections_welding_process";
export const getProjectQaWeldingInspectionsWeldingProcess = (id) => {
	const url = `/project/qa/welding_inspections/welding_processes/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCESS,
		payload: request
	};
};

export const UPDATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCESS = "update_project_qa_welding_inspections_welding_process";
export const updateProjectQaWeldingInspectionsWeldingProcess = (id,data) => {
	const url = `/project/qa/welding_inspections/welding_processes/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCESS,
		payload: request
	};
};

export const DESTROY_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCESS = "destroy_project_qa_welding_inspections_welding_process";
export const destroyProjectQaWeldingInspectionsWeldingProcess = (id) => {
	const url = `/project/qa/welding_inspections/welding_processes/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCESS,
		payload: request
	};
};
