import React, { Fragment, useState } from "react";
import { isPersisted, clearPersisted, getPersisted } from "modules/4_final_form/PersistDecorator";

import { Block, Button } from "framework7-react";

export const handleNewClick = (f7router, name, url, resumeString = "Resume previous?") => {
  isPersisted(name).then((values) => {
    const isPersisted = !!values;
    if (isPersisted) {
      // temp Rename Buttons on F7router
      f7router.app.params.dialog.buttonOk = "Yes";
      f7router.app.params.dialog.buttonCancel = "No";

      f7router.app.dialog.confirm(
        resumeString,
        () => {
          f7router.navigate(url);
        },
        () => {
          // When clicked cancel
          clearPersisted(name).then(() => {
            f7router.navigate(url);
          });
        }
      );
      f7router.app.params.dialog.buttonOk = "Ok";
      f7router.app.params.dialog.buttonCancel = "Cancel";
    } else {
      f7router.navigate(url);
    }
  });
};

export const ResumePersistedButton = (props) => {
  const { name, url, resumeString } = props;
  const [hasUnsaved, setHasUnsaved] = useState(false);
  isPersisted(name).then((values) => {
    setHasUnsaved(!!values);
  });

  return (
    <Fragment>
      {hasUnsaved && (
        <Block>
          <p>
            <Button fill href={url}>
              {resumeString}
            </Button>
          </p>
          {/* <p><Button fill color="red" onClick={() => {
            app.dialog.confirm("Are you sure?", () => {
              clearPersisted(name).then(() => {
                setHasUnsaved(false);
              })
            })
          }}>Delete unsaved</Button></p> */}
        </Block>
      )}
    </Fragment>
  );
};

ResumePersistedButton.defaultProps = {
  resumeString: "Resume previous?",
};
