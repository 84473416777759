import { 
	GET_PROJECT_QA_SITE_SURVEILLANCES, 
	GET_PROJECT_QA_SITE_SURVEILLANCE,
	CREATE_PROJECT_QA_SITE_SURVEILLANCE,
	UPDATE_PROJECT_QA_SITE_SURVEILLANCE,
	DESTROY_PROJECT_QA_SITE_SURVEILLANCE,
} from "./actions"

export function ProjectQaSiteSurveillancesReducer(state = {}, action) {
	switch (action.type) {
		case `${GET_PROJECT_QA_SITE_SURVEILLANCES}_FULFILLED`:{
			return _.mapKeys(action.payload.data, "id");
		}
		case `${GET_PROJECT_QA_SITE_SURVEILLANCE}_FULFILLED`:
		case `${CREATE_PROJECT_QA_SITE_SURVEILLANCE}_FULFILLED`:
		case `${UPDATE_PROJECT_QA_SITE_SURVEILLANCE}_FULFILLED`:{
			let newState = _.clone(state);
			const newItem = action.payload.data;
			const id = _.get(newItem,"id")
			newState[id] = newItem
			return newState;
		}		
		case `${DESTROY_PROJECT_QA_SITE_SURVEILLANCE}_FULFILLED`:{
			let newState = _.clone(state);
			const deletedItem = action.payload.data;
			const id = _.get(deletedItem,"id")
			newState = _.filter(newState,i => i.id != id)
			return newState;
		}
		default:
			return state;
	}
}

// Move this to the reducer page
// import { ProjectQaSiteSurveillancesReducer } from "modules/project/qa/site/surveillances/reducer"

// project_qa_site_surveillances:ProjectQaSiteSurveillancesReducer,
