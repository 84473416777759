import React, { Fragment } from "react";
import { Field, useForm } from "react-final-form";
import { OnChange } from "react-final-form-listeners";

// Web Final Form Components
import {
  FormGroup,
  Label,
  Col,
  PopoverBody,
  Button,
  UncontrolledTooltip,
  UncontrolledPopover,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import {
  InputField,
  RadioButtons as WebRadioButtons,
  ToggleField,
  required,
  ReactDropzone as WebReactDropzone,
  AccordionArray,
  ReactSelect,
} from "modules/4_final_form/web";

// Mobile Final Form Components
import {
  RadioButtons as MobileRadioButtons,
  InputField as MobileInputField,
  ReactDropzone as MobileReactDropzone,
  ListArray,
  SmartSelect,
} from "modules/4_final_form/mobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faExclamationCircle, faPlus, faWrench } from "@fortawesome/free-solid-svg-icons";
import { Block, List } from "framework7-react";
import { FieldArray } from "react-final-form-arrays";
import _ from "lodash";
import { useSelector } from "react-redux";

export const YesNoNAFieldAttributes = (props) => {
  const { fieldName, namesUsed } = props;
  const form = useForm();
  const { values } = form.getState();
  const thisField = _.get(values, fieldName);

  return (
    <Fragment>
      <FormGroup row>
        <Label md={3}>Label</Label>
        <Col md={8}>
          <Field name={`${fieldName}.label`} component={InputField} />
          <OnChange name={`${fieldName}.label`}>
            {(value, previous) => {
              if (value) {
                const name = _.snakeCase(value);
                form.change(`${fieldName}.name`, name);
              }
            }}
          </OnChange>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>Name</Label>
        <Col md={8}>
          <Field
            name={`${fieldName}.name`}
            component={InputField}
            validate={(value, values) => {
              if (!value) {
                return "is required";
              }
              if (namesUsed.includes(value)) {
                return "must be unique";
              }
            }}
            convertToLowerCase
            lettersOnly
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>Placeholder</Label>
        <Col md={8}>
          <Field name={`${fieldName}.placeholder`} component={InputField} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>On Dashboard</Label>
        <Col md={3}>
          <Field name={`${fieldName}.on_dashboard`} component={ToggleField} />
        </Col>
        <Label md={3}>On Report</Label>
        <Col md={3}>
          <Field name={`${fieldName}.on_report`} component={ToggleField} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>Can Add Comments</Label>
        <Col md={3}>
          <Field name={`${fieldName}.can_add_comments`} component={ToggleField} />
        </Col>
        {thisField?.can_add_comments && (
          <Fragment>
            <Label md={3}>Show comments at the end</Label>
            <Col md={3}>
              <Field name={`${fieldName}.show_comments_at_the_end`} component={ToggleField} />
            </Col>
          </Fragment>
        )}
      </FormGroup>
      {values?.parent_type == "V2::Maintenance::Category" && (
        <FormGroup row>
          <Label md={3}>Can add Maintenance issues</Label>
          <Col md={3}>
            <Field name={`${fieldName}.can_add_maintenance_issues`} component={ToggleField} />
          </Col>
        </FormGroup>
      )}
      <FormGroup row>
        <Label md={3}>Can add attachments</Label>
        <Col md={3}>
          <Field name={`${fieldName}.can_add_attachments`} component={ToggleField} />
        </Col>
        {thisField?.can_add_attachments && (
          <Fragment>
            <Label md={3}>Attachments on report</Label>
            <Col md={3}>
              <Field name={`${fieldName}.attachments_on_report`} component={ToggleField} />
            </Col>
          </Fragment>
        )}
      </FormGroup>
    </Fragment>
  );
};

export const YesNoNAFieldForm = (props) => {
  const { label, placeholder, preview, name, prefixName, can_add_comments, can_add_maintenance_issues } = props;

  const form = useForm();
  const { values } = form.getState();

  return (
    <FormGroup row>
      {label && (
        <Label md={2}>
          {label}
          {required && "*"}
        </Label>
      )}
      <Col md={4}>
        <Field
          name={`${prefixName}.${name}`}
          disabled={preview}
          component={WebRadioButtons}
          options={[
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" },
            { label: "N/A", value: "N/A" },
          ]}
          placeholder={placeholder}
          validate={preview ? null : required}
        >
          {can_add_comments && <WebCommentsButton form={form} values={values} {...props} />}
          {can_add_maintenance_issues && <WebMaintenanceIssuesModal {...props} />}
        </Field>
      </Col>
      {can_add_comments && <WebCommentsField form={form} {...props} />}
    </FormGroup>
  );
};
YesNoNAFieldForm.defaultProps = {
  prefixName: "data",
};

const WebCommentsButton = (props) => {
  const { values, name } = props;
  const hasComments = _.get(values, `comments.${name}`);

  let commentsIcon = faPlus;
  let commentsIconColor = "black";
  if (hasComments) {
    commentsIcon = faComment;
    commentsIconColor = "green";
  }

  return (
    <Fragment>
      <Button color="link" id={`popover-${name}`} type="button">
        <FontAwesomeIcon icon={commentsIcon} color={commentsIconColor} />
      </Button>
      <UncontrolledTooltip placement="bottom" target={`popover-${name}`}>
        {hasComments ? "Edit comments" : "Add Comments"}
      </UncontrolledTooltip>
    </Fragment>
  );
};

const WebCommentsField = (props) => {
  const { label, placeholder, name, can_add_attachments } = props;

  return (
    <UncontrolledPopover placement="bottom" target={`popover-${name}`} trigger="legacy" style={{ width: "500px" }}>
      <PopoverBody>
        <Field
          name={`comments.${name}`}
          component={InputField}
          type="textarea"
          label={`${label} Comments`}
          placeholder={placeholder || "Enter comments here"}
        />
        <hr />
        {can_add_attachments && (
          <FieldArray
            name={`attachments_attributes`}
            component={WebReactDropzone}
            label={`${label} Attachments`}
            placeholder={placeholder || "Upload attachments here"}
            addProps={{ field_name: name }}
            field_name={name}
            filterBy="field_name"
          />
        )}
      </PopoverBody>
    </UncontrolledPopover>
  );
};

const WebMaintenanceIssuesModal = (props) => {
  const { name } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const { values, errors } = useForm().getState();

  const findErrors = _.get(errors, "maintenance_item_issues_attributes");
  const hasErrors = findErrors ? findErrors.length > 0 : false;
  const current_user = useSelector((state) => state.current_user);

  return (
    <Fragment>
      <Button
        color="danger"
        id={`popover-${name}-issues`}
        type="button"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <FontAwesomeIcon icon={faWrench} />
      </Button>
      <UncontrolledTooltip placement="bottom" target={`popover-${name}-issues`}>
        Add Maintenance Issues
      </UncontrolledTooltip>

      <Modal
        size="lg"
        isOpen={isOpen}
        toggle={() => {
          hasErrors ? null : setIsOpen(!isOpen);
        }}
      >
        <ModalHeader>Maintenance Issues</ModalHeader>
        <ModalBody>
          <FieldArray
            component={AccordionArray}
            name="maintenance_item_issues_attributes"
            label="Issue"
            titleField="description"
            columns={[
              {
                label: "Description",
                name: "description",
                component: InputField,
                type: "textarea",
                validate: required,
                labelWidth: "2",
                fieldWidth: "10",
              },
              {
                label: "Priority",
                name: "priority",
                component: ReactSelect,
                options: [
                  { label: "High", value: "High" },
                  { label: "Medium", value: "Medium" },
                  { label: "Low", value: "Low" },
                ],
                defaultValue: "Medium",
                validate: required,
                labelWidth: "2",
                fieldWidth: "10",
              },
            ]}
            addProps={{ item_id: values.parent_id, date: moment(), person: current_user?.name, issue_status_id: 1, fieldName: name }}
            filterBy={name}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="success" disabled={hasErrors} onClick={() => setIsOpen(false)}>
            Done
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export const MobileYesNoNAFieldForm = (props) => {
  const { label, placeholder, preview, name, prefixName, can_add_comments, can_add_attachments, can_add_maintenance_issues } = props;

  const { values } = useForm().getState();
  const footerLabel = _.get(values, `comments.${name}`) ? "With Comments" : "";
  const current_user = useSelector((state) => state.current_user);

  return (
    <Field
      name={`${prefixName}.${name}`}
      component={MobileRadioButtons}
      label={label}
      options={[
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
        { label: "N/A", value: "N/A" },
      ]}
      slot="list"
      validate={preview ? null : required}
      footer={footerLabel}
      openIn={can_add_attachments || can_add_maintenance_issues ? "popup" : "popover"}
    >
      {can_add_maintenance_issues && (
        <Block>
          <FieldArray
            component={ListArray}
            name="maintenance_item_issues_attributes"
            label="Maintenance Issues"
            itemLabel="Issue"
            buttonLabel="Add Issue"
            titleField="description"
            columns={[
              {
                label: "Description",
                name: "description",
                component: MobileInputField,
                type: "textarea",
                validate: required,
                labelWidth: "2",
                fieldWidth: "10",
              },
              {
                label: "Priority",
                name: "priority",
                component: SmartSelect,
                options: [
                  { label: "High", value: "High" },
                  { label: "Medium", value: "Medium" },
                  { label: "Low", value: "Low" },
                ],
                defaultValue: "Medium",
                validate: required,
                valueName: "value",
                labelName: "label",
                labelWidth: "2",
                fieldWidth: "10",
              },
            ]}
            addProps={{ item_id: values.parent_id, date: moment(), person: current_user?.name, issue_status_id: 1, fieldName: name }}
            filterBy={name}
          />
        </Block>
      )}
      {can_add_comments && (
        <List>
          <Field
            name={`comments.${name}`}
            component={MobileInputField}
            type="textarea"
            slot="list"
            label={`${label} Comments`}
            placeholder={placeholder || "Enter comments here"}
          />
        </List>
      )}
      {can_add_attachments && (
        <Block>
          <FieldArray
            name={`attachments_attributes`}
            component={MobileReactDropzone}
            label={`${label} Attachments`}
            placeholder={placeholder || "Upload attachments here"}
            addProps={{ field_name: name }}
            field_name={name}
            filterBy="field_name"
          />
        </Block>
      )}
    </Field>
  );
};
MobileYesNoNAFieldForm.defaultProps = {
  prefixName: "data",
};
