import React from "react";
import { required, InputField, ReactSelect, ToggleField, ColorPicker } from "modules/4_final_form/web";

import { FormGroup, Label, Row, Col, Button } from "reactstrap";

import { Field } from "react-final-form";

import { StatusTypes } from "modules/project/communications/shared.js";
import { OnChange } from "react-final-form-listeners";

const ProjectCommunicationsStatusForm = (props) => {
  const {
    handleSubmit,
    formLabel,
    cancelFunction,
    form: { change },
  } = props;

  return (
    <form>
      <FormGroup row>
        <Label md={2}>Name</Label>
        <Col md={4}>
          <Field component={InputField} name="name" type="string" parse={(value) => value} validate={required} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={2}>Type</Label>
        <Col md={4}>
          <Field component={ReactSelect} name="type_string" options={StatusTypes} validate={required} />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md={2}>Colour</Label>
        <Col md={4}>
          <Field component={ColorPicker} name="color" clearButton />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md={2}>Completes Communication</Label>
        <Col md={4}>
          <Field component={ToggleField} name="completes" />
          <OnChange name="completes">
            {(value, previous) => {
              if (value == true) {
                change("voids", false);
              }
            }}
          </OnChange>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md={2}>Voids Communication</Label>
        <Col md={4}>
          <Field component={ToggleField} name="voids" />
          <OnChange name="voids">
            {(value, previous) => {
              if (value == true) {
                change("completes", false);
              }
            }}
          </OnChange>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md={2}>Default</Label>
        <Col md={4}>
          <Field component={ToggleField} name="default" />
        </Col>
      </FormGroup>

      <Row>
        <Col>
          <Button color="success" onClick={handleSubmit}>
            {formLabel} Statuses
          </Button>
        </Col>
        <Col className="text-right">
          <Button onClick={cancelFunction} className="float-right" color="danger">
            Cancel
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default ProjectCommunicationsStatusForm;
