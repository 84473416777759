import React, { Fragment } from "react";
import { Field, useForm } from "react-final-form";
import { FormGroup, Label, Col } from "reactstrap";
import { FieldArray } from "react-final-form-arrays";

import { InputField, ToggleField, required, ReactDropzone } from "modules/4_final_form/web";
import { ReactDropzone as MobileReactDropzone } from "modules/4_final_form/mobile";
import { OnChange } from "react-final-form-listeners";
import { Block } from "framework7-react";

export const AttachmentsAttributes = ({ fieldName, namesUsed }) => {
  const form = useForm();

  return (
    <Fragment>
      <FormGroup row>
        <Label md={3}>Label</Label>
        <Col md={8}>
          <Field name={`${fieldName}.label`} component={InputField} />
          <OnChange name={`${fieldName}.label`}>
            {(value, previous) => {
              if (value) {
                const name = _.snakeCase(value);
                form.change(`${fieldName}.name`, name);
              }
            }}
          </OnChange>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>Name</Label>
        <Col md={8}>
          <Field
            name={`${fieldName}.name`}
            component={InputField}
            validate={(value, values) => {
              if (!value) {
                return "is required";
              }
              if (namesUsed.includes(value)) {
                return "must be unique";
              }
            }}
            convertToLowerCase
            lettersOnly
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>Placeholder</Label>
        <Col md={8}>
          <Field name={`${fieldName}.placeholder`} component={InputField} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>Max Files</Label>
        <Col md={8}>
          <Field name={`${fieldName}.max`} component={InputField} type="number" placeholder="Leave blank for unlimited" numbersOnly />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>Required Field</Label>
        <Col md={8}>
          <Field name={`${fieldName}.required`} component={ToggleField} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>On Dashboard</Label>
        <Col md={8}>
          <Field name={`${fieldName}.on_dashboard`} component={ToggleField} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>On Report</Label>
        <Col md={8}>
          <Field name={`${fieldName}.on_report`} component={ToggleField} />
        </Col>
      </FormGroup>
    </Fragment>
  );
};

export const AttachmentsForm = (props) => {
  const { label, placeholder, required: isRequired, preview, name, max } = props;

  return (
    <FormGroup row>
      {label && (
        <Label md={12}>
          {label}
          {required && "*"}
        </Label>
      )}
      <Col md={12}>
        <FieldArray
          name="attachments_attributes"
          disabled={preview}
          component={ReactDropzone}
          placeholder={placeholder}
          validate={isRequired && !preview ? required : null}
          addProps={{ field_name: name }}
          field_name={name}
          filterBy="field_name"
          maxFiles={max}
          uploadNow
        />
      </Col>
    </FormGroup>
  );
};

export const MobileAttachmentsForm = (props) => {
  const { label, placeholder, required: isRequired, preview, name, max } = props;

  return (
    <Block className="margin-bottom">
      <label>{label}</label>
      <FieldArray
        name="attachments_attributes"
        disabled={preview}
        component={MobileReactDropzone}
        placeholder={placeholder}
        validate={isRequired && !preview ? required : null}
        addProps={{ field_name: name }}
        field_name={name}
        filterBy="field_name"
        maxFiles={max}
        uploadNow
      />
    </Block>
  );
};
