import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Card,CardHeader, CardBody} from "reactstrap"

const mapStateToProps = (state) => ({});

export class QAIndex extends Component {
    render() {
        return (
            <Card>
                <CardHeader>Quality Assurance</CardHeader>
                <CardBody>Welcome to Lion V3 QA Module, please use the menu on the left</CardBody>
            </Card>
        )
    }
}

export default connect(mapStateToProps, {})(QAIndex)
