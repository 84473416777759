import React from 'react';

const TrainingBooking = React.lazy(() => import("./index"))
const NewTrainingBooking = React.lazy(() => import("./new"))
const EditTrainingBooking = React.lazy(() => import("./edit"))

export default[
	{path:'/training/bookings/:id/edit',component: EditTrainingBooking,name:'Edit TrainingBooking'},
	{path:'/training/bookings/new',component: NewTrainingBooking,name:'New TrainingBooking'},  
	{path:'/training/bookings',component: TrainingBooking,name:'TrainingBooking'}, 
]

// import TrainingBookingRoutes from "modules/training/bookings/web/routes"
// routes = _.concat(routes,TrainingBookingRoutes)
