import React from 'react';

const ProjectArea = React.lazy(() => import("./index"))
const NewProjectArea = React.lazy(() => import("./new"))
const EditProjectArea = React.lazy(() => import("./edit"))

export default[
	{path:'/project/:project_id/areas/:id/edit',component: EditProjectArea,name:'Edit ProjectArea'},
	{path:'/project/:project_id/areas/new',component: NewProjectArea,name:'New ProjectArea'},  
]

// import ProjectAreaRoutes from "modules/project/areas/web/routes"
// routes = _.concat(routes,ProjectAreaRoutes)
