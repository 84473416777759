import React from 'react';

const Projects = React.lazy(() => import("./index"))
const NewProjects = React.lazy(() => import("./new"))
const EditProjects = React.lazy(() => import("./edit"))
const ShowProjects = React.lazy(() => import("./show"))


export default[
	{path:'/projects/:id/show',component: ShowProjects,name:'Show Project'},
	{path:'/projects/:id/edit',component: EditProjects,name:'Edit Project'},
	{path:'/projects/new',component: NewProjects,name:'New Project'},  
	{path:'/projects',component: Projects,name:'Projects'}, 
]

// import ProjectsRoutes from "modules/projects/web/routes"
// routes = _.concat(routes,ProjectsRoutes)
