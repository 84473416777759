import axios from "axios";

const prepareData = (data) => {return {project_qa_action_items_category:data}}

// Plural Actions
export const GET_PROJECT_QA_ACTION_ITEMS_CATEGORIES = "get_project_qa_action_items_categories";
export const getProjectQaActionItemsCategories = () => {
	const url = `/project/qa/action_items/categories.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_ACTION_ITEMS_CATEGORIES,
		payload: request
	};
};


// Singular Actions
export const CREATE_PROJECT_QA_ACTION_ITEMS_CATEGORY = "create_project_qa_action_items_category";
export const createProjectQaActionItemsCategory = (data) => {
	const url = `/project/qa/action_items/categories.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PROJECT_QA_ACTION_ITEMS_CATEGORY,
		payload: request
	};
};

export const GET_PROJECT_QA_ACTION_ITEMS_CATEGORY = "get_project_qa_action_items_category";
export const getProjectQaActionItemsCategory = (id) => {
	const url = `/project/qa/action_items/categories/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_ACTION_ITEMS_CATEGORY,
		payload: request
	};
};

export const UPDATE_PROJECT_QA_ACTION_ITEMS_CATEGORY = "update_project_qa_action_items_category";
export const updateProjectQaActionItemsCategory = (id,data) => {
	const url = `/project/qa/action_items/categories/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PROJECT_QA_ACTION_ITEMS_CATEGORY,
		payload: request
	};
};

export const DESTROY_PROJECT_QA_ACTION_ITEMS_CATEGORY = "destroy_project_qa_action_items_category";
export const destroyProjectQaActionItemsCategory = (id) => {
	const url = `/project/qa/action_items/categories/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PROJECT_QA_ACTION_ITEMS_CATEGORY,
		payload: request
	};
};
