import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { getProjectAreas } from "modules/project/areas/actions";
import { getUserManagements } from "modules/user_managements/actions.js";
import { getProjectQaActionItemsStatuses } from "modules/project/qa/action_items/statuses/actions";
import { Toolbar, Link, Tabs, Tab, Page, f7, Popover, List, Button, Icon } from "framework7-react";
import Area from "./form/0_0_Area";
import MarkedUpDrawings from "./form/0_0_MarkedUpDrawings";
import ChecklistItemsAttributes from "./form/ChecklistItemsAttributes";
import { FieldArray } from "react-final-form-arrays";

function mapStateToProps(state) {
  return {
    project_areas: state.project_areas,
    current_user: state.current_user,
    users: state.users,
    project_qa_action_items_statuses: state.project_qa_action_items_statuses,
  };
}

const ProjectQaSiteSiteInstallationForm = (props) => {
  const { f7route, project_id, values, project_areas, getUserManagements, getProjectQaActionItemsStatuses } = props;

  useEffect(() => {
    getUserManagements();
    getProjectQaActionItemsStatuses();
  }, []);

  const { project_area_id, checklist_item_links_attributes } = values;
  const project_area = project_areas[project_area_id];
  const checklist_items = project_area?.checklist_items;
  const sorted_checklist_items = _.sortBy(checklist_items, "order");
  const tab = _.get(f7route, "query.tab");

  useEffect(() => {
    // Change to the tab from the url
    f7.tab.show(`#tab-${tab}`);
  }, [tab]);

  return (
    <Page>
      <Toolbar tabbar bottom>
        <Link tabLink="#tab-drawings" tabLinkActive>
          Area / Drawings
        </Link>

        {sorted_checklist_items.length >= 2 ? (
          <Link popoverOpen=".popover-menu">
            <Icon f7="ellipsis" />
          </Link>
        ) : (
          <Fragment>
            {_.map(sorted_checklist_items, (ci, i) => {
              const findValues = _.find(checklist_item_links_attributes, (vci) => vci.checklist_item_id == ci.id);

              return <Link tabLink={`#tab-${ci.id}`}>{ci.name}</Link>;
            })}
          </Fragment>
        )}
      </Toolbar>
      <form>
        <Tabs>
          <Tab id="tab-drawings" tabActive>
            <Area {...props} />
            <MarkedUpDrawings {...props} />
          </Tab>
          <FieldArray
            name="checklist_item_links_attributes"
            sorted_checklist_items={sorted_checklist_items}
            project_area_id={project_area_id}
            component={ChecklistItemsAttributes}
            {...props}
          />
        </Tabs>
      </form>

      <Popover className="popover-menu" backdrop={false}>
        <List>
          {_.map(sorted_checklist_items, (ci, i) => {
            return (
              <Button popoverClose key={i} tabLink={`#tab-${ci.id}`}>
                {ci.name}
              </Button>
            );
          })}
        </List>
      </Popover>
    </Page>
  );
};

export default connect(mapStateToProps, {
  getProjectAreas,
  getUserManagements,
  getProjectQaActionItemsStatuses,
})(ProjectQaSiteSiteInstallationForm);
