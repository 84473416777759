import {
  GET_HIREAGES_EXTERNALS,
  GET_HIREAGES_EXTERNAL,
  CREATE_HIREAGES_EXTERNAL,
  UPDATE_HIREAGES_EXTERNAL,
  DESTROY_HIREAGES_EXTERNAL,
} from "./actions";

export function HireagesExternalReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_HIREAGES_EXTERNALS}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    case `${GET_HIREAGES_EXTERNAL}_FULFILLED`:
    case `${CREATE_HIREAGES_EXTERNAL}_FULFILLED`:
    case `${UPDATE_HIREAGES_EXTERNAL}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_HIREAGES_EXTERNAL}_FULFILLED`: {
      let newState = _.clone(state);
      const {
        meta: { id },
      } = action;
      _.unset(newState, id);
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { HireagesExternalReducer } from "modules/hireages/externals/reducer"

// hireages_externals:HireagesExternalReducer,
