import React, { Component } from "react";
import { connect } from "react-redux";
import ProjectForm from "./form";
import { updateProject, getProject, destroyProject } from "../actions";
import { Form } from "react-final-form";
import NotAuthorized from "modules/5_view_helpers/web/NotAuthorized";
import HasRole from "modules/5_view_helpers/HasRole";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

function mapStateToProps(state, ownProps) {
  const { project_id } = ownProps;
  return {
    current_user: state.current_user,
    project: state.projects[project_id],
  };
}

class EditProjectSliding extends Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.successfullySubmitted = this.successfullySubmitted.bind(this);

    const updateRole = HasRole({
      resource_type: "Project",
      name: ["update", "moderator"],
    });

    this.state = {
      isOpen: this.props.isOpen,
      updateRole: updateRole,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const prevIsOpen = prevState.isOpen;
    const { isOpen } = this.props;

    if (prevIsOpen != isOpen) {
      this.setState({ isOpen: isOpen });
    }
  }

  submitForm(values) {
    const { project_id } = this.props;
    const createRequest = this.props.updateProject(project_id, values);
    createRequest.then(this.successfullySubmitted);
  }

  successfullySubmitted() {
    const { toggle } = this.props;
    toggle();
    toastr.success("Updated Project");
  }

  render() {
    const { isOpen, updateRole } = this.state;
    const { toggle, project } = this.props;

    if (!updateRole) {
      return <NotAuthorized />;
    }

    return (
      <SlidingPane isOpen={isOpen} onRequestClose={toggle} title="Update Project" width="80%">
        <Form component={ProjectForm} formLabel="Update" initialValues={project} onSubmit={this.submitForm} cancelFunction={toggle} {...this.props} />
      </SlidingPane>
    );
  }
}

export default connect(mapStateToProps, { updateProject })(EditProjectSliding);
