import React from "react";

import ProjectQaSiteSiteInstallationEdit from "./edit.jsx";
import ProjectQaSiteSiteInstallationNew from "./new.jsx";
import ProjectQaSiteSiteInstallationDashboard from "./dashboard.jsx";
import ProjectQaSiteSiteInstallationIndex from "./index.jsx";

export default [
  {
    path: "/project/:project_id/qa/site/site_installations/:id/edit",
    component: ProjectQaSiteSiteInstallationEdit,
    name: "Edit ProjectQaSiteSiteInstallation",
  },
  {
    path: "/project/:project_id/qa/site/site_installations/new",
    component: ProjectQaSiteSiteInstallationNew,
    name: "New ProjectQaSiteSiteInstallation",
  },
  {
    path: "/project/qa/site/site_installations/dashboard",
    component: ProjectQaSiteSiteInstallationDashboard,
    name: "Site Installation QA Dashboard",
  },
  {
    path: "/project/qa/site/site_installations",
    component: ProjectQaSiteSiteInstallationIndex,
    name: "Site Installation QA Index",
  },
];

// import ProjectsRoutes from "modules/projects/web/routes"
// routes = _.concat(routes,ProjectsRoutes)
