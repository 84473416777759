import { 
	GET_EMPLOYEES, 
	GET_EMPLOYEE,
	CREATE_EMPLOYEE,
	UPDATE_EMPLOYEE,
} from "./actions"

export function EmployeesReducer(state = {}, action) {
	switch (action.type) {
		case `${GET_EMPLOYEES}_FULFILLED`:{
			if(_.isNotEmpty(state)){
				const newState = _.clone(state)
				return _.merge(newState,_.mapKeys(action.payload.data, "id"))
			}
			else{
				return _.mapKeys(action.payload.data, "id");
			}
		}
		case `${GET_EMPLOYEE}_FULFILLED`:
		case `${CREATE_EMPLOYEE}_FULFILLED`:
		case `${UPDATE_EMPLOYEE}_FULFILLED`:{
			let newState = _.clone(state);
			const newItem = action.payload.data;
			const id = _.get(newItem,"id")
			newState[id] = newItem
			return newState;
		}
		default:
			return state;
	}
}

// Move this to the reducer page
// import { EmployeesReducer } from "modules/employees/reducer"

// employees:EmployeesReducer,
