import axios from "axios";

const prepareData = (data) => {
  return { training_company: data };
};

// Plural Actions
export const GET_TRAINING_COMPANIES = "get_training_companies";
export const getTrainingCompanies = () => {
  const url = "/training/companies.json";
  const request = axios.get(url);
  return {
    type: GET_TRAINING_COMPANIES,
    payload: request,
  };
};

// Singular Actions
export const CREATE_TRAINING_COMPANY = "create_training_company";
export const createTrainingCompany = (data) => {
  const url = "/training/companies.json";
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_TRAINING_COMPANY,
    payload: request,
  };
};

export const GET_TRAINING_COMPANY = "get_training_company";
export const getTrainingCompany = (id) => {
  const url = `/training/companies/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_TRAINING_COMPANY,
    payload: request,
  };
};

export const UPDATE_TRAINING_COMPANY = "update_training_company";
export const updateTrainingCompany = (id, data) => {
  const url = `/training/companies/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_TRAINING_COMPANY,
    payload: request,
  };
};

export const DESTROY_TRAINING_COMPANY = "destroy_training_company";
export const destroyTrainingCompany = (id) => {
  const url = `/training/companies/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_TRAINING_COMPANY,
    payload: request,
    meta: { id: id },
  };
};
