import React, { Fragment } from "react";

import { Button } from "reactstrap";

const QuickInsertTextArea = (props) => {
  const {
    input: { value, onChange },
    meta: { error },
    snippets,
    snippetLabel,
    rows,
  } = props;

  return (
    <Fragment>
      <textarea style={{ width: "100%" }} rows={rows} onChange={onChange} value={value} />
      <div>{error && <div className="text-danger">{error}</div>}</div>

      <small>{snippetLabel}</small>
      <div>
        {_.map(snippets, (snippet, i) => {
          return (
            <Button
              key={i}
              size="sm"
              className="mr-1 mb-1"
              onClick={(e) => {
                e.preventDefault();
                let newValue = _.clone(value);
                newValue += ` ${snippet.value}`;
                onChange(newValue);
              }}
            >
              {snippet?.label}
            </Button>
          );
        })}
      </div>
    </Fragment>
  );
};

QuickInsertTextArea.defaultProps = {
  snippetLabel: "Add Inputs",
  rows: 5,
};

export default QuickInsertTextArea;
