import React from "react";

import { ListItem, Toggle } from "framework7-react";

const ToggleField = (props) => {
  const {
    input: { onChange, value },
    color,
    label,
    disabled,
  } = props;

  return (
    <ListItem>
      <span>{label}</span>
      <Toggle
        onChange={(nv) => {
          props.input.onChange(!nv.target.checked)
        }}
        disabled={disabled}
        color={color}
        checked={value}
      />
    </ListItem>
  );
};

export default ToggleField;
