import axios from "axios";
import jsonFormData from "json-form-data";

const prepareData = (data) => {
  return jsonFormData(
    { employee_qualification: data },
    { showLeafArrayIndexes: true }
  );
};

// Plural Actions
export const GET_ALL_EMPLOYEE_QUALIFICATIONS =
  "get_all_employee_qualifications";
export const getAllQualifications = () => {
  const url = `/employee/qualifications/get_all.json`;
  const request = axios.get(url);
  return {
    type: GET_ALL_EMPLOYEE_QUALIFICATIONS,
    payload: request,
  };
};

export const GET_EMPLOYEE_QUALIFICATIONS = "get_employee_qualifications";
export const getQualifications = (employee_id) => {
  const url = `/employee/${employee_id}/qualifications.json`;
  const request = axios.get(url);
  return {
    type: GET_EMPLOYEE_QUALIFICATIONS,
    payload: request,
  };
};

// Singular Actions
export const CREATE_EMPLOYEE_QUALIFICATION = "create_employee_qualification";
export const createQualification = (data, employee_id) => {
  const url = `/employee/${employee_id}/qualifications.json`;
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_EMPLOYEE_QUALIFICATION,
    payload: request,
  };
};

export const GET_EMPLOYEE_QUALIFICATION = "get_employee_qualification";
export const getQualification = (employee_id, id) => {
  const url = `/employee/${employee_id}/qualifications/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_EMPLOYEE_QUALIFICATION,
    payload: request,
  };
};

export const UPDATE_EMPLOYEE_QUALIFICATION = "update_employee_qualification";
export const updateQualification = (
  employee_id,
  id,
  data,
  disableSpinner = false
) => {
  const url = `/employee/${employee_id}/qualifications/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_EMPLOYEE_QUALIFICATION,
    payload: request,
    meta: { disableSpinner: disableSpinner },
  };
};

export const DESTROY_EMPLOYEE_QUALIFICATION = "destroy_employee_qualification";
export const destroyQualification = (employee_id, id) => {
  const url = `/employee/${employee_id}/qualifications/${id}.json  `;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_EMPLOYEE_QUALIFICATION,
    payload: request,
    meta: { id: id },
  };
};
