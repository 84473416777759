import React from 'react';

const ProjectQaWeldingInspectionsWeldingProceduresIndex = React.lazy(() => import("./index"))
const NewProjectQaWeldingInspectionsWeldingProcedures = React.lazy(() => import("./new"))
const EditProjectQaWeldingInspectionsWeldingProcedures = React.lazy(() => import("./edit"))

export default[
	{path:'/project/qa/welding_inspections/welding_procedures/:id/edit',component: EditProjectQaWeldingInspectionsWeldingProcedures,name:'Edit ProjectQaWeldingInspectionsWeldingProcedure'},
	{path:'/project/qa/welding_inspections/welding_procedures/new',component: NewProjectQaWeldingInspectionsWeldingProcedures,name:'New ProjectQaWeldingInspectionsWeldingProcedure'},  
	{path:'/project/qa/welding_inspections/welding_procedures',component: ProjectQaWeldingInspectionsWeldingProceduresIndex,name:'ProjectQaWeldingInspectionsWeldingProcedures'}, 
]

// import ProjectQaWeldingInspectionsWeldingProceduresRoutes from "modules/project/qa/welding_inspections/welding_procedures/web/routes"
// routes = _.concat(routes,ProjectQaWeldingInspectionsWeldingProceduresRoutes)
