import axios from "axios";

const prepareData = (data) => {return {project_qa_welding_inspections_inspection_spec:data}}

// Plural Actions
export const GET_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_SPECS = "get_project_qa_welding_inspections_inspection_specs";
export const getProjectQaWeldingInspectionsInspectionSpecs = () => {
	const url = `/project/qa/welding_inspections/inspection_specs.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_SPECS,
		payload: request
	};
};


// Singular Actions
export const CREATE_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_SPEC = "create_project_qa_welding_inspections_inspection_spec";
export const createProjectQaWeldingInspectionsInspectionSpec = (data) => {
	const url = `/project/qa/welding_inspections/inspection_specs.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_SPEC,
		payload: request
	};
};

export const GET_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_SPEC = "get_project_qa_welding_inspections_inspection_spec";
export const getProjectQaWeldingInspectionsInspectionSpec = (id) => {
	const url = `/project/qa/welding_inspections/inspection_specs/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_SPEC,
		payload: request
	};
};

export const UPDATE_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_SPEC = "update_project_qa_welding_inspections_inspection_spec";
export const updateProjectQaWeldingInspectionsInspectionSpec = (id,data) => {
	const url = `/project/qa/welding_inspections/inspection_specs/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_SPEC,
		payload: request
	};
};

export const DESTROY_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_SPEC = "destroy_project_qa_welding_inspections_inspection_spec";
export const destroyProjectQaWeldingInspectionsInspectionSpec = (id) => {
	const url = `/project/qa/welding_inspections/inspection_specs/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_SPEC,
		payload: request
	};
};
