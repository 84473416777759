import React, { Fragment } from "react";
import { Label, Input } from "reactstrap";

const InputField = (props) => {
  const {
    input: { value, onChange, onBlur, name, type },
    meta: { touched, error, warning },
    label,
    placeholder,
    readonly,
    disabled,
    bgColor,
    className,
    errorName,
    convertToUpperCase,
    convertToLowerCase,
    postiveOnly,
    lettersOnly,
    ...rest
  } = props;

  const handleOnKeyDown = (e) => {
    if (type == "number" && postiveOnly) {
      if (e.key == "+" || e.key == "-" || e.key == "e") {
        e.preventDefault();
      }
    }

    if (lettersOnly) {
      // Use regex to allow only alphabetic characters
      if (!e.key.match(/[a-z_]/i)) {
        e.preventDefault();
      }
    }
  };

  const handleChange = (e) => {
    let newValue = e.target.value;
    if (convertToUpperCase) {
      newValue = newValue.toUpperCase();
    } else if (convertToLowerCase) {
      newValue = newValue.toLowerCase();
    }
    if (postiveOnly && type == "number" && newValue.includes("-")) {
      newValue = Math.abs(newValue);
    }
    onChange(newValue);
  };

  return (
    <Fragment>
      {label && <Label>{label}</Label>}
      <Input
        onKeyDown={handleOnKeyDown}
        onChange={handleChange}
        onBlur={onBlur}
        type={type}
        placeholder={placeholder}
        readOnly={readonly}
        disabled={disabled}
        style={{ backgroundColor: bgColor }}
        className={className}
        name={name}
        value={value}
        {...rest}
      />
      {touched &&
        ((error && (
          <span className="text-danger">
            {label ? label : errorName ? errorName : name} {error}
          </span>
        )) ||
          (warning && <span className="text-warning">{warning}</span>))}
    </Fragment>
  );
};

export default InputField;
