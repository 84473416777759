import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";

// Helpers
import NotAuthorized from "modules/5_view_helpers/mobile/NotAuthorized";
import HasRole from "modules/5_view_helpers/HasRole";

import { getCustomForm, getCustomForms } from "modules/custom_forms/actions";

// Actions
import { getCustomFormRecords } from "../actions";

import { Page, List, ListItem, AccordionContent, Block, Button, Fab, Icon, Preloader } from "framework7-react";
import AppNavbar from "modules/1_layout/mobile/AppNavbar";
import { getColsAttributes } from "modules/5_view_helpers/web/FormBuilder";

import column_types from "modules/5_view_helpers/web/FormBuilder/column_types";
import SmartSelect from "modules/5_view_helpers/mobile/SmartSelect.jsx";
import { getProjects } from "modules/projects/actions";
import { getEmployees } from "modules/employees/actions";
import { getV2MaintenanceItems } from "modules/v2/maintenance/items/actions";

function mapStateToProps(state, ownProps) {
  // If we have a custom_form passed in, use that, otherwise, get it from the store
  const custom_form_id = ownProps.f7route?.params?.custom_form_id;

  return {
    custom_forms: state.custom_forms,
    custom_form_records: state.custom_form_records,
    projects: state.projects,
    employees: state.employees,
    v2_maintenance_items: state.v2_maintenance_items,
  };
}

const CustomFormRecordsIndex = (props) => {
  const [loading, setLoading] = useState(true);
  const {
    custom_forms,
    custom_form_records,
    employees,
    projects,
    f7route: { params, query },
    f7router,
    v2_maintenance_items,
  } = props;
  const { custom_form_id } = params;
  const { parent_id } = query;

  const custom_form = React.useMemo(() => custom_forms?.[custom_form_id], [custom_forms]);

  const [parentSelected, setParentSelected] = useState(parent_id ? parent_id : "");

  const columns = getColsAttributes(custom_form);
  const inputs = _.filter(columns, (col) => col.column_type != "text");

  useEffect(() => {
    setLoading(true);
    // If we don't have a custom_form, get it
    props.getCustomForms();
    const req = props.getCustomForm(custom_form_id);
    req.then(() => {
      props.getCustomFormRecords(custom_form_id).then(() => {
        setLoading(false);
      });
    });
  }, []);

  // Get parents
  useEffect(() => {
    if (custom_form && !loading) {
      if (custom_form?.parent_type === "Project") {
        props.getProjects();
      }
      if (custom_form?.parent_type === "Employee") {
        props.getEmployees();
      }
      if (custom_form?.parent_type == "CustomForm") {
        props.getCustomFormRecords(custom_form?.parent_id);
      }
      if (custom_form?.parent_type === "V2::Maintenance::Category") {
        props.getV2MaintenanceItems(custom_form?.parent_id);
      }
    }
  }, [custom_form, loading]);

  const readRole = HasRole({ resource_type: "CustomForm::Record", name: ["read", "moderator"] }, true);
  const createRole = HasRole({ resource_type: "CustomForm::Record", name: ["create", "moderator"] }, true);

  // Get an array of possible parents to pass to the form since we didnt select the parent. This will make a select box at the top.
  // Only used for the outlook module.
  let parentSelectLabel = "";
  let parents = null;
  if (custom_form?.parent_type === "Project") {
    parentSelectLabel = "Project";
    parents = _.map(projects, (project) => {
      return {
        value: project.id,
        label: project.display_name,
      };
    });
  }
  if (custom_form?.parent_type === "Employee") {
    parentSelectLabel = "Employee";
    parents = _.map(employees, (employee) => {
      return {
        value: employee.id,
        label: employee.display_name,
      };
    });
  }
  if (custom_form?.parent_type === "CustomForm") {
    parentSelectLabel = custom_forms?.[custom_form?.parent_id]?.name; // Get the name of the parent custom form
    const parent_custom_form_records = _.filter(custom_form_records, { custom_form_id: custom_form?.parent_id });
    parents = _.map(parent_custom_form_records, (custom_form_record) => {
      return {
        value: custom_form_record.id,
        label: custom_form_record.display_name,
      };
    });
  }
  if (custom_form?.parent_type === "V2::Maintenance::Category") {
    parentSelectLabel = "Maintenance Item";
    parents = _.map(v2_maintenance_items, (item) => {
      return {
        value: item.id,
        label: item.display_name,
      };
    });
  }

  let this_custom_form_records = _.filter(custom_form_records, { custom_form_id: custom_form?.id });
  if (custom_form?.parent_type) {
    if (custom_form?.parent_type == "V2::Maintenance::Category") {
      this_custom_form_records = _.filter(this_custom_form_records, (p) => p.parent_type == "V2::Maintenance::Item" && p.parent_id == parentSelected);
    } else {
      this_custom_form_records = _.filter(
        this_custom_form_records,
        (p) => p.parent_type == custom_form?.parent_type && p.parent_id == parentSelected
      );
    }
  }
  if (!readRole) {
    return <NotAuthorized />;
  }

  const showCreateButton = createRole && (!custom_form?.parent_type || (custom_form?.parent_type && parentSelected));
  let newUrl = `/custom_form/${custom_form?.id}/records/new`;
  if (parentSelected) {
    newUrl += `/${parentSelected}`;
  }

  return (
    <Page>
      <AppNavbar title={custom_form?.name} />
      {loading && <Preloader />}

      {custom_form?.parent_type && (
        <SmartSelect
          label={parentSelectLabel}
          placeholder={`Select ${parentSelectLabel}`}
          options={parents}
          onChange={(e) => {
            setParentSelected(e.target.value);
            f7router.navigate(`/custom_form/${custom_form?.id}/records?parent_id=${e.target.value}`);
          }}
          value={parentSelected}
        />
      )}

      <List accordionList className="search-list searchbar-found">
        {_.map(this_custom_form_records, (custom_form_record, i) => {
          return <CustomFormRecordsListItem key={i} slot="list" custom_form_record={custom_form_record} inputs={inputs} />;
        })}
      </List>
      {_.isEmpty(this_custom_form_records) && <Block>No {custom_form?.name} records</Block>}

      {showCreateButton && (
        <Fab position="right-bottom" slot="fixed" text="New" href={newUrl}>
          <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
        </Fab>
      )}
    </Page>
  );
};
export default connect(mapStateToProps, { getCustomFormRecords, getCustomForm, getCustomForms, getEmployees, getProjects, getV2MaintenanceItems })(
  CustomFormRecordsIndex
);

const CustomFormRecordsListItem = ({ custom_form_record, inputs }) => {
  let editUrl = `/custom_form/${custom_form_record?.custom_form_id}/records/${custom_form_record?.id}/edit`;
  if (custom_form_record?.parent_id) {
    editUrl += `?parent_id=${custom_form_record?.parent_id}`;
  }

  return (
    <ListItem accordionItem title={custom_form_record.display_name}>
      <AccordionContent>
        <div className="data-table">
          <table>
            <tbody>
              {_.map(inputs, (col, i) => {
                const column_type = _.find(column_types, { value: col.column_type });
                const value = _.get(custom_form_record, `data.${col.name}`);
                const renderValue = column_type.formatter ? column_type.formatter(value, custom_form_record) : value;

                return (
                  <tr key={i}>
                    <td className="label-cell">{col.label}</td>
                    <td className="label-cell">{renderValue}</td>
                  </tr>
                );
              })}
              <tr>
                <td className="label-cell">Author</td>
                <td className="label-cell">{custom_form_record?.user_name}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Block>
          <Button
            fill
            color="green"
            external
            target="_blank"
            href={`/custom_forms/${custom_form_record?.custom_form_id}/records/${custom_form_record?.id}.pdf`}
          >
            PDF Report
          </Button>
        </Block>
        <Block className="padding-top">
          <Button fill href={editUrl}>
            Edit
          </Button>
        </Block>
      </AccordionContent>
    </ListItem>
  );
};
