import {
  GET_ALL_PROJECT_QA_ACTION_ITEMS,
  GET_PROJECT_QA_ACTION_ITEMS,
  GET_PROJECT_QA_ACTION_ITEM,
  CREATE_PROJECT_QA_ACTION_ITEM,
  UPDATE_PROJECT_QA_ACTION_ITEM,
  DESTROY_PROJECT_QA_ACTION_ITEM,
} from "./actions";

export function ProjectQaActionItemsReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_ALL_PROJECT_QA_ACTION_ITEMS}_FULFILLED`:
    case `${GET_PROJECT_QA_ACTION_ITEMS}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    case `${GET_PROJECT_QA_ACTION_ITEM}_FULFILLED`:
    case `${CREATE_PROJECT_QA_ACTION_ITEM}_FULFILLED`:
    case `${UPDATE_PROJECT_QA_ACTION_ITEM}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_PROJECT_QA_ACTION_ITEM}_FULFILLED`: {
      let newState = _.clone(state);
      const deletedItem = action.payload.data;
      const id = _.get(deletedItem, "id");
      newState = _.filter(newState, (i) => i.id != id);
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { ProjectQaActionItemsReducer } from "modules/project/qa/action_items/reducer"

// project_qa_action_items:ProjectQaActionItemsReducer,
