import axios from "axios";
import jsonFormData from "json-form-data";

const prepareData = (data, showLeafArrayIndexes) => {
  return jsonFormData(
    { qa_site_installation: data },
    { showLeafArrayIndexes: showLeafArrayIndexes }
  );
};

// Plural Actions
export const GET_PROJECT_QA_SITE_SITE_INSTALLATIONS =
  "get_project_qa_site_site_installations";
export const getProjectQaSiteSiteInstallations = (project_id) => {
  const url = `/project/${project_id}/qa/site/site_installations.json`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_QA_SITE_SITE_INSTALLATIONS,
    payload: request,
  };
};

// Singular Actions
export const CREATE_PROJECT_QA_SITE_SITE_INSTALLATION =
  "create_project_qa_site_site_installation";
export const createProjectQaSiteSiteInstallation = (project_id, data,notify) => {
  let url = `/project/${project_id}/qa/site/site_installations.json`;
	if (notify) {
    url += "?notify";
  }

  const request = axios({
    method: "post",
    url: url,
    data: prepareData(data, false),
  });
  return {
    type: CREATE_PROJECT_QA_SITE_SITE_INSTALLATION,
    payload: request,
  };
};

export const GET_PROJECT_QA_SITE_SITE_INSTALLATION =
  "get_project_qa_site_site_installation";
export const getProjectQaSiteSiteInstallation = (project_id, id) => {
  const url = `/project/${project_id}/qa/site/site_installations/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_QA_SITE_SITE_INSTALLATION,
    payload: request,
  };
};

export const UPDATE_PROJECT_QA_SITE_SITE_INSTALLATION =
  "update_project_qa_site_site_installation";
export const updateProjectQaSiteSiteInstallation = (project_id, id, data,notify) => {
  let url = `/project/${project_id}/qa/site/site_installations/${id}.json`;
	if (notify) {
    url += "?notify";
  }

  const request = axios({
    method: "patch",
    url: url,
    data: prepareData(data, false),
  });
  return {
    type: UPDATE_PROJECT_QA_SITE_SITE_INSTALLATION,
    payload: request,
  };
};

export const DESTROY_PROJECT_QA_SITE_SITE_INSTALLATION =
  "destroy_project_qa_site_site_installation";
export const destroyProjectQaSiteSiteInstallation = (project_id, id) => {
  const url = `/project/${project_id}/qa/site/site_installations/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_PROJECT_QA_SITE_SITE_INSTALLATION,
    payload: request,
    meta: { id: id },
  };
};
