import React from "react";

const NewEmployeeQualifications = React.lazy(() => import("./new"));
const EditEmployeeQualifications = React.lazy(() => import("./edit"));

export default [
  {
    path: "/employee/:employee_id/qualifications/new",
    component: NewEmployeeQualifications,
    name: "New Qualification",
  },
  {
    path: "/employee/:employee_id/qualifications/:id/edit",
    component: EditEmployeeQualifications,
    name: "Edit Qualification",
  },
];
