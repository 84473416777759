import {
  GET_ALL_EMPLOYEE_TRAINING_REQUIREMENTS,
  GET_EMPLOYEE_TRAINING_REQUIREMENTS,
  GET_EMPLOYEE_TRAINING_REQUIREMENT,
  CREATE_EMPLOYEE_TRAINING_REQUIREMENT,
  UPDATE_EMPLOYEE_TRAINING_REQUIREMENT,
  DESTROY_EMPLOYEE_TRAINING_REQUIREMENT,
} from "./actions";

export function EmployeeTrainingRequirementsReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_ALL_EMPLOYEE_TRAINING_REQUIREMENTS}_FULFILLED`:
    case `${GET_EMPLOYEE_TRAINING_REQUIREMENTS}_FULFILLED`: {
      if (_.isNotEmpty(state)) {
        const newState = _.clone(state);
        return _.merge(newState, _.mapKeys(action.payload.data, "id"));
      } else {
        return _.mapKeys(action.payload.data, "id");
      }
    }
    case `${GET_EMPLOYEE_TRAINING_REQUIREMENT}_FULFILLED`:
    case `${CREATE_EMPLOYEE_TRAINING_REQUIREMENT}_FULFILLED`:
    case `${UPDATE_EMPLOYEE_TRAINING_REQUIREMENT}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_EMPLOYEE_TRAINING_REQUIREMENT}_FULFILLED`: {
      let newState = _.clone(state);
      const {
        meta: { id },
      } = action;
      _.unset(newState, id);
      return newState;
    }
    default:
      return state;
  }
}
