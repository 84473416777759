import React,{ Fragment } from 'react'

import { UncontrolledTooltip } from 'reactstrap';

const Tooltip = (props) => {
  return (
    <Fragment>
      <span style={{ borderBottom: "1px dotted black" }} id={props.id}>
        {props.label}
      </span>
      <UncontrolledTooltip placement="right" target={props.id}>
        {props.text}
      </UncontrolledTooltip>
    </Fragment>
  );
};

export default Tooltip
