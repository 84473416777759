import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Page, List, ListItem, BlockTitle, AccordionContent, Block, Button, Searchbar, Preloader } from "framework7-react";

// Helpers
import HasRole from "modules/5_view_helpers/HasRole";
import AppNavbar from "modules/1_layout/mobile/AppNavbar";

import SelectProject from "modules/projects/mobile/select";
import { setCurrentProject } from "modules/current_user/actions";

// Actions
import { getProjectDrawings } from "../actions";
import { getProjectVariationNumbers } from "modules/project/variation_numbers/actions";

import NotAuthorized from "modules/5_view_helpers/mobile/NotAuthorized";
import _ from "lodash";

function mapStateToProps(state) {
  const current_user = state.current_user;
  const current_project_id = _.get(current_user, "current_project_id");
  const project_variation_numbers = _.filter(state.project_variation_numbers, (pvn) => pvn.project_id == current_project_id);

  return {
    projects: state.projects,
    current_user: current_user,
    project_drawings: state.project_drawings,
    project_variation_numbers: project_variation_numbers,
  };
}

const ProjectDrawingsIndex = (props) => {
  const [variationNumberIDSelected, setVariationNumberIDSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const { project_drawings, current_user, projects, project_variation_numbers } = props;
  const current_project_id = _.get(current_user, "current_project_id");
  const project = projects[current_project_id];

  const readRole = HasRole(
    {
      resource_type: "Project::Drawing",
      name: ["read", "moderator"],
    },
    true
  );

  useEffect(() => {
    if (current_project_id && readRole) {
      setLoading(true);
      props.getProjectVariationNumbers(current_project_id);
      props.getProjectDrawings(current_project_id).then(() => {
        setLoading(false);
      });
    }
  }, [current_project_id]);

  let drawings_to_display = _.clone(project_drawings);
  if (variationNumberIDSelected && variationNumberIDSelected != "main") {
    drawings_to_display = _.filter(project_drawings, (dl) => dl.variation_number_id == variationNumberIDSelected);
  } else {
    drawings_to_display = _.filter(project_drawings, (dl) => !dl.variation_number_id);
  }

  if (!readRole) {
    return <NotAuthorized />;
  }
  return (
    <Page>
      <AppNavbar title="Drawings" />

      <SelectProject
        onChange={({ target: { value } }) => {
          props.setCurrentProject(value);
        }}
        value={current_project_id}
      />
      {_.isNotEmpty(project_variation_numbers) && (
        <List>
          <ListItem
            title="Variation Number"
            smartSelect
            smartSelectParams={{
              openIn: "popover",
              closeOnSelect: true,
            }}
            placeholder="Main Contract"
          >
            <select
              name="project_variation_numbers"
              onChange={(e) => {
                setVariationNumberIDSelected(event.target.value);
              }}
            >
              <option value="main">Main Contract</option>
              {_.map(project_variation_numbers, (vn) => (
                <option key={vn.id} value={vn.id}>
                  {vn.number} - {vn.description}
                </option>
              ))}
            </select>
          </ListItem>
        </List>
      )}

      {loading && <Preloader />}
      {!loading && <DrawingsList drawings={drawings_to_display} title={`${project?.display_name} - Drawings`} />}
    </Page>
  );
};
export default connect(mapStateToProps, { getProjectDrawings, setCurrentProject, getProjectVariationNumbers })(ProjectDrawingsIndex);

const DrawingsList = ({ title, drawings }) => {
  return (
    <Fragment>
      <Block>
        <BlockTitle>{title}</BlockTitle>
        <Searchbar searchContainer=".search-list" searchIn=".item-title" />
        <List accordionList className="search-list searchbar-found">
          {_.map(drawings, (drawing, i) => (
            <DrawingRow slot="list" drawing={drawing} key={i} />
          ))}
        </List>
      </Block>
      {_.isEmpty(drawings) && (
        <List>
          <ListItem>No Drawings for this project</ListItem>
        </List>
      )}
    </Fragment>
  );
};

const DrawingRow = (props) => {
  const { drawing } = props;
  const revisions = [...drawing?.revisions_attributes].reverse();

  return (
    <ListItem accordionItem title={`${drawing?.name} - ${drawing.description}`}>
      <AccordionContent>
        <Block>
          {_.map(revisions, (revision, i) => (
            <Button
              key={i}
              className="margin"
              color={revision.latest ? "green" : "red"}
              fill
              external
              target="_blank"
              href={revision.drawing_pdf_url}
            >
              Rev - {revision.mark} {revision.latest && "(LATEST)"}
            </Button>
          ))}
        </Block>
      </AccordionContent>
    </ListItem>
  );
};
