import React, { Component, Fragment } from "react";
import { Block, Chip, Popup, Page, Navbar, NavRight, Link, f7, List, BlockTitle, Button } from "framework7-react";
import { v4 as uuidv4 } from "uuid";

import IsolatedLowReadingsPopup from "./IsolatedLow";
import SpotCheckReadingsPopup from "./SpotCheck";

// Well this is some nice spaghetti code

export function defaultInsertElcoReading() {
  alert("You must select an assembly and paint system/coat first.");
}

export default class ElcometerReader extends Component {
  constructor(props) {
    super(props);

    this.calculateReadings = this.calculateReadings.bind(this);
    this.deleteReading = this.deleteReading.bind(this);
    this.closeIsolatedLowProcess = this.closeIsolatedLowProcess.bind(this);
    this.openIsolatedLowProcess = this.openIsolatedLowProcess.bind(this);

    this.openSpotCheckProcess = this.openSpotCheckProcess.bind(this);
    this.closeSpotCheckProcess = this.closeSpotCheckProcess.bind(this);

    this.attachInsertElcoReading = this.attachInsertElcoReading.bind(this);
    this.getReadings = this.getReadings.bind(this);

    this.state = {
      isolatedLowPopup: false,
      parentReadingName: null,
      spotCheckPopup: false,
    };
  }

  componentDidMount() {
    // Overwrite the insert reading function with the one to put shit into the form

    this.attachInsertElcoReading();
  }

  attachInsertElcoReading() {
    // Add readings with elcometer
    window.insertElcoReading = (value) => {
      const { values, useDFT, selectedCoat, form_name } = this.props;
      const { isolatedLowPopup } = this.state;

      const pi_readings_attributes = _.get(values, form_name);

      if (!isolatedLowPopup) {
        let dft_input;
        if (_.isString(value)) {
          // Split the string to get the first value and parse the int
          const splitValue = value.split(",");
          dft_input = parseInt(splitValue[0]);
        } else {
          dft_input = value;
        }

        const above_low = dft_input >= useDFT * (selectedCoat?.min_percentage / 100);
        const is_low = !above_low;
        const below_average = dft_input < useDFT;
        const between_low_and_avg = above_low && below_average;

        let newReadings = _.clone(pi_readings_attributes ? pi_readings_attributes : []);
        newReadings.push({ uuid: uuidv4(), dft: dft_input, is_low: is_low });
        this.props.form.change(form_name, newReadings);
        this.calculateReadings(newReadings);

        const parentReadingIndex = _.findLastIndex(newReadings, (r) => !r.user_deleted);
        const parentReadingName = `${form_name}[${parentReadingIndex}]`;

        this.setState({ parentReadingName: parentReadingName });
        if (between_low_and_avg) {
          this.openIsolatedLowProcess();
          return true;
        }
        if (is_low) {
          this.openSpotCheckProcess();
          return true;
        }
      }
    };
  }

  calculateReadings() {
    const {
      form: { change },
      average_dft_name,
      low_dft_name,
      high_dft_name,
      readings_name,
    } = this.props;

    let average_dft;
    let low_dft;
    let high_dft;

    const getReadings = this.getReadings();

    const readings_dfts = _.map(getReadings, "dft");

    let number_of_readings = readings_dfts.length;
    if (number_of_readings != 0) {
      average_dft = _.round(_.mean(readings_dfts), 0);
      low_dft = _.round(_.min(readings_dfts), 0);
      high_dft = _.round(_.max(readings_dfts), 0);
    } else {
      average_dft = null;
      low_dft = null;
      high_dft = null;
      number_of_readings = null;
    }

    change(average_dft_name, average_dft);
    change(low_dft_name, low_dft);
    change(high_dft_name, high_dft);
    change(readings_name, number_of_readings);

    // If there are any isolated low checks, set the paint inspection to be isolated low
    if (_.some(getReadings, "isolated_low_checked")) {
      // Next check if all pi_readings are isolated lows and set the paint inspection to be isolated low
      const all_isolated_low = _.every(getReadings, (r) => r.is_low == false); // Check everything is low
      change("isolated_low", all_isolated_low);
    }
  }

  componentWillUnmount() {
    window.insertElcoReading = defaultInsertElcoReading; // Set back to default to warn that you need to select stuff
  }

  openIsolatedLowProcess() {
    this.setState({ isolatedLowPopup: true });
  }

  closeIsolatedLowProcess() {
    this.setState({ isolatedLowPopup: false });
    this.attachInsertElcoReading(); // attach the elco reader back to the main readings not the nested readings
    this.calculateReadings();
  }

  openSpotCheckProcess() {
    this.setState({ spotCheckPopup: true });
  }

  closeSpotCheckProcess(uuid) {
    const {
      useDFT,
      selectedCoat,
      values: { pi_readings_attributes },
    } = this.props;

    const findReading = _.find(pi_readings_attributes, (r) => r.uuid == uuid);
    const dft_input = findReading?.dft;
    const above_low = dft_input >= useDFT * (selectedCoat?.min_percentage / 100);
    const below_average = dft_input < useDFT;
    const between_low_and_avg = above_low && below_average;

    this.setState({ spotCheckPopup: false, parentReadingName: findReading });
    this.attachInsertElcoReading(); // attach the elco reader back to the main readings not the nested readings
    this.calculateReadings();

    if (between_low_and_avg) {
      this.openIsolatedLowProcess();
    }
  }

  deleteReading(uuid) {
    const { values, form_name } = this.props;
    const pi_readings_attributes = _.get(values, form_name);

    const findIndex = _.findIndex(pi_readings_attributes, (r) => r.uuid == uuid);
    if (findIndex != -1) {
      let newReadings = _.clone(pi_readings_attributes ? pi_readings_attributes : []);
      newReadings[findIndex].user_deleted = true;
      this.props.form.change(form_name, newReadings);
      this.calculateReadings(newReadings);
    }
  }

  getReadings() {
    const { values, form_name } = this.props;
    const pi_readings_attributes = _.get(values, form_name);

    let readings = [];
    _.each(pi_readings_attributes, (reading) => {
      // Only add the parent reading if it is not deleted, ignore if the spot check has been passed
      if (!reading.user_deleted && !reading.spot_check_pass) {
        readings.push(reading);
      }

      // Only add the child reading if it is not deleted, ignore if the spot check has been passed
      if (!reading.user_deleted) {
        // for Isolated Lows / Spot Checks
        _.each(reading.pi_readings_attributes, (child_reading) => {
          if (!child_reading.user_deleted && !child_reading.spot_check_pass) {
            readings.push(child_reading);
          }

          // For Isolated Lows inside a spot check
          _.each(child_reading.pi_readings_attributes, (grand_child_reading) => {
            if (!grand_child_reading.user_deleted) {
              readings.push(grand_child_reading);
            }
          });
        });
      }
    });

    return readings;
  }

  render() {
    const { isolatedLowPopup, spotCheckPopup, parentReadingName } = this.state;
    const { values, form_name } = this.props;

    const readings = _.get(values, form_name);
    const filtered_readings = _.filter(readings, (r) => !r.user_deleted);

    return (
      <Fragment>
        <IsolatedLowReadingsPopup
          opened={isolatedLowPopup}
          closeIsolatedLowProcess={this.closeIsolatedLowProcess}
          deleteParentReading={() => {
            const uuid = _.get(this.props.values, `${parentReadingName}.uuid`);
            this.deleteReading(uuid);
          }}
          originalAttachInsertElcoReading={this.attachInsertElcoReading}
          parentReadingName={parentReadingName}
          {...this.props}
        />
        <SpotCheckReadingsPopup
          opened={spotCheckPopup}
          closeSpotCheckProcess={this.closeSpotCheckProcess}
          deleteParentReading={() => {
            const uuid = _.get(this.props.values, `${parentReadingName}.uuid`);
            this.deleteReading(uuid);
          }}
          originalAttachInsertElcoReading={this.attachInsertElcoReading}
          parentReadingName={parentReadingName}
          {...this.props}
        />
        {!_.isEmpty(filtered_readings) != 0 && filtered_readings && (
          <Block>
            <b>Elco Readings : </b>
            {_.map(filtered_readings, (reading, i) => {
              return (
                <Chip
                  deleteable
                  key={i}
                  onDelete={() => {
                    this.deleteReading(reading.uuid);
                  }}
                  color={!reading.is_low ? "green" : reading.isolated_low ? "yellow" : "red"}
                >
                  <div
                    onClick={() => {
                      const pi_readings_attributes = _.get(values, form_name);
                      const readingIndex = _.findIndex(pi_readings_attributes, (r) => r.uuid == reading.uuid);
                      this.setState({ parentReadingName: `${form_name}[${readingIndex}]` });
                      if (reading.spot_checked) {
                        this.openSpotCheckProcess();
                      } else if (reading.isolated_low_checked) {
                        this.openIsolatedLowProcess();
                      }
                    }}
                  >
                    <ReadingText reading={reading} />
                  </div>
                </Chip>
              );
            })}
          </Block>
        )}
      </Fragment>
    );
  }
}

ElcometerReader.defaultProps = {
  average_dft_name: "average_dft",
  low_dft_name: "low_dft",
  high_dft_name: "high_dft",
  readings_name: "readings",
  form_name: "pi_readings_attributes",
};

export const ReadingText = ({ reading }) => {
  const filtered_child_readings = _.filter(reading?.pi_readings_attributes, (r) => !r.user_deleted);
  const child_readings = _.map(filtered_child_readings, (r, i) => (
    <Fragment key={i}>
      {i != 0 && " "}
      {r.dft}&micro;
    </Fragment>
  ));
  const readingText = reading.spot_check_pass ? <strike>{reading?.dft}&micro;</strike> : <Fragment>{reading?.dft}&micro;</Fragment>;

  return (
    <Fragment>
      {readingText} {(reading?.spot_checked || reading?.isolated_low_checked) && child_readings.length > 0 && <Fragment>({child_readings})</Fragment>}
    </Fragment>
  );
};

// Jordan Moss QA Manager 2023
// Gents,

// If we look at AS2312.1, it refers to the acceptance criteria from AS3894.3, which states in short:

// •	The average must be higher than the DFT required
// •	All readings must be at least 80% of the DFT required, or in some cases all should exceed the DFT (ACC & PUR systems).

// SSPC – PA2 appears to support the same conclusion. However, there is an allowance to discard any unusually high or low readings that cannot be consistently repeated. It also refers to a “spot reading” which is the average of 3(min) acceptable readings within a 1.5inch circle. What is the most interesting is so far I have not been able to confirm what is described as an isolated low and the process supporting that other than measurements at 1.5m radius from original low measurements.

// My suggestion:

// •	Individual reading is above 100% of DFT required – complies
// •	Individual reading is between 80% and 100% of DFT required – launch isolated low process
// •	Individual reading is below 80% of DFT required – launch spot process

// Spot Process:

// •	Take additional 2 readings within a 1.5inch circle.
// •	If both additional readings exceed 100% - complies and delete 1st reading
// •	If one or more of the additional readings are between 80% - 100% of DFT – launch isolated low process
// •	If one or more of the additional readings are below 80% - non-compliant and markup for repair

// Isolated Low Process:

// •	Take 4 readings at yet to be agreed distance from original reading, equally spaced.
// •	If 4 additional readings are above 100% of DFT – complies and mark 1st  reading as isolated low.
// •	If one or more of the readings are below 100% - non-compliant and markup for repair
