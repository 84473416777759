import axios from "axios";

const prepareData = (data) => {return {project_site_document_release:data}}

// Plural Actions
export const GET_PROJECT_SITE_DOCUMENT_RELEASES = "get_project_site_document_releases";
export const getProjectSiteDocumentReleases = (project_id) => {
	const url = `/project/${project_id}/site_document_releases.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_SITE_DOCUMENT_RELEASES,
		payload: request
	};
};


// Singular Actions
export const CREATE_PROJECT_SITE_DOCUMENT_RELEASE = "create_project_site_document_release";
export const createProjectSiteDocumentRelease = (project_id,data) => {
	const url = `/project/${project_id}/site_document_releases.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PROJECT_SITE_DOCUMENT_RELEASE,
		payload: request
	};
};

export const GET_PROJECT_SITE_DOCUMENT_RELEASE = "get_project_site_document_release";
export const getProjectSiteDocumentRelease = (project_id,id) => {
	const url = `/project/${project_id}/site_document_releases/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_SITE_DOCUMENT_RELEASE,
		payload: request
	};
};

export const UPDATE_PROJECT_SITE_DOCUMENT_RELEASE = "update_project_site_document_release";
export const updateProjectSiteDocumentRelease = (project_id,id,data) => {
	const url = `/project/${project_id}/site_document_releases/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PROJECT_SITE_DOCUMENT_RELEASE,
		payload: request
	};
};

export const DESTROY_PROJECT_SITE_DOCUMENT_RELEASE = "destroy_project_site_document_release";
export const destroyProjectSiteDocumentRelease = (project_id,id) => {
	const url = `/project/${project_id}/site_document_releases/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PROJECT_SITE_DOCUMENT_RELEASE,
		payload: request
	};
};

export const RESENED_PROJECT_SITE_DOCUMENT_RELEASE = "resend_project_site_document_release";
export const resendProjectSiteDocumentRelease = (project_id,id) => {
	const url = `/project/${project_id}/site_document_releases/${id}/resend.json`
	const request = axios({method: "post",url: url});
	return {
		type: RESENED_PROJECT_SITE_DOCUMENT_RELEASE,
		payload: request
	};
};
