import React from 'react';

const ProjectQaWeldingInspectionsWeldingStagesIndex = React.lazy(() => import("./index"))
const NewProjectQaWeldingInspectionsWeldingStages = React.lazy(() => import("./new"))
const EditProjectQaWeldingInspectionsWeldingStages = React.lazy(() => import("./edit"))

export default[
	{path:'/project/qa/welding_inspections/welding_stages/:id/edit',component: EditProjectQaWeldingInspectionsWeldingStages,name:'Edit ProjectQaWeldingInspectionsWeldingStage'},
	{path:'/project/qa/welding_inspections/welding_stages/new',component: NewProjectQaWeldingInspectionsWeldingStages,name:'New ProjectQaWeldingInspectionsWeldingStage'},  
	{path:'/project/qa/welding_inspections/welding_stages',component: ProjectQaWeldingInspectionsWeldingStagesIndex,name:'ProjectQaWeldingInspectionsWeldingStages'}, 
]

// import ProjectQaWeldingInspectionsWeldingStagesRoutes from "modules/project/qa/welding_inspections/welding_stages/web/routes"
// routes = _.concat(routes,ProjectQaWeldingInspectionsWeldingStagesRoutes)
