import axios from "axios";
import jsonFormData from "json-form-data";

const prepareData = (data) => {
  return jsonFormData({ project_qa_welding_inspection: data }, { showLeafArrayIndexes: true });
};

// Plural Actions
export const GET_PROJECT_QA_WELDING_INSPECTIONS = "get_project_qa_welding_inspections";
export const getProjectQaWeldingInspections = (project_id) => {
  const url = `/project/${project_id}/qa/welding_inspections.json`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_QA_WELDING_INSPECTIONS,
    payload: request,
  };
};

// Singular Actions
export const CREATE_PROJECT_QA_WELDING_INSPECTION = "create_project_qa_welding_inspection";
export const createProjectQaWeldingInspection = (project_id, data) => {
  const url = `/project/${project_id}/qa/welding_inspections.json`;
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_PROJECT_QA_WELDING_INSPECTION,
    payload: request,
  };
};

export const GET_PROJECT_QA_WELDING_INSPECTION = "get_project_qa_welding_inspection";
export const getProjectQaWeldingInspection = (project_id, id) => {
  const url = `/project/${project_id}/qa/welding_inspections/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_QA_WELDING_INSPECTION,
    payload: request,
  };
};


export const UPDATE_PROJECT_QA_WELDING_INSPECTION = "update_project_qa_welding_inspection";
export const updateProjectQaWeldingInspection = (project_id, id, data) => {
  const url = `/project/${project_id}/qa/welding_inspections/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_PROJECT_QA_WELDING_INSPECTION,
    payload: request,
  };
};

export const DESTROY_PROJECT_QA_WELDING_INSPECTION = "destroy_project_qa_welding_inspection";
export const destroyProjectQaWeldingInspection = (project_id, id) => {
  const url = `/project/${project_id}/qa/welding_inspections/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_PROJECT_QA_WELDING_INSPECTION,
    payload: request,
  };
};
