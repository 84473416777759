import React from 'react';

const ProjectAssembliesIndex = React.lazy(() => import("./index"))
const NewProjectAssemblies = React.lazy(() => import("./new"))
const EditProjectAssemblies = React.lazy(() => import("./edit"))

export default[
	{path:'/project/:project_id/assemblies/:id/edit',component: EditProjectAssemblies,name:'Edit ProjectAssembly'},
	{path:'/project/:project_id/assemblies/new',component: NewProjectAssemblies,name:'New ProjectAssembly'},  
	{path:'/project/:project_id/assemblies',component: ProjectAssembliesIndex,name:'ProjectAssemblies'}, 
]

// import ProjectAssembliesRoutes from "modules/project/assemblies/web/routes"
// routes = _.concat(routes,ProjectAssembliesRoutes)
