import React, { Fragment } from "react";
import { Field } from "react-final-form";
import { FormGroup, Label, Col } from "reactstrap";

import { CKEditorField, ToggleField, required } from "modules/4_final_form/web";
import Interweave from "interweave";

export const TextFieldAttributes = ({ fieldName }) => {
  return (
    <div>
      <FormGroup row>
        <Label md={12}>Text</Label>
        <Col md={12}>
          <Field name={`${fieldName}.text`} component={CKEditorField} validate={required} height="500px" />
        </Col>
      </FormGroup>
      {/* <FormGroup row>
        <Label md={3}>On Report</Label>
        <Col md={8}>
          <Field name={`${fieldName}.on_report`} component={ToggleField} />
        </Col>
      </FormGroup> */}
    </div>
  );
};

export const TextFieldForm = (props) => {
  const { text, class_names } = props;
  return (
    <div style={{ minHeight: "50px" }}>
      <Interweave content={text} />
    </div>
  );
};
