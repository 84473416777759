import React from "react";

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { createPromise } from "redux-promise-middleware";

import authCatcher from "./authCatcher";
import Router from "./2_router";
import Reducers from "modules/3_reducers";


const createStoreWithMiddleware = applyMiddleware(authCatcher, createPromise())(createStore);
export const Store = createStoreWithMiddleware(
  Reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ trace: false })
);

const Entry = (props) => {
  return (
    <Provider store={Store}>
      <Router />
    </Provider>
  );
};

export default Entry;
