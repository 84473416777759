import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Table, Button, Card, CardHeader, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import _ from "lodash";

import DefaultDataGrid from "modules/5_view_helpers/web/DefaultDataGrid";
import columns from "./columns";

// Helpers
import NotAuthorized from "modules/5_view_helpers/web/NotAuthorized";
import HasRole from "modules/5_view_helpers/HasRole";

// Actions
import { getProjectQaWeldingInspectionsAccessRestrictions, updateProjectQaWeldingInspectionsAccessRestriction } from "../actions";

function mapStateToProps(state) {
  return {
    project_qa_welding_inspections_access_restrictions: state.project_qa_welding_inspections_access_restrictions,
  };
}

const ProjectQaWeldingInspectionsAccessRestrictionsIndex = (props) => {
  useEffect(() => {
    props.getProjectQaWeldingInspectionsAccessRestrictions();
  }, []);

  const { project_qa_welding_inspections_access_restrictions } = props;

  const readRole = HasRole({ resource_type: "Project::Qa::WeldingInspections::AccessRestriction", name: ["read", "moderator"] });
  const createRole = HasRole({ resource_type: "Project::Qa::WeldingInspections::AccessRestriction", name: ["create", "moderator"] });
  const updateRole = HasRole({ resource_type: "Project::Qa::WeldingInspections::AccessRestriction", name: ["update", "moderator"] });

  if (!readRole) {
    return <NotAuthorized />;
  } else {
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <h3>Access restrictions</h3>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
          {createRole && (
            <Row>
              <Col>
                <Link to={`/project/qa/welding_inspections/access_restrictions/new`}>
                  <Button color="primary" size="md">
                    <i className="fa fa-plus"> Create New Access restriction</i>
                  </Button>
                </Link>
              </Col>
            </Row>
          )}

          <Row className="mt-2">
            <Col>
              <DefaultDataGrid
                columns={columns(updateRole, props.updateProjectQaWeldingInspectionsAccessRestriction)}
                data={project_qa_welding_inspections_access_restrictions}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
};
export default connect(mapStateToProps, { getProjectQaWeldingInspectionsAccessRestrictions, updateProjectQaWeldingInspectionsAccessRestriction })(
  ProjectQaWeldingInspectionsAccessRestrictionsIndex
);
