import React, { Component } from "react";
import { connect } from "react-redux";

import ProjectQaSiteSiteInstallationForm from "./form";
import { createProjectQaSiteSurveillance } from "../actions";

import { Page, Block, BlockTitle, Navbar, Toolbar, Link } from "framework7-react";

import { getProject } from "modules/projects/actions";

// Helpers
import HasRole from "modules/5_view_helpers/HasRole";
import NotAuthorized from "modules/5_view_helpers/mobile/NotAuthorized";

import arrayMutators from "final-form-arrays";
import moment from "moment";
import { hasNewActionItems } from "modules/project/qa/action_items/helpers";

// Persisted Stuff
import Form from "modules/4_final_form/PersistedForm";
import { clearPersisted } from "modules/4_final_form/PersistDecorator";
import { persistedName } from "./vairables";

function mapStateToProps(state, ownProps) {
  const { project_id } = ownProps;
  return {
    project: state.projects[project_id],
  };
}

class NewProjectQaSiteSiteInstallation extends Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.successfullySubmitted = this.successfullySubmitted.bind(this);

    const createRole = HasRole(
      {
        resource_type: "Project::Qa::Site::Surveillance",
        name: ["create", "moderator"],
      },
      true
    );

    const { project_id } = props;
    this.props.getProject(project_id);

    const returnUrl = `/project/qa/site/surveillances`;

    this.state = {
      returnUrl: returnUrl,
      createRole: createRole,
    };
  }

  submitForm(values) {
    const {
      f7router: { app },
      project_id,
    } = this.props;

    let actionItems = _.flatten(_.map(values.area_links_attributes, (al) => al?.action_items_attributes)); // get array of action items

    if (hasNewActionItems(actionItems)) {
      app.dialog.confirm(
        "Send Email Notification to Owner/Person Responsible?",
        () => {
          // When clicked okay
          this.preloader = app.preloader;
          this.preloader.show();
          const createRequest = this.props.createProjectQaSiteSurveillance(project_id, values, true);
          createRequest.then(this.successfullySubmitted);
        },
        () => {
          // When clicked cancel
          this.preloader = app.preloader;
          this.preloader.show();
          const createRequest = this.props.createProjectQaSiteSurveillance(project_id, values, false);
          createRequest.then(this.successfullySubmitted);
        }
      );
    } else {
      this.preloader = app.preloader;
      this.preloader.show();
      const createRequest = this.props.createProjectQaSiteSurveillance(project_id, values, false);
      createRequest.then(this.successfullySubmitted);
    }
  }

  successfullySubmitted() {
    const {
      f7router,
      f7router: { app, navigate },
    } = this.props;

    const { returnUrl } = this.state;

    this.preloader.hide();
    clearPersisted(persistedName).then(() => {
      app.toast
        .create({
          icon: '<i class="material-icons">check</i>',
          text: "Created Surveillance Report!",
          position: "center",
          closeTimeout: 2000,
          on: {
            opened: () => {
              f7router.navigate(returnUrl, { reloadAll: true });
            },
          },
        })
        .open();
    });
  }

  render() {
    const { history, project } = this.props;
    const { returnUrl, createRole } = this.state;

    if (!createRole) {
      return <NotAuthorized />;
    }

    return (
      <Page>
        <Navbar backLink title="New Surveillance Report" />
        <Form
          persist={{ name: persistedName }}
          component={ProjectQaSiteSiteInstallationForm}
          project={project}
          onSubmit={this.submitForm}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={{ date: moment().format("YYYY-MM-DD"), time_in: moment().format("HH:mm") }}
          formLabel="Create"
          cancelFunction={() => {
            history.push(returnUrl);
          }}
          {...this.props}
        />
      </Page>
    );
  }
}

export default connect(mapStateToProps, {
  getProject,
  createProjectQaSiteSurveillance,
})(NewProjectQaSiteSiteInstallation);
