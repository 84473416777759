import React, { Fragment } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrop, useDrag } from "react-dnd";

import { Row, Col, Button } from "reactstrap";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import RowComponent from "./3_Cols";

import "./FormBuilder.css";
import _ from "lodash";

const RowsAttributes = (props) => {
  const { fields, column_types, disabled } = props;
  const addRow = () => {
    fields.push({});
  };

  const lastRow = fields.value ? fields.value[fields.length - 1] : null;
  let lastRowCols = null;
  if (lastRow) {
    lastRowCols = _.filter(lastRow?.cols_attributes, (col) => !col._destroy);
  }
  const removeRow = (i) => {
    const thisField = fields.value[i];
    if (thisField?.id) {
      fields.update(i, { id: thisField.id, _destroy: "1" });
    } else {
      fields.remove(i);
    }
  };

  return (
    <Row>
      <Col md={12}>
        {fields.map((field, rowIndex) => {
          const thisField = fields.value[rowIndex];
          const markedForDestruction = _.get(thisField, "_destroy");

          const belowRow = fields.value[rowIndex + 1];
          const belowRowWidthSum = _.sumBy(belowRow?.cols_attributes, (col) => parseInt(col.desktop_width)) || 0;
          const maybeCanGoDown = rowIndex < fields.length - 1;

          const aboveRow = fields.value[rowIndex - 1];
          const aboveRowWidthSum = _.sumBy(aboveRow?.cols_attributes, (col) => parseInt(col.desktop_width)) || 0;
          const maybeCanGoUp = rowIndex > 0;

          const moveDown = (colIndex) => {
            const getCell = fields.value?.[rowIndex].cols_attributes?.[colIndex];
            const toIndex = rowIndex + 1;

            const thisRowNewCols = fields.value[rowIndex].cols_attributes.filter((col, i) => i !== colIndex);
            let nextRowNewCols = _.clone(fields.value[toIndex].cols_attributes);
            nextRowNewCols.push(getCell);
            fields.update(rowIndex, { cols_attributes: thisRowNewCols });
            fields.update(toIndex, { cols_attributes: nextRowNewCols });
          };

          const moveUp = (colIndex) => {
            const getCell = fields.value?.[rowIndex].cols_attributes?.[colIndex];
            const toIndex = rowIndex - 1;

            const thisRowNewCols = fields.value[rowIndex].cols_attributes.filter((col, i) => i !== colIndex);
            let nextRowNewCols = _.clone(fields.value[toIndex].cols_attributes);
            nextRowNewCols.push(getCell);
            fields.update(rowIndex, { cols_attributes: thisRowNewCols });
            fields.update(toIndex, { cols_attributes: nextRowNewCols });
          };

          if (!markedForDestruction) {
            return (
              <Fragment key={rowIndex}>
                <hr />
                <FieldArray
                  key={rowIndex}
                  component={RowComponent}
                  name={`${field}.cols_attributes`}
                  column_types={column_types}
                  disabled={disabled}
                  maybeCanGoDown={maybeCanGoDown}
                  belowRowWidthSum={belowRowWidthSum}
                  maybeCanGoUp={maybeCanGoUp}
                  aboveRowWidthSum={aboveRowWidthSum}
                  moveDown={moveDown}
                  moveUp={moveUp}
                />
              </Fragment>
            );
          }
        })}

        <hr />
        {!disabled && (
          <Row className="mt-2">
            <Col>
              <Button color="success" size="sm" onClick={addRow}>
                Add Row
              </Button>
              {fields.length > 0 && _.isEmpty(lastRowCols) && (
                <Button
                  className="ml-2"
                  size="sm"
                  onClick={() => {
                    removeRow(fields.length - 1);
                  }}
                >
                  Remove Last Row
                </Button>
              )}
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default RowsAttributes;
