import React from 'react';

const ProjectsQaSiteChecklistItem = React.lazy(() => import("./index"))
const NewProjectsQaSiteChecklistItem = React.lazy(() => import("./new"))
const EditProjectsQaSiteChecklistItem = React.lazy(() => import("./edit"))

export default[
	{path:'/projects/qa/site/checklist_items/:id/edit',component: EditProjectsQaSiteChecklistItem,name:'Edit ProjectsQaSiteChecklistItem'},
	{path:'/projects/qa/site/checklist_items/new',component: NewProjectsQaSiteChecklistItem,name:'New ProjectsQaSiteChecklistItem'},  
	{path:'/projects/qa/site/checklist_items',component: ProjectsQaSiteChecklistItem,name:'ProjectsQaSiteChecklistItem'}, 
]

// import ProjectsQaSiteChecklistItemRoutes from "modules/projects/qa/site/checklist_items/web/routes"
// routes = _.concat(routes,ProjectsQaSiteChecklistItemRoutes)
