import {
  GET_HIREAGES_CATEGORIES,
  GET_HIREAGES_CATEGORY,
  CREATE_HIREAGES_CATEGORY,
  UPDATE_HIREAGES_CATEGORY,
  DESTROY_HIREAGES_CATEGORY,
} from "./actions";

export function HireagesCategoryReducer(state = [], action) {
  switch (action.type) {
    case `${GET_HIREAGES_CATEGORIES}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    case `${GET_HIREAGES_CATEGORY}_FULFILLED`:
    case `${CREATE_HIREAGES_CATEGORY}_FULFILLED`:
    case `${UPDATE_HIREAGES_CATEGORY}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_HIREAGES_CATEGORY}_FULFILLED`: {
      let newState = _.clone(state);
      const {
        meta: { id },
      } = action;
      _.unset(newState, id);
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { HireagesCategoryReducer } from "modules/hireages/categories/reducer"

// hireages_categories:HireagesCategoryReducer,
