import React, { Fragment, useEffect } from "react";

import { CheckboxField, ReactDropzone, InputField } from "modules/4_final_form/mobile";

import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import { BlockTitle, Block, List, Button } from "framework7-react";

const MarkedUpDrawings = (props) => {
  const {
    values: { marked_up_drawings_attached },
  } = props;

  return (
    <Block>
      <BlockTitle>
        <h5>Marked Up Drawings</h5>
      </BlockTitle>

      <List>
        <Field slot="list" component={CheckboxField} name="marked_up_drawings_attached" label="Marked Up Drawings Attached?" type="checkbox" />
      </List>

      {marked_up_drawings_attached && (
        <div className="mb-5">
          <List>
            <Field
              slot="list"
              component={InputField}
              type="textarea"
              placeholder="List of drawings"
              name="marked_up_drawings_list"
              label="List of Drawings"
              parse={(value) => value}
            />
          </List>
          <FieldArray component={ReactDropzone} name="drawings_attributes" />
        </div>
      )}
    </Block>
  );
};

export default MarkedUpDrawings;
