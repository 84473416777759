import {
  GET_INTERNAL_COMPANIES,
  GET_INTERNAL_COMPANY,
  CREATE_INTERNAL_COMPANY,
  UPDATE_INTERNAL_COMPANY,
  DESTROY_INTERNAL_COMPANY,
} from "./actions";

export function InternalCompaniesReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_INTERNAL_COMPANIES}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    case `${GET_INTERNAL_COMPANY}_FULFILLED`:
    case `${CREATE_INTERNAL_COMPANY}_FULFILLED`:
    case `${UPDATE_INTERNAL_COMPANY}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_INTERNAL_COMPANY}_FULFILLED`: {
      let newState = _.clone(state);
      const {
        meta: { id },
      } = action;
      _.unset(newState, id);
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { InternalCompaniesReducer } from "modules/internal_companies/reducer"

// internal_companies:InternalCompaniesReducer,
