import axios from "axios";

const prepareData = (data) => {
  return { project_assembly: data };
};

// Plural Actions
export const GET_PROJECT_ASSEMBLIES = "get_project_assemblies";
export const getProjectAssemblies = (project_id, guid = null) => {
  let url = `/project/${project_id}/assemblies.json`;
  if (guid) {
    url += `?guid=${guid}`;
  }
  const request = axios.get(url);
  return {
    type: GET_PROJECT_ASSEMBLIES,
    payload: request,
  };
};
// Plural Actions
export const GET_PROJECT_ASSEMBLY_AND_LAST_WI = "get_project_assembly_and_last_wi";
export const getProjectAssemblyAndLastWI = (project_id, guid = "") => {
  let url = `/project/${project_id}/assemblies/assembly_and_last_wi.json?guid=${guid}`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_ASSEMBLY_AND_LAST_WI,
    payload: request,
  };
};

// Singular Actions
export const CREATE_PROJECT_ASSEMBLY = "create_project_assembly";
export const createProjectAssembly = (project_id, data) => {
  const url = `/project/${project_id}/assemblies.json`;
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_PROJECT_ASSEMBLY,
    payload: request,
  };
};

export const GET_PROJECT_ASSEMBLY = "get_project_assembly";
export const getProjectAssembly = (project_id, id) => {
  const url = `/project/${project_id}/assemblies/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_ASSEMBLY,
    payload: request,
  };
};

export const UPDATE_PROJECT_ASSEMBLY = "update_project_assembly";
export const updateProjectAssembly = (project_id, id, data) => {
  const url = `/project/${project_id}/assemblies/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_PROJECT_ASSEMBLY,
    payload: request,
  };
};

export const DESTROY_PROJECT_ASSEMBLY = "destroy_project_assembly";
export const destroyProjectAssembly = (project_id, id) => {
  const url = `/project/${project_id}/assemblies/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_PROJECT_ASSEMBLY,
    payload: request,
  };
};
