import axios from "axios";

const prepareData = (data) => {
  return { project_qa_paint_inspection: data };
};

// Plural Actions
export const GET_PROJECT_QA_PAINT_INSPECTIONS = "get_project_qa_paint_inspections";
export const getProjectQaPaintInspections = (project_id) => {
  const url = `/project/${project_id}/qa/paint_inspections.json`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_QA_PAINT_INSPECTIONS,
    payload: request,
  };
};

export const GET_PROJECT_QA_PAINT_INSPECTIONS_BY_ASSEMBLY_GUID = "get_project_qa_paint_inspections_by_assembly_guid";
export const getProjectQaPaintInspectionsByAssemblyGuid = (project_id,guid) => {
  const url = `/project/${project_id}/qa/paint_inspections.json?guid=${guid}`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_QA_PAINT_INSPECTIONS_BY_ASSEMBLY_GUID,
    payload: request,
  };
};

// Singular Actions
export const CREATE_PROJECT_QA_PAINT_INSPECTION = "create_project_qa_paint_inspection";
export const createProjectQaPaintInspection = (project_id, data) => {
  const url = `/project/${project_id}/qa/paint_inspections.json`;
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_PROJECT_QA_PAINT_INSPECTION,
    payload: request,
  };
};

export const GET_PROJECT_QA_PAINT_INSPECTION = "get_project_qa_paint_inspection";
export const getProjectQaPaintInspection = (project_id, id) => {
  const url = `/project/${project_id}/qa/paint_inspections/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_QA_PAINT_INSPECTION,
    payload: request,
  };
};

export const UPDATE_PROJECT_QA_PAINT_INSPECTION = "update_project_qa_paint_inspection";
export const updateProjectQaPaintInspection = (project_id, id, data) => {
  const url = `/project/${project_id}/qa/paint_inspections/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_PROJECT_QA_PAINT_INSPECTION,
    payload: request,
  };
};

export const DESTROY_PROJECT_QA_PAINT_INSPECTION = "destroy_project_qa_paint_inspection";
export const destroyProjectQaPaintInspection = (project_id, id) => {
  const url = `/project/${project_id}/qa/paint_inspections/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_PROJECT_QA_PAINT_INSPECTION,
    payload: request,
  };
};
