import {
  GET_TRAINING_NZQA_NUMBERS,
  GET_TRAINING_NZQA_NUMBER,
  CREATE_TRAINING_NZQA_NUMBER,
  UPDATE_TRAINING_NZQA_NUMBER,
  DESTROY_TRAINING_NZQA_NUMBER,
} from "./actions";

export function TrainingNzqaNumbersReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_TRAINING_NZQA_NUMBERS}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    case `${GET_TRAINING_NZQA_NUMBER}_FULFILLED`:
    case `${CREATE_TRAINING_NZQA_NUMBER}_FULFILLED`:
    case `${UPDATE_TRAINING_NZQA_NUMBER}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_TRAINING_NZQA_NUMBER}_FULFILLED`: {
      let newState = _.clone(state);
      const deletedItem = action.payload.data;
      const id = _.get(deletedItem, "id");
      newState = _.filter(newState, (i) => i.id != id);
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { TrainingNzqaNumbersReducer } from "modules/training/nzqa_numbers/reducer"

// training_nzqa_numbers:TrainingNzqaNumbersReducer,
