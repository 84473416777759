import {
  GET_PROJECT_COMMUNICATIONS,
  GET_PROJECT_COMMUNICATION,
  CREATE_PROJECT_COMMUNICATION,
  UPDATE_PROJECT_COMMUNICATION,
  DESTROY_PROJECT_COMMUNICATION,
  SEND_PROJECT_COMMUNICATION_OUTGOING,
  CHANGE_TO_SENT_PROJECT_COMMUNICATION_OUTGOING,
} from "./actions";

export function ProjectCommunicationsReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_PROJECT_COMMUNICATIONS}_FULFILLED`: {
      let newState = _.cloneDeep(state);
      _.each(action.payload.data, (item) => {
        if (newState[item.id]) {
          _.merge(newState[item.id], item); // Merge the new data into the existing data
        } else {
          newState[item.id] = item; // Add the new data to the state
        }
      });
      return _.mapKeys(newState, "id");
    }
    case `${GET_PROJECT_COMMUNICATION}_FULFILLED`:
    case `${CHANGE_TO_SENT_PROJECT_COMMUNICATION_OUTGOING}_FULFILLED`:
    case `${SEND_PROJECT_COMMUNICATION_OUTGOING}_FULFILLED`:
    case `${CREATE_PROJECT_COMMUNICATION}_FULFILLED`:
    case `${UPDATE_PROJECT_COMMUNICATION}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_PROJECT_COMMUNICATION}_FULFILLED`: {
      let newState = _.clone(state);
      const deletedItem = action.payload.data;
      const id = _.get(deletedItem, "id");
      newState = _.filter(newState, (i) => i.id != id);
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { ProjectCommunicationsReducer } from "modules/project/communications/reducer"

// project_communications:ProjectCommunicationsReducer,
