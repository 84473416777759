import axios from "axios";

const prepareData = (data) => {return {project_variation_number:data}}

// Plural Actions
export const GET_PROJECT_VARIATION_NUMBERS = "get_project_variation_numbers";
export const getProjectVariationNumbers = (project_id) => {
	const url = `/project/${project_id}/variation_numbers.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_VARIATION_NUMBERS,
		payload: request
	};
};


// Singular Actions
export const CREATE_PROJECT_VARIATION_NUMBER = "create_project_variation_number";
export const createProjectVariationNumber = (project_id,data) => {
	const url = `/project/${project_id}/variation_numbers.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PROJECT_VARIATION_NUMBER,
		payload: request
	};
};

export const GET_PROJECT_VARIATION_NUMBER = "get_project_variation_number";
export const getProjectVariationNumber = (project_id,id) => {
	const url = `/project/${project_id}/variation_numbers/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_VARIATION_NUMBER,
		payload: request
	};
};

export const UPDATE_PROJECT_VARIATION_NUMBER = "update_project_variation_number";
export const updateProjectVariationNumber = (project_id,id,data) => {
	const url = `/project/${project_id}/variation_numbers/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PROJECT_VARIATION_NUMBER,
		payload: request
	};
};

export const DESTROY_PROJECT_VARIATION_NUMBER = "destroy_project_variation_number";
export const destroyProjectVariationNumber = (project_id,id) => {
	const url = `/project/${project_id}/variation_numbers/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PROJECT_VARIATION_NUMBER,
		payload: request
	};
};
