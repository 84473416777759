import React from "react";

import ProjectQaActionItemsEdit from "./edit.jsx";
import ProjectQaActionItemsNew from "./new.jsx";
import ProjectQaActionItemsIndex from "./index.jsx";

export default [
  { path: "/project/:project_id/qa/action_items/:id/edit", component: ProjectQaActionItemsEdit, name: "Edit ProjectQaActionItems" },
  { path: "/project/:project_id/qa/action_items/new", component: ProjectQaActionItemsNew, name: "New ProjectQaActionItems" },
  { path: "/project/qa/action_items", component: ProjectQaActionItemsIndex, name: "ProjectQaActionItems" },
];

// import ProjectQaActionItemsRoutes from "modules/project/qa/site/action_items/web/routes"
// routes = _.concat(routes,ProjectQaActionItemsRoutes)
