import axios from "axios";

const prepareData = (data) => {return {drawing_office_drawing_type:data}}

// Plural Actions
export const GET_DRAWING_OFFICE_DRAWING_TYPES = "get_drawing_office_drawing_types";
export const getDrawingOfficeDrawingTypes = () => {
	const url = `/drawing_office/drawing_types.json`
	const request = axios.get(url);
	return {
		type: GET_DRAWING_OFFICE_DRAWING_TYPES,
		payload: request
	};
};


// Singular Actions
export const CREATE_DRAWING_OFFICE_DRAWING_TYPE = "create_drawing_office_drawing_type";
export const createDrawingOfficeDrawingType = (data) => {
	const url = `/drawing_office/drawing_types.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_DRAWING_OFFICE_DRAWING_TYPE,
		payload: request
	};
};

export const GET_DRAWING_OFFICE_DRAWING_TYPE = "get_drawing_office_drawing_type";
export const getDrawingOfficeDrawingType = (id) => {
	const url = `/drawing_office/drawing_types/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_DRAWING_OFFICE_DRAWING_TYPE,
		payload: request
	};
};

export const UPDATE_DRAWING_OFFICE_DRAWING_TYPE = "update_drawing_office_drawing_type";
export const updateDrawingOfficeDrawingType = (id,data) => {
	const url = `/drawing_office/drawing_types/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_DRAWING_OFFICE_DRAWING_TYPE,
		payload: request
	};
};

export const DESTROY_DRAWING_OFFICE_DRAWING_TYPE = "destroy_drawing_office_drawing_type";
export const destroyDrawingOfficeDrawingType = (id) => {
	const url = `/drawing_office/drawing_types/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_DRAWING_OFFICE_DRAWING_TYPE,
		payload: request
	};
};
