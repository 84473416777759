import axios from "axios";

// Plural Actions
export const GET_PRODUCTION_STRUMIS_CONTRACT_DOCUMENTS = "GET_PRODUCTION_STRUMIS_CONTRACT_DOCUMENTS";
export const getProductionStrumisContractDocuments = (project_id) => {
  const url = `/production/strumis/contracts/${project_id}/documents.json`;
  const request = axios.get(url);
  return {
    type: GET_PRODUCTION_STRUMIS_CONTRACT_DOCUMENTS,
    payload: request,
  };
};
