import React from "react";

import QAIndex from "./index";
import QASettingsIndex from "./settings_index";


let routes = [
  {
    path: "/qa/settings",
    component: QASettingsIndex,
    name: "QA Settings",
  },
  {
    path: "/qa",
    component: QAIndex,
    name: "QA Index",
  },
];

export default routes;
