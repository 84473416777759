import { TextFieldAttributes, TextFieldForm } from "./TextField";
import { InputFieldAttributes, InputFieldForm, MobileInputFieldForm } from "./InputField";
import { TextAreaFieldAttributes, TextAreaFieldForm, MobileTextAreaFieldForm } from "./TextAreaField";
import { TimeFieldAttributes, TimeFieldForm } from "./TimeField";
import { DateFieldAttributes, MobileDateFieldForm, DateFieldForm } from "./DateField";
import { YesNoNAFieldAttributes, YesNoNAFieldForm, MobileYesNoNAFieldForm } from "./YesNoNA";
import { SelectFieldAttributes, SelectFieldForm, MobileSelectFieldForm } from "./SelectField";
import { MultiSelectFieldAttributes, MultiSelectFieldForm, MobileMultiSelectFieldForm } from "./MultiSelectField";
import { ToggleFieldAttributes, ToggleFieldForm, MobileToggleFieldForm } from "./ToggleField.jsx";
// import { MaintenanceItemAttributes, MaintenanceItemFieldForm, MobileMaintenanceItemFieldForm } from "./MaintenanceItem.old";
import { AttachmentsAttributes, AttachmentsForm, MobileAttachmentsForm } from "./Attachments";
import _ from "lodash";

const column_types = [
  { label: "Text", value: "text", attributesComponent: TextFieldAttributes, formComponent: TextFieldForm },
  {
    label: "Input",
    value: "input",
    attributesComponent: InputFieldAttributes,
    formComponent: InputFieldForm,
    mobileFormComponent: MobileInputFieldForm,
  },
  {
    label: "Textarea",
    value: "textarea",
    attributesComponent: TextAreaFieldAttributes,
    formComponent: TextAreaFieldForm,
    mobileFormComponent: MobileTextAreaFieldForm,
  },
  {
    label: "Select",
    value: "select",
    attributesComponent: SelectFieldAttributes,
    formComponent: SelectFieldForm,
    mobileFormComponent: MobileSelectFieldForm,
  },
  {
    label: "Multi Select",
    value: "multiselect",
    attributesComponent: MultiSelectFieldAttributes,
    formComponent: MultiSelectFieldForm,
    mobileFormComponent: MobileMultiSelectFieldForm,
    formatter: (cell) => {
      if (_.isArray(cell)) {
        return cell.map((option) => option.label).join(", ");
      }
      return "";
    },
  },
  {
    label: "Toggle",
    value: "toggle",
    attributesComponent: ToggleFieldAttributes,
    formComponent: ToggleFieldForm,
    mobileFormComponent: MobileToggleFieldForm,
    formatter: (cell) => (cell == "1" ? "Yes" : "No"),
  },
  {
    label: "Date",
    value: "date",
    attributesComponent: DateFieldAttributes,
    formComponent: DateFieldForm,
    mobileFormComponent: MobileDateFieldForm,
    formatter: (cell) => (cell ? moment(cell).format("DD/MM/YYYY") : "Not Set"),
  },
  {
    label: "Yes/No/NA",
    value: "yesnona",
    attributesComponent: YesNoNAFieldAttributes,
    formComponent: YesNoNAFieldForm,
    mobileFormComponent: MobileYesNoNAFieldForm,
  },
  // Move Maintenance Item to custom form belongs to
  // {
  //   label: "Maintenance Item",
  //   value: "maintenance_item",
  //   attributesComponent: MaintenanceItemAttributes,
  //   formComponent: MaintenanceItemFieldForm,
  //   mobileFormComponent: MobileMaintenanceItemFieldForm,
  //   formatter: (cell, row) => row.v2_maintenance_item_display_name,
  // },
  { label: "Time", value: "time", attributesComponent: TimeFieldAttributes, formComponent: TimeFieldForm },
  {
    label: "Attachments",
    value: "attachments",
    attributesComponent: AttachmentsAttributes,
    formComponent: AttachmentsForm,
    mobileFormComponent: MobileAttachmentsForm,
  },
];

export default column_types;
