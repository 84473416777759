import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { required, InputField, ReactSelect, ToggleField } from "modules/4_final_form/web";
import { FormGroup, Label, Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Field } from "react-final-form";
import classnames from "classnames";

// actions
import { getEmployees } from "modules/employees/actions";
import { getProductionStrumisContracts } from "modules/production/strumis/contracts/actions";
import { getUserManagements } from "modules/user_managements/actions";

const mapStateToProps = (state) => ({
  users: state.users,
  employees: state.employees,
  production_strumis_contracts: state.production_strumis_contracts,
});

class DetailsTab extends React.Component {
  constructor(props) {
    super(props);

    this.props.getUserManagements(true);
    this.props.getEmployees();
    this.props.getProductionStrumisContracts();
  }

  render() {
    const { employees, production_strumis_contracts, users } = this.props;

    return (
      <Fragment>
        <FormGroup row>
          <Label md={2}>Number</Label>
          <Col md={4}>
            <Field component={InputField} name="number" type="number" parse={(value) => value} validate={required} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={2}>Name</Label>
          <Col md={4}>
            <Field component={InputField} name="name" type="string" parse={(value) => value} validate={required} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={2}>Project manager</Label>
          <Col md={4}>
            <Field
              component={ReactSelect}
              options={_.map(users, (e) => {
                return { label: e.name, value: e.id };
              })}
              name="project_manager_id"
              placeholder="Select Project Manager..."
            />
          </Col>
          <Label md={2}>Site manager</Label>
          <Col md={4}>
            <Field
              component={ReactSelect}
              options={_.map(users, (e) => {
                return { label: e.name, value: e.id };
              })}
              name="site_manager_id"
              placeholder="Select Site Manager..."
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={2}>Detailer</Label>
          <Col md={4}>
            <Field
              component={ReactSelect}
              options={_.map(users, (e) => {
                return { label: e.name, value: e.id };
              })}
              name="detailer_id"
              placeholder="Select Detailer..."
            />
          </Col>
        </FormGroup>
        <FormGroup row>
        </FormGroup>
        <FormGroup row>
          <Label md={2}>Strumis Contract</Label>
          <Col md={10}>
            <Field
              component={ReactSelect}
              options={_.map(production_strumis_contracts, (sc) => {
                return { label: sc.display_name, value: sc.id, strumis_contract_id: sc.id };
              })}
              name="strumis_contracts_attributes"
              placeholder="Select Strumis Contract..."
              isClearable
              parse={(value) => value}
              multi
              sendDestroy
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={2}>Site Document Release Users Notifications</Label>
          <Col md={10}>
            <Field
              component={ReactSelect}
              options={_.map(users, (u) => {
                return { label: u.name, value: u.id, user_id: u.id };
              })}
              multi
              name="sdr_notify_users_attributes"
              sendDestroy
              placeholder="Select users to notify..."
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={2}>Archived</Label>
          <Col md={4}>
            <Field component={ToggleField} name="archived" />
          </Col>
        </FormGroup>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, { getEmployees, getProductionStrumisContracts, getUserManagements })(DetailsTab);
