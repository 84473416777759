import {
  GET_PROJECT_QA_SITE_SITE_INSTALLATIONS,
  GET_PROJECT_QA_SITE_SITE_INSTALLATION,
  CREATE_PROJECT_QA_SITE_SITE_INSTALLATION,
  UPDATE_PROJECT_QA_SITE_SITE_INSTALLATION,
  DESTROY_PROJECT_QA_SITE_SITE_INSTALLATION,
} from "./actions";

export function ProjectQaSiteSiteInstallationReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_PROJECT_QA_SITE_SITE_INSTALLATIONS}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    case `${GET_PROJECT_QA_SITE_SITE_INSTALLATION}_FULFILLED`:
    case `${CREATE_PROJECT_QA_SITE_SITE_INSTALLATION}_FULFILLED`:
    case `${UPDATE_PROJECT_QA_SITE_SITE_INSTALLATION}_FULFILLED`: {
      let newState = _.clone(state);
      let newItem = _.clone(action.payload.data);
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_PROJECT_QA_SITE_SITE_INSTALLATION}_FULFILLED`: {
      let newState = _.clone(state);
      const {
        meta: { id },
      } = action;
      _.unset(newState, id);
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { ProjectQaSiteSiteInstallationReducer } from "modules/project/qa/site/site_installations/reducer"

// project_qa_site_site_installations:ProjectQaSiteSiteInstallationReducer,
