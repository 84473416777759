import React from "react";

const TrainingDashboard = React.lazy(() => import("./index"));

export default [
  {
    path: "/training/dashboard/",
    component: TrainingDashboard,
    name: "Training Dashboard",
  },
];

// import TrainingDashboardRoutes from "modules/training/dashboard/web/routes"
// routes = _.concat(routes,TrainingDashboardRoutes)
