import axios from "axios";

// Plural Actions
export const GET_PRODUCTION_STRUMIS_SECTION_TYPES = "get_production_strumis_section_types";
export const getProductionStrumisSectionTypes = () => {
  const url = "/production/strumis/section_types.json";
  const request = axios.get(url);
  return {
    type: GET_PRODUCTION_STRUMIS_SECTION_TYPES,
    payload: request,
  };
};

