import React from "react";
import { Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { FieldArray } from "react-final-form-arrays";

import { Button, Row, Col, Table } from "reactstrap";

function ListArray(props) {
  const {
    fields,
    buttonLabel,
    addProps,
    columns,
    showHeaders,
    tableStyle,
    numberColumnPrefix,
    numberColumnWidth,
    meta: { error },
  } = props;

  const getColumns = _.isFunction(columns) ? columns(0) : columns;

  const removeItem = (i) => {
    const thisField = fields.value[i];
    if (thisField?.id) {
      fields.update(i, { id: thisField.id, _destroy: "1" });
    } else {
      fields.remove(i);
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <Table size="sm" style={tableStyle}>
            {showHeaders && (
              <thead>
                <tr>
                  {numberColumnPrefix && <th style={{ width: numberColumnWidth }}></th>}
                  {_.map(getColumns, (column, i) => {
                    return <th key={i}>{column.label}</th>;
                  })}
                  <th style={{ width: "50px" }}></th>
                </tr>
              </thead>
            )}
            <tbody>
              {fields.map((field, i) => {
                const thisField = fields.value[i];
                const markedForDestruction = _.get(thisField, "_destroy");
                if (!markedForDestruction) {
                  return (
                    <TableRow
                      thisField={thisField}
                      field={field}
                      key={i}
                      i={i}
                      columns={columns}
                      numberColumnPrefix={numberColumnPrefix}
                      numberColumnWidth={numberColumnWidth}
                      removeItem={() => {
                        removeItem(i);
                      }}
                    />
                  );
                }
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Button
        className="mt-2"
        size="sm"
        onClick={() => {
          fields.push({ ...addProps });
        }}
      >
        {buttonLabel}
      </Button>
      {_.isString(error) && <div className="text-danger">{error}</div>}
    </div>
  );
}
export default ListArray;

ListArray.defaultProps = {
  buttonLabel: "Add Item",
  addProps: {},
  showHeaders: true,
  tableStyle: { tableLayout: "fixed" },
  numberColumnPrefix: false,
  numberColumnWidth: "10%",
};

function TableRow(props) {
  const { field, i, removeItem, columns, thisField, numberColumnPrefix, numberColumnWidth } = props;

  const getColumns = _.isFunction(columns) ? columns(thisField, i) : columns;

  return (
    <tr className="pt-2">
      {numberColumnPrefix && <td style={{ width: numberColumnWidth }}>{`${numberColumnPrefix} ${i + 1}`}</td>}
      {_.map(getColumns, (column, n) => {
        return (
          <td key={n} style={{ verticalAlign: "top" }}>
            {column.array && <FieldArray name={`${field}.${column.name}`} {..._.omit(column, ["name", "label", "onChange"])} />}
            {!column.array && <Field name={`${field}.${column.name}`} {..._.omit(column, ["name", "label", "onChange"])} />}

            <OnChange name={`${field}.${column.name}`}>
              {(selectedValue) => {
                if (column.onChange) {
                  column.onChange(selectedValue, column);
                }
              }}
            </OnChange>
          </td>
        );
      })}

      <td>
        <Button size="sm" color="danger" onClick={removeItem}>
          X
        </Button>
      </td>
    </tr>
  );
}
