import React from 'react';

const ProjectQaSiteSurveillancesIndex = React.lazy(() => import("./index"))
const NewProjectQaSiteSurveillances = React.lazy(() => import("./new"))
const EditProjectQaSiteSurveillances = React.lazy(() => import("./edit"))

export default[
	{path:'/project/:project_id/qa/site/surveillances/:id/edit',component: EditProjectQaSiteSurveillances,name:'Edit ProjectQaSiteSurveillance'},
	{path:'/project/:project_id/qa/site/surveillances/new',component: NewProjectQaSiteSurveillances,name:'New ProjectQaSiteSurveillance'},  
	{path:'/project/:project_id/qa/site/surveillances',component: ProjectQaSiteSurveillancesIndex,name:'ProjectQaSiteSurveillances'}, 
]

// import ProjectQaSiteSurveillancesRoutes from "modules/project/qa/site/surveillances/web/routes"
// routes = _.concat(routes,ProjectQaSiteSurveillancesRoutes)
