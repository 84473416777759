import HasRole from "modules/5_view_helpers/HasRole";

const getTrainingNav = () => {
  const TrainingRoles = HasRole({ category: "Training" });

  const TrainingBookingsRoles = HasRole({
    resource_type: "Training::Bookings",
  });

  const TrainingCompaniesRoles = HasRole({
    resource_type: "Training::Company",
  });
  const TrainingCoursesRoles = HasRole({
    resource_type: "Training::Course",
  });
  const TrainingCourseCategoryRoles = HasRole({
    resource_type: "Training::CourseCategory",
  });
  const TrainingQualificationStatusRoles = HasRole({
    resource_type: "Training::QualificationStatus",
  });
  const TraniningNZQANumbersRoles = HasRole({
    resource_type: "Training::NzqaNumber",
  });

  if (TrainingRoles) {
    let trainingMenu = {};
    _.set(trainingMenu, "icon", "fa fa-graduation-cap");
    _.set(trainingMenu, "label", "Training");
    _.set(trainingMenu, "content", []);
    let content = _.get(trainingMenu, "content", []);

    content.push({ label: "Dashboard", to: "/training/dashboard" });

    if (TrainingBookingsRoles) {
      content.push({ label: "Bookings", to: "/training/bookings" });
    }

    if (TrainingCoursesRoles) {
      content.push({ label: "Courses", to: "/training/courses" });
    }
    if (TrainingCourseCategoryRoles) {
      content.push({
        label: "Course Categories",
        to: "/training/course_categories",
      });
    }
    if (TrainingCompaniesRoles) {
      content.push({ label: "Companies", to: "/training/companies" });
    }
    if (TraniningNZQANumbersRoles) {
      content.push({ label: "NZQA Numbers", to: "/training/nzqa_numbers" });
    }

    return trainingMenu;
  }
};

export default getTrainingNav;
