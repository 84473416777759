import React, { Component } from "react";
import { connect } from "react-redux";

import StoreBookoutItemForm from "./form";
import { createStoreBookoutItem } from "../actions";

import { Page, Navbar, Block } from "framework7-react";

// Persisted Stuff
import Form from "modules/4_final_form/PersistedForm";

// Helpers
import HasRole from "modules/5_view_helpers/HasRole";
import arrayMutators from "final-form-arrays";
import NotAuthorized from "modules/5_view_helpers/mobile/NotAuthorized";
import { getMWJobs } from "modules/moneyworks/jobs/actions";
import { getMWProducts } from "modules/moneyworks/products/actions";
import _ from "lodash";
function mapStateToProps(state) {
  return {
    mw_products: state.mw_products,
    mw_jobs: state.mw_jobs,
  };
}

class NewStoreBookoutItem extends Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.successfullySubmitted = this.successfullySubmitted.bind(this);
    const { mw_products, mw_jobs } = this.props;


    if (_.isEmpty(mw_products)) {
      this.props.getMWProducts();
    }

    if (_.isEmpty(mw_jobs)) {
      this.props.getMWJobs();
    }

    const createRole = HasRole(
      {
        resource_type: "Store::BookoutItem",
        name: ["create", "moderator"],
      },
      true
    );

    const returnUrl = `/store/bookout_items`;

    this.state = {
      returnUrl: returnUrl,
      createRole,
    };
  }

  submitForm(values) {
    const {
      f7router: { app },
    } = this.props;
    this.preloader = app.preloader;
    this.preloader.show();
    const createRequest = this.props.createStoreBookoutItem(values);
    createRequest.then(this.successfullySubmitted);
  }

  successfullySubmitted() {
    const {
      f7router,
      f7router: { app }
    } = this.props;


    const { returnUrl } = this.state;

    this.preloader.hide();
    app.toast
      .create({
        icon: '<i class="material-icons">check</i>',
        text: "Created Store Bookout Item!",
        position: "center",
        closeTimeout: 2000,
        on: {
          opened: () => {
            f7router.navigate(returnUrl);
          },
        },
      })
      .open();
  }

  render() {
    const { history, mw_products, mw_jobs } = this.props;
    const { returnUrl, createRole } = this.state;


    if (!createRole) {
      return <NotAuthorized />;
    }


    return (
      <Page>
        <Navbar title="New Store Bookout Item" backLink />

        {(_.isEmpty(mw_products) || _.isEmpty(mw_jobs)) && (
          <Block>Loading moneyworks jobs and products...</Block>
        )}
        {_.isNotEmpty(mw_products) && _.isNotEmpty(mw_jobs) && (<Form
          component={StoreBookoutItemForm}
          onSubmit={this.submitForm}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={{
            date: moment().format("YYYY-MM-DD"),
          }}
          formLabel="Create"
          cancelFunction={() => {
            history.push(returnUrl);
          }}
          {...this.props}
        />)}
      </Page>
    );
  }
}

export default connect(mapStateToProps, { createStoreBookoutItem, getMWJobs, getMWProducts })(NewStoreBookoutItem);
