import React, { useEffect } from "react";
import { Sidebar, Menu, MenuItem, SubMenu, sidebarClasses } from "react-pro-sidebar";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { NavbarBrand } from "reactstrap";

import getMainNav from "./MainNav.js";
import getProductionNav from "modules/production/web/ProductionNav";
import getQANav from "modules/qa/web/QANav";
import getStoreNav from "modules/store/web/StoreNav";

// Actions for Production maybe this should be all in the production nav file?
import { getProductionJobBoards } from "modules/production/job_boards/actions";
import { getProductionPpBoards } from "modules/production/pp_boards/actions";

import "./AppSidebar.css";

const mapStateToProps = (state) => {
  return {
    current_user: state.current_user,
    production_job_boards: state.production_job_boards,
    production_pp_boards: state.production_pp_boards,
    custom_forms: state.custom_forms,
  };
};

const AppSidebar = (props) => {
  const { current_user, showMenu, setShowMenu, locationType, custom_forms } = props;

  let display_custom_forms = _.filter(custom_forms, (form) => {
    return (form.include_parent_index_page || !form.parent_type) && !form.archived;
  });

  useEffect(() => {
    if (locationType == "production") {
      // Get Production Job Boards and PP Boards if the location type is production
      props.getProductionJobBoards();
      props.getProductionPpBoards();
    }
  }, [locationType]);

  let menuLabel = "Menu";
  // let SidebarNav = MainSidebarNav;
  let sidebarLinks = getMainNav(current_user?.current_project_id, display_custom_forms);
  if (locationType == "production") {
    const { production_job_boards, production_pp_boards } = props;
    menuLabel = "Production Menu";
    sidebarLinks = getProductionNav(production_job_boards, production_pp_boards);
  }
  if (locationType == "qa") {
    menuLabel = "QA Menu";
    sidebarLinks = getQANav(current_user?.current_project_id);
  }
  if (locationType == "store") {
    menuLabel = "Store Menu";
    sidebarLinks = getStoreNav();
  }

  return (
    <Sidebar
      onBackdropClick={() => setShowMenu(false)}
      toggled={showMenu}
      breakPoint="md"
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          backgroundColor: "white",
        },
      }}
    >
      <NavbarBrand className="d-none d-md-block pl-4">
        <Link to="/">
          <img src="/assets/lion-logo-HD.png" width="130" alt="logo" />
        </Link>
      </NavbarBrand>
      <div className="sidebar-heading pl-4">{menuLabel}</div>
      <Menu
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            // only apply styles on first level elements of the tree
            if (level != 0) {
              return {
                paddingLeft: "20px",
                height: "unset",
                color: "grey",
              };
            } else {
              return {
                height: "unset",
              };
            }
          },
        }}
      >
        {_.map(sidebarLinks, (navItem, i) => {
          return <SidebarMenuItems key={i} navItem={navItem} />;
        })}
      </Menu>
    </Sidebar>
  );
};

export default connect(mapStateToProps, { getProductionJobBoards, getProductionPpBoards })(AppSidebar);

const SidebarMenuItems = ({ navItem }) => {
  if (navItem.content) {
    return (
      <SubMenu label={navItem.label} icon={<i className={navItem.icon} />}>
        {_.map(navItem.content, (subNavItem, i) => (
          <SidebarMenuItems key={i} navItem={subNavItem} />
        ))}
      </SubMenu>
    );
  }

  return (
    <MenuItem icon={<i className={navItem.icon} />} component={navItem.external ? <a href={navItem.to} /> : <Link to={navItem.to} />}>
      {navItem.label}
    </MenuItem>
  );
};
