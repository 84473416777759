import axios from "axios";

const prepareData = (data) => {
  return { project_technical_details_paint_system: data };
};

// Plural Actions
export const GET_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEMS = "get_project_technical_details_paint_systems";
export const getProjectTechnicalDetailsPaintSystems = (project_id) => {
  const url = `/project/${project_id}/technical_details/paint_systems.json`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEMS,
    payload: request,
  };
};

// Singular Actions
export const CREATE_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM = "create_project_technical_details_paint_system";
export const createProjectTechnicalDetailsPaintSystem = (project_id, data) => {
  const url = `/project/${project_id}/technical_details/paint_systems.json`;
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM,
    payload: request,
  };
};

export const GET_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM = "get_project_technical_details_paint_system";
export const getProjectTechnicalDetailsPaintSystem = (project_id, id) => {
  const url = `/project/${project_id}/technical_details/paint_systems/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM,
    payload: request,
  };
};

export const APPROVE_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM = "approve_project_technical_details_paint_system";
export const approveProjectTechnicalDetailsPaintSystem = (project_id, id) => {
  const url = `/project/${project_id}/technical_details/paint_systems/${id}/approve.json`;
  const request = axios({ method: "post", url: url, data: {} });
  return {
    type: APPROVE_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM,
    payload: request,
  };
};

export const UNAPPROVE_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM = "unapprove_project_technical_details_paint_system";
export const unapproveProjectTechnicalDetailsPaintSystem = (project_id, id) => {
  const url = `/project/${project_id}/technical_details/paint_systems/${id}/unapprove.json`;
  const request = axios({ method: "post", url: url, data: {} });
  return {
    type: UNAPPROVE_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM,
    payload: request,
  };
};

export const UPDATE_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM = "update_project_technical_details_paint_system";
export const updateProjectTechnicalDetailsPaintSystem = (project_id, id, data) => {
  const url = `/project/${project_id}/technical_details/paint_systems/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM,
    payload: request,
  };
};

export const DESTROY_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM = "destroy_project_technical_details_paint_system";
export const destroyProjectTechnicalDetailsPaintSystem = (project_id, id) => {
  const url = `/project/${project_id}/technical_details/paint_systems/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM,
    payload: request,
  };
};
