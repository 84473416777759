import { 
	GET_PROJECTS_QA_SITE_CHECKLIST_ITEMS, 
	GET_PROJECTS_QA_SITE_CHECKLIST_ITEM,
	CREATE_PROJECTS_QA_SITE_CHECKLIST_ITEM,
	UPDATE_PROJECTS_QA_SITE_CHECKLIST_ITEM,
} from "./actions"

export function ProjectsQaSiteChecklistItemReducer(state = [], action) {
	switch (action.type) {
		case `${GET_PROJECTS_QA_SITE_CHECKLIST_ITEMS}_FULFILLED`:{
			return _.mapKeys(action.payload.data, "id");
		}
		case `${GET_PROJECTS_QA_SITE_CHECKLIST_ITEM}_FULFILLED`:
		case `${CREATE_PROJECTS_QA_SITE_CHECKLIST_ITEM}_FULFILLED`:
		case `${UPDATE_PROJECTS_QA_SITE_CHECKLIST_ITEM}_FULFILLED`:{
			let newState = _.clone(state);
			const newItem = action.payload.data;
			const id = _.get(newItem,"id")
			newState[id] = newItem
			return newState;
		}		
		// case `${DESTROY_PROJECTS_QA_SITE_CHECKLIST_ITEM}_FULFILLED`:{
		// 	let newState = _.clone(state);
		// 	const deletedItem = action.payload.data;
		// 	const id = _.get(deletedItem,"id")
		// 	newState = _.filter(newState,i => i.id != id)
		// 	return newState;
		// }
		default:
			return state;
	}
}

// Move this to the reducer page
// import { ProjectsQaSiteChecklistItemReducer } from "modules/projects/qa/site/checklist_items/reducer"

// projects_qa_site_checklist_items:ProjectsQaSiteChecklistItemReducer,
