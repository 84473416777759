import axios from "axios";

const prepareData = (data) => {return {paint_code_detail:data}}

// Plural Actions
export const GET_PAINT_CODE_DETAILS = "get_paint_code_details";
export const getPaintCodeDetails = () => {
	const url = `/paint/code_details.json`
	const request = axios.get(url);
	return {
		type: GET_PAINT_CODE_DETAILS,
		payload: request
	};
};


// Singular Actions
export const CREATE_PAINT_CODE_DETAIL = "create_paint_code_detail";
export const createPaintCodeDetail = (data) => {
	const url = `/paint/code_details.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PAINT_CODE_DETAIL,
		payload: request
	};
};

export const GET_PAINT_CODE_DETAIL = "get_paint_code_detail";
export const getPaintCodeDetail = (id) => {
	const url = `/paint/code_details/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PAINT_CODE_DETAIL,
		payload: request
	};
};

export const UPDATE_PAINT_CODE_DETAIL = "update_paint_code_detail";
export const updatePaintCodeDetail = (id,data) => {
	const url = `/paint/code_details/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PAINT_CODE_DETAIL,
		payload: request
	};
};

export const DESTROY_PAINT_CODE_DETAIL = "destroy_paint_code_detail";
export const destroyPaintCodeDetail = (id) => {
	const url = `/paint/code_details/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PAINT_CODE_DETAIL,
		payload: request
	};
};
