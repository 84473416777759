export const GET_PRODUCTION_STRUMIS_BUNDLE =
  "GET_PRODUCTION_STRUMIS_BUNDLE";
export const getProductionStrumisBundle = (strumis_contract_id,bundle_name) => {
  const url = `/production/strumis/contract/bundles/show.json?strumis_contract_id=${strumis_contract_id}&bundle_name=${bundle_name}`;
  const request = axios.get(url);
  return {
    type: GET_PRODUCTION_STRUMIS_BUNDLE,
    payload: request,
  };
};
