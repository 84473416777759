import React from 'react';

const ProductionPpBoard = React.lazy(() => import("./index"))
const NewProductionPpBoard = React.lazy(() => import("./new"))
const EditProductionPpBoard = React.lazy(() => import("./edit"))
const ShowProductionPpBoard = React.lazy(() => import("./show"))

export default[
	{path:'/production/pp_boards/:id/show',component: ShowProductionPpBoard,name:'Show ProductionPpBoard'},
	{path:'/production/pp_boards/:id/edit',component: EditProductionPpBoard,name:'Edit ProductionPpBoard'},
	{path:'/production/pp_boards/new',component: NewProductionPpBoard,name:'New ProductionPpBoard'},  
	{path:'/production/pp_boards',component: ProductionPpBoard,name:'ProductionPpBoard'}, 
]

// import ProductionPpBoardRoutes from "modules/production/pp_boards/web/routes"
// routes = _.concat(routes,ProductionPpBoardRoutes)
