import axios from "axios";

const prepareData = (data) => {
  return { paint_treatment_grade: data };
};

// Plural Actions
export const GET_PAINT_TREATMENT_GRADES = "get_paint_treatment_grades";
export const getPaintTreatmentGrades = () => {
  const url = `/paint/treatment_grades.json`;
  const request = axios.get(url);
  return {
    type: GET_PAINT_TREATMENT_GRADES,
    payload: request,
  };
};

// Singular Actions
export const CREATE_PAINT_TREATMENT_GRADE = "create_paint_treatment_grade";
export const createPaintTreatmentGrade = (data) => {
  const url = `/paint/treatment_grades.json`;
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_PAINT_TREATMENT_GRADE,
    payload: request,
  };
};

export const GET_PAINT_TREATMENT_GRADE = "get_paint_treatment_grade";
export const getPaintTreatmentGrade = (id) => {
  const url = `/paint/treatment_grades/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_PAINT_TREATMENT_GRADE,
    payload: request,
  };
};

export const UPDATE_PAINT_TREATMENT_GRADE = "update_paint_treatment_grade";
export const updatePaintTreatmentGrade = (id, data) => {
  const url = `/paint/treatment_grades/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_PAINT_TREATMENT_GRADE,
    payload: request,
  };
};

export const DESTROY_PAINT_TREATMENT_GRADE = "destroy_paint_treatment_grade";
export const destroyPaintTreatmentGrade = (id) => {
  const url = `/paint/treatment_grades/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_PAINT_TREATMENT_GRADE,
    payload: request,
  };
};
