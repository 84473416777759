import axios from "axios";

const prepareData = (data) => {return {project_qa_welding_inspections_welding_type:data}}

// Plural Actions
export const GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_TYPES = "get_project_qa_welding_inspections_welding_types";
export const getProjectQaWeldingInspectionsWeldingTypes = () => {
	const url = `/project/qa/welding_inspections/welding_types.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_TYPES,
		payload: request
	};
};


// Singular Actions
export const CREATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_TYPE = "create_project_qa_welding_inspections_welding_type";
export const createProjectQaWeldingInspectionsWeldingType = (data) => {
	const url = `/project/qa/welding_inspections/welding_types.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_TYPE,
		payload: request
	};
};

export const GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_TYPE = "get_project_qa_welding_inspections_welding_type";
export const getProjectQaWeldingInspectionsWeldingType = (id) => {
	const url = `/project/qa/welding_inspections/welding_types/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_TYPE,
		payload: request
	};
};

export const UPDATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_TYPE = "update_project_qa_welding_inspections_welding_type";
export const updateProjectQaWeldingInspectionsWeldingType = (id,data) => {
	const url = `/project/qa/welding_inspections/welding_types/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_TYPE,
		payload: request
	};
};

export const DESTROY_PROJECT_QA_WELDING_INSPECTIONS_WELDING_TYPE = "destroy_project_qa_welding_inspections_welding_type";
export const destroyProjectQaWeldingInspectionsWeldingType = (id) => {
	const url = `/project/qa/welding_inspections/welding_types/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PROJECT_QA_WELDING_INSPECTIONS_WELDING_TYPE,
		payload: request
	};
};
