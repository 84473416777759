import React, { Component, Fragment } from "react";
import { ListInput, Input, Button } from "framework7-react";

class InputField extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const {
      input: { onChange },
      upcase,
    } = this.props;
    let newValue = upcase ? e.target.value.toLocaleUpperCase() : e.target.value;
    onChange(newValue);
  }

  render() {
    const {
      input: { value, onChange, onBlur, type, name },
      meta: { touched, error, warning },
      tabIndex,
      listInput,
      showNow,
      ...rest
    } = this.props;

    let InputType;
    if (listInput) {
      InputType = ListInput;
    } else {
      InputType = Input;
    }

    // Pass errors back to parent
    // if(touched && error && this.props.error){this.props.error = error}
    // Pass errors back to parent

    return (
      <Fragment>
        <InputType
          onChange={this.handleChange}
          onBlur={onBlur}
          value={value}
          errorMessage={touched && error ? error : undefined}
          errorMessageForce={touched && error ? true : false}
          tabindex={tabIndex}
          type="time"
          {...rest}
        >
          {showNow && !value && (
            <Button
              color="green"
              className="margin"
              slot="content-end"
              fill
              onClick={() => {
                onChange(moment().format("HH:mm"));
              }}
            >
              NOW
            </Button>
          )}
        </InputType>
      </Fragment>
    );
  }
}

InputField.defaultProps = {
  inlineLabel: false,
  listInput: true,
};

export default InputField;
