import React from "react";

const ProjectTechnicalDetailsPaintSystemsIndex = React.lazy(() => import("./index"));
const NewProjectTechnicalDetailsPaintSystems = React.lazy(() => import("./new"));
const EditProjectTechnicalDetailsPaintSystems = React.lazy(() => import("./edit"));

export default [
  {
    path: "/project/:project_id/technical_details/paint_systems/:id/edit",
    component: EditProjectTechnicalDetailsPaintSystems,
    name: "Edit ProjectTechnicalDetailsPaintSystem",
  },
  {
    path: "/project/:project_id/technical_details/paint_systems/new",
    component: NewProjectTechnicalDetailsPaintSystems,
    name: "New ProjectTechnicalDetailsPaintSystem",
  },
  {
    path: "/project/:project_id/technical_details/paint_systems",
    component: ProjectTechnicalDetailsPaintSystemsIndex,
    name: "ProjectTechnicalDetailsPaintSystems",
  },
];

// import ProjectTechnicalDetailsPaintSystemsRoutes from "modules/project/technical_details/paint_systems/web/routes"
// routes = _.concat(routes,ProjectTechnicalDetailsPaintSystemsRoutes)
