const required = (value) => {
  if (value === "<p><br></p>") {
    return "is required";
  } else if (value || typeof value === "number") {
    return undefined;
  } else {
    return "is required";
  }
};

export default required;
