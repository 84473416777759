import axios from "axios";

const prepareData = (data) => {
  return { department: data };
};

// Plural Actions
export const GET_DEPARTMENTS = "get_departments";
export const getDepartments = () => {
  const url = "/departments.json";
  const request = axios.get(url);
  return {
    type: GET_DEPARTMENTS,
    payload: request,
  };
};

// Singular Actions
export const CREATE_DEPARTMENT = "create_department";
export const createDepartment = (data) => {
  const url = "/departments.json";
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_DEPARTMENT,
    payload: request,
  };
};

export const GET_DEPARTMENT = "get_department";
export const getDepartment = (id) => {
  const url = `/departments/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_DEPARTMENT,
    payload: request,
  };
};

export const UPDATE_DEPARTMENT = "update_department";
export const updateDepartment = (id, data) => {
  const url = `/departments/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_DEPARTMENT,
    payload: request,
  };
};

export const DESTROY_DEPARTMENT = "destroy_department";
export const destroyDepartment = (id) => {
  const url = `/departments/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_DEPARTMENT,
    payload: request,
    meta: { id: id },
  };
};
