import React from 'react';

// const ProjectQaSiteSiteInstallation = React.lazy(() => import("./index"))
const NewProjectQaSiteSiteInstallation = React.lazy(() => import("./new"))
const EditProjectQaSiteSiteInstallation = React.lazy(() => import("./edit"))

export default[
	{path:'/project/:project_id/qa/site/site_installations/:id/edit',component: EditProjectQaSiteSiteInstallation,name:'Edit ProjectQaSiteSiteInstallation'},
	{path:'/project/:project_id/qa/site/site_installations/new',component: NewProjectQaSiteSiteInstallation,name:'New ProjectQaSiteSiteInstallation'},  
	// {path:'/project/qa/site/site_installations',component: ProjectQaSiteSiteInstallation,name:'ProjectQaSiteSiteInstallation'}, 
]

// import ProjectQaSiteSiteInstallationRoutes from "modules/project/qa/site/site_installations/web/routes"
// routes = _.concat(routes,ProjectQaSiteSiteInstallationRoutes)
