import React, { useEffect } from "react";
import { connect } from "react-redux";

import Select from "react-select";

import HasRole from "modules/5_view_helpers/HasRole";
import { getProjects } from "../actions";

function mapStateToProps(state) {
  return {
    projects: state.projects,
  };
}

const SelectProject = (props) => {
  const { projects, value, onChange } = props;
  const mappedOptions = _.map(projects, (p) => {
    return { value: p?.id, label: p?.display_name };
  });

  const readRole = HasRole({
    resource_type: "Project",
    name: ["read", "moderator"],
  });

  useEffect(() => {
    if (readRole) {
      props.getProjects(true);
    }
  }, [readRole]);

  if (readRole) {
    return (
      <Select
        value={_.find(mappedOptions, (p) => p.value == value)}
        onChange={onChange}
        options={mappedOptions}
        placeholder="Select Project..."
        className="w-100"
      />
    );
  } else {
    return <Select placeholder="No read permission for projects" className="w-100" />;
  }
};

export default connect(mapStateToProps, { getProjects })(SelectProject);
