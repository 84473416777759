import React from "react";

import { ListItem, Toggle } from "framework7-react";

export default class Checkbox extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      input: { onChange, value, checked },
      color,
      label,
      disabled,
    } = this.props;

    return <ListItem checkbox title={label} onChange={onChange} checked={checked} />;
  }
}
