import axios from "axios";

const prepareData = (data) => {return {paint_surface_prep:data}}

// Plural Actions
export const GET_PAINT_SURFACE_PREPS = "get_paint_surface_preps";
export const getPaintSurfacePreps = () => {
	const url = `/paint/surface_preps.json`
	const request = axios.get(url);
	return {
		type: GET_PAINT_SURFACE_PREPS,
		payload: request
	};
};


// Singular Actions
export const CREATE_PAINT_SURFACE_PREP = "create_paint_surface_prep";
export const createPaintSurfacePrep = (data) => {
	const url = `/paint/surface_preps.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PAINT_SURFACE_PREP,
		payload: request
	};
};

export const GET_PAINT_SURFACE_PREP = "get_paint_surface_prep";
export const getPaintSurfacePrep = (id) => {
	const url = `/paint/surface_preps/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PAINT_SURFACE_PREP,
		payload: request
	};
};

export const UPDATE_PAINT_SURFACE_PREP = "update_paint_surface_prep";
export const updatePaintSurfacePrep = (id,data) => {
	const url = `/paint/surface_preps/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PAINT_SURFACE_PREP,
		payload: request
	};
};

export const DESTROY_PAINT_SURFACE_PREP = "destroy_paint_surface_prep";
export const destroyPaintSurfacePrep = (id) => {
	const url = `/paint/surface_preps/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PAINT_SURFACE_PREP,
		payload: request
	};
};
