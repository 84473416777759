import React from 'react';

const InternalCompanies = React.lazy(() => import("./index"))
const NewInternalCompanies = React.lazy(() => import("./new"))
const EditInternalCompanies = React.lazy(() => import("./edit"))

export default[
	{path:'/internal_companies/:id/edit',component: EditInternalCompanies,name:'Edit InternalCompany'},
	{path:'/internal_companies/new',component: NewInternalCompanies,name:'New InternalCompany'},  
	{path:'/internal_companies',component: InternalCompanies,name:'InternalCompanies'}, 
]

// import InternalCompaniesRoutes from "modules/internal_companies/web/routes"
// routes = _.concat(routes,InternalCompaniesRoutes)
