import React from 'react';

const ProjectQaActionItemsIndex = React.lazy(() => import("./index"))
const NewProjectQaActionItems = React.lazy(() => import("./new"))
const EditProjectQaActionItems = React.lazy(() => import("./edit"))


export default [
  {
    path: "/project/:project_id/qa/action_items/:id/edit",
    component: EditProjectQaActionItems,
    name: "Edit ProjectQaActionItem",
  },
  {
    path: "/project/:project_id/qa/action_items/new",
    component: NewProjectQaActionItems,
    name: "New ProjectQaActionItem",
  },
  {
    path: "/project/qa/action_items",
    component: ProjectQaActionItemsIndex,
    name: "ProjectQaActionItems",
  },
];

// import ProjectQaActionItemsRoutes from "modules/project/qa/action_items/web/routes"
// routes = _.concat(routes,ProjectQaActionItemsRoutes)
