import React, { Component } from "react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Row, Col } from "reactstrap";

const defaultConfig = {
  toolbar: {
    items: [
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      "outdent",
      "indent",
      "|",
      "imageUpload",
      "blockQuote",
      "insertTable",
      "undo",
      "redo",
    ],
  },
  language: "en-au",
  image: {
    toolbar: ["imageTextAlternative", "toggleImageCaption", "imageStyle:inline", "imageStyle:block", "imageStyle:side"],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
};

const CKEditorField = (props) => {
  const {
    input: { value, onChange, onBlur, name, type },
    meta: { touched, error, warning },
    label,
    errorName,
    height,
    disabled,
  } = props;

  return (
    <div style={{ width: "100%", height: height, resize: "vertical", overflow: "auto" }} className="mb-3">
      <Row style={{ height: "90%" }}>
        <Col style={{ height: "100%" }}>
          <CKEditor
            editor={Editor}
            data={value}
            config={defaultConfig}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              // console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              onChange(data);
            }}
            onBlur={onBlur}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {touched &&
            ((error && (
              <span className="pt-5 text-danger">
                {label ? label : errorName ? errorName : name} {error}
              </span>
            )) ||
              (warning && <span className="text-warning">{warning}</span>))}
        </Col>
      </Row>
    </div>
  );
};

CKEditorField.defaultProps = {
  height: "150px",
};

export default CKEditorField;
