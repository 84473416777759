import React, { Fragment, useState } from "react";
import { Link, List, ListItem, NavRight, Navbar, Popover, Popup, f7 } from "framework7-react";
import { useFormState } from "react-final-form";
import _ from "lodash";

const RadioButtons = (props) => {
  const [opened, setOpened] = useState(false);

  const {
    input: { onBlur, name, onChange, value },
    meta: { touched, error },
    label,
    options,
    checkboxes,
    placeholder,
    openIn,
    footer,
    style,
  } = props;

  let OpenContainerType;
  if (openIn === "popover") {
    OpenContainerType = Popover;
  } else if (openIn === "popup") {
    OpenContainerType = Popup;
  }

  return (
    <Fragment>
      <ListItem
        id={_.snakeCase(name)}
        onClick={() => {
          setOpened(true);
        }}
        title={label}
        after={value || "Select..."}
        link="#"
        footer={footer}
        style={style}
      >
        {touched && error ? (
          <div slot="footer" className="margin-left text-color-red">
            <small>{error}</small>
          </div>
        ) : null}
      </ListItem>
      <OpenContainerType
        targetEl={`#${_.snakeCase(name)}`}
        opened={opened}
        onPopoverClosed={() => {
          setOpened(false);
        }}
        onPopupClosed={() => {
          setOpened(false);
        }}
      >
        {openIn === "popup" && (
          <Navbar title={placeholder || `Select ${label}`}>
            <NavRight>
              <Link popupClose>Close</Link>
            </NavRight>
          </Navbar>
        )}

        <List>
          {_.map(options, (option, i) => (
            <ListItem
              radio
              name={name}
              key={i}
              value={option.value}
              title={option.label}
              onChange={() => {
                onChange(option.value);
                if (openIn === "popover") {
                  f7.popover.close();
                } else if (openIn === "popup") {
                  f7.popup.close();
                }
              }}
              checked={value === option.value}
            />
          ))}
          {props.children}
        </List>
      </OpenContainerType>
    </Fragment>
  );
};

RadioButtons.defaultProps = {
  openIn: "popover",
};

export default RadioButtons;
