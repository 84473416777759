import React, { Fragment, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

// Helpers
import HasRole from "modules/5_view_helpers/HasRole";
import EditSlidng from "./edit_sliding";

const ProjectUpdateButton = (props) => {
  const { project_id } = props;
  const [modalOpen, setModalOpen] = useState(false);

  const updateRole = HasRole({
    resource_type: "Project",
    name: ["update", "moderator"],
  });
  // Hide button if the user does not have the role
  if (!updateRole) {
    return null;
  }
  return (
    <Fragment>
      <FontAwesomeIcon
        className="ml-2"
        icon={faPencilAlt}
        style={{ cursor: "pointer" }}
        onClick={() => {
          setModalOpen(!modalOpen);
        }}
      />

      {modalOpen && (
        <EditSlidng
          project_id={project_id}
          isOpen={modalOpen}
          toggle={() => {
            setModalOpen(!modalOpen);
          }}
        />
      )}
    </Fragment>
  );
};

export default ProjectUpdateButton;
