import React, { Component } from "react";
import { connect } from "react-redux";

import ProjectQaActionItemForm from "./form";
import { updateProjectQaActionItem, getProjectQaActionItem, destroyProjectQaActionItem } from "../actions";

import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";

import { Page, Navbar } from "framework7-react";

// Helpers
import NotAuthorized from "modules/5_view_helpers/mobile/NotAuthorized";
import HasRole from "modules/5_view_helpers/HasRole";

function mapStateToProps(state, ownProps) {
  const {
    f7route: {
      params: { id },
    },
  } = ownProps;

  return {
    current_user: state.current_user,
    project_qa_action_items: state.project_qa_action_items[id],
  };
}

class EditProjectQaActionItem extends Component {
  constructor(props) {
    super(props);

    this.submitForm = this.submitForm.bind(this);
    this.successfullySubmitted = this.successfullySubmitted.bind(this);

    const updateRole = HasRole(
      {
        resource_type: "Project::Qa::ActionItem",
        name: ["update", "moderator"],
      },
      true
    );

    const { project_id, id } = this.props;

    if (updateRole) {
      this.props.getProjectQaActionItem(project_id, id);
    }

    const returnUrl = `/project/qa/action_items`;

    this.state = {
      returnUrl: returnUrl,
      updateRole,
    };
  }

  submitForm(values) {
    const {
      f7router: { app },
      project_id,
      id,
    } = this.props;

    app.dialog.confirm(
      "Send Email Notification to Owner/Person Responsible?",
      () => {
        // When clicked okay
        this.preloader = app.preloader;
        this.preloader.show();
        const updateRequest = this.props.updateProjectQaActionItem(project_id, id, values, true);
        updateRequest.then(this.successfullySubmitted);
      },
      () => {
        // When clicked cancel
        this.preloader = app.preloader;
        this.preloader.show();
        const updateRequest = this.props.updateProjectQaActionItem(project_id, id, values, false);
        updateRequest.then(this.successfullySubmitted);
      }
    );
  }

  successfullySubmitted() {
    const {
      f7router,
      f7router: { app, navigate },
    } = this.props;

    const { returnUrl } = this.state;

    this.preloader.hide();
    app.toast
      .create({
        icon: '<i class="material-icons">check</i>',
        text: "Updated Action Item!",
        position: "center",
        closeTimeout: 2000,
        on: {
          opened: () => {
            f7router.navigate(returnUrl);
          },
        },
      })
      .open();
  }

  render() {
    const {
      project_qa_action_items,
      history,
      current_user: { admin, roles },
    } = this.props;
    const { returnUrl, updateRole } = this.state;

    if (!updateRole) {
      return <NotAuthorized />;
    }
    return (
      <Page>
        <Navbar title="Update Action Item" backLink />
        {_.isNotEmpty(project_qa_action_items) && (
          <Form
            component={ProjectQaActionItemForm}
            onSubmit={this.submitForm}
            formLabel="Update"
            mutators={{
              ...arrayMutators,
            }}
            initialValues={project_qa_action_items}
            cancelFunction={() => {
              history.push(returnUrl);
            }}
            {...this.props}
          />
        )}
      </Page>
    );
  }
}

export default connect(mapStateToProps, {
  getProjectQaActionItem,
  updateProjectQaActionItem,
  destroyProjectQaActionItem,
})(EditProjectQaActionItem);
