import React from "react";

const CustomFormRecordsIndex = React.lazy(() => import("./index"));
const NewCustomFormRecords = React.lazy(() => import("./new"));
const EditCustomFormRecords = React.lazy(() => import("./edit"));
const OpenCustomFormRecords = React.lazy(() => import("./open"));

export default [
  { path: "/custom_form/:custom_form_id/records/:id/open", component: OpenCustomFormRecords, name: "OpenCustomFormRecords" },
  { path: "/custom_form/:custom_form_id/records/:id/edit/:parent_id?", component: EditCustomFormRecords, name: "Edit CustomFormRecord" },
  { path: "/custom_form/:custom_form_id/records/new/:parent_id?", component: NewCustomFormRecords, name: "New CustomFormRecord" },
  { path: "/custom_form/:custom_form_id/records", component: CustomFormRecordsIndex, name: "CustomFormRecords" },
];

// import CustomFormRecordsRoutes from "modules/custom_form/records/web/routes"
// routes = _.concat(routes,CustomFormRecordsRoutes)
