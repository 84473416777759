import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";

import { Page, BlockTitle, Block, Button, List, ListItem, Fab, Icon, AccordionContent, Preloader } from "framework7-react";
import SelectProject from "modules/projects/mobile/select";

import { getProjectQaSiteSurveillances } from "../actions";

import { setCurrentProject } from "modules/current_user/actions";

import AppNavbar from "modules/1_layout/mobile/AppNavbar";

import NotAuthorized from "modules/5_view_helpers/mobile/NotAuthorized";

// Helpers
import HasRole from "modules/5_view_helpers/HasRole";

import Interweave from "interweave";

import { handleNewClick, ResumePersistedButton } from "modules/5_view_helpers/mobile/PersistedHelpers.jsx";
import { persistedName } from "./vairables";

function mapStateToProps(state) {
  const current_project_id = state.current_user?.current_project_id;
  const project = state.projects?.[current_project_id];
  return {
    project,
    current_project_id,
    project_qa_site_surveillances: state.project_qa_site_surveillances,
  };
}

const ProjectQaSiteSurveillancesIndex = (props) => {
  const { current_project_id, project_qa_site_surveillances, f7router, project } = props;
  const [loading, setLoading] = useState(false);

  const readRole = HasRole({ resource_type: "Project::Qa::Site::Surveillance", name: ["read", "moderator"] }, true);
  const createRole = HasRole({ resource_type: "Project::Qa::Site::Surveillance", name: ["create", "moderator"] }, true);
  const updateRole = HasRole({ resource_type: "Project::Qa::Site::Surveillance", name: ["update", "moderator"] }, true);

  const newUrl = `/project/${current_project_id}/qa/site/surveillances/new`;

  useEffect(() => {
    if (current_project_id) {
      setLoading(true);
      props.getProjectQaSiteSurveillances(current_project_id).then(() => {
        setLoading(false);
      });
    }
  }, [current_project_id]);

  if (!readRole) {
    return <NotAuthorized />;
  }

  return (
    <Page>
      <AppNavbar title={`${project?.display_name} - Surveillance Reports`} />

      <SelectProject
        onChange={({ target: { value } }) => {
          props.setCurrentProject(value);
        }}
        value={current_project_id}
      />
      <ResumePersistedButton name={persistedName} url={newUrl} />

      <BlockTitle>{project?.display_name} - Surveillance Reports</BlockTitle>

      {loading && <Preloader />}
      {!loading && (
        <Fragment>
          <List accordionList>
            {_.map(project_qa_site_surveillances, (surveillance_report, i) => (
              <SurveillanceReportView key={i} surveillance_report={surveillance_report} slot="list" f7router={f7router} updateRole={updateRole} />
            ))}
          </List>

          {_.isEmpty(project_qa_site_surveillances) && (
            <List>
              <ListItem>No Surveillance Reports</ListItem>
            </List>
          )}
        </Fragment>
      )}

      {createRole && (
        <Fab
          position="right-bottom"
          slot="fixed"
          text="New"
          href=""
          onClick={() => {
            handleNewClick(f7router, persistedName, newUrl);
          }}
        >
          <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
        </Fab>
      )}
    </Page>
  );
};

export default connect(mapStateToProps, {
  getProjectQaSiteSurveillances,
  setCurrentProject,
})(ProjectQaSiteSurveillancesIndex);

const SurveillanceReportView = (props) => {
  const { surveillance_report, updateRole } = props;

  const title = `${moment(surveillance_report.date).format("DD/MM/YYYY")} - #${surveillance_report.report_number}`;

  return (
    <ListItem accordionItem title={title}>
      <AccordionContent>
        <Block>
          <table>
            <tbody>
              <tr>
                <th>Date</th>
                <td>{moment(surveillance_report.date).format("DD/MM/YYYY")}</td>
              </tr>
              <tr>
                <th>Time In</th>
                <td>{surveillance_report.time_in}</td>
              </tr>
              <tr>
                <th>Report Number</th>
                <td>{surveillance_report.report_number}</td>
              </tr>
              <tr>
                <th>Site Representative</th>
                <td>{surveillance_report.site_representive_display_name}</td>
              </tr>
              <tr>
                <th>Areas Visited</th>
                <td>{surveillance_report.areas_visited}</td>
              </tr>
              <tr>
                <th>Work Being Performed</th>
                <td>
                  <Interweave content={surveillance_report.work_being_performed} />
                </td>
              </tr>
              <tr>
                <th>Time Out</th>
                <td>{surveillance_report.time_out ? surveillance_report.time_out : "Not Set"}</td>
              </tr>
              <tr>
                <th>For Client</th>
                <td>{surveillance_report.for_client ? <div style={{backgroundColor:"yellow"}}>Yes</div> : "No"}</td>
              </tr>
            </tbody>
          </table>
          <Button fill external target="_blank" color="green" href={`/project/${surveillance_report?.project_id}/qa/site/surveillances/${surveillance_report?.id}.pdf`}>
            PDF Report
          </Button>
          {updateRole && (
            <Button
              className="margin-top"
              fill
              color="blue"
              href={`/project/${surveillance_report?.project_id}/qa/site/surveillances/${surveillance_report?.id}/edit`}
            >
              Edit
            </Button>
          )}
        </Block>
      </AccordionContent>
    </ListItem>
  );
};
