import axios from "axios";

const prepareData = (data) => {
  return { production_job_board: data };
};

// Plural Actions
export const GET_PRODUCTION_JOB_BOARDS = "get_production_job_boards";
export const getProductionJobBoards = () => {
  const url = "/production/job_boards.json";
  const request = axios.get(url);
  return {
    type: GET_PRODUCTION_JOB_BOARDS,
    payload: request,
  };
};

// Singular Actions
export const CREATE_PRODUCTION_JOB_BOARD = "create_production_job_board";
export const createProductionJobBoard = (data) => {
  const url = "/production/job_boards.json";
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_PRODUCTION_JOB_BOARD,
    payload: request,
  };
};

export const GET_PRODUCTION_JOB_BOARD = "get_production_job_board";
export const getProductionJobBoard = (id, getStrumisData = false, groupBy) => {
  let url = `/production/job_boards/${id}.json`;
  if (getStrumisData) {
    url += "?getStrumisData";
    if (groupBy) {
      url += `&groupBy=${groupBy}`;
    }
  }

  const request = axios.get(url);
  return {
    type: GET_PRODUCTION_JOB_BOARD,
    payload: request,
  };
};

export const UPDATE_PRODUCTION_JOB_BOARD = "update_production_job_board";
export const updateProductionJobBoard = (id, data) => {
  const url = `/production/job_boards/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_PRODUCTION_JOB_BOARD,
    payload: request,
  };
};

export const DESTROY_PRODUCTION_JOB_BOARD = "destroy_production_job_board";
export const destroyProductionJobBoard = (id) => {
  const url = `/production/job_boards/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_PRODUCTION_JOB_BOARD,
    payload: request,
    meta: { id: id },
  };
};
