import axios from "axios";

const prepareData = (data) => {return {project_communications_custom_reference:data}}

// Plural Actions
export const GET_PROJECT_COMMUNICATIONS_CUSTOM_REFERENCES = "get_project_communications_custom_references";
export const getProjectCommunicationsCustomReferences = (project_id) => {
	const url = `/project/${project_id}/communications/custom_references.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_COMMUNICATIONS_CUSTOM_REFERENCES,
		payload: request
	};
};


// Singular Actions
export const CREATE_PROJECT_COMMUNICATIONS_CUSTOM_REFERENCE = "create_project_communications_custom_reference";
export const createProjectCommunicationsCustomReference = (project_id,data) => {
	const url = `/project/${project_id}/communications/custom_references.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PROJECT_COMMUNICATIONS_CUSTOM_REFERENCE,
		payload: request
	};
};

export const GET_PROJECT_COMMUNICATIONS_CUSTOM_REFERENCE = "get_project_communications_custom_reference";
export const getProjectCommunicationsCustomReference = (project_id,id) => {
	const url = `/project/${project_id}/communications/custom_references/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_COMMUNICATIONS_CUSTOM_REFERENCE,
		payload: request
	};
};

export const UPDATE_PROJECT_COMMUNICATIONS_CUSTOM_REFERENCE = "update_project_communications_custom_reference";
export const updateProjectCommunicationsCustomReference = (project_id,id,data) => {
	const url = `/project/${project_id}/communications/custom_references/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PROJECT_COMMUNICATIONS_CUSTOM_REFERENCE,
		payload: request
	};
};

export const DESTROY_PROJECT_COMMUNICATIONS_CUSTOM_REFERENCE = "destroy_project_communications_custom_reference";
export const destroyProjectCommunicationsCustomReference = (project_id,id) => {
	const url = `/project/${project_id}/communications/custom_references/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PROJECT_COMMUNICATIONS_CUSTOM_REFERENCE,
		payload: request
	};
};
