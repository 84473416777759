import { 
	GET_ROLES, 
} from "./actions"

export function RolesReducer(state = [], action) {
	switch (action.type) {
		case `${GET_ROLES}_FULFILLED`:{
			return _.mapKeys(action.payload.data, "id");
		}
		default:
			return state;
	}
}

