import axios from "axios";
import jsonFormData from "json-form-data";

const prepareData = (data) => {
  return jsonFormData({ custom_form_record: data }, { showLeafArrayIndexes: false });
};

// Plural Actions
export const GET_CUSTOM_FORM_RECORDS = "get_custom_form_records";
export const getCustomFormRecords = (custom_form_id, get_all) => {
  let url = `/custom_forms/${custom_form_id}/records.json`;
  if (get_all) {
    url += "?get_all=true";
  }

  const request = axios.get(url);
  return {
    type: GET_CUSTOM_FORM_RECORDS,
    payload: request,
  };
};

// Singular Actions
export const CREATE_CUSTOM_FORM_RECORD = "create_custom_form_record";
export const createCustomFormRecord = (custom_form_id, data) => {
  const url = `/custom_forms/${custom_form_id}/records.json`;
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_CUSTOM_FORM_RECORD,
    payload: request,
  };
};

export const GET_CUSTOM_FORM_RECORD = "get_custom_form_record";
export const getCustomFormRecord = (custom_form_id, id) => {
  const url = `/custom_forms/${custom_form_id}/records/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_CUSTOM_FORM_RECORD,
    payload: request,
  };
};

export const UPDATE_CUSTOM_FORM_RECORD = "update_custom_form_record";
export const updateCustomFormRecord = (custom_form_id, id, data) => {
  const url = `/custom_forms/${custom_form_id}/records/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_CUSTOM_FORM_RECORD,
    payload: request,
  };
};

export const DESTROY_CUSTOM_FORM_RECORD = "destroy_custom_form_record";
export const destroyCustomFormRecord = (custom_form_id, id) => {
  const url = `/custom_forms/${custom_form_id}/records/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_CUSTOM_FORM_RECORD,
    payload: request,
  };
};
