import {
  GET_POSITIONS,
  GET_POSITION,
  CREATE_POSITION,
  UPDATE_POSITION,
  DESTROY_POSITION,
} from "./actions";

export function PositionsReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_POSITIONS}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    case `${GET_POSITION}_FULFILLED`:
    case `${CREATE_POSITION}_FULFILLED`:
    case `${UPDATE_POSITION}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_POSITION}_FULFILLED`: {
      let newState = _.clone(state);
      const {
        meta: { id },
      } = action;
      _.unset(newState, id);
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { PositionsReducer } from "modules/positions/reducer"

// positions:PositionsReducer,
