import {
  GET_PRODUCTION_STRUMIS_WORKSTATIONS,
} from "./actions";

export function ProductionStrumisWorkstationsReducer(state = [], action) {
  switch (action.type) {
    case `${GET_PRODUCTION_STRUMIS_WORKSTATIONS}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { ProductionStrumisWorkstationsReducer } from "modules/production/strumis/contracts/reducer"

// production_strumis_contracts:ProductionStrumisWorkstationsReducer,
