import React from "react";

const ShowTransportBoard = React.lazy(() => import("./show"));

export default [
  {
    path: "/production/transport_boards/show",
    component: ShowTransportBoard,
    name: "Show Transport Board",
  },
];

// import ProductionTransportBoardRoutes from "modules/production/transport_boards/web/routes"
// routes = _.concat(routes,ProductionTransportBoardRoutes)
