import React from "react";
import { Form } from "react-final-form";
import { createPersistDecorator } from "./PersistDecorator";


class CustomForm extends React.Component {
  constructor(props) {
    super(props);
    let decorators = props.decorators ? props.decorators : [];

    if (props.persist?.name) {
      const { persistDecorator, clear } = createPersistDecorator({
        ...props.persist,
      });
      decorators.push(persistDecorator);
    }

    this.state = {
      decorators: decorators,
    };
  }
  render() {
    const { decorators } = this.state;
    return <Form decorators={decorators} {...this.props} />;
  }
}

CustomForm.defaultProps = {
  persist: {
    debounceTime: 500,
    whitelist: [],
    blacklist: [],
  },
};

export default CustomForm;