import React from "react";
import { connect } from "react-redux";

import { required, InputField, ToggleField, SmartSelect, ReactDropzone } from "modules/4_final_form/mobile";

import { Button, List, Block, BlockTitle } from "framework7-react";

import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import { getProjectAreas } from "modules/project/areas/actions";
import { getUserManagements } from "modules/user_managements/actions.js";

import { getProjectQaActionItemsStatuses } from "modules/project/qa/action_items/statuses/actions";

function mapStateToProps(state, ownProps) {
  const {
    f7route: {
      params: { project_id },
    },
  } = ownProps;

  return {
    this_project_areas: _.filter(state.project_areas, (pa) => pa.project_id == project_id),
    users: state.users,
    project_qa_action_items_statuses: state.project_qa_action_items_statuses,
    project: state.projects[project_id],
  };
}

class ProjectQaActionItemForm extends React.Component {
  constructor(props) {
    super(props);

    const {
      f7route: {
        params: { project_id },
      },
    } = this.props;

    this.props.getProjectAreas(project_id);
    this.props.getUserManagements();
    this.props.getProjectQaActionItemsStatuses();
  }

  render() {
    const { handleSubmit, formLabel, project, this_project_areas, users, project_qa_action_items_statuses } = this.props;

    return (
      <form>
        <Block>
          <BlockTitle>{project?.display_name}</BlockTitle>
        </Block>
        <List>
          <hr />
          <Field component={InputField} label="Date" name="date" placeholder="Enter date..." type="date" slot="list" validate={required} />

          <Field
            component={SmartSelect}
            name="project_area_links_attributes"
            options={_.map(this_project_areas, (pa) => {
              return {
                label: pa.name,
                value: pa.id,
                project_area_id: pa.id,
              };
            })}
            multi
            label="Project Area/s"
            labelName="label"
            valueName="value"
            slot="list"
          />

          <Field
            component={SmartSelect}
            name="user_links_attributes"
            options={_.map(users, (user) => {
              return {
                label: user.name,
                value: user.id,
                user_id: user.id,
              };
            })}
            labelName="label"
            valueName="value"
            slot="list"
            label="User/s Responsible"
            multi
          />

          <Field
            component={InputField}
            label="Description"
            name="description"
            placeholder="Enter description..."
            type="textarea"
            slot="list"
            validate={required}
          />

          <Field
            label="Status"
            component={SmartSelect}
            name="status_id"
            options={_.map(project_qa_action_items_statuses, (status) => {
              return {
                label: status.name,
                value: status.id,
              };
            })}
            labelName="label"
            placeholder="Select status"
            valueName="value"
            validate={required}
            slot="list"
            openIn="popover"
          />

          <Block slot="list">
            <div className="item-label">Attachments</div>
            <FieldArray component={ReactDropzone} name="attachments_attributes" {...this.props} />
          </Block>
        </List>

        <Block>
          <Button onClick={handleSubmit} fill color="green">
            {formLabel} Action Item
          </Button>
        </Block>
      </form>
    );
  }
}

export default connect(mapStateToProps, {
  getProjectAreas,
  getUserManagements,
  getProjectQaActionItemsStatuses,
})(ProjectQaActionItemForm);
