import { Store } from "modules/0_web/1_entry";
import { mobileStore } from "modules/0_mobile/1_entry";
import { outlookStore } from "modules/0_outlook/1_entry";

// options can be array,string or object
const HasRole = (rolesToCheck, onMobile = false, onOutlook = false) => {
  let state;
  if (onMobile) {
    state = mobileStore.getState();
  } else if (onOutlook) {
    state = outlookStore.getState();
  } else {
    state = Store.getState();
  }

  const {
    current_user: { roles, admin },
  } = state;

  if (admin) {
    return true;
  }

  if (_.isArray(rolesToCheck)) {
    return _.some(rolesToCheck, (searchForThisRole) => SearchRole(roles, searchForThisRole));
  } else if (_.isObject(rolesToCheck)) {
    const { name, resource_type } = rolesToCheck;
    if (_.isArray(name)) {
      return _.some(name, (lookForThisName) => SearchRole(roles, { name: lookForThisName, resource_type: resource_type }));
    } else {
      return SearchRole(roles, rolesToCheck);
    }
  } else if (_.isString(rolesToCheck)) {
    if (rolesToCheck == "admin") {
      return admin;
    } else {
      return SearchRole(roles, { name: rolesToCheck });
    }
  }
};

export default HasRole;

const SearchRole = (roles, searchForThisRole) => {
  const { name, category, resource_type } = searchForThisRole;
  if (category) {
    return _.some(roles, (r) => r.category == category);
  } else if (name && !resource_type && !category) {
    return _.some(roles, (r) => r.name == name);
  } else if (!name && resource_type) {
    return _.some(roles, (r) => r.resource_type == resource_type);
  } else if (name && resource_type) {
    return _.some(roles, (r) => r.name == name && r.resource_type == resource_type);
  }
};
