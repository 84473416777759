import React from 'react';

const ProjectDrawingsIndex = React.lazy(() => import("./index"))
const NewProjectDrawings = React.lazy(() => import("./new"))
const EditProjectDrawings = React.lazy(() => import("./edit"))

export default[
	// {path:'/project/:project_id/drawings/:id/edit',component: EditProjectDrawings,name:'Edit ProjectDrawing'},
	// {path:'/project/:project_id/drawings/new',component: NewProjectDrawings,name:'New ProjectDrawing'},  
	{path:'/project/:project_id/drawings',component: ProjectDrawingsIndex,name:'ProjectDrawings'}, 
]

// import ProjectDrawingsRoutes from "modules/project/drawings/web/routes"
// routes = _.concat(routes,ProjectDrawingsRoutes)
