export const GET_CURRENT_USER = "get_current_user";
export const getCurrentUser = () => {
  const url = `/current_user.json`;
  const request = axios.get(url);
  return {
    type: GET_CURRENT_USER,
    payload: request,
  };
};

export const SET_CURRENT_PROJECT = "set_current_project";
export const setCurrentProject = (current_project_id) => {
  const url = `/current_project.json`;
  if (current_project_id) {
    const request = axios.post(url, { current_project_id: current_project_id });
    return {
      type: SET_CURRENT_PROJECT,
      payload: request,
    };
  }
};

export const READ_NOTIFICATION = "read_notification";
export const readNotification = (id) => {
  const url = `/read_notification.json`;
  const request = axios.patch(url, { id });
  return {
    type: SET_CURRENT_PROJECT,
    payload: request,
  };
};

export const ADD_NOTIFICATION = "add_notification";
export const addNotification = (data) => {
  return {
    type: ADD_NOTIFICATION,
    data: data,
  };
};
