import React, { Component, Fragment } from 'react'
import { Block, Chip, Popup, Page, Navbar, NavRight, Link, f7, List, Button } from "framework7-react";
import { v4 as uuidv4 } from "uuid";

import IsolatedLowReadingsPopup from "./IsolatedLow"
import { ReadingText } from './ElcometerReader';

export default class SpotCheckReadingsPopup extends Component {

  constructor(props) {
    super(props)

    this.closeSpotCheckProcess = this.closeSpotCheckProcess.bind(this)
    this.deleteReading = this.deleteReading.bind(this)
    this.attachInsertElcoReading = this.attachInsertElcoReading.bind(this)

    this.openIsolatedLowProcess = this.openIsolatedLowProcess.bind(this)
    this.closeIsolatedLowProcess = this.closeIsolatedLowProcess.bind(this)

    this.state = {
      childReadingToEditName: null,
      isolatedLowPopup: false,
    }
  }

  closeSpotCheckProcess(showDialog = true) {
    const { parentReadingToEdit } = this.props
    const { dialog } = f7;
    this.checkForLowsAndSet() // Checks for the lows and sets the parent reading to be low if needed

    if (showDialog) {
      dialog.confirm("This will remove the low reading are you sure?", () => {
        // Remove the low reading
        this.props.deleteParentReading()
        this.props.closeSpotCheckProcess(parentReadingToEdit?.uuid)
      });
    }
    else {
      this.props.closeSpotCheckProcess(parentReadingToEdit?.uuid)
    }
  }

  openIsolatedLowProcess() {
    this.setState({ isolatedLowPopup: true })
  }

  closeIsolatedLowProcess() {
    this.setState({ isolatedLowPopup: false })
    this.attachInsertElcoReading(); // attach the elco reader back to the main readings not the nested readings
  }

  checkForLowsAndSet() {
    const { parentReadingName } = this.props

    const fieldNameToChange = `${parentReadingName}.pi_readings_attributes`
    const this_pi_readings_attributes = _.get(this.props.values, fieldNameToChange, [])
    let newReadings = _.clone(this_pi_readings_attributes)
    const filtered_readings = _.filter(newReadings, r => !r.user_deleted)
    const all_above_reqd_dft = _.every(filtered_readings, r => r.is_low == false)
    this.props.form.change(`${parentReadingName}.is_low`, !all_above_reqd_dft) // Set it to is low for the color and because it is low
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.opened && this.props.opened) {
      this.attachInsertElcoReading();
    }
  }

  attachInsertElcoReading() {
    // On open change the window.insertElcoReading to a function that will add the reading to this pi_readings_attributes
    // Add readings with elcometer
    window.insertElcoReading = (value) => {
      // Inserts into the parent reading
      const { useDFT, selectedCoat, parentReadingName } = this.props

      const fieldNameToChange = `${parentReadingName}.pi_readings_attributes`
      const this_pi_readings_attributes = _.get(this.props.values, fieldNameToChange, [])
      let newReadings = _.clone(this_pi_readings_attributes)
      const filtered_readings = _.filter(newReadings, r => !r.user_deleted)

      if (filtered_readings?.length < 2) {
        let dft_input
        if (_.isString(value)) {
          // Split the string to get the first value and parse the int
          const splitValue = value.split(",")
          dft_input = parseInt(splitValue[0])
        }
        else {
          dft_input = value
        }

        const above_low = dft_input >= useDFT * (selectedCoat?.min_percentage / 100)
        const below_average = dft_input < useDFT;
        const between_low_and_avg = above_low && below_average

        const low_check = dft_input >= useDFT // Touch confusing but you dont use the 80% rule to determine if its low. In the isolated low process you use the reqd dft. But we keep the same variable name for the sake of consistency.
        const is_low = !low_check
        newReadings.push({ uuid: uuidv4(), dft: dft_input, is_low: is_low })
        const new_filtered_readings = _.filter(newReadings, r => !r.user_deleted)

        // If its the last reading in the sequence of 4 for an isolate low. Determine if it passes the isolate low test and change the parent to be isolated low.
        // Use the length of 3 as the filtered readings were created before the push
        if (new_filtered_readings.length == 2) {
          const all_above_reqd_dft = _.every(new_filtered_readings, r => r.is_low == false)
          this.props.form.change(`${parentReadingName}.spot_check_pass`, all_above_reqd_dft)
          this.props.form.change(`${parentReadingName}.spot_checked`, true)
        }

        // Insert the readings into the parent reading
        this.props.form.change(fieldNameToChange, newReadings)

        if (between_low_and_avg) {
          const fieldNameToChange = `${parentReadingName}.pi_readings_attributes`
          const this_pi_readings_attributes = _.get(this.props.values, fieldNameToChange, [])
          const lastReadingIndex = _.findLastIndex(this_pi_readings_attributes, r => !r.user_deleted)
          const childReadingName = `${fieldNameToChange}[${lastReadingIndex}]`
          this.setState({ childReadingName: childReadingName })
          this.openIsolatedLowProcess()
          return true
        }
        else{
          return is_low
        }
      }
      else{
        return true
      }
    }
  }

  deleteReading(uuid) {
    const { parentReadingName } = this.props
    const fieldNameToChange = `${parentReadingName}.pi_readings_attributes`
    const this_pi_readings_attributes = _.get(this.props.values, fieldNameToChange, [])
    const findIndexToDelete = _.findIndex(this_pi_readings_attributes, r => r.uuid == uuid)
    let newReadings = _.clone(this_pi_readings_attributes)
    newReadings[findIndexToDelete].user_deleted = true
    this.props.form.change(fieldNameToChange, newReadings)
  }

  render() {
    const { isolatedLowPopup, childReadingName } = this.state

    const { selectedCoat, useDFT } = this.props
    const { opened, parentReadingName, ...restProps } = this.props

    const minDFT = useDFT * (selectedCoat?.min_percentage / 100)
    const fieldNameToChange = `${parentReadingName}.pi_readings_attributes`
    const parentReading = _.get(this.props.values, parentReadingName, {})
    const this_pi_readings_attributes = _.get(this.props.values, fieldNameToChange, [])
    const filtered_readings = _.filter(this_pi_readings_attributes, r => !r.user_deleted)

    return (
      <Popup opened={opened}>

        <IsolatedLowReadingsPopup
          opened={isolatedLowPopup}
          closeIsolatedLowProcess={this.closeIsolatedLowProcess}
          deleteReading={() => { this.deleteReading(parentReadingName?.uuid) }}
          originalAttachInsertElcoReading={this.attachInsertElcoReading}
          parentReadingName={childReadingName}
          {...restProps}
        />

        <Navbar title="Spot Check Process">
          <NavRight>
            <Link onClick={this.closeSpotCheckProcess}>Close</Link>
          </NavRight>
        </Navbar>

        <Page>
          <Block>
            A low reading (<b>{parentReading?.dft}&micro;</b>) which is lower than the {selectedCoat?.min_percentage}% of {useDFT}&micro; has been detected.
            <br /> You need to take an additional 2 readings within a 1.5inch circle.
          </Block>
          <List>
            {!_.isEmpty(filtered_readings) != 0 && filtered_readings && (<Block>
              <b>Elco Readings - Coat - {selectedCoat.display_name} - {selectedCoat.dft}&micro;</b>
              <br />
              {_.map(filtered_readings, (reading, i) => {
                return (
                  <Fragment key={i}>
                    <Chip deleteable onDelete={() => { this.deleteReading(reading.uuid) }} color={!reading.is_low ? "green" : "red"}>
                      <div onClick={() => {
                        const readingIndex = _.findIndex(this_pi_readings_attributes, r => r.uuid == reading.uuid)
                        const newChildReadingName = `${fieldNameToChange}[${readingIndex}]`
                        this.setState({ childReadingName: newChildReadingName })
                        if (reading.isolated_low_checked) {
                          this.openIsolatedLowProcess()
                        }
                      }}>Reading {i + 1}/2 - <ReadingText reading={reading}/></div>
                    </Chip>
                    <br />
                  </Fragment>
                )
              })}
            </Block>)}
          </List>
          <Block>
            {filtered_readings?.length == 2 && (
              <Button color="green" fill onClick={() => { this.closeSpotCheckProcess(false) }}>Save</Button>
            )}
          </Block>

        </Page>
      </Popup>
    )
  }
}
