import axios from "axios";

const prepareData = (data) => {
  return { hireages_category: data };
};

// Plural Actions
export const GET_HIREAGES_CATEGORIES = "get_hireages_categories";
export const getHireagesCategories = () => {
  const url = "/hireages/categories.json";
  const request = axios.get(url);
  return {
    type: GET_HIREAGES_CATEGORIES,
    payload: request,
  };
};

// Singular Actions
export const CREATE_HIREAGES_CATEGORY = "create_hireages_category";
export const createHireagesCategory = (data) => {
  const url = "/hireages/categories.json";
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_HIREAGES_CATEGORY,
    payload: request,
  };
};

export const GET_HIREAGES_CATEGORY = "get_hireages_category";
export const getHireagesCategory = (id) => {
  const url = `/hireages/categories/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_HIREAGES_CATEGORY,
    payload: request,
  };
};

export const UPDATE_HIREAGES_CATEGORY = "update_hireages_category";
export const updateHireagesCategory = (id, data) => {
  const url = `/hireages/categories/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_HIREAGES_CATEGORY,
    payload: request,
  };
};

export const DESTROY_HIREAGES_CATEGORY = "destroy_hireages_category";
export const destroyHireagesCategory = (id) => {
  const url = `/hireages/categories/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_HIREAGES_CATEGORY,
    payload: request,
    meta: { id: id },
  };
};
