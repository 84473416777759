import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";

import { Page, BlockTitle, Block, Button, List, ListItem, Fab, Icon, AccordionContent, Preloader } from "framework7-react";
import SelectProject from "modules/projects/mobile/select";

import { getProjectQaSiteSiteInstallations } from "../actions";

import { setCurrentProject } from "modules/current_user/actions";

import AppNavbar from "modules/1_layout/mobile/AppNavbar";

import NotAuthorized from "modules/5_view_helpers/mobile/NotAuthorized";

// Helpers
import HasRole from "modules/5_view_helpers/HasRole";

import { handleNewClick, ResumePersistedButton } from "modules/5_view_helpers/mobile/PersistedHelpers.jsx";
import { persistedName } from "./vairables";

function mapStateToProps(state) {
  return {
    current_user: state.current_user,
    project_qa_site_site_installations: state.project_qa_site_site_installations,
  };
}

const ProjectQaSiteSiteInstallationIndex = (props) => {
  const [loading, setLoading] = useState(false);
  const {
    current_user,
    project_qa_site_site_installations,
    f7router,
    f7router: { app },
  } = props;
  const current_project_id = _.get(current_user, "current_project_id");
  const readRole = HasRole(
    {
      resource_type: "Project::Qa::Site::SiteInstallation",
      name: ["read", "moderator"],
    },
    true
  );
  useEffect(() => {
    if (current_project_id) {
      setLoading(true);
      props.getProjectQaSiteSiteInstallations(current_project_id).then(() => {
        setLoading(false);
      });
    }
  }, [current_project_id]);

  const createRole = HasRole(
    {
      resource_type: "Project::Qa::Site::SiteInstallation",
      name: ["create", "moderator"],
    },
    true
  );
  const updateRole = HasRole(
    {
      resource_type: "Project::Qa::Site::SiteInstallation",
      name: ["update", "moderator"],
    },
    true
  );

  if (!readRole) {
    return <NotAuthorized />;
  }

  const newUrl = `/project/${current_project_id}/qa/site/site_installations/new`;

  return (
    <Page>
      <AppNavbar title="Site Installation QA" />

      <SelectProject
        onChange={({ target: { value } }) => {
          props.setCurrentProject(value);
        }}
        value={current_project_id}
      />

      {loading && <Preloader />}

      {!loading && (
        <Fragment>
          <ResumePersistedButton name={persistedName} url={newUrl} />
          <BlockTitle>Site Installation QA</BlockTitle>
          <List accordionList>
            {_.map(project_qa_site_site_installations, (site_install_qa, i) => (
              <SiteInstallQAView key={i} site_install_qa={site_install_qa} slot="list" f7router={f7router} updateRole={updateRole} />
            ))}
          </List>
          {_.isEmpty(project_qa_site_site_installations) && (
            <List>
              <ListItem>No Site Installation QA</ListItem>
            </List>
          )}
        </Fragment>
      )}

      {createRole && (
        <Fab
          position="right-bottom"
          slot="fixed"
          text="New"
          href=""
          onClick={() => {
            handleNewClick(f7router, persistedName, newUrl);
          }}
        >
          <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
        </Fab>
      )}
    </Page>
  );
};

export default connect(mapStateToProps, {
  getProjectQaSiteSiteInstallations,
  setCurrentProject,
})(ProjectQaSiteSiteInstallationIndex);

const SiteInstallQAView = (props) => {
  const { site_install_qa, updateRole } = props;

  return (
    <ListItem accordionItem title={site_install_qa?.display_name}>
      <AccordionContent>
        <Block>
          {updateRole && (
            <Button fill color="blue" href={`/project/${site_install_qa?.project_id}/qa/site/site_installations/${site_install_qa?.id}/edit`}>
              Edit
            </Button>
          )}
          <Button
            className="margin-top"
            fill
            color="blue"
            target="_blank"
            external
            href={`/project/${site_install_qa?.project_id}/qa/site/site_installations/${site_install_qa?.id}.pdf`}
          >
            Open PDF
          </Button>
        </Block>
      </AccordionContent>
    </ListItem>
  );
};
