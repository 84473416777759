import React from "react";

// const Home = React.lazy(() => import("modules/mobile/Home"));
// const CantFindRoute = React.lazy(() => import("./CantFindRoute"));

import Home from "modules/0_mobile/Home";

// Sidebars
import AppSidebar from "modules/1_layout/mobile/AppSidebar";

import QARoutes from "modules/project/qa/mobile/routes";
import ProjectDrawingsRoutes from "modules/project/drawings/mobile/routes";
import CustomFormRecordsRoutes from "modules/custom_form/records/mobile/routes";
import StoreRoutes from "modules/store/mobile/routes";

const defaultRoutes = [
  {
    path: "/panel-left/",
    component: AppSidebar,
  },
  { path: "/", name: "Home", component: Home },
];

let routes = [];
routes = _.concat(routes, QARoutes);
routes = _.concat(routes, ProjectDrawingsRoutes);
routes = _.concat(routes, CustomFormRecordsRoutes);
routes = _.concat(routes, StoreRoutes);
routes = _.concat(routes, defaultRoutes);

export default routes;
