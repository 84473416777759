import React, { Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Select from "react-select";
import DateField from "modules/5_view_helpers/web/DateField";

import { Row, Col, Input } from "reactstrap";

import RightSidebar from "modules/1_layout/web/RightSidebar.jsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";

import { setFilterValues } from "./ArrayFilter/actions";
import { toggleRightSidebarOpen } from "modules/3_reducers/web/ThemeOptions";

function mapStateToProps(state) {
  return {
    filterValues: state.filterValues,
    rightSidebarOpen: state.ThemeOptions.rightSidebarOpen,
  };
}

class ArrayFilter extends React.Component {
  constructor(props) {
    super(props);

    this.filterArray = this.filterArray.bind(this);

    this.handleFilter = this.handleFilter.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { filterValues, inputArray, outputArrayFunction } = this.props;

    // Update filtered array on change
    if (inputArray != prevProps.inputArray) {
      const outputArray = this.filterArray(inputArray, filterValues);
      outputArrayFunction(outputArray);
    }

    // Update filtered array on filter change
    if (filterValues != prevProps.filterValues) {
      const outputArray = this.filterArray(inputArray, filterValues);
      outputArrayFunction(outputArray);
    }
  }

  handleFilter(column, inputValue) {
    const { filterValues } = this.props;

    // Set filter attributes
    let newFilterValues = _.clone(filterValues);
    newFilterValues[column.dataField] = {
      column: column,
      dataFilter: column.dataFilter,
      value: inputValue,
    };

    this.props.setFilterValues(newFilterValues);
  }

  filterArray(dataArray, filterValues) {
    let filteredData = _.clone(dataArray);

    // Check filter type then filter array based on filterValues
    _.each(filterValues, (filterObject, key) => {
      let valueToFilter = filterObject.value;
      // Text filter
      if (filterObject.dataFilter == "text") {
        filteredData = _.filter(filteredData, (row) => {
          // Check the value inside the relevant row key against the valueToFilter
          return row[key] && _.includes(row[key].toString().toLowerCase(), valueToFilter.toString().toLowerCase());
        });
      }
      // Select Filter
      if (filterObject.dataFilter == "select" && valueToFilter) {
        filteredData = _.filter(filteredData, (row) => {
          let dataToCheck = row[key];
          if (filterObject.dataFilter != "date" && filterObject.column?.formatter) {
            dataToCheck = filterObject.column?.formatter(row[key]);
          }
          return dataToCheck.toString().toLowerCase() == valueToFilter.toString().toLowerCase();
        });
      }
      // Multiselect Filter
      if (filterObject.dataFilter == "multiselect" && _.isPresent(valueToFilter)) {
        filteredData = _.filter(filteredData, (row) => _.includes(valueToFilter, row[key]));
      }
      // Date Filter
      if (filterObject.dataFilter == "date" && valueToFilter) {
        if (valueToFilter?.endDate) {
          filteredData = _.filter(filteredData, (row) =>
            moment(row[key]).isBetween(moment(valueToFilter.startDate).subtract(1, "days"), valueToFilter.endDate)
          );
        } else {
          filteredData = _.filter(filteredData, (row) => moment(row[key]).isSame(valueToFilter.startDate, "day"));
        }
      }
    });

    return filteredData;
  }

  render() {
    const { inputArray, filterValues, columns, rightSidebarOpen } = this.props;

    const header = (
      <Fragment>
        <FontAwesomeIcon icon={faFilter} /> Filters
        <FontAwesomeIcon
          icon={faTimes}
          onClick={() => {
            this.props.toggleRightSidebarOpen(!rightSidebarOpen);
          }}
          className="filter-close"
        />
      </Fragment>
    );

    if (!rightSidebarOpen) {
      return null;
    }
    return (
      <RightSidebar header={header}>
        {_.map(columns, (column, i) => (
          <Fragment key={i}>
            <Row className="pl-3 pr-3">
              <Col md={12} style={{ paddingTop: "1rem" }}>
                <b>{column.text}</b>
              </Col>
            </Row>
            <Row className="pl-3 pr-3">
              <Col md={12}>
                {column.dataFilter == "text" && (
                  <TextFilter column={column} handleFilter={this.handleFilter} filterValue={filterValues[column.dataField]?.value} />
                )}
                {column.dataFilter == "select" && (
                  <div className="select-filter">
                    <SelectFilter
                      column={column}
                      handleFilter={this.handleFilter}
                      data={inputArray}
                      filterValue={{
                        label: filterValues[column.dataField]?.value,
                        value: filterValues[column.dataField]?.value,
                      }}
                    />
                  </div>
                )}
                {column.dataFilter == "multiselect" && (
                  <div className="select-filter">
                    <MultiSelectFilter
                      column={column}
                      handleFilter={this.handleFilter}
                      data={inputArray}
                      filterValue={_.map(filterValues[column.dataField]?.value, (v) => {
                        return {
                          label: v,
                          value: v,
                        };
                      })}
                    />
                  </div>
                )}
                {column.dataFilter == "date" && (
                  <DateFilter column={column} handleFilter={this.handleFilter} filterValue={filterValues[column.dataField]?.value} />
                )}
              </Col>
            </Row>
          </Fragment>
        ))}
      </RightSidebar>
    );
  }
}

export default connect(mapStateToProps, { setFilterValues, toggleRightSidebarOpen })(ArrayFilter);

const TextFilter = (props) => {
  const { column, handleFilter, filterValue } = props;

  return (
    <Input
      type="text"
      onChange={(e) => {
        handleFilter(column, e.target.value);
      }}
      value={filterValue ? filterValue : ""}
      placeholder={`Filter ${_.startCase(column?.dataField)}...`}
    />
  );
};

const SelectFilter = (props) => {
  const { column, handleFilter, data, filterValue } = props;

  let options = _.map(data, (d) => {
    const dataValue = column.formatter ? column.formatter(d[column.dataField]) : d[column.dataField];
    return { label: dataValue, value: dataValue };
  });

  // Filtering blank options and React components from formatter from options
  options = _.filter(options, (o) => o.value && typeof o.value != "object");

  return (
    <Select
      options={_.uniqBy(options, (o) => o.value)}
      onChange={(e) => {
        handleFilter(column, e.value);
      }}
      value={filterValue ? filterValue : ""}
      placeholder={`Filter ${_.startCase(column?.dataField)}...`}
      // Options weren't rendering overtop of other elements without this
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 10 }) }}
    />
  );
};

const MultiSelectFilter = (props) => {
  const { column, handleFilter, data, filterValue } = props;

  let options = _.map(data, (d) => {
    const dataValue = column.formatter ? column.formatter(d[column.dataField]) : d[column.dataField];
    return { label: dataValue, value: dataValue };
  });

  // Filtering blank options and React components from formatter from options
  options = _.filter(options, (o) => o.value && typeof o.value != "object");

  return (
    <Select
      options={_.uniqBy(options, (o) => o.value)}
      onChange={(e) => {
        handleFilter(
          column,
          _.map(e, (e) => e.value)
        );
      }}
      isMulti
      value={filterValue ? filterValue : []}
      placeholder={`Filter ${_.startCase(column?.dataField)}...`}
      // Options weren't rendering overtop of other elements without this
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 10 }) }}
    />
  );
};

const DateFilter = (props) => {
  const { column, handleFilter, filterValue } = props;

  let minDate = filterValue?.startDate;

  // Change end date field's minimum date based on the start date value
  if (moment(filterValue?.endDate).isBefore(filterValue?.startDate)) {
    if (filterValue?.endDate) {
      filterValue.endDate = filterValue?.startDate;
    }
  }

  return (
    <Fragment>
      <div className="date-rows">
        <Row>
          <Col>
            <DateField
              enablePreviousDates
              onChange={(date) => {
                if (date) {
                  let newFilterValue = filterValue ? _.clone(filterValue) : {};
                  newFilterValue.startDate = date;
                  handleFilter(column, newFilterValue);
                }
              }}
              id={`${column.dataField}-startDate`}
              value={filterValue?.startDate}
              displayFormat="DD/MM/YYYY"
              placeholder={`Start Date...`}
            />
          </Col>
        </Row>
        {filterValue?.startDate && (
          <Row className="mt-4">
            <Col>
              <div className="mt-3">
                <DateField
                  enablePreviousDates
                  onChange={(date) => {
                    if (date) {
                      let newFilterValue = filterValue ? _.clone(filterValue) : {};
                      newFilterValue.endDate = date;
                      handleFilter(column, newFilterValue);
                    }
                  }}
                  id={`${column.dataField}-endDate`}
                  value={filterValue?.endDate}
                  displayFormat="DD/MM/YYYY"
                  placeholder={`End Date...`}
                  minDate={minDate}
                />
              </div>
            </Col>
          </Row>
        )}
      </div>
    </Fragment>
  );
};
