import React, { Fragment } from "react";

import { CirclePicker } from "react-color";
import { Row, Col, Button } from "reactstrap";

export default class ColorPicker extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(color) {
    const {
      input: { onChange },
    } = this.props;
    const hex = _.get(color, "hex");
    onChange(hex);
  }

  render() {
    const {
      input: { value, onChange },
      clearButton,
    } = this.props;

    return (
      <Fragment>
        <Row>
          <Col sm={12}>
            <CirclePicker color={value} onChangeComplete={this.handleChange} />
          </Col>
        </Row>

        {clearButton && (
          <Row className="mt-2">
            <Col sm={12}>
              <Button
                size="sm"
                onClick={() => {
                  onChange(null);
                }}
                color="primary"
              >
                Clear Color
              </Button>
            </Col>
          </Row>
        )}
      </Fragment>
    );
  }
}
