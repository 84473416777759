import React from "react";

import { Link } from "react-router-dom";

const columns = (updateRole = false) => {
  let returnArray = [
    {
      text: "Name",
      dataField: "name",
    },

    {
      text: "Company",
      dataField: "company_label",
    },

    {
      text: "Email",
      dataField: "email",
    },
    {
      text: "Phone Number",
      dataField: "phone_number",
    },
  ];

  if (updateRole) {
    returnArray.push({
      text: "Edit",
      dataField: "edit",
      csvExport: false,
      formatter: (cell, row) => <Link to={`/address_book/company/contacts/${row.id}/edit`}>Edit</Link>,
    });
  }

  return returnArray;
};

export default columns;
