import { GET_PRODUCTION_SCHEDULE_BY_LOT, GET_PRODUCTION_SCHEDULE_BY_BUNDLE } from "./actions";

export function ProductionScheduleReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_PRODUCTION_SCHEDULE_BY_BUNDLE}_FULFILLED`:
    case `${GET_PRODUCTION_SCHEDULE_BY_LOT}_FULFILLED`: {
      return action.payload.data;
    }
    default:
      return state;
  }
}
