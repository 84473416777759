import React from 'react';

const AddressBookCompaniesIndex = React.lazy(() => import("./index"))
const NewAddressBookCompanies = React.lazy(() => import("./new"))
const EditAddressBookCompanies = React.lazy(() => import("./edit"))

export default[
	{path:'/address_book/companies/:id/edit',component: EditAddressBookCompanies,name:'Edit AddressBookCompany'},
	{path:'/address_book/companies/new',component: NewAddressBookCompanies,name:'New AddressBookCompany'},  
	{path:'/address_book/companies',component: AddressBookCompaniesIndex,name:'AddressBookCompanies'}, 
]

// import AddressBookCompaniesRoutes from "modules/address_book/companies/web/routes"
// routes = _.concat(routes,AddressBookCompaniesRoutes)
