import React from "react";
import { connect } from "react-redux";

import { getProject } from "modules/projects/actions";
import { getProjectTechnicalDetailsPaintSystems } from "modules/project/technical_details/paint_systems/actions";
// import { getProductionStrumisContractMarkInstancesAtPaint } from "modules/production/strumis/contract/mark_instances/actions.js";

import { FieldArray } from "react-final-form-arrays";

import AssemblyDetails from "./form/AssemblyDetails";
import NormalReadings from "./form/NormalReadings";
import SectionSizeReadings from "./form/SectionSizeReadings";

import { isNotEmpty } from "modules/4_final_form/web";
import { Button, Block, Tabs, Tab, BlockTitle, Chip } from "framework7-react";
import { getPaintLocations } from "modules/paint/locations/actions";
import { ReactDropzone } from "modules/4_final_form/mobile";

import { defaultInsertElcoReading } from "./form/ElcometerReader/ElcometerReader";

const mapStateToProps = (state, ownProps) => {
  const { project_id } = ownProps;

  const project = state.projects[project_id];

  return {
    project: project,
    paint_locations: state.paint_locations,
    project_technical_details_paint_systems: state.project_technical_details_paint_systems,
  };
};

class ProjectQaPaintInspectionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "assembly_details",
    };

    const { project_id } = this.props;

    this.toggleTab = this.toggleTab.bind(this);
    this.props.getProjectTechnicalDetailsPaintSystems(project_id);
    this.props.getPaintLocations();
  }

  componentDidUpdate(prevProps, prevState) {
    const { project_id } = this.props;

    const { project_id: prev_project_id, project: prev_project } = prevProps;

    if (prev_project_id != project_id) {
      this.props.getProjectTechnicalDetailsPaintSystems(project_id);
    }
  }

  toggleTab(tab) {
    const { activeTab } = this.state;

    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  // getSelectedPaintSystem() {
  //   const { values: { paint_system_id, child_paint_system_id }, project, project_technical_details_paint_systems } = this.props
  //   const paint_systems = _.filter(project_technical_details_paint_systems, (ps) => ps.project_id == project?.id);
  //   let selectedPaintSystem = _.find(paint_systems, (ps) => ps.id == paint_system_id); // get the paint system to do the reading based off.
  //   if (selectedPaintSystem?.is_multi_system) {
  //     if (child_paint_system_id) {
  //       const findChildPaintSystem = _.find(paint_systems, (ps) => ps.id == child_paint_system_id);
  //       selectedPaintSystem = findChildPaintSystem;
  //     } else {
  //       selectedPaintSystem = null;
  //     }
  //   }
  //   return selectedPaintSystem
  // }

  // getSelectedCoat() {
  //   const { values: { paint_system_id, coat_id } } = this.props
  //   const selectedPaintSystem = this.getSelectedPaintSystem()
  //   const coats_attributes = selectedPaintSystem?.coats_attributes;
  //   const selectedCoat = _.find(coats_attributes, (coat) => coat.id == coat_id);
  //   return selectedCoat
  // }

  // checkDFT(dft_input) {
  //   const { values: { paint_system_id, id,use_dft_from_previous }, assembly_paint_inspections, project_technical_details_paint_systems, project } = this.props

  //   const paint_systems = _.filter(project_technical_details_paint_systems, (ps) => ps.project_id == project?.id);
  //   const parentSelectedPaintSystem = _.find(paint_systems, (ps) => ps.id == paint_system_id);

  //   // Filter paint systems to the project selected.
  //   const previousCoatNumber = selectedCoat?.number - 1;
  //   let findCompliantLastCoat;
  //   if (parentSelectedPaintSystem?.is_multi_system) {
  //     findCompliantLastCoat = _.findLast(
  //       assembly_paint_inspections,
  //       (pi) =>
  //         pi.complies &&
  //         pi.coat_number == previousCoatNumber &&
  //         pi.id != id &&
  //         pi.paint_system_id == parentSelectedPaintSystem.id &&
  //         pi.child_paint_system_id == selectedPaintSystem.id
  //     );
  //   } else {
  //     findCompliantLastCoat = _.findLast(
  //       assembly_paint_inspections,
  //       (pi) => pi.complies && pi.coat_number == previousCoatNumber && pi.id != id && pi.paint_system_id == selectedPaintSystem.id
  //     );
  //   }

  //   const selectedCoat = this.getSelectedCoat()
  //   const requiredDFT = selectedCoat?.dft;
  //   const notFirstCoat = selectedCoat?.number > 1;
  //   const baseDFT = findCompliantLastCoat && use_dft_from_previous ? findCompliantLastCoat?.average_dft : selectedCoat?.sum_of_dfts;
  //   const useDFT = use_dft_from_previous && baseDFT && notFirstCoat ? baseDFT + requiredDFT : requiredDFT;

  //   const average_dft_check_pass = dft_input >= useDFT;
  //   const low_dft_check_pass = dft_input >= useDFT * (selectedCoat?.min_percentage / 100);

  //   return { average_dft_check_pass, low_dft_check_pass }
  // }

  componentDidMount() {
    window.insertElcoReading = defaultInsertElcoReading;
  }

  render() {
    const { activeTab } = this.state;

    const {
      handleSubmit,
      formLabel,
      project_technical_details_paint_systems,
      cancelFunction,
      values: { assembly, paint_system_id, coat_id, child_paint_system_id },
      project,
      paint_locations,
    } = this.props;

    const paint_systems = _.filter(project_technical_details_paint_systems, (ps) => ps.project_id == project?.id);

    const parentSelectedPaintSystem = _.find(paint_systems, (ps) => ps.id == paint_system_id);

    let selectedPaintSystem = _.find(paint_systems, (ps) => ps.id == paint_system_id); // get the paint system to do the reading based off.
    if (selectedPaintSystem?.is_multi_system) {
      if (child_paint_system_id) {
        const findChildPaintSystem = _.find(paint_systems, (ps) => ps.id == child_paint_system_id);
        selectedPaintSystem = findChildPaintSystem;
      } else {
        selectedPaintSystem = null;
      }
    }

    const coats_attributes = selectedPaintSystem?.coats_attributes;
    const selectedCoat = _.find(coats_attributes, (coat) => coat.id == coat_id);

    const previousCoats = selectedCoat?.number > 1 ? _.filter(coats_attributes, (c) => c.number < selectedCoat?.number) : null;

    const dft_by_sections = selectedCoat?.dft_by_sections || _.some(previousCoats, "dft_by_sections");

    let firstCoatWithSections = null;
    if (dft_by_sections) {
      firstCoatWithSections = _.find(previousCoats, "dft_by_sections");
    }

    return (
      <form>
        {window.elco && (
          <Block>
            <BlockTitle className="text-center">USING ELCOMETER</BlockTitle>
          </Block>
        )}
        <Tabs>
          <Tab id="tab-1" tabActive>
            <AssemblyDetails parentSelectedPaintSystem={parentSelectedPaintSystem} selectedPaintSystem={selectedPaintSystem} {...this.props} />
          </Tab>
        </Tabs>
        {assembly && coat_id && !dft_by_sections && (
          <NormalReadings
            parentSelectedPaintSystem={parentSelectedPaintSystem}
            selectedPaintSystem={selectedPaintSystem}
            selectedCoat={selectedCoat}
            {...this.props}
          />
        )}
        {assembly && coat_id && dft_by_sections && (
          <FieldArray
            component={SectionSizeReadings}
            parentSelectedPaintSystem={parentSelectedPaintSystem}
            name="section_readings_attributes"
            selectedPaintSystem={selectedPaintSystem}
            selectedCoat={selectedCoat}
            previousCoats={previousCoats}
            firstCoatWithSections={firstCoatWithSections}
            validate={isNotEmpty}
            {...this.props}
          />
        )}

        {assembly && coat_id && (
          <Block slot="list">
            <div className="item-label">Photos</div>
            <FieldArray component={ReactDropzone} name="attachments_attributes" uploadNow {...this.props} />
          </Block>
        )}
        <hr />
        <Block>
          <Button color="green" fill onClick={handleSubmit}>
            {formLabel} Paint inspections
          </Button>
        </Block>
      </form>
    );
  }
}

export default connect(mapStateToProps, { getProject, getProjectTechnicalDetailsPaintSystems, getPaintLocations })(ProjectQaPaintInspectionForm);
