import {
  GET_USER_MANAGEMENTS,
  GET_USER_MANAGEMENT,
  CREATE_USER_MANAGEMENT,
  UPDATE_USER_MANAGEMENT,
} from "./actions";

export function UserManagementsReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_USER_MANAGEMENTS}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    case `${GET_USER_MANAGEMENT}_FULFILLED`:
    case `${CREATE_USER_MANAGEMENT}_FULFILLED`:
    case `${UPDATE_USER_MANAGEMENT}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { UserManagementsReducer } from "modules/user_managements/reducer"

// user_managements:UserManagementsReducer,
