import React from 'react';

const PaintProductsIndex = React.lazy(() => import("./index"))
const NewPaintProducts = React.lazy(() => import("./new"))
const EditPaintProducts = React.lazy(() => import("./edit"))

export default[
	{path:'/paint/products/:id/edit',component: EditPaintProducts,name:'Edit PaintProduct'},
	{path:'/paint/products/new',component: NewPaintProducts,name:'New PaintProduct'},  
	{path:'/paint/products',component: PaintProductsIndex,name:'PaintProducts'}, 
]

// import PaintProductsRoutes from "modules/paint/products/web/routes"
// routes = _.concat(routes,PaintProductsRoutes)
