import axios from "axios";

// Plural Actions
export const GET_PRODUCTION_STRUMIS_SECTION_SIZES = "get_production_strumis_section_sizes";
export const getProductionStrumisSectionSizes = () => {
  const url = "/production/strumis/section_sizes.json";
  const request = axios.get(url);
  return {
    type: GET_PRODUCTION_STRUMIS_SECTION_SIZES,
    payload: request,
  };
};

