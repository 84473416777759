import { 
	GET_V2_MAINTENANCE_CATEGORIES, 
} from "./actions"

export function V2MaintenanceCategoriesReducer(state = [], action) {
	switch (action.type) {
		case `${GET_V2_MAINTENANCE_CATEGORIES}_FULFILLED`:{
			return _.mapKeys(action.payload.data, "id");
		}
		default:
			return state;
	}
}

