import React from "react";

import { Page, Block } from "framework7-react";
import AppNavbar from "modules/1_layout/mobile/AppNavbar";

const StoreHome = (props) => {
  return (
    <Page>
      <AppNavbar title="Store" />
      <Block>Store Home, please use the menu in the top left</Block>
    </Page>
  );
};

export default StoreHome;
