import { 
	GET_PROJECT_VARIATION_NUMBERS, 
	GET_PROJECT_VARIATION_NUMBER,
	CREATE_PROJECT_VARIATION_NUMBER,
	UPDATE_PROJECT_VARIATION_NUMBER,
	DESTROY_PROJECT_VARIATION_NUMBER,
} from "./actions"

export function ProjectVariationNumbersReducer(state = {}, action) {
	switch (action.type) {
		case `${GET_PROJECT_VARIATION_NUMBERS}_FULFILLED`:{
			return _.mapKeys(action.payload.data, "id");
		}
		case `${GET_PROJECT_VARIATION_NUMBER}_FULFILLED`:
		case `${CREATE_PROJECT_VARIATION_NUMBER}_FULFILLED`:
		case `${UPDATE_PROJECT_VARIATION_NUMBER}_FULFILLED`:{
			let newState = _.clone(state);
			const newItem = action.payload.data;
			const id = _.get(newItem,"id")
			newState[id] = newItem
			return newState;
		}		
		case `${DESTROY_PROJECT_VARIATION_NUMBER}_FULFILLED`:{
			let newState = _.clone(state);
			const deletedItem = action.payload.data;
			const id = _.get(deletedItem,"id")
			newState = _.filter(newState,i => i.id != id)
			return newState;
		}
		default:
			return state;
	}
}

// Move this to the reducer page
// import { ProjectVariationNumbersReducer } from "modules/project/variation_numbers/reducer"

// project_variation_numbers:ProjectVariationNumbersReducer,
