import axios from "axios";
import jsonFormData from "json-form-data";

const prepareData = (data) => {
  return jsonFormData({ training_booking: data }, { showLeafArrayIndexes: true });
};

// Plural Actions
export const GET_TRAINING_BOOKINGS = "get_training_bookings";
export const getTrainingBookings = (get_all = false) => {
  let url = "/training/bookings.json";
  if (get_all) {
    url += "?get_all";
  }

  const request = axios.get(url);
  return {
    type: GET_TRAINING_BOOKINGS,
    payload: request,
  };
};

// Singular Actions
export const CREATE_TRAINING_BOOKING = "create_training_booking";
export const createTrainingBooking = (data) => {
  const url = "/training/bookings.json";
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_TRAINING_BOOKING,
    payload: request,
  };
};

export const GET_TRAINING_BOOKING = "get_training_booking";
export const getTrainingBooking = (id) => {
  const url = `/training/bookings/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_TRAINING_BOOKING,
    payload: request,
  };
};

export const UPDATE_TRAINING_BOOKING = "update_training_booking";
export const updateTrainingBooking = (id, data) => {
  const url = `/training/bookings/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_TRAINING_BOOKING,
    payload: request,
  };
};

export const DESTROY_TRAINING_BOOKING = "destroy_training_booking";
export const destroyTrainingBooking = (id) => {
  const url = `/training/bookings/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_TRAINING_BOOKING,
    payload: request,
    meta: { id: id },
  };
};
