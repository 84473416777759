import React, { Fragment } from "react";
import { connect } from "react-redux";

import { required, InputField, ReactSelect, ToggleField } from "modules/4_final_form/web";

import { FormGroup, Label, Col } from "reactstrap";

import { Field } from "react-final-form";

// actions
import { getEmployees } from "modules/employees/actions";
import { getProductionStrumisContracts } from "modules/production/strumis/contracts/actions";
import { getAddressBookCompanyContacts } from "modules/address_book/company/contacts/actions";

const mapStateToProps = (state) => ({
  address_book_contacts: state.address_book_company_contacts,
});

class CommunicationsTab extends React.Component {
  constructor(props) {
    super(props);

    this.props.getAddressBookCompanyContacts();
    this.props.getEmployees();
    this.props.getProductionStrumisContracts();
  }

  render() {
    const { address_book_contacts } = this.props;

    return (
      <Fragment>
        <FormGroup row>
          <Label md={2}>Default To's Contacts (Only one allowed)</Label>
          <Col md={10}>
            <Field
              component={ReactSelect}
              options={_.map(address_book_contacts, (adc) => {
                return { label: adc.display_name, value: adc.id, contact_id: adc.id };
              })}
              multi
              name="default_comms_tos_attributes"
              sendDestroy
              placeholder="Select default tos contacts"
              maxOptionsSelected={1}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={2}>Default CC's Contacts</Label>
          <Col md={10}>
            <Field
              component={ReactSelect}
              options={_.map(address_book_contacts, (adc) => {
                return { label: adc.display_name, value: adc.id, contact_id: adc.id };
              })}
              multi
              name="default_comms_ccs_attributes"
              sendDestroy
              placeholder="Select default ccs contacts"
            />
          </Col>
        </FormGroup>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, { getEmployees, getProductionStrumisContracts, getAddressBookCompanyContacts })(CommunicationsTab);
