// Plural Actions
export const GET_MW_PRODUCTS = "GET_MW_PRODUCTS";
export const getMWProducts = () => {
  const url = "/moneyworks/products.json";
  const request = axios.get(url);
  return {
    type: GET_MW_PRODUCTS,
    payload: request,
  };
};
