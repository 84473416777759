import React, { Component } from "react";
import { connect } from "react-redux";

import ProjectQaPaintInspectionForm from "./form";
import { updateProjectQaPaintInspection, getProjectQaPaintInspection, destroyProjectQaPaintInspection } from "../actions";
import { Page, Navbar } from "framework7-react";

import { Form } from "react-final-form";

// Helpers
import NotAuthorized from "modules/5_view_helpers/web/NotAuthorized";
import HasRole from "modules/5_view_helpers/HasRole";
import arrayMutators from "final-form-arrays";

function mapStateToProps(state, ownProps) {
  const id = _.get(ownProps, "id");
  const project_id = _.get(ownProps, "project_id");
  return {
    project: state.projects[project_id],
    project_qa_paint_inspection: state.project_qa_paint_inspections[id],
  };
}

class EditProjectQaPaintInspection extends Component {
  constructor(props) {
    super(props);

    this.submitForm = this.submitForm.bind(this);
    this.successfullySubmitted = this.successfullySubmitted.bind(this);

    const updateRole = HasRole({ resource_type: "Project::Qa::PaintInspection", name: ["update", "moderator"] }, true);
    const moderatorRole = HasRole({ resource_type: "Project::Qa::PaintInspection", name: ["moderator"] }, true);

    const { id, project_id } = this.props;
    this.props.getProjectQaPaintInspection(project_id, id);

    const returnUrl = `/project/qa/paint_inspections`;

    this.state = {
      returnUrl: returnUrl,
      updateRole: updateRole,
      moderatorRole: moderatorRole,
    };
  }

  submitForm(values) {
    const {
      f7router: { app },
      project_id,
      id,
    } = this.props;

    this.preloader = app.preloader;
    this.preloader.show();
    const createRequest = this.props.updateProjectQaPaintInspection(project_id, id, values, true);
    createRequest.then(this.successfullySubmitted);
  }

  successfullySubmitted() {
    const {
      f7router,
      f7router: { app },
    } = this.props;

    const { returnUrl } = this.state;

    this.preloader.hide();
    app.toast
      .create({
        icon: '<i class="material-icons">check</i>',
        text: "Updated New Paint inspection!",
        position: "center",
        closeTimeout: 2000,
        on: {
          opened: () => {
            f7router.navigate(returnUrl);
          },
        },
      })
      .open();
  }

  render() {
    const { project_qa_paint_inspection } = this.props;
    const { updateRole } = this.state;

    if (!updateRole) {
      return <NotAuthorized />;
    } else {
      return (
        <Page>
          <Navbar title="Edit Paint Inspection" backLink />

          {_.isNotEmpty(project_qa_paint_inspection) && (
            <Form
              component={ProjectQaPaintInspectionForm}
              onSubmit={this.submitForm}
              formLabel="Update"
              mutators={{
                ...arrayMutators,
              }}
              initialValues={project_qa_paint_inspection}
              edit
              {...this.props}
            />
          )}
        </Page>
      );
    }
  }
}

export default connect(mapStateToProps, { getProjectQaPaintInspection, updateProjectQaPaintInspection, destroyProjectQaPaintInspection })(
  EditProjectQaPaintInspection
);
