import React from "react";

import PaintSettingsIndex from "./settings";
import PaintCodeDetailsRoutes from "modules/paint/code_details/web/routes"
import PaintProductsRoutes from "../products/web/routes";
import PaintProductCategoriesRoutes from "modules/paint/product_categories/web/routes";

import PaintTreatmentGradesRoutes from "../treatment_grades/web/routes";
import PaintLocationsRoutes from "modules/paint/locations/web/routes";
import PaintSurfacePrepsRoutes from "modules/paint/surface_preps/web/routes";


let routes = [
  {
    path: "/paint/settings",
    component: PaintSettingsIndex,
    name: "Paint Settings",
  },
];

routes = _.concat(routes,PaintCodeDetailsRoutes)
routes = _.concat(routes, PaintProductsRoutes);
routes = _.concat(routes, PaintProductCategoriesRoutes);

routes = _.concat(routes, PaintTreatmentGradesRoutes);
routes = _.concat(routes, PaintLocationsRoutes);
routes = _.concat(routes, PaintSurfacePrepsRoutes);

export default routes;
