import React from 'react';

const ProjectCommunicationsStatusesIndex = React.lazy(() => import("./index"))
const NewProjectCommunicationsStatuses = React.lazy(() => import("./new"))
const EditProjectCommunicationsStatuses = React.lazy(() => import("./edit"))

export default[
	{path:'/project/communications/statuses/:id/edit',component: EditProjectCommunicationsStatuses,name:'Edit ProjectCommunicationsStatus'},
	{path:'/project/communications/statuses/new',component: NewProjectCommunicationsStatuses,name:'New ProjectCommunicationsStatus'},  
	// {path:'/project/communications/statuses',component: ProjectCommunicationsStatusesIndex,name:'ProjectCommunicationsStatuses'}, 
]

// import ProjectCommunicationsStatusesRoutes from "modules/project/communications/statuses/web/routes"
// routes = _.concat(routes,ProjectCommunicationsStatusesRoutes)
