import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Page, BlockTitle, List, Preloader, ListItem, AccordionContent, Link } from "framework7-react";
import AppNavbar from "modules/1_layout/mobile/AppNavbar";
import SelectProject from "modules/projects/mobile/select";

import { getProjectAreas } from "modules/project/areas/actions";

import { setCurrentProject } from "modules/current_user/actions";
import NotAuthorized from "modules/5_view_helpers/mobile/NotAuthorized";
import HasRole from "modules/5_view_helpers/HasRole";

function mapStateToProps(state, ownProps) {
  const current_project_id = _.get(state.current_user, "current_project_id");

  const project_areas = _.filter(state.project_areas, (a) => a.project_id === current_project_id);

  return {
    current_user: state.current_user,
    project_areas: project_areas,
  };
}

export const SiteInstallDashboard = (props) => {
  const [loading, setLoading] = useState(false);
  const { current_user, project_areas, f7router } = props;
  const current_project_id = _.get(current_user, "current_project_id");

  const readRole = HasRole(
    {
      resource_type: "Project::Qa::Site::SiteInstallation",
      name: ["read", "moderator"],
    },
    true
  );

  useEffect(() => {
    if (readRole) {
      setLoading(true);
      props.getProjectAreas(current_project_id).then(() => {
        setLoading(false);
      });
    }
  }, [current_project_id]);

  if (!readRole) {
    return <NotAuthorized />;
  }

  return (
    <Page>
      <AppNavbar title="Site Installation Dashboard" />
      <SelectProject
        onChange={({ target: { value } }) => {
          props.setCurrentProject(value);
        }}
        value={current_project_id}
      />
      {loading && <Preloader />}
      {!loading && (
        <Fragment>
          <BlockTitle>Site Installation Dashboard</BlockTitle>
          <List accordionList>
            {_.map(project_areas, (project_area, i) => (
              <ProjectAreaView key={i} project_area={project_area} slot="list" f7router={f7router} />
            ))}
          </List>
        </Fragment>
      )}
    </Page>
  );
};

export default connect(mapStateToProps, { getProjectAreas, setCurrentProject })(SiteInstallDashboard);

const ProjectAreaView = (props) => {
  const {
    project_area,
    project_area: { qa_site_site_installations },
  } = props;

  let AreaTitle = project_area.name + " - ";
  if (!project_area.site_installation_qa) {
    AreaTitle += "Not Required";
  } else if (_.isEmpty(qa_site_site_installations)) {
    AreaTitle += `Not Started Due ${moment(project_area?.site_installation_qa_date).format("DD/MM/YYYY")}`;
  } else {
    AreaTitle += `Due ${moment(project_area.site_installation_qa_date).format("DD/MM/YYYY")}`;
    if (qa_site_site_installations.length == 1) {
      AreaTitle += ` - ${qa_site_site_installations[0].sections_complete}/${project_area.total_sections} Sections Complete`;
    } else if (qa_site_site_installations.length > 1) {
      AreaTitle += ` - ${qa_site_site_installations.length} QA Documents`;
    }
  }

  return (
    <ListItem accordionItem={_.isNotEmpty(qa_site_site_installations)} title={AreaTitle}>
      <AccordionContent>
        {qa_site_site_installations.length > 1 && (
          <List accordionList>
            {_.map(project_area.qa_site_site_installations, (site_installation_qa, i) => (
              <SiteInstallQAList key={i} project_area={project_area} site_installation_qa={site_installation_qa} slot="list" />
            ))}
          </List>
        )}

        {qa_site_site_installations.length == 1 && (
          <SiteInstallQADetails project_area={project_area} site_installation_qa={qa_site_site_installations[0]} />
        )}
      </AccordionContent>
    </ListItem>
  );
};

const SiteInstallQAList = (props) => {
  const { project_area, site_installation_qa } = props;
  const title = `${site_installation_qa.sections_complete}/${project_area.total_sections} Sections Complete`;

  return (
    <ListItem accordionItem title={title}>
      <AccordionContent>
        <SiteInstallQADetails project_area={project_area} site_installation_qa={site_installation_qa} />
      </AccordionContent>
    </ListItem>
  );
};

const SiteInstallQADetails = (props) => {
  const {
    project_area,
    project_area: { checklist_items },
    site_installation_qa,
  } = props;
  const sorted_checklist_items = _.sortBy(checklist_items, "order");

  return (
    <div className="data-table">
      <table>
        <thead>
          <tr>
            <th colSpan="2">Date Done - {moment(site_installation_qa.date_done).format("DD/MM/YYYY")}</th>
          </tr>
          <tr>
            <th>Item</th>
            <th>Complete</th>
          </tr>
        </thead>
        <tbody>
          {_.map(sorted_checklist_items, (cl_item, i) => (
            <tr key={i}>
              <td>{cl_item.name}</td>
              <td>
                <Link href={`/project/${project_area.project_id}/qa/site/site_installations/${site_installation_qa.id}/edit?tab=${cl_item.id}`}>
                  {_.find(site_installation_qa.checklist_item_links, (siclil) => siclil.checklist_item_id == cl_item.id)?.toggle_value
                    ? "Complete"
                    : "Not Complete"}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
