// Example usage
// import BasicModal from "modules/5_view_helpers/web/BasicModal.jsx";
//   <BasicModal
//     buttonLabel={<FontAwesomeIcon icon={faTimes} />}
//     buttonColor="danger"
//     successButtonLabel="Yes"
//     onSuccessClick={() => {
//       removeItem();
//     }}
//     title={`Delete ${label}`}
//   >
//     Are you sure you want to delete this {label}?
//   </BasicModal>

import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const BasicModal = (props) => {
  const {
    children,
    isOpen,
    className,
    buttonLabel,
    buttonColor,
    title,
    successButtonLabel,
    successButtonColor,
    cancelButtonLabel,
    cancelButtonColor,
    onSuccessClick,
    successButton,
    buttonClassName,
    closeOnSuccess,
  } = props;

  const [modal, setModal] = useState(isOpen);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);

  return (
    <Fragment>
      <Button color={buttonColor} onClick={toggle} className={buttonClassName}>
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          {!successButton && (
            <Fragment>
              {successButtonLabel && (
                <Button
                  color={successButtonColor}
                  onClick={() => {
                    onSuccessClick();
                    if (closeOnSuccess) {
                      toggle();
                    }
                  }}
                >
                  {successButtonLabel}
                </Button>
              )}
            </Fragment>
          )}
          {successButton && <successButton onClick={onSuccessClick} />}

          {cancelButtonLabel && (
            <Button color={cancelButtonColor} onClick={toggle}>
              {cancelButtonLabel}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

BasicModal.defaultProps = {
  isOpen: false,
  buttonLabel: "Open",
  buttonColor: "primary",
  title: "",
  successButtonLabel: "Save",
  successButtonColor: "success",
  cancelButtonLabel: "Cancel",
  cancelButtonColor: "danger",
  buttonClassName: "",
  closeOnSuccess: true,
};

BasicModal.propTypes = {
  isOpen: PropTypes.bool,
  buttonLabel: PropTypes.string,
  buttonColor: PropTypes.string,
  title: PropTypes.string,
  successButtonLabel: PropTypes.string,
  successButtonColor: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  cancelButtonColor: PropTypes.string,
  buttonClassName: PropTypes.string,
  onSuccessClick: PropTypes.func,
  successButton: PropTypes.func,
  closeOnSuccess: PropTypes.bool,
};

export default BasicModal;
