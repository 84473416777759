import React from "react";
import StoreHome from "./home";
const StoreBookoutItemsIndex = React.lazy(() => import("../bookout_items/web/"));

let routes = [];
routes.push({ path: "/store/bookout_items", component: StoreBookoutItemsIndex, name: "StoreBookoutItems" });

routes.push({
  path: "/store",
  component: StoreHome,
  name: "Store Home",
});


export default routes;
