import React from 'react';

const NewProjectQaActionItemsCategories = React.lazy(() => import("./new"))
const EditProjectQaActionItemsCategories = React.lazy(() => import("./edit"))

export default[
	{path:'/project/qa/action_items/categories/:id/edit',component: EditProjectQaActionItemsCategories,name:'Edit ProjectQaActionItemsCategory'},
	{path:'/project/qa/action_items/categories/new',component: NewProjectQaActionItemsCategories,name:'New ProjectQaActionItemsCategory'},  
]

// import ProjectQaActionItemsCategoriesRoutes from "modules/project/qa/action_items/categories/web/routes"
// routes = _.concat(routes,ProjectQaActionItemsCategoriesRoutes)
