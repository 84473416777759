import { 
	GET_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIAS, 
	GET_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIA,
	CREATE_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIA,
	UPDATE_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIA,
	DESTROY_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIA,
} from "./actions"

export function ProjectQaWeldingInspectionsInspectionCriteriasReducer(state = {}, action) {
	switch (action.type) {
		case `${GET_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIAS}_FULFILLED`:{
			return _.mapKeys(action.payload.data, "id");
		}
		case `${GET_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIA}_FULFILLED`:
		case `${CREATE_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIA}_FULFILLED`:
		case `${UPDATE_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIA}_FULFILLED`:{
			let newState = _.clone(state);
			const newItem = action.payload.data;
			const id = _.get(newItem,"id")
			newState[id] = newItem
			return newState;
		}		
		case `${DESTROY_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIA}_FULFILLED`:{
			let newState = _.clone(state);
			const deletedItem = action.payload.data;
			const id = _.get(deletedItem,"id")
			newState = _.filter(newState,i => i.id != id)
			return newState;
		}
		default:
			return state;
	}
}

// Move this to the reducer page
// import { ProjectQaWeldingInspectionsInspectionCriteriasReducer } from "modules/project/qa/welding_inspections/inspection_criterias/reducer"

// project_qa_welding_inspections_inspection_criterias:ProjectQaWeldingInspectionsInspectionCriteriasReducer,
