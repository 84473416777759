import React from 'react';

const ProjectCommunicationsOutgoingTypesIndex = React.lazy(() => import("./index"))
const NewProjectCommunicationsOutgoingTypes = React.lazy(() => import("./new"))
const EditProjectCommunicationsOutgoingTypes = React.lazy(() => import("./edit"))

export default[
	{path:'/project/communications/outgoing_types/:id/edit',component: EditProjectCommunicationsOutgoingTypes,name:'Edit ProjectCommunicationsOutgoingType'},
	{path:'/project/communications/outgoing_types/new',component: NewProjectCommunicationsOutgoingTypes,name:'New ProjectCommunicationsOutgoingType'},  
	// {path:'/project/communications/outgoing_types',component: ProjectCommunicationsOutgoingTypesIndex,name:'ProjectCommunicationsOutgoingTypes'}, 
]

// import ProjectCommunicationsOutgoingTypesRoutes from "modules/project/communications/outgoing_types/web/routes"
// routes = _.concat(routes,ProjectCommunicationsOutgoingTypesRoutes)
