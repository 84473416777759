import React from "react";
import { required, InputField, ReactSelect } from "modules/4_final_form/web";

import { FormGroup, Label, Row, Col, Button } from "reactstrap";

import { Field } from "react-final-form";

import { StatusTypes } from "modules/project/communications/shared.js";

export default class ProjectCommunicationsCustomReferenceForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { handleSubmit, formLabel, cancelFunction } = this.props;

    return (
      <form>
        <FormGroup row>
          <Label md={2}>Title</Label>
          <Col md={4}>
            <Field component={InputField} name="title" type="string" parse={(value) => value} validate={required} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={2}>Type string</Label>
          <Col md={4}>
            <Field component={ReactSelect} name="type_string" options={StatusTypes} />
          </Col>
        </FormGroup>

        <Row>
          <Col>
            <Button color="success" onClick={handleSubmit}>
              {formLabel} Custom references
            </Button>
          </Col>
          <Col className="text-right">
            <Button onClick={cancelFunction} className="float-right" color="danger">
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}
