import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import ProjectCommunicationForm from "modules/project/communications/web/form.jsx";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";

// Helpers
import NotAuthorized from "modules/5_view_helpers/web/NotAuthorized";
import HasRole from "modules/5_view_helpers/HasRole";
import { createProjectCommunication, searchProjectCommunications } from "../actions";
import { PacmanLoader } from "react-spinners";

import { Card, CardBody, Alert } from "reactstrap";

import { Base64toFileObject, MakeEMLFileObject } from "modules/5_view_helpers/FileFunctions.js";
import _, { create } from "lodash";

function mapStateToProps(state) {
  return {};
}

const NewProjectCommunicationOutlook = (props) => {
  const { history } = props;
  const returnURL = "/outlook";
  const createRole = HasRole({ resource_type: "Project::Communication", name: ["create", "moderator"] }, false, true);

  const [loading, setLoading] = useState(true);
  const [loadingString, setLoadingString] = useState(false);
  const [alerts, setAlerts] = useState([]);

  const item = Office.context.mailbox.item;
  const [initialValues, setInitialValues] = useState({ direction: "incoming", subject: item.subject, from: item.from, attachments_attributes: [] });
  useEffect(() => {
    // Get the email body and from START
    item.body.getAsync("html", function (result) {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        setInitialValues({ ...initialValues, body: result.value }); // Email body as HTML
      }
    });
    // Get the email body and from END

    // Get the email attachments START
    _.each(item.attachments, (attachment) => {
      if (attachment.attachmentType == Office.MailboxEnums.AttachmentType.File) {
        item.getAttachmentContentAsync(attachment.id, (result) => {
          if (result.status == Office.AsyncResultStatus.Succeeded) {
            const file = Base64toFileObject(result.value.content, attachment.name, attachment.contentType);

            if (file instanceof File) {
              const newAttachmentObject = {
                attachment: file,
                name: file.name,
                type: file.type,
                size: file.size,
                url: URL.createObjectURL(file),
              };
              // Careful here!
              setInitialValues((prevState) => {
                let updatedAttachments = [...prevState.attachments_attributes];
                updatedAttachments.push(newAttachmentObject);
                return { ...prevState, attachments_attributes: updatedAttachments };
              });
            }
          }
        });
      }
    });
    // Get the email attachments END

    // Get the original email START
    Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, function (asyncResult) {
      if (asyncResult.status === "succeeded") {
        var token = asyncResult.value;
        var messageId = Office.context.mailbox.item.itemId;
        var apiUrl = Office.context.mailbox.restUrl + "/v2.0/me/messages/" + getItemRestId(messageId) + "/$value";

        fetch(apiUrl, {
          headers: { Authorization: "Bearer " + token },
        })
          .then((response) => response.text())
          .then((data) => {
            const file = MakeEMLFileObject(data, "original_email.eml", "message/rfc822");
            const newAttachmentObject = {
              attachment: file,
              name: file.name,
              type: file.type,
              size: file.size,
              url: URL.createObjectURL(file),
            };
            setInitialValues((prevState) => {
              let updatedAttachments = [...prevState.attachments_attributes];
              updatedAttachments.push(newAttachmentObject);
              return { ...prevState, attachments_attributes: updatedAttachments };
            });
            setLoading(false);
          });
      }
    });
    // Get the original email END
  }, []);

  // Run this when loading is done
  useEffect(() => {
    if (!loading) {
      setLoadingString("Searching for related communications...");
      // Get the headers START
      item.getAllInternetHeadersAsync(function (asyncResult) {
        const emailHeaders = asyncResult.value;
        const lines = emailHeaders.split("\n");
        const referencesLine = lines.find((line) => line.startsWith("References:"));

        if (referencesLine) {
          const regex = /<([^>]+)>/g;
          let match;
          const referenceIDS = [];

          while ((match = regex.exec(referencesLine)) !== null) {
            const matched = match[1];
            referenceIDS.push(matched);
          }

          const searchRequest = axios({
            method: "post",
            url: "/project/communications/search.json",
            data: { reference_ids: referenceIDS, subject: item.subject },
          });

          searchRequest.then((response) => {
            const data = response.data;
            const project_id_selected = data[0]?.project_id;

            setInitialValues((prevState) => {
              return { ...prevState, project_id_selected, parents_attributes: data };
            });

            _.each(data, (parent) => {
              setAlerts((prevState) => {
                let updatedAlerts = [...prevState];
                updatedAlerts.push(`Found/Added related communications : ${parent.display_name}`);
                return updatedAlerts;
              });
            });

            setLoadingString(false);
          });
        } else {
          console.log("References tag not found.");
        }
      });
      // // Get the headers END
    }
  }, [loading]);

  const submitForm = (values) => {
    setLoadingString("Creating communication...");
    const createRequest = props.createProjectCommunication(values?.project_id_selected, values);
    createRequest.then(successfullySubmitted);
    createRequest.catch((error) => {
      setAlerts((prevState) => {
        let updatedAlerts = [...prevState];
        updatedAlerts.push(`That didn't work. Please try again.`);
        return updatedAlerts;
      });
      setLoadingString(false);
    });
  };

  const successfullySubmitted = () => {
    setLoadingString("Communication created! Redirecting...");
    // Office.context.ui.displayDialogAsync("https://localhost:3000/outlook", { height: 300, width: 200 }, function (result) {});
    Office.context.ui.closeContainer();
  };

  if (loading) {
    return <PacmanLoader />;
  }

  if (loadingString) {
    return <div>{loadingString}</div>;
  }

  if (!createRole) {
    return <NotAuthorized />;
  } else {
    return (
      <Card>
        <CardBody>
          {alerts.map((alert) => {
            return <Alert color="success">{alert}</Alert>;
          })}
          <Form
            component={ProjectCommunicationForm}
            outlookMode
            onSubmit={submitForm}
            formLabel="Create"
            initialValues={initialValues}
            cancelFunction={() => {
              history.push(returnURL);
            }}
            mutators={{
              ...arrayMutators,
            }}
            {...props}
          />
        </CardBody>
      </Card>
    );
  }
};

export default connect(mapStateToProps, { createProjectCommunication, searchProjectCommunications })(NewProjectCommunicationOutlook);

function getItemRestId() {
  if (Office.context.mailbox.diagnostics.hostName === "OutlookIOS") {
    // itemId is already REST-formatted.
    return Office.context.mailbox.item.itemId;
  } else {
    // Convert to an item ID for API v2.0.
    return Office.context.mailbox.convertToRestId(Office.context.mailbox.item.itemId, Office.MailboxEnums.RestVersion.v2_0);
  }
}
