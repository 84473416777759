import React from "react";
import { Page, BlockTitle, List, ListItem } from "framework7-react";
import HasRole from "modules/5_view_helpers/HasRole";

export const QASideBar = (props) => {
  const [, forceRerender] = React.useState();
  const SiteQARoles = HasRole({ category: "Site QA" }, true);
  const SiteInstallReadRole = HasRole(
    {
      resource_type: "Project::Qa::Site::SiteInstallation",
      name: ["read", "moderator"],
    },
    true
  );
  const ActionItemsReadRole = HasRole(
    {
      resource_type: "Project::Qa::ActionItem",
      name: ["read", "moderator"],
    },
    true
  );
  const surveillanceReadRole = HasRole({ resource_type: "Project::Qa::Site::Surveillance", name: ["read", "moderator"] }, true);

  const paintInspectionReadRole = HasRole(
    {
      resource_type: "Project::Qa::PaintInspection",
      name: ["read", "moderator"],
    },
    true
  );

  return (
    <Page>
      <BlockTitle>QA Menu</BlockTitle>
      <List>
        {SiteQARoles && <ListItem title="Site Installation Dashboard" link="/project/qa/site/site_installations/dashboard" panelClose></ListItem>}
        {SiteInstallReadRole && <ListItem title="Site Installation QA" link="/project/qa/site/site_installations" panelClose></ListItem>}
        {ActionItemsReadRole && <ListItem title="Action Items" link="/project/qa/action_items" panelClose></ListItem>}
        {surveillanceReadRole && <ListItem title="Surveillance Reports" link="/project/qa/site/surveillances" panelClose></ListItem>}
        {paintInspectionReadRole && <ListItem title="Paint Inspections" link="/project/qa/paint_inspections" panelClose></ListItem>}
        <ListItem title="Back to Lion" link="/" panelClose onClick={forceRerender}></ListItem>
      </List>
    </Page>
  );
};

export default QASideBar;