import DrawingOfficeSettings from "./settings_index";

import DrawingOfficeDrawingTypesRoutes from "modules/drawing_office/drawing_types/web/routes";

let routes = [
  {
    path: "/drawing_office/settings",
    name: "drawing_office_settings",
    component: DrawingOfficeSettings,
  },
];
routes = _.concat(routes, DrawingOfficeDrawingTypesRoutes);

export default routes;
