import React from 'react';

const ProjectQaWeldingInspectionsInspectionSpecsIndex = React.lazy(() => import("./index"))
const NewProjectQaWeldingInspectionsInspectionSpecs = React.lazy(() => import("./new"))
const EditProjectQaWeldingInspectionsInspectionSpecs = React.lazy(() => import("./edit"))

export default[
	{path:'/project/qa/welding_inspections/inspection_specs/:id/edit',component: EditProjectQaWeldingInspectionsInspectionSpecs,name:'Edit ProjectQaWeldingInspectionsInspectionSpec'},
	{path:'/project/qa/welding_inspections/inspection_specs/new',component: NewProjectQaWeldingInspectionsInspectionSpecs,name:'New ProjectQaWeldingInspectionsInspectionSpec'},  
	{path:'/project/qa/welding_inspections/inspection_specs',component: ProjectQaWeldingInspectionsInspectionSpecsIndex,name:'ProjectQaWeldingInspectionsInspectionSpecs'}, 
]

// import ProjectQaWeldingInspectionsInspectionSpecsRoutes from "modules/project/qa/welding_inspections/inspection_specs/web/routes"
// routes = _.concat(routes,ProjectQaWeldingInspectionsInspectionSpecsRoutes)
