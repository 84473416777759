import React from "react";
import { connect } from "react-redux";
import { withRouter, generatePath } from "react-router";

import { Link } from "react-router-dom";

import SelectProject from "modules/projects/web/select";
import { setCurrentProject } from "modules/current_user/actions";

import { Navbar, Badge, NavbarToggler, Nav, NavbarText } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import "./AppNavbar.css";
import ProjectUpdateButton from "modules/projects/web/update_button";

function mapStateToProps(state) {
  return {
    current_user: state.current_user,
  };
}

let AppNavbar = (props) => {
  const {
    current_user: { current_project_id, notifications },
    setShowMenu,
    setShowNotifications,
  } = props;

  const handleProjectChange = ({ value: newProjectID }) => {
    const {
      actualMatch: {
        params: { project_id },
        path,
      },
      history,
    } = props;

    props.setCurrentProject(newProjectID);
    if (project_id) {
      // Redirect if the current route has a project_id in it.
      const newPath = generatePath(path, { project_id: newProjectID });
      history.push(newPath);
    } else if (_.includes(path, "/projects/:id")) {
      // special case for project show route
      const newPath = generatePath(path, { id: newProjectID });
      history.push(newPath);
    }
  };

  return (
    <div>
      <Navbar className="navbar" expand="md" fixed>
        <NavbarToggler onClick={setShowMenu}>
          <FontAwesomeIcon icon={faBars} />
        </NavbarToggler>
        <Nav className="mr-auto" navbar></Nav>
        {notifications.length > 0 && (
          <div className="mr-2">
            <Badge
              color={notifications.length == 0 ? "success" : "danger"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowNotifications(true);
              }}
            >
              {/* <FontAwesomeIcon icon={faBell} /> */}
              {notifications.length}
            </Badge>
          </div>
        )}
        <div className="mr-2">
          <Link to={`/projects/${current_project_id}/show`}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </Link>
          <ProjectUpdateButton project_id={current_project_id} />
        </div>
        <NavbarText>
          <SelectProject onChange={handleProjectChange} value={current_project_id} />
        </NavbarText>
      </Navbar>
    </div>
  );
};

AppNavbar = withRouter(AppNavbar);

export default connect(mapStateToProps, { setCurrentProject })(AppNavbar);
