export function ErrorsReducer(state = {}, action) {
  const split = action.type.split("_");
  const type = split.slice(-1)[0];
  switch (type) {
    case "PENDING":
      return null;
    case "FULFILLED":
      return null;
    case "REJECTED": {
      let newState = [];
      const status = _.get(action, "payload.response.status");
      if (status == 422) {
        const errors = _.get(action, "payload.response.data");
        if (_.isNotEmpty(errors)) {
          newState = _.map(errors, (v, k) => `${_.capitalize(k)} ${v}`);
        }
      }
      if (status == 403) {
        newState = ["You do not have permission for this page."];
      }
      return newState;
    }
    default:
      return state;
  }
}
