import React from 'react';

const PaintProductCategoriesIndex = React.lazy(() => import("./index"))
const NewPaintProductCategories = React.lazy(() => import("./new"))
const EditPaintProductCategories = React.lazy(() => import("./edit"))

export default[
	{path:'/paint/product_categories/:id/edit',component: EditPaintProductCategories,name:'Edit PaintProductCategory'},
	{path:'/paint/product_categories/new',component: NewPaintProductCategories,name:'New PaintProductCategory'},  
	{path:'/paint/product_categories',component: PaintProductCategoriesIndex,name:'PaintProductCategories'}, 
]

// import PaintProductCategoriesRoutes from "modules/paint/product_categories/web/routes"
// routes = _.concat(routes,PaintProductCategoriesRoutes)
