import axios from "axios";

export const GET_PRODUCTION_SCHEDULE_BY_LOT = "GET_PRODUCTION_SCHEDULE_BY_LOT";
export const getProductionScheduleByLot = () => {
  const url = `/production/schedule/by_lot.json`;
  const request = axios.get(url);
  return {
    type: GET_PRODUCTION_SCHEDULE_BY_LOT,
    payload: request,
  };
};

export const GET_PRODUCTION_SCHEDULE_BY_BUNDLE = "GET_PRODUCTION_SCHEDULE_BY_BUNDLE";
export const getProductionScheduleByBundle = () => {
  const url = `/production/schedule/by_bundle.json`;
  const request = axios.get(url);
  return {
    type: GET_PRODUCTION_SCHEDULE_BY_BUNDLE,
    payload: request,
  };
};
