import React from 'react';

const PaintCodeDetailsIndex = React.lazy(() => import("./index"))
const NewPaintCodeDetails = React.lazy(() => import("./new"))
const EditPaintCodeDetails = React.lazy(() => import("./edit"))

export default[
	{path:'/paint/code_details/:id/edit',component: EditPaintCodeDetails,name:'Edit PaintCodeDetail'},
	{path:'/paint/code_details/new',component: NewPaintCodeDetails,name:'New PaintCodeDetail'},  
	{path:'/paint/code_details',component: PaintCodeDetailsIndex,name:'PaintCodeDetails'}, 
]

// import PaintCodeDetailsRoutes from "modules/paint/code_details/web/routes"
// routes = _.concat(routes,PaintCodeDetailsRoutes)
