import React, { useEffect } from "react";
import { connect } from "react-redux";

import { HashRouter, Route, Switch } from "react-router-dom";

import { getCurrentUser } from "modules/current_user/actions";
import PacmanLoader from "react-spinners/PacmanLoader";
import routes from "modules/2_routes/outlook";

function mapStateToProps(state) {
  return {
    current_user: state.current_user,
  };
}

const Router = (props) => {
  const { current_user } = props;
  useEffect(() => {
    props.getCurrentUser();
  }, []);

  if (_.isEmpty(current_user)) {
    return <PacmanLoader />;
  }

  return (
    <HashRouter>
      {/* Router */}
      <Switch>
        {_.map(routes, (route, i) => {
          return <Route key={i} path={route.path} exact={route.exact} name={route.name} component={route.component} />;
        })}
      </Switch>
      {/* Router */}
    </HashRouter>
  );
};

export default connect(mapStateToProps, { getCurrentUser })(Router);
