import React, { useState, useEffect, Fragment } from "react";

import { Button, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { getCustomForms } from "modules/custom_forms/actions";
import _ from "lodash";

function mapStateToProps(state) {
  return {
    custom_forms: _.filter(state.custom_forms, (cf) => cf.on_outlook_addin),
    current_user: state.current_user,
  };
}

const OutlookHome = (props) => {
  const { custom_forms } = props;
  const [ready, setReady] = useState(false);
  useEffect(() => {
    Office.onReady(() => {
      setReady(true);
    });
    props.getCustomForms();
  }, []);

  if (!ready) {
    return <div>Loading Office Addin...</div>;
  }

  return (
    <Container>
      <hr />
      <Link to="/project/communications/new">
        <Button size="lg" color="primary" className="w-100">
          Log Communication
        </Button>
      </Link>
      <hr />
      {_.map(custom_forms, (cf, i) => (
        <Fragment key={i}>
          <Link to={`/custom_form/${cf.id}/records/new`}>
            <Button size="lg" color="primary" className="w-100">
              {cf.name}
            </Button>
          </Link>
          <hr />
        </Fragment>
      ))}
    </Container>
  );
};

export default connect(mapStateToProps, { getCustomForms })(OutlookHome);
