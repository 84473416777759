import { GET_PRODUCTION_STRUMIS_SECTION_TYPES } from "./actions";

export function ProductionStrumisSectionTypesReducer(state = [], action) {
  switch (action.type) {
    case `${GET_PRODUCTION_STRUMIS_SECTION_TYPES}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { ProductionStrumisSectionTypesReducer } from "modules/production/strumis/section_types/reducer"

// production_strumis_section_types:ProductionStrumisSectionTypesReducer,
