import { 
	GET_PAINT_PRODUCTS, 
	GET_PAINT_PRODUCT,
	CREATE_PAINT_PRODUCT,
	UPDATE_PAINT_PRODUCT,
	// DESTROY_PAINT_PRODUCT,
} from "./actions"

export function PaintProductsReducer(state = {}, action) {
	switch (action.type) {
		case `${GET_PAINT_PRODUCTS}_FULFILLED`:{
			return _.mapKeys(action.payload.data, "id");
		}
		case `${GET_PAINT_PRODUCT}_FULFILLED`:
		case `${CREATE_PAINT_PRODUCT}_FULFILLED`:
		case `${UPDATE_PAINT_PRODUCT}_FULFILLED`:{
			let newState = _.clone(state);
			const newItem = action.payload.data;
			const id = _.get(newItem,"id")
			newState[id] = newItem
			return newState;
		}		
		// case `${DESTROY_PAINT_PRODUCT}_FULFILLED`:{
		// 	let newState = _.clone(state);
		// 	const deletedItem = action.payload.data;
		// 	const id = _.get(deletedItem,"id")
		// 	newState = _.filter(newState,i => i.id != id)
		// 	return newState;
		// }
		default:
			return state;
	}
}

// Move this to the reducer page
// import { PaintProductsReducer } from "modules/paint/products/reducer"

// paint_products:PaintProductsReducer,
