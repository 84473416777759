import React from "react";
import { Alert } from "reactstrap";
// const NotAuthorized = () => {
//   // alert("You are not authorized for this.");
//   // window.location.assign("/error_403");
// };

const NotAuthorized = () => {
  return (
    <Alert color="danger">
      <b>The following errors prevented further action</b>
      <li>You do not have permission for this page.</li>
    </Alert>
  );
};

export default NotAuthorized;
