import React from "react";
import { connect } from "react-redux";

import FormViewer from "modules/5_view_helpers/web/FormBuilder/FormViewer";

function mapStateToProps(state) {
  return {
    projects: state.projects,
    employees: state.employees,
    custom_forms: state.custom_forms,
    custom_form_records: state.custom_form_records,
    react_dropzone_status: state.react_dropzone_status,
  };
}

const CustomFormRecordForm = (props) => {
  const { handleSubmit, formLabel, cancelFunction, custom_form, outlookMode, parents, parentSelectLabel, edit } = props;

  const stillUploading = props.react_dropzone_status > 0;

  return (
    <form>
      <FormViewer
        custom_form={custom_form}
        cancelFunction={cancelFunction}
        formLabel={formLabel}
        handleSubmit={handleSubmit}
        outlookMode={outlookMode} // Outlook Mode
        parents={parents} // Passing parents to form viewer for the outlook mode only.
        parentSelectLabel={parentSelectLabel}
        edit={edit}
        disabled={stillUploading}
        disabledMessage="File/s still uploading. Please wait."
        {...props}
      />
    </form>
  );
};

export default connect(mapStateToProps, {})(CustomFormRecordForm);
