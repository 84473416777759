import React, { Fragment } from "react";
import { connect } from "react-redux";
import { getProjectAreas } from "modules/project/areas/actions";
import { getUserManagements } from "modules/user_managements/actions.js";

import { getProjectQaActionItemsStatuses } from "modules/project/qa/action_items/statuses/actions";

import { Toolbar, Link, Button, List, Page, Tabs, Tab, BlockTitle, Block, Popover, Icon } from "framework7-react";

import { required, InputField, ToggleField, SmartSelect, ReactDropzone, TimeField } from "modules/4_final_form/mobile";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import { getEmployees } from "modules/employees/actions";

import ProjectAreaTab from "./form/ProjectAreaTab";

function mapStateToProps(state, ownProps) {
  return {
    employees: state.employees,
    project_areas: state.project_areas,
    users: state.users,
    project_qa_action_items_statuses: state.project_qa_action_items_statuses,
    current_user: state.current_user,
    react_dropzone_status: state.react_dropzone_status,
  };
}

class ProjectQaSiteSurveillanceForm extends React.Component {
  constructor(props) {
    super(props);

    const {
      f7route: {
        params: { project_id },
      },
    } = this.props;

    this.props.getEmployees();
    this.props.getProjectAreas(project_id);

    this.props.getUserManagements();
    this.props.getProjectQaActionItemsStatuses();
  }

  render() {
    const { handleSubmit, values, project_areas } = this.props;

    const {
      f7route: {
        params: { project_id },
      },
      project,
    } = this.props;
    const stillUploading = this.props.react_dropzone_status > 0;

    const selected_project_areas = _.map(values?.area_links_attributes, (selected_project_area) => {
      return project_areas[selected_project_area.value];
    });

    return (
      <Page>
        <Toolbar tabbar bottom>
          <Link tabLink="#details" tabLinkActive>
            Details
          </Link>

          {selected_project_areas.length >= 2 ? (
            <Link popoverOpen=".popover">
              <Icon f7="ellipsis" />
            </Link>
          ) : (
            <Fragment>
              {_.map(selected_project_areas, (project_area, i) => (
                <Link key={project_area?.id} tabLink={`#pa-${project_area?.id}`}>
                  {project_area?.name}
                </Link>
              ))}
            </Fragment>
          )}
        </Toolbar>
        <form>
          <BlockTitle>{project?.display_name}</BlockTitle>
          <hr />
          <Tabs>
            <Tab id="details" tabActive>
              <DetailsTab {...this.props} />
            </Tab>

            {_.map(selected_project_areas, (project_area, i) => (
              <Tab id={`pa-${project_area?.id}`} key={i}>
                <ProjectAreaTab project_id={project_id} project_area={project_area} index={i} {...this.props} />
              </Tab>
            ))}
          </Tabs>
          <Block>
            <Button
              color="green"
              fill
              round
              onClick={stillUploading ? null : handleSubmit}
              tooltip={stillUploading && "Please wait for the attachments to finish uploading before saving."}
            >
              Save
            </Button>
          </Block>
        </form>

        <Popover className="popover" backdrop={false}>
          <List>
            {_.map(selected_project_areas, (project_area, i) => (
              <Button key={i} popoverClose tabLink={`#pa-${project_area?.id}`}>
                {project_area?.name}
              </Button>
            ))}
          </List>
        </Popover>
      </Page>
    );
  }
}

export default connect(mapStateToProps, {
  getEmployees,
  getProjectAreas,
  getUserManagements,
  getProjectQaActionItemsStatuses,
})(ProjectQaSiteSurveillanceForm);

const DetailsTab = (props) => {
  const { employees, project_areas } = props;

  return (
    <List>
      <Field component={InputField} label="Date" name="date" placeholder="Enter date..." type="date" slot="list" validate={required} />
      <Field component={TimeField} label="Time In" type="time" name="time_in" slot="list" showNow />

      <Field
        component={SmartSelect}
        slot="list"
        name="site_rep_id"
        options={_.map(employees, (emp) => {
          return { label: emp.display_name, value: emp.id };
        })}
        label="Site Representative"
        labelName="label"
        placeholder="Select Site Rep"
        valueName="value"
        openIn="popover"
        validate={required}
      />

      <Field
        component={SmartSelect}
        slot="list"
        name="area_links_attributes"
        options={_.map(project_areas, (area) => {
          return { label: area.name, value: area.id, area_id: area.id };
        })}
        label="Areas Visited"
        multi
        labelName="label"
        valueName="value"
        validate={required}
      />

      <Field
        component={InputField}
        label="Work Being Performed"
        name="work_being_performed"
        placeholder="Enter here..."
        type="textarea"
        slot="list"
      />
      <Field component={TimeField} label="Time Out" type="time" name="time_out" slot="list" showNow />
      <Field component={ToggleField} label="For Client" name="for_client" slot="list" />

      <Block slot="list">
        <BlockTitle>General Surveillance Attachments</BlockTitle>
        <FieldArray component={ReactDropzone} name="attachments_attributes" uploadNow />
      </Block>
    </List>
  );
};
