import React from "react";
import { connect } from "react-redux";

import { Row, Col, Button } from "reactstrap";
import SlidingPane from "react-sliding-pane";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { readNotification } from "modules/current_user/actions.jsx";

import "react-sliding-pane/dist/react-sliding-pane.css";

function mapStateToProps(state) {
  return {
    current_user: state.current_user,
  };
}

const UserNotifications = (props) => {
  const { current_user, open, setOpen } = props;

  return (
    <SlidingPane
      isOpen={open}
      onRequestClose={() => {
        setOpen(false);
      }}
      title={`${current_user?.notifications.length} unread Notifications`}
      width="25%"
    >
      {_.map(current_user?.notifications, (notification) => {
        const { message, link, link_text, link_new_tab, read, created_at } = notification;

        return (
          <div className="border p-2 mb-2">
            <Row className="border-bottom">
              <Col>{moment(created_at).format("DD/MM/YYYY HH:mm:a")}</Col>
              <Col className="text-right">
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props.readNotification(notification.id);
                    if (current_user?.notifications.length === 1) {
                      setOpen(false);
                    }
                  }}
                />
              </Col>
            </Row>
            <br />
            {message}
            {link && (
              <a href={link} target={link_new_tab ? "_blank" : null}>
                <Button className="ml-1" color="success">
                  {link_text}
                </Button>
              </a>
            )}
          </div>
        );
      })}
    </SlidingPane>
  );
};

export default connect(mapStateToProps, { readNotification })(UserNotifications);
