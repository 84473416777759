import {
  GET_MW_JOBS,
} from "./actions";

export function MWJobsReducer(state = [], action) {
  switch (action.type) {
    case `${GET_MW_JOBS}_FULFILLED`: {
      return action.payload.data
    }
    default:
      return state;
  }
}
