import React from 'react';

const Users = React.lazy(() => import("./index"))
const NewUsers = React.lazy(() => import("./new"))
const EditUsers = React.lazy(() => import("./edit"))

export default[
	{path:'/users/:id/edit',component: EditUsers,name:'Edit User'},
	{path:'/users/new',component: NewUsers,name:'New User'},  
	{path:'/users',component: Users,name:'Users'}, 
]

// import UsersRoutes from "modules/user_managements/web/routes"
// routes = _.concat(routes,UsersRoutes)
