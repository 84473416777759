import React from 'react';

const TrainingCourseCategory = React.lazy(() => import("./index"))
const NewTrainingCourseCategory = React.lazy(() => import("./new"))
const EditTrainingCourseCategory = React.lazy(() => import("./edit"))

export default[
	{path:'/training/course_categories/:id/edit',component: EditTrainingCourseCategory,name:'Edit TrainingCourseCategory'},
	{path:'/training/course_categories/new',component: NewTrainingCourseCategory,name:'New TrainingCourseCategory'},  
	{path:'/training/course_categories',component: TrainingCourseCategory,name:'TrainingCourseCategory'}, 
]

// import TrainingCourseCategoryRoutes from "modules/training/course_categories/web/routes"
// routes = _.concat(routes,TrainingCourseCategoryRoutes)
