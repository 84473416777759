import { faArrowCircleDown, faArrowCircleLeft, faArrowCircleRight, faArrowCircleUp, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import toSentence from "modules/5_view_helpers/to_sentence";
import React, { Fragment } from "react";
import { Col, Popover, PopoverBody, Row } from "reactstrap";

const DroppableCol = (props) => {
  const { openEdit, columnValues, name, column_types, disabled, canGoDown, canGoLeft, canGoRight, canGoUp, moveLeft, moveRight, moveDown, moveUp } =
    props;

  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const thisRef = React.useRef(null);
  const canMove = canGoDown || canGoLeft || canGoRight || canGoUp;
  const targetGuid = "col_" + props?.columnValues?.uid;

  return (
    <Fragment>
      <div className="colContainer" style={{ cursor: disabled ? "not-allowed" : "zoom-in" }} ref={thisRef}>
        <div
          onClick={() => {
            openEdit();
          }}
        >
          <ColumnRenderer column_types={column_types} {...columnValues} />
        </div>
        {canMove && (
          <Fragment>
            <div className="text-right" id={_.snakeCase(name)}>
              <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faEllipsisV} style={{ cursor: "pointer", padding: "5px", textAlign: "right" }} />
            </div>
            <Popover isOpen={popoverOpen} trigger="legacy" toggle={togglePopover} target={_.snakeCase(name)}>
              <PopoverBody>
                <Row>
                  <Col md={{ offset: 4 }}>
                    {canGoUp && (
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        icon={faArrowCircleUp}
                        size="2x"
                        onClick={() => {
                          moveUp();
                          togglePopover();
                        }}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {canGoLeft && (
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        icon={faArrowCircleLeft}
                        size="2x"
                        onClick={() => {
                          moveLeft();
                          togglePopover();
                        }}
                      />
                    )}
                    {canGoRight && (
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        icon={faArrowCircleRight}
                        size="2x"
                        onClick={() => {
                          moveRight();
                          togglePopover();
                        }}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={{ offset: 4 }}>
                    {canGoDown && (
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        icon={faArrowCircleDown}
                        size="2x"
                        onClick={() => {
                          moveDown();
                          togglePopover();
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </PopoverBody>
            </Popover>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default DroppableCol;

const ColumnRenderer = (props) => {
  const { column_types, column_type, conditionally_visible, conditional_value, conditional_field } = props;

  const findColumn = column_types.find((c) => c.value == column_type)
  const SelectedFormComponent = findColumn?.formComponent;

  let conditional_value_text = conditional_value;
  if (_.isArray(conditional_value)) {
    conditional_value_text = toSentence(_.map(conditional_value, "label"), { twoWordsConnector: ' or ' });
  }

  let ConditionLabel = conditionally_visible && <i>Is visible when {conditional_field} is <b>{conditional_value_text}</b></i>


  if (SelectedFormComponent) {
    return (<Fragment>
      <SelectedFormComponent preview {...props} />
      {ConditionLabel}
    </Fragment>)
  }
  return null;
};
