import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import classnames from "classnames";

import HasRole from "modules/5_view_helpers/HasRole";
import qs from "query-string";
import ProjectQaWeldingInspectionsAccessRestrictionsIndex from "modules/project/qa/welding_inspections/access_restrictions/web/index";
import ProjectQaWeldingInspectionsSurfaceConditionsIndex from "modules/project/qa/welding_inspections/surface_conditions/web/index.jsx";
import ProjectQaWeldingInspectionsViewingConditionsIndex from "modules/project/qa/welding_inspections/viewing_conditions/web/index.jsx";
import ProjectQaWeldingInspectionsInspectionSpecsIndex from "modules/project/qa/welding_inspections/inspection_specs/web/index.jsx";
import ProjectQaWeldingInspectionsInspectionCriteriasIndex from "modules/project/qa/welding_inspections/inspection_criterias/web/index.jsx";

import ProjectQaWeldingInspectionsWeldingStandardsIndex from "modules/project/qa/welding_inspections/welding_standards/web/index.jsx";
import ProjectQaWeldingInspectionsWeldingStagesIndex from "modules/project/qa/welding_inspections/welding_stages/web/index.jsx";
import ProjectQaWeldingInspectionsWeldingProcessesIndex from "modules/project/qa/welding_inspections/welding_processes/web/index.jsx";
import ProjectQaWeldingInspectionsWeldingTypesIndex from "modules/project/qa/welding_inspections/welding_types/web/index.jsx";
import ProjectQaWeldingInspectionsWeldingProceduresIndex from "modules/project/qa/welding_inspections/welding_procedures/web/index.jsx";
import ProjectQaWeldingInspectionsFailureReasonsIndex from "modules/project/qa/welding_inspections/failure_reasons/web/index.jsx";
import ProjectQaWeldingInspectionsFilletSizesIndex from "modules/project/qa/welding_inspections/fillet_sizes/web/index.jsx";

const ProjectsQaSiteChecklistItemIndex = React.lazy(() => import("modules/projects/qa/site/checklist_items/web"));
const ProjectQaActionItemsStatusesIndex = React.lazy(() => import("modules/project/qa/action_items/statuses/web/index"));
const ProjectQaActionItemsCategoriesIndex = React.lazy(() => import("modules/project/qa/action_items/categories/web/index.jsx"));

const SettingsIndex = (props) => {
  const { history } = props;
  const query = qs.parse(props.location.search);
  const { tab } = query;
  const [activeTab, setActiveTab] = useState(tab ? tab : "welding_inspections");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Card>
      <CardHeader>Quality Assurance Module Settings</CardHeader>
      <CardBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "welding_inspections" })}
              onClick={() => {
                toggle("welding_inspections");
                history.push("/qa/settings?tab=welding_inspections");
              }}
            >
              Welding Inspections
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "action_items" })}
              onClick={() => {
                toggle("action_items");
                history.push("/qa/settings?tab=action_items");
              }}
            >
              Action Items
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "site_qa" })}
              onClick={() => {
                toggle("site_qa");
                history.push("/qa/settings?tab=site_qa");
              }}
            >
              Site QA
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="welding_inspections">
            <WeldingInspectionsSettings {...props} />
          </TabPane>
          <TabPane tabId="action_items">
            <ActionItemsSettings {...props} />
          </TabPane>
          <TabPane tabId="site_qa">
            <SiteQASettings {...props} />
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  );
};

export default SettingsIndex;

const WeldingInspectionsSettings = (props) => {
  const { history } = props;
  const query = qs.parse(props.location.search);
  const { child_tab } = query;
  const [activeTab, setActiveTab] = useState(child_tab ? child_tab : "access_restrictions");

  const weldingStandardsRole = HasRole({
    resource_type: "Project::Qa::WeldingInspections::WeldingStandard",
    name: ["read", "moderator"],
  });

  const weldingStagesRole = HasRole({
    resource_type: "Project::Qa::WeldingInspections::WeldingStage",
    name: ["read", "moderator"],
  });

  const weldingProcessesRole = HasRole({
    resource_type: "Project::Qa::WeldingInspections::WeldingProcess",
    name: ["read", "moderator"],
  });

  const weldingTypesRole = HasRole({
    resource_type: "Project::Qa::WeldingInspections::WeldingType",
    name: ["read", "moderator"],
  });

  const weldingProceduresRole = HasRole({ resource_type: "Project::Qa::WeldingInspections::WeldingProcedure", name: ["read", "moderator"] });

  const accessRestrictionsRole = HasRole({
    resource_type: "Project::Qa::WeldingInspections::AccessRestriction",
    name: ["read", "moderator"],
  });
  const surfaceConditionsRole = HasRole({
    resource_type: "Project::Qa::WeldingInspections::SurfaceCondition",
    name: ["read", "moderator"],
  });
  const viewingConditionsRole = HasRole({
    resource_type: "Project::Qa::WeldingInspections::SurfaceCondition",
    name: ["read", "moderator"],
  });

  const inspectionSpecsRole = HasRole({
    resource_type: "Project::Qa::WeldingInspections::InspectionSpec",
    name: ["read", "moderator"],
  });

  const inspectionCriteriasRole = HasRole({
    resource_type: "Project::Qa::WeldingInspections::InspectionCriteria",
    name: ["read", "moderator"],
  });

  const failureReasonRole = HasRole({
    resource_type: "Project::Qa::WeldingInspections::FailureReason",
    name: ["read", "moderator"],
  });
  const filletSizesRoles = HasRole({
    resource_type: "Project::Qa::WeldingInspections::FilletSize",
    name: ["read", "moderator"],
  });

  const settingsRole = accessRestrictionsRole || surfaceConditionsRole || viewingConditionsRole || weldingStandardsRole;

  if (!settingsRole) {
    return <NotAuthorized />;
  } else {
    return (
      <div>
        <Nav tabs>
          {accessRestrictionsRole && (
            <NavItem>
              <NavLink
                className={activeTab == "access_restrictions" ? "active" : ""}
                onClick={() => {
                  setActiveTab("access_restrictions");
                  history.push("/qa/settings?tab=welding_inspections&child_tab=access_restrictions");
                }}
              >
                Access Restrictions
              </NavLink>
            </NavItem>
          )}
          {surfaceConditionsRole && (
            <NavItem>
              <NavLink
                className={activeTab == "surface_conditions" ? "active" : ""}
                onClick={() => {
                  setActiveTab("surface_conditions");
                  history.push("/qa/settings?tab=welding_inspections&child_tab=surface_conditions");
                }}
              >
                Surface Conditions
              </NavLink>
            </NavItem>
          )}
          {viewingConditionsRole && (
            <NavItem>
              <NavLink
                className={activeTab == "viewing_conditions" ? "active" : ""}
                onClick={() => {
                  setActiveTab("viewing_conditions");
                  history.push("/qa/settings?tab=welding_inspections&child_tab=viewing_conditions");
                }}
              >
                Viewing Conditions
              </NavLink>
            </NavItem>
          )}
          {weldingStandardsRole && (
            <NavItem>
              <NavLink
                className={activeTab == "welding_standards" ? "active" : ""}
                onClick={() => {
                  setActiveTab("welding_standards");
                  history.push("/qa/settings?tab=welding_inspections&child_tab=welding_standards");
                }}
              >
                Welding Standards
              </NavLink>
            </NavItem>
          )}
          {weldingStagesRole && (
            <NavItem>
              <NavLink
                className={activeTab == "welding_stages" ? "active" : ""}
                onClick={() => {
                  setActiveTab("welding_stages");
                  history.push("/qa/settings?tab=welding_inspections&child_tab=welding_stages");
                }}
              >
                Welding Stages
              </NavLink>
            </NavItem>
          )}
          {weldingProcessesRole && (
            <NavItem>
              <NavLink
                className={activeTab == "welding_processes" ? "active" : ""}
                onClick={() => {
                  setActiveTab("welding_processes");
                  history.push("/qa/settings?tab=welding_inspections&child_tab=welding_processes");
                }}
              >
                Welding Processes
              </NavLink>
            </NavItem>
          )}
          {weldingTypesRole && (
            <NavItem>
              <NavLink
                className={activeTab == "welding_types" ? "active" : ""}
                onClick={() => {
                  setActiveTab("welding_types");
                  history.push("/qa/settings?tab=welding_inspections&child_tab=welding_types");
                }}
              >
                Welding Types
              </NavLink>
            </NavItem>
          )}
          {weldingProceduresRole && (
            <NavItem>
              <NavLink
                className={activeTab == "welding_procedures" ? "active" : ""}
                onClick={() => {
                  setActiveTab("welding_procedures");
                  history.push("/qa/settings?tab=welding_inspections&child_tab=welding_procedures");
                }}
              >
                Welding Procedures
              </NavLink>
            </NavItem>
          )}
          {weldingProceduresRole && (
            <NavItem>
              <NavLink
                className={activeTab == "failure_reasons" ? "active" : ""}
                onClick={() => {
                  setActiveTab("failure_reasons");
                  history.push("/qa/settings?tab=welding_inspections&child_tab=failure_reasons");
                }}
              >
                Failure Reasons
              </NavLink>
            </NavItem>
          )}
          {filletSizesRoles && (
            <NavItem>
              <NavLink
                className={activeTab == "fillet_sizes" ? "active" : ""}
                onClick={() => {
                  setActiveTab("fillet_sizes");
                  history.push("/qa/settings?tab=welding_inspections&child_tab=fillet_sizes");
                }}
              >
                Fillet Sizes
              </NavLink>
            </NavItem>
          )}
          {inspectionSpecsRole && (
            <NavItem>
              <NavLink
                className={activeTab == "inspection_specs" ? "active" : ""}
                onClick={() => {
                  setActiveTab("inspection_specs");
                  history.push("/qa/settings?tab=welding_inspections&child_tab=inspection_specs");
                }}
              >
                Inspection Specs
              </NavLink>
            </NavItem>
          )}
          {inspectionCriteriasRole && (
            <NavItem>
              <NavLink
                className={activeTab == "inspection_criterias" ? "active" : ""}
                onClick={() => {
                  setActiveTab("inspection_criterias");
                  history.push("/qa/settings?tab=welding_inspections&child_tab=inspection_criterias");
                }}
              >
                Inspection Critera/s
              </NavLink>
            </NavItem>
          )}
        </Nav>

        <TabContent activeTab={activeTab}>
          {accessRestrictionsRole && activeTab == "access_restrictions" && (
            <TabPane tabId="access_restrictions" active>
              <ProjectQaWeldingInspectionsAccessRestrictionsIndex {...props} />
            </TabPane>
          )}
          {surfaceConditionsRole && activeTab == "surface_conditions" && (
            <TabPane tabId="surface_conditions" active>
              <ProjectQaWeldingInspectionsSurfaceConditionsIndex {...props} />
            </TabPane>
          )}
          {viewingConditionsRole && activeTab == "viewing_conditions" && (
            <TabPane tabId="viewing_conditions" active>
              <ProjectQaWeldingInspectionsViewingConditionsIndex {...props} />
            </TabPane>
          )}
          {weldingStandardsRole && activeTab == "welding_standards" && (
            <TabPane tabId="welding_standards" active>
              <ProjectQaWeldingInspectionsWeldingStandardsIndex {...props} />
            </TabPane>
          )}
          {weldingStagesRole && activeTab == "welding_stages" && (
            <TabPane tabId="welding_stages" active>
              <ProjectQaWeldingInspectionsWeldingStagesIndex {...props} />
            </TabPane>
          )}
          {weldingProcessesRole && activeTab == "welding_processes" && (
            <TabPane tabId="welding_processes" active>
              <ProjectQaWeldingInspectionsWeldingProcessesIndex {...props} />
            </TabPane>
          )}
          {weldingTypesRole && activeTab == "welding_types" && (
            <TabPane tabId="welding_types" active>
              <ProjectQaWeldingInspectionsWeldingTypesIndex {...props} />
            </TabPane>
          )}
          {weldingProceduresRole && activeTab == "welding_procedures" && (
            <TabPane tabId="welding_procedures" active>
              <ProjectQaWeldingInspectionsWeldingProceduresIndex {...props} />
            </TabPane>
          )}
          {failureReasonRole && activeTab == "failure_reasons" && (
            <TabPane tabId="failure_reasons" active>
              <ProjectQaWeldingInspectionsFailureReasonsIndex {...props} />
            </TabPane>
          )}
          {filletSizesRoles && activeTab == "fillet_sizes" && (
            <TabPane tabId="fillet_sizes" active>
              <ProjectQaWeldingInspectionsFilletSizesIndex {...props} />
            </TabPane>
          )}
          {inspectionSpecsRole && activeTab == "inspection_specs" && (
            <TabPane tabId="inspection_specs" active>
              <ProjectQaWeldingInspectionsInspectionSpecsIndex {...props} />
            </TabPane>
          )}
          {inspectionCriteriasRole && activeTab == "inspection_criterias" && (
            <TabPane tabId="inspection_criterias" active>
              <ProjectQaWeldingInspectionsInspectionCriteriasIndex {...props} />
            </TabPane>
          )}
        </TabContent>
      </div>
    );
  }
};

const ActionItemsSettings = (props) => {
  const { history } = props;
  const query = qs.parse(props.location.search);
  const { child_tab } = query;
  const [activeTab, setActiveTab] = useState(child_tab ? child_tab : "action_item_statuses");

  const categoryRole = HasRole({ resource_type: "Project::Qa::ActionItems::Category", name: ["read", "moderator"] });

  const settingsRole = HasRole({
    resource_type: "Projects::Qa::Site::ChecklistItem",
    name: ["read", "moderator"],
  });

  if (!settingsRole) {
    return <NotAuthorized />;
  } else {
    return (
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab == "action_item_statuses" ? "active" : ""}
              onClick={() => {
                setActiveTab("action_item_statuses");
                history.push("/qa/settings?tab=action_items&child_tab=action_item_statuses");
              }}
            >
              Statuses
            </NavLink>
          </NavItem>
          {categoryRole && (
            <NavItem>
              <NavLink
                className={activeTab == "action_items_categories" ? "active" : ""}
                onClick={() => {
                  setActiveTab("action_items_categories");
                  history.push("/qa/settings?tab=action_items&child_tab=action_items_categories");
                }}
              >
                Categories
              </NavLink>
            </NavItem>
          )}
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="action_item_statuses" active>
            <ProjectQaActionItemsStatusesIndex {...props} />
          </TabPane>
          {categoryRole && (
            <TabPane tabId="action_items_categories" active>
              <ProjectQaActionItemsCategoriesIndex {...props} />
            </TabPane>
          )}
        </TabContent>
      </div>
    );
  }
};

const SiteQASettings = (props) => {
  const { history } = props;
  const query = qs.parse(props.location.search);
  const { child_tab } = query;
  const [activeTab, setActiveTab] = useState(child_tab ? child_tab : "checklist_items");

  const settingsRole = HasRole({
    resource_type: "Projects::Qa::Site::ChecklistItem",
    name: ["read", "moderator"],
  });

  if (!settingsRole) {
    return <NotAuthorized />;
  } else {
    return (
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab == "checklist_items" ? "active" : ""}
              onClick={() => {
                setActiveTab("checklist_items");
                history.push("/qa/settings?tab=site_qa&child_tab=checklist_items");
              }}
            >
              Checklist Items
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="checklist_items" active>
            <ProjectsQaSiteChecklistItemIndex {...props} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
};
