import React, { Component } from "react";
import { connect } from "react-redux";

import ProjectQaSiteSurveillanceForm from "./form";
import { updateProjectQaSiteSurveillance, getProjectQaSiteSurveillance } from "../actions";

import { Page, Navbar } from "framework7-react";
import { Form } from "react-final-form";
import { getProject } from "modules/projects/actions";

// Helpers
import NotAuthorized from "modules/5_view_helpers/mobile/NotAuthorized";
import HasRole from "modules/5_view_helpers/HasRole";
import arrayMutators from "final-form-arrays";

import { hasNewOrUpdatedActionItems } from "modules/project/qa/action_items/helpers";

function mapStateToProps(state, ownProps) {
  const { id, project_id } = ownProps;

  return {
    project: state.projects[project_id],
    current_user: state.current_user,
    project_qa_site_surveillance: state.project_qa_site_surveillances[id],
  };
}

class EdiProjectQaSiteSurveillance extends Component {
  constructor(props) {
    super(props);

    this.submitForm = this.submitForm.bind(this);
    this.successfullySubmitted = this.successfullySubmitted.bind(this);

    const updateRole = HasRole(
      {
        resource_type: "ProjectQaSiteSurveillance",
        name: ["update", "moderator"],
      },
      true
    );
    const { id, project_id } = this.props;

    if (!updateRole) {
      this.props.getProject(project_id);
      this.props.getProjectQaSiteSurveillance(project_id, id);
    }

    const returnUrl = `/project/qa/site/surveillances`;

    this.state = {
      returnUrl: returnUrl,
      updateRole,
    };
  }

  submitForm(values) {
    const {
      f7router: { app },
      project_id,
      id,
      project_qa_site_surveillance,
    } = this.props;

    const originalActionItems = _.flatten(_.map(project_qa_site_surveillance.area_links_attributes, (al) => al?.action_items_attributes)); // get array of original action items
    const actionItems = _.flatten(_.map(values.area_links_attributes, (al) => al?.action_items_attributes)); // get array of action items

    if (hasNewOrUpdatedActionItems(originalActionItems, actionItems)) {
      app.dialog.confirm(
        "Send Email Notification to Owner/Person Responsible?",
        () => {
          // When clicked okay
          this.preloader = app.preloader;
          this.preloader.show();
          const updateRequest = this.props.updateProjectQaSiteSurveillance(project_id, id, values, true);
          updateRequest.then(this.successfullySubmitted);
        },
        () => {
          // When clicked cancel
          this.preloader = app.preloader;
          this.preloader.show();
          const updateRequest = this.props.updateProjectQaSiteSurveillance(project_id, id, values, false);
          updateRequest.then(this.successfullySubmitted);
        }
      );
    } else {
      this.preloader = app.preloader;
      this.preloader.show();
      const updateRequest = this.props.updateProjectQaSiteSurveillance(project_id, id, values, false);
      updateRequest.then(this.successfullySubmitted);
    }
  }

  successfullySubmitted() {
    const {
      f7router,
      f7router: { app, navigate },
    } = this.props;

    const { returnUrl } = this.state;

    this.preloader.hide();
    app.toast
      .create({
        icon: '<i class="material-icons">check</i>',
        text: "Updated Site QA!",
        position: "center",
        closeTimeout: 2000,
        on: {
          opened: () => {
            f7router.navigate(returnUrl, { reloadAll: true });
          },
        },
      })
      .open();
  }

  render() {
    const {
      project_qa_site_surveillance,
      history,
      project,
    } = this.props;

    const { returnUrl, updateRole } = this.state;

    if (!updateRole) {
      return <NotAuthorized />;
    }

    return (
      <Page>
        <Navbar backLink title="Update Surveillance Report" />
        {_.isNotEmpty(project_qa_site_surveillance) && (
          <Form
            component={ProjectQaSiteSurveillanceForm}
            mutators={{
              ...arrayMutators,
            }}
            project={project}
            onSubmit={this.submitForm}
            formLabel="Update"
            initialValues={project_qa_site_surveillance}
            cancelFunction={() => {
              history.push(returnUrl);
            }}
            {...this.props}
          />
        )}
      </Page>
    );
  }
}

export default connect(mapStateToProps, {
  getProject,
  getProjectQaSiteSurveillance,
  updateProjectQaSiteSurveillance,
})(EdiProjectQaSiteSurveillance);
