import { GET_PRODUCTION_STRUMIS_PRODUCTION_DELIVERY_NOTES } from "./actions";

export function ProductionStrumisProductionDeliveryNotesReducer(state = [], action) {
  switch (action.type) {
    case `${GET_PRODUCTION_STRUMIS_PRODUCTION_DELIVERY_NOTES}_FULFILLED`: {
      return action.payload.data
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { ProductionStrumisProductionDeliveryNotesReducer } from "modules/production/strumis/production/delivery_notes/reducer"

// production_strumis_production_delivery_notes:ProductionStrumisContractsReducer,
