import axios from "axios";

const prepareData = (data) => {return {paint_product:data}}

// Plural Actions
export const GET_PAINT_PRODUCTS = "get_paint_products";
export const getPaintProducts = () => {
	const url = `/paint/products.json`
	const request = axios.get(url);
	return {
		type: GET_PAINT_PRODUCTS,
		payload: request
	};
};


// Singular Actions
export const CREATE_PAINT_PRODUCT = "create_paint_product";
export const createPaintProduct = (data) => {
	const url = `/paint/products.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PAINT_PRODUCT,
		payload: request
	};
};

export const GET_PAINT_PRODUCT = "get_paint_product";
export const getPaintProduct = (id) => {
	const url = `/paint/products/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PAINT_PRODUCT,
		payload: request
	};
};

export const UPDATE_PAINT_PRODUCT = "update_paint_product";
export const updatePaintProduct = (id,data) => {
	const url = `/paint/products/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PAINT_PRODUCT,
		payload: request
	};
};

// export const DESTROY_PAINT_PRODUCT = "destroy_paint_product";
// export const destroyPaintProduct = (id) => {
// 	const url = `/paint/products/${id}.json`
// 	const request = axios({method: "delete",url: url});
// 	return {
// 		type: DESTROY_PAINT_PRODUCT,
// 		payload: request
// 	};
// };
