import axios from "axios";
import jsonFormData from "json-form-data";

const prepareData = (data) => {
  return jsonFormData({ project_communication: data }, { showLeafArrayIndexes: false });
};

// Plural Actions
export const GET_PROJECT_COMMUNICATIONS = "get_project_communications";
export const getProjectCommunications = (project_id, showComplete) => {
  let url = `/project/${project_id}/communications.json`;
  if (showComplete) {
    url += "?show_complete=true";
  }
  const request = axios.get(url);
  return {
    type: GET_PROJECT_COMMUNICATIONS,
    payload: request,
  };
};

// Singular Actions
export const CREATE_PROJECT_COMMUNICATION = "create_project_communication";
export const createProjectCommunication = (project_id, data) => {
  const url = `/project/${project_id}/communications.json`;
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_PROJECT_COMMUNICATION,
    payload: request,
  };
};

export const GET_PROJECT_COMMUNICATION = "get_project_communication";
export const getProjectCommunication = (project_id, id) => {
  const url = `/project/${project_id}/communications/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_COMMUNICATION,
    payload: request,
  };
};

export const UPDATE_PROJECT_COMMUNICATION = "update_project_communication";
export const updateProjectCommunication = (project_id, id, data) => {
  const url = `/project/${project_id}/communications/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_PROJECT_COMMUNICATION,
    payload: request,
  };
};

export const DESTROY_PROJECT_COMMUNICATION = "destroy_project_communication";
export const destroyProjectCommunication = (project_id, id) => {
  const url = `/project/${project_id}/communications/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_PROJECT_COMMUNICATION,
    payload: request,
  };
};

// Singular Actions
export const SEND_PROJECT_COMMUNICATION_OUTGOING = "send_project_communication_outgoing";
export const sendProjectCommunicationOutgoing = (project_id, communication_id) => {
  const url = `/project/${project_id}/communications/${communication_id}/send_outgoing.json`;
  const request = axios({ method: "post", url: url });
  return {
    type: SEND_PROJECT_COMMUNICATION_OUTGOING,
    payload: request,
  };
};

// Singular Actions
export const CHANGE_TO_SENT_PROJECT_COMMUNICATION_OUTGOING = "change_to_sent_project_communication_outgoing";
export const changeToSentProjectCommunicationOutgoing = (project_id, communication_id) => {
  const url = `/project/${project_id}/communications/${communication_id}/change_to_sent.json`;
  const request = axios({ method: "patch", url: url });
  return {
    type: CHANGE_TO_SENT_PROJECT_COMMUNICATION_OUTGOING,
    payload: request,
  };
};

// Singular Actions
export const SEARCH_PROJECT_COMMUNICATIONS = "search_project_communications";
export const searchProjectCommunications = (data) => {
  const url = `/project/communications/search.json`;
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: SEARCH_PROJECT_COMMUNICATION,
    payload: request,
  };
};
