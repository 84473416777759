import React, { Component, Fragment } from 'react'
import { Block, Chip, Popup, Page, Navbar, NavRight, Link, f7, List, Button } from "framework7-react";
import { v4 as uuidv4 } from "uuid";

export default class IsolatedLowReadingsPopup extends Component {
  constructor(props) {
    super(props)
    this.closeIsolatedLowProcess = this.closeIsolatedLowProcess.bind(this)
    this.deleteReading = this.deleteReading.bind(this)
  }

  closeIsolatedLowProcess(showDialog = true) {
    const { dialog } = f7;

    if (showDialog) {
      dialog.confirm("This will remove the low reading are you sure?", () => {
        // Remove the low reading
        this.props.deleteParentReading()
        this.props.closeIsolatedLowProcess()
      });
    }
    else {
      this.props.closeIsolatedLowProcess()
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.opened && this.props.opened) {
      // On open change the window.insertElcoReading to a function that will add the reading to this pi_readings_attributes
      // Add readings with elcometer
      window.insertElcoReading = (value) => {
        // Inserts into the parent reading
        const { useDFT, parentReadingName } = this.props
        const fieldNameToChange = `${parentReadingName}.pi_readings_attributes`
        const this_pi_readings_attributes = _.get(this.props.values, fieldNameToChange, [])

        let newReadings = _.clone(this_pi_readings_attributes)
        const filtered_readings = _.filter(newReadings, r => !r.user_deleted)

        if (filtered_readings?.length < 4) {
          let dft_input
          if (_.isString(value)) {
            // Split the string to get the first value and parse the int
            const splitValue = value.split(",")
            dft_input = parseInt(splitValue[0])
          }
          else {
            dft_input = value
          }

          const low_check = dft_input >= useDFT // Touch confusing but you dont use the 80% rule to determine if its low. In the isolated low process you use the reqd dft. But we keep the same variable name for the sake of consistency.
          const is_low = !low_check
          newReadings.push({ uuid: uuidv4(), dft: dft_input, is_low: is_low })
          const new_filtered_readings = _.filter(newReadings, r => !r.user_deleted)

          // If its the last reading in the sequence of 4 for an isolate low. Determine if it passes the isolate low test and change the parent to be isolated low.
          // Use the length of 3 as the filtered readings were created before the push
          if (new_filtered_readings.length == 4) {
            const all_above_reqd_dft = _.every(new_filtered_readings, r => r.is_low == false)
            this.props.form.change(`${parentReadingName}.isolated_low`, all_above_reqd_dft)
            this.props.form.change(`${parentReadingName}.isolated_low_checked`, true) // Set it failed isolated low test for the text
            // Also if there is one low which is determined as under reqd dft in this process then we set the parent to be low
            this.props.form.change(`${parentReadingName}.is_low`, !all_above_reqd_dft) // Set it to is low for the color and because it is low
          }

          // Insert the readings into the parent reading
          this.props.form.change(`${parentReadingName}.pi_readings_attributes`, newReadings)
          return is_low // App will vibrate a few times if this is true
        }
        else{
          return true
        }
      }
    }
  }

  /**
   * Function to mark a reading as deleted.
   * This function is designed to work in a React context, with lodash helping with object manipulations.
   * 
   * @param {string} uuid - Unique identifier of the reading to delete
   */
  deleteReading(uuid) {
    const { parentReadingName } = this.props
    const fieldNameToChange = `${parentReadingName}.pi_readings_attributes`
    const this_pi_readings_attributes = _.get(this.props.values, fieldNameToChange, [])
    const findIndexToDelete = _.findIndex(this_pi_readings_attributes, r => r.uuid == uuid)
    let newReadings = _.clone(this_pi_readings_attributes)
    newReadings[findIndexToDelete].user_deleted = true
    this.props.form.change(fieldNameToChange, newReadings)
  }

  render() {
    const { opened, selectedCoat, useDFT, parentReadingName } = this.props

    const minDFT = useDFT * (selectedCoat?.min_percentage / 100)

    const fieldNameToChange = `${parentReadingName}.pi_readings_attributes`

    
    const parentReading = _.get(this.props.values, parentReadingName, {})
    const this_pi_readings_attributes = _.get(this.props.values, fieldNameToChange, [])
    const filtered_readings = _.filter(this_pi_readings_attributes, r => !r.user_deleted)

    return (
      <Popup opened={opened}>
        <Navbar title="Isolated Low Process">
          <NavRight>
            <Link onClick={this.closeIsolatedLowProcess}>Cancel</Link>
          </NavRight>
        </Navbar>

        <Page>
          <Block>
            A low reading (<b>{parentReading?.dft}&micro;</b>) which is higher than the {selectedCoat?.min_percentage}% ({minDFT}&micro;) and below the reqd dft ({useDFT}&micro;) has been detected.
            <br />This allows an isolated low reading measurement.
            <br />Four spots now need measuring around the low point in a 100mm radius.
            This is done to determine if this is an Isolated low or if more paint is required.
            <br />
            <b>You can continue to use the elcometer to start reading...</b>
          </Block>
          <List>
            {!_.isEmpty(filtered_readings) != 0 && filtered_readings && (<Block>
              <b>Elco Readings - Coat - {selectedCoat.display_name} - {selectedCoat.dft}&micro;</b>
              <br />
              {_.map(filtered_readings, (reading, i) => {
                return (
                  <Fragment key={i}>
                    <Chip deleteable onDelete={() => { this.deleteReading(reading.uuid) }} color={!reading.is_low ? "green" : "red"}>
                      Reading {i + 1}/4 - {reading.dft}&micro;
                    </Chip>
                    <br />
                  </Fragment>
                )
              })}
            </Block>)}
          </List>
          <Block>
            {filtered_readings?.length == 4 && (
              <Button color="green" fill onClick={() => { this.closeIsolatedLowProcess(false) }}>Save</Button>
            )}
          </Block>

        </Page>
      </Popup>
    )
  }
}