import { GET_PRODUCTION_STRUMIS_CONTRACTS, GET_PRODUCTION_STRUMIS_CONTRACT } from "./actions";

export function ProductionStrumisContractsReducer(state = [], action) {
  switch (action.type) {
    case `${GET_PRODUCTION_STRUMIS_CONTRACTS}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    case `${GET_PRODUCTION_STRUMIS_CONTRACT}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { ProductionStrumisContractsReducer } from "modules/production/strumis/contracts/reducer"

// production_strumis_contracts:ProductionStrumisContractsReducer,
