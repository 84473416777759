import axios from "axios";

const prepareData = (data) => {return {project_qa_action_items_status:data}}

// Plural Actions
export const GET_PROJECT_QA_ACTION_ITEMS_STATUSES = "get_project_qa_action_items_statuses";
export const getProjectQaActionItemsStatuses = () => {
	const url = `/project/qa/action_items/statuses.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_ACTION_ITEMS_STATUSES,
		payload: request
	};
};


// Singular Actions
export const CREATE_PROJECT_QA_ACTION_ITEMS_STATUS = "create_project_qa_action_items_status";
export const createProjectQaActionItemsStatus = (data) => {
	const url = `/project/qa/action_items/statuses.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PROJECT_QA_ACTION_ITEMS_STATUS,
		payload: request
	};
};

export const GET_PROJECT_QA_ACTION_ITEMS_STATUS = "get_project_qa_action_items_status";
export const getProjectQaActionItemsStatus = (id) => {
	const url = `/project/qa/action_items/statuses/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_ACTION_ITEMS_STATUS,
		payload: request
	};
};

export const UPDATE_PROJECT_QA_ACTION_ITEMS_STATUS = "update_project_qa_action_items_status";
export const updateProjectQaActionItemsStatus = (id,data) => {
	const url = `/project/qa/action_items/statuses/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PROJECT_QA_ACTION_ITEMS_STATUS,
		payload: request
	};
};

export const DESTROY_PROJECT_QA_ACTION_ITEMS_STATUS = "destroy_project_qa_action_items_status";
export const destroyProjectQaActionItemsStatus = (id) => {
	const url = `/project/qa/action_items/statuses/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PROJECT_QA_ACTION_ITEMS_STATUS,
		payload: request
	};
};
