import React, { Fragment, useState } from "react";

import { FieldArray } from "react-final-form-arrays";
import _ from "lodash";
import { Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { AccordionContent, Block, Button, List, ListItem } from "framework7-react";
import { SmartSelect, InputField, required, isNotEmpty } from "modules/4_final_form/mobile";

import { f7 } from "framework7-react";
import BarcodeReader from "react-barcode-reader";

const StoreBookoutItemForm = (props) => {
  const { handleSubmit, mw_products, mw_jobs, form } = props;

  return (
    <form>
      <FieldArray name="resources" component={Resources} validate={isNotEmpty} form={form} mw_products={mw_products} mw_jobs={mw_jobs} />

      <Block>
        <Button onClick={handleSubmit} fill color="green">
          Bookout Items
        </Button>
      </Block>
    </form>
  );
};

export default StoreBookoutItemForm;

const Resources = (props) => {
  const {
    fields,
    meta: { error, touched },
    form,
    mw_products,
    mw_jobs
  } = props;

  const [indexOpen, setIndexOpen] = useState(null);
  const lastItemIncomplete = _.isNotEmpty(fields.value) && !_.last(fields.value)?.mw_product_code;

  return (
    <Fragment>
      <List accordionList>
        <Field
          name="mw_job_id"
          component={SmartSelect}
          options={_.map(_.sortBy(mw_jobs, "code"), (mw_job) => {
            return { label: `${mw_job.code} - ${mw_job.description}`, value: mw_job.code };
          })}
          validate={required}
          virtualList
          label="Job"
          placeholder="Select Job"
          valueName="value"
          labelName="label"
          slot="list"
          searchbar
          searchbarPlaceholder="Search Job"
        />
        <hr slot="list" />
        {fields.map((field, i) => {
          const { qty, mw_resource_memo } = fields.value?.[i];


          return (
            <ListItem id={`a-${i}`} accordionItem title={mw_resource_memo ? `${qty ? qty : "0"}x ${mw_resource_memo}` : "Select Product"} key={i}
              onAccordionOpen={() => {
                setIndexOpen(i)
              }}
              onAccordionClose={() => {
                setIndexOpen(null)
              }}
            >
              <AccordionContent>
                <List key={i} className="margin" style={{ backgroundColor: "lightblue" }}>
                  {indexOpen == i && (
                    <Fragment>
                      <Field
                        component={SmartSelect}
                        options={_.map(_.sortBy(mw_products, "code"), (p) => {
                          return { label: `${p.code} - ${p.description}`, value: p.code };
                        })}
                        name={`${field}.mw_product_code`}
                        validate={required}
                        label="Product"
                        placeholder="Select Product"
                        virtualList
                        valueName="value"
                        labelName="label"
                        slot="list"
                        searchbar
                        searchbarPlaceholder="Search Product"
                      />
                      <OnChange name={`${field}.mw_product_code`}>
                        {(value) => {
                          const product = _.find(mw_products, { code: value });
                          if (product) {
                            form.change(`${field}.mw_resource_memo`, `${product.code} - ${product.description}`);
                          }
                        }}
                      </OnChange>
                      <Field
                        component={InputField}
                        name={`${field}.qty`}
                        type="number"
                        validate={required}
                        label="QTY"
                        placeholder="QTY of Product"
                        inlineLabel
                        slot="list"
                      />
                      <Button
                        fill
                        color="red"
                        size="sm"
                        onClick={() => {
                          fields.remove(i);
                        }}
                        slot="list"
                        className="margin"
                      >
                        Remove
                      </Button>
                    </Fragment>
                  )}
                </List>
              </AccordionContent>
            </ListItem>
          );
        })}
      </List>

      <Button
        onClick={() => {
          fields.push({ qty: 1 });
          if (fields.length > 0) {
            f7.accordion.close(".accordion-item");
          }
        }}
        color="blue"
        disabled={lastItemIncomplete}
      >
        Add Item
      </Button>
      {_.isString(error) && touched && <div className="text-danger">{error}</div>}
      <hr />

      <BarcodeReader
        onError={(error) => {
          // alert(error);
        }}
        onScan={(barCode) => {
          const searchForProduct = _.find(mw_products, (p) => p.code == barCode);
          if (searchForProduct) {
            const allFields = fields.value;

            const findIndex = _.findIndex(allFields, (f) => f.mw_product_code == barCode);

            if (findIndex != -1) {
              const currentValues = _.find(allFields, (f) => f.mw_product_code == barCode);
              let qty = _.get(currentValues, "qty");
              fields.update(findIndex, {
                mw_product_code: searchForProduct.code,
                mw_resource_memo: `${searchForProduct.code} - ${searchForProduct.description}`,
                qty: qty + 1,
              });
            } else {
              fields.push({
                mw_product_code: searchForProduct.code,
                mw_resource_memo: `${searchForProduct.code} - ${searchForProduct.description}`,
                qty: 1,
              });
            }
          }
        }}
      />
    </Fragment>
  );
};

