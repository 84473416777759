import axios from "axios";

const prepareData = (data) => {return {project_qa_welding_inspections_viewing_condition:data}}

// Plural Actions
export const GET_PROJECT_QA_WELDING_INSPECTIONS_VIEWING_CONDITIONS = "get_project_qa_welding_inspections_viewing_conditions";
export const getProjectQaWeldingInspectionsViewingConditions = () => {
	const url = `/project/qa/welding_inspections/viewing_conditions.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_WELDING_INSPECTIONS_VIEWING_CONDITIONS,
		payload: request
	};
};


// Singular Actions
export const CREATE_PROJECT_QA_WELDING_INSPECTIONS_VIEWING_CONDITION = "create_project_qa_welding_inspections_viewing_condition";
export const createProjectQaWeldingInspectionsViewingCondition = (data) => {
	const url = `/project/qa/welding_inspections/viewing_conditions.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PROJECT_QA_WELDING_INSPECTIONS_VIEWING_CONDITION,
		payload: request
	};
};

export const GET_PROJECT_QA_WELDING_INSPECTIONS_VIEWING_CONDITION = "get_project_qa_welding_inspections_viewing_condition";
export const getProjectQaWeldingInspectionsViewingCondition = (id) => {
	const url = `/project/qa/welding_inspections/viewing_conditions/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_WELDING_INSPECTIONS_VIEWING_CONDITION,
		payload: request
	};
};

export const UPDATE_PROJECT_QA_WELDING_INSPECTIONS_VIEWING_CONDITION = "update_project_qa_welding_inspections_viewing_condition";
export const updateProjectQaWeldingInspectionsViewingCondition = (id,data) => {
	const url = `/project/qa/welding_inspections/viewing_conditions/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PROJECT_QA_WELDING_INSPECTIONS_VIEWING_CONDITION,
		payload: request
	};
};

export const DESTROY_PROJECT_QA_WELDING_INSPECTIONS_VIEWING_CONDITION = "destroy_project_qa_welding_inspections_viewing_condition";
export const destroyProjectQaWeldingInspectionsViewingCondition = (id) => {
	const url = `/project/qa/welding_inspections/viewing_conditions/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PROJECT_QA_WELDING_INSPECTIONS_VIEWING_CONDITION,
		payload: request
	};
};
