import React from "react";
import { required, InputField, ToggleField } from "modules/4_final_form/web";

import { FormGroup, Label, Row, Col, Button } from "reactstrap";

import { Field } from "react-final-form";

export default class ProjectCommunicationsOutgoingTypeForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { handleSubmit, formLabel, cancelFunction } = this.props;

    return (
      <form>
        <FormGroup row>
          <Label md={2}>Name</Label>
          <Col md={4}>
            <Field component={InputField} name="name" type="string" validate={required} parse={(value) => value} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={2}>Abbreviation</Label>
          <Col md={4}>
            <Field component={InputField} name="abbreviation" validate={required} type="string" parse={(value) => value} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={2}>Questions</Label>
          <Col md={4}>
            <Field component={ToggleField} name="questions" type="boolean" />
          </Col>
        </FormGroup>

        <Row>
          <Col>
            <Button color="success" onClick={handleSubmit}>
              {formLabel} Outgoing types
            </Button>
          </Col>
          <Col className="text-right">
            <Button onClick={cancelFunction} className="float-right" color="danger">
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}
