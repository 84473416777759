import { 
	GET_USER_GROUPS, 
	GET_USER_GROUP,
	CREATE_USER_GROUP,
	UPDATE_USER_GROUP,
	DESTROY_USER_GROUP,
} from "./actions"

export function UserGroupsReducer(state = {}, action) {
	switch (action.type) {
		case `${GET_USER_GROUPS}_FULFILLED`:{
			return _.mapKeys(action.payload.data, "id");
		}
		case `${GET_USER_GROUP}_FULFILLED`:
		case `${CREATE_USER_GROUP}_FULFILLED`:
		case `${UPDATE_USER_GROUP}_FULFILLED`:{
			let newState = _.clone(state);
			const newItem = action.payload.data;
			const id = _.get(newItem,"id")
			newState[id] = newItem
			return newState;
		}		
		case `${DESTROY_USER_GROUP}_FULFILLED`:{
			let newState = _.clone(state);
			const deletedItem = action.payload.data;
			const id = _.get(deletedItem,"id")
			newState = _.filter(newState,i => i.id != id)
			return newState;
		}
		default:
			return state;
	}
}

// Move this to the reducer page
// import { UserGroupsReducer } from "modules/user_groups/reducer"

// user_groups:UserGroupsReducer,
