import React from "react";

import { Link } from "react-router-dom";

const columns = (updateRole = false) => {
  let returnArray = [
    {
      text: "Name",
      dataField: "name",
    },

    {
      text: "Questions",
      dataField: "questions",
	  formatter:(cell) => cell ? "Yes" : "No"
    },
  ];

  if (updateRole) {
    returnArray.push({
      text: "Edit",
      dataField: "edit",
      csvExport: false,
      formatter: (cell, row) => <Link to={`/project/communications/outgoing_types/${row.id}/edit`}>Edit</Link>,
    });
  }

  return returnArray;
};

export default columns;
