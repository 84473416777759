// Plural Actions
export const GET_MW_NAMES = "GET_MW_NAMES";
export const getMWNames = () => {
  const url = "/moneyworks/names.json";
  const request = axios.get(url);
  return {
    type: GET_MW_NAMES,
    payload: request,
  };
};
