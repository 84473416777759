import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { CKEditorField, InputField, ReactSelect, isNotEmpty, ReactDropzone, required } from "modules/4_final_form/web";

import { Button, Col, FormGroup, Label, Row, Tooltip } from "reactstrap";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import { createProjectCommunication, getProjectCommunications } from "../actions";

import { getUserManagements } from "modules/user_managements/actions";
import { getProjects } from "modules/projects/actions";
import { getProjectCommunicationsOutgoingTypes } from "modules/project/communications/outgoing_types/actions.js";
import { getAddressBookCompanyContacts } from "modules/address_book/company/contacts/actions";
import { getProjectCommunicationsStatuses } from "modules/project/communications/statuses/actions";
import { getProjectCommunicationsCustomReferences } from "modules/project/communications/custom_references/actions.js";

import QuestionsAttributes from "./form/QuestionsAttributes";

import ProjectCommunicationsStatusAddButton from "modules/project/communications/statuses/web/add_button.jsx";
import ProjectCommunicationsOutgoingTypeAddButton from "modules/project/communications/outgoing_types/web/add_button.jsx";

import ParentsAttributes from "./form/ParentsAttributes";

import AddressBookCompanyContactAddButton from "modules/address_book/company/contacts/web/add_button";
import ProjectCommunicationsCustomReferencesAddButton from "modules/project/communications/custom_references/web/add_button";

import ReactHtmlParser from "react-html-parser";

import "./form.css";
import _ from "lodash";

const DirectionTypes = [
  { value: "incoming", label: "Incoming" },
  { value: "outgoing", label: "Outgoing" },
];
function mapStateToProps(state, ownProps) {
  return {
    users: state.users,
    project_communications_outgoing_types: state.project_communications_outgoing_types,
    address_book_company_contacts: state.address_book_company_contacts,
    project_communications_statuses: state.project_communications_statuses,
    project_communications: _.filter(state.project_communications, pc => pc.project_id == ownProps.project_id),
    project_communications_custom_references: state.project_communications_custom_references,
    current_user: state.current_user,
    projects: state.projects,
  };
}

const ProjectCommunicationForm = (props) => {
  const { handleSubmit, formLabel, cancelFunction, values, project_id, outlookMode, projects } = props;
  const { direction, sent, project_id_selected } = values;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const showRestOfForm = (outlookMode && project_id_selected) || !outlookMode;

  useEffect(() => {
    props.getUserManagements();
    props.getProjects();
    props.getProjectCommunicationsOutgoingTypes();
    props.getAddressBookCompanyContacts();
    props.getProjectCommunicationsStatuses();
  }, []);

  const use_project_id = project_id ? project_id : project_id_selected; // To deal with outlook
  useEffect(() => {
    if (use_project_id) {
      props.getProjectCommunications(use_project_id);
      props.getProjectCommunicationsCustomReferences(use_project_id);
    }
  }, [use_project_id]);

  return (
    <Fragment>
      {outlookMode && (
        <Fragment>
          <FormGroup row>
            <Label sm={2}>Project</Label>
            <Col>
              <Field
                name="project_id_selected"
                component={ReactSelect}
                options={_.map(projects, (p) => {
                  return { label: p.display_name, value: p.id };
                })}
                validate={required}
                disabled={sent}
              />
            </Col>
          </FormGroup>
          <hr />
        </Fragment>
      )}

      {showRestOfForm && (
        <Fragment>
          <FormGroup row>
            <Label sm={2}>Direction</Label>
            <Col>
              <Field name="direction" component={ReactSelect} options={DirectionTypes} validate={required} disabled={sent} />
            </Col>
          </FormGroup>
          <hr />
          {direction == "incoming" && <IncomingForm {...props} />}
          {direction == "outgoing" && <OutgoingForm {...props} />}
          <Row>
            <Col>
              <Button color="success" onClick={handleSubmit} id="submitButton">
                {formLabel} Communication
              </Button>
              {sent && (
                <Tooltip placement="top" isOpen={tooltipOpen} target="submitButton" toggle={toggleTooltip}>
                  Cannot update a sent outgoing communication
                </Tooltip>
              )}
            </Col>
            <Col className="text-right">
              <Button onClick={cancelFunction} className="float-right" color="danger">
                Cancel
              </Button>
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

export default connect(mapStateToProps, {
  createProjectCommunication,
  getUserManagements,
  getProjects,
  getProjectCommunicationsOutgoingTypes,
  getAddressBookCompanyContacts,
  getProjectCommunicationsStatuses,
  getProjectCommunications,
  getProjectCommunicationsCustomReferences,
})(ProjectCommunicationForm);

const OutgoingForm = (props) => {
  const {
    project_communications_outgoing_types,
    address_book_company_contacts,
    project_communications_statuses,
    values,
    project_communications,
    project_communications_custom_references,
    project_id,
    current_user,
    outlookMode,
    projects,
    edit,
    form,
  } = props;
  const { outgoing_type_id, sent } = values;
  const project = projects?.[project_id];

  useEffect(() => {
    if (!edit) {
      // Add default to's
      if (_.isNotEmpty(project?.default_comms_tos_attributes)) {
        const newTos = _.map(project?.default_comms_tos_attributes, (item) => _.pick(item, ["label", "value", "contact_id"]));
        form.change("tos_attributes", newTos);
      }

      // Add default cc's
      if (_.isNotEmpty(project?.default_comms_ccs_attributes)) {
        const newCCs = _.map(project?.default_comms_ccs_attributes, (item) => _.pick(item, ["label", "value", "contact_id"]));
        form.change("ccs_attributes", newCCs);
      }
    }
  }, []);

  const selected_outgoing_type = React.useMemo(
    () => _.find(project_communications_outgoing_types, { id: outgoing_type_id }),
    [project_communications_outgoing_types, outgoing_type_id]
  );
  const outgoing_statuses = React.useMemo(
    () => _.filter(project_communications_statuses, { type_string: "outgoing" }),
    [project_communications_statuses]
  );
  const question_statuses = React.useMemo(
    () => _.filter(project_communications_statuses, { type_string: "question" }),
    [project_communications_statuses]
  );

  const default_outgoing_status = React.useMemo(() => {
    const find_default_outgoing_status = _.find(outgoing_statuses, { default: true });
    if (find_default_outgoing_status) {
      return {
        value: find_default_outgoing_status.id,
        label: find_default_outgoing_status.name,
        status_id: find_default_outgoing_status.id,
        user_id: current_user.id,
      };
    }
  }, [project_communications_statuses]);

  const default_question_status = React.useMemo(() => {
    const find_default_question_status = _.find(question_statuses, { default: true });
    if (find_default_question_status) {
      return {
        value: find_default_question_status.id,
        label: find_default_question_status.name,
        status_id: find_default_question_status.id,
        user_id: current_user.id,
      };
    }
  }, [project_communications_statuses]);

  const outgoing_custom_references = React.useMemo(() => {
    return _.filter(project_communications_custom_references, { type_string: "outgoing" });
  }, [project_communications_custom_references]);

  return (
    <Fragment>
      <FormGroup row>
        <Label sm={2}>
          Outgoing Type
          <ProjectCommunicationsOutgoingTypeAddButton />
        </Label>
        <Col>
          <Field
            name="outgoing_type_id"
            component={ReactSelect}
            options={_.map(project_communications_outgoing_types, (ogt) => {
              return { value: ogt.id, label: ogt.name };
            })}
            validate={required}
            disabled={sent}
          />
        </Col>
      </FormGroup>
      <hr />
      {values?.outgoing_type_id && (
        <Fragment>
          <FormGroup row>
            <Label sm={2}>
              To (Only one allowed)
              <AddressBookCompanyContactAddButton />
            </Label>
            <Col>
              <Field
                name="tos_attributes"
                component={ReactSelect}
                options={_.map(address_book_company_contacts, (contact) => {
                  return { value: contact.id, label: contact.display_name, contact_id: contact.id };
                })}
                sendDestroy
                multi
                disabled={sent}
                maxOptionsSelected={1}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>
              CC
              <AddressBookCompanyContactAddButton />
            </Label>
            <Col>
              <Field
                name="ccs_attributes"
                component={ReactSelect}
                options={_.map(address_book_company_contacts, (contact) => {
                  return { value: contact.id, label: contact.display_name, contact_id: contact.id };
                })}
                sendDestroy
                disabled={sent}
                multi
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>Subject</Label>
            <Col sm={10}>
              <Field name="subject" component={InputField} validate={required} disabled={sent} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>
              Status
              <ProjectCommunicationsStatusAddButton initialValues={{ type_string: "outgoing" }} />
            </Label>
            <Col sm={10}>
              <Field
                // Creating an array with one element which then creates a new status record
                // On edit we receive an array with one element which is the existing status record
                // Because that element has the id of the status record, it will not create a new record
                // But when you change the status, it will create a new record because it looses the id because it sets it based from the options
                // Cheeky...
                name="statuses_attributes[0]"
                saveObject // Need this other it will save just the value in an array, this will save the whole object with everything in the return
                component={ReactSelect}
                options={_.map(outgoing_statuses, (status) => {
                  return { value: status.id, label: status.name, status_id: status.id, user_id: current_user.id };
                })}
                defaultValue={default_outgoing_status}
                validate={required}
              />
            </Col>
          </FormGroup>
          <Label>
            Custom References
            <ProjectCommunicationsCustomReferencesAddButton project_id={project_id} initialValues={{ type_string: "outgoing" }} />
          </Label>
          <FormGroup row>
            <CustomReferencesFields custom_references={outgoing_custom_references} project_id={project_id} />
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>Message Body</Label>
            <Col sm={12}>
              {outlookMode && <div>{ReactHtmlParser(values?.body)}</div>}
              {!outlookMode && (
                <Field
                  name="body"
                  component={CKEditorField}
                  height={selected_outgoing_type?.questions ? "400px" : "400px"}
                  validate={required}
                  disabled={sent}
                />
              )}
            </Col>
          </FormGroup>
          <hr />
          {selected_outgoing_type?.questions && (
            <FormGroup row>
              <Col>
                <FieldArray
                  validate={isNotEmpty}
                  name="questions_attributes"
                  component={QuestionsAttributes}
                  numberColumnPrefix="Question"
                  current_user={current_user}
                  statuses={question_statuses}
                  default_question_status={default_question_status}
                  disabled={sent}
                />
              </Col>
            </FormGroup>
          )}
          <FieldArray
            name="parents_attributes"
            component={ParentsAttributes}
            project_communications={project_communications}
            project_communications_statuses={project_communications_statuses}
            project_communication_id={values?.id}
            disabled={sent}
          />
          <FormGroup row>
            <Label md={12}>Attachment/s</Label>
            <Col md={12}>
              <FieldArray component={ReactDropzone} name="attachments_attributes" disabled={sent || outlookMode} />
            </Col>
          </FormGroup>
        </Fragment>
      )}
    </Fragment>
  );
};

const IncomingForm = (props) => {
  const {
    project_communications_statuses,
    address_book_company_contacts,
    values,
    project_communications,
    project_communications_custom_references,
    project_id,
    current_user,
    outlookMode,
  } = props;

  const incoming_statuses = React.useMemo(
    () => _.filter(project_communications_statuses, { type_string: "incoming" }),
    [project_communications_statuses]
  );

  const default_incoming_status = React.useMemo(() => {
    const find_default_incoming_status = _.find(incoming_statuses, { default: true });
    if (find_default_incoming_status) {
      return {
        value: find_default_incoming_status.id,
        label: find_default_incoming_status.name,
        status_id: find_default_incoming_status.id,
        user_id: current_user.id,
      };
    }
  }, [project_communications_statuses]);

  const incoming_custom_references = _.filter(project_communications_custom_references, { type_string: "incoming" });

  return (
    <Fragment>
      <FormGroup row>
        <Label sm={2}>
          From
          <AddressBookCompanyContactAddButton />
        </Label>
        <Col>
          {!outlookMode && (
            <Field
              name="from_id"
              component={ReactSelect}
              options={_.map(address_book_company_contacts, (contact) => {
                return { value: contact.id, label: contact.display_name };
              })}
              validate={required}
            />
          )}
          {outlookMode && <div>{values?.from?.displayName}</div>}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Subject</Label>
        <Col sm={10}>
          <Field name="subject" component={InputField} validate={required} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>
          Status
          <ProjectCommunicationsStatusAddButton initialValues={{ type_string: "incoming" }} />
        </Label>
        <Col sm={10}>
          <Field
            // Creating an array with one element which then creates a new status record
            // On edit we receive an array with one element which is the existing status record
            // Because that element has the id of the status record, it will not create a new record
            // But when you change the status, it will create a new record because it looses the id because it sets it based from the options
            // Cheeky...
            name="statuses_attributes[0]"
            saveObject // Need this other it will save just the value in an array, this will save the whole object with everything in the return
            component={ReactSelect}
            options={_.map(incoming_statuses, (status) => {
              return { value: status.id, label: status.name, status_id: status.id, user_id: current_user.id };
            })}
            defaultValue={default_incoming_status}
            validate={required}
          />
        </Col>
      </FormGroup>

      <Label>
        Custom References
        <ProjectCommunicationsCustomReferencesAddButton project_id={project_id} initialValues={{ type_string: "incoming" }} />
      </Label>
      <FormGroup row>
        <CustomReferencesFields custom_references={incoming_custom_references} />
      </FormGroup>

      <FormGroup row>
        <Label sm={12}>Message Body</Label>
        <Col sm={12}>
          {outlookMode && <div>{ReactHtmlParser(values?.body)}</div>}
          {!outlookMode && <Field name="body" component={CKEditorField} height="400px" validate={required} />}
        </Col>
      </FormGroup>
      <FieldArray
        name="parents_attributes"
        component={ParentsAttributes}
        project_communications={project_communications}
        project_communications_statuses={project_communications_statuses}
        project_communication_id={values?.id}
      />
      <FormGroup row>
        <Label md={12}>Attachment/s</Label>
        <Col md={12}>
          <FieldArray component={ReactDropzone} name="attachments_attributes" disabled={outlookMode} />
        </Col>
      </FormGroup>
    </Fragment>
  );
};

const CustomReferencesFields = ({ custom_references }) => {
  return (
    <Fragment>
      {_.map(custom_references, (custom_reference, index) => {
        return (
          <Fragment key={index}>
            <Label md={2}>{custom_reference?.title}</Label>
            <Col md={2}>
              <Field component={InputField} name={`custom_references.c${custom_reference.id}`} />
            </Col>
          </Fragment>
        );
      })}
    </Fragment>
  );
};
