import React, { Component } from "react";
import { connect } from "react-redux";
import AddressBookCompanyForm from "./form";
import { createAddressBookCompany } from "../actions";
import { Form } from "react-final-form"
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
function mapStateToProps(state) {
return {};
}
class NewAddressBookCompanySliding extends Component {
constructor(props) {
super(props);
this.submitForm = this.submitForm.bind(this);
this.successfullySubmitted = this.successfullySubmitted.bind(this);
this.state = {
isOpen:this.props.isOpen
}
}
componentDidUpdate(prevProps, prevState) {
const prevIsOpen = prevState.isOpen
const { isOpen } = this.props
if(prevIsOpen != isOpen){
this.setState({isOpen:isOpen})
}
}
submitForm(values) {
const createRequest = this.props.createAddressBookCompany(values);
createRequest.then(this.successfullySubmitted)
}
successfullySubmitted() {
const { toggle } = this.props
toggle()
toastr.success("Created New Company")
}
render() {
const { isOpen } = this.state
const { toggle } = this.props
return (
<SlidingPane isOpen={isOpen} onRequestClose={toggle} title="New Company" width="50%">
				<Form
					component={AddressBookCompanyForm}
					formLabel="Create"
					onSubmit={this.submitForm} 
					cancelFunction={toggle}
					{...this.props}
				/>
</SlidingPane>
);
}
}
export default connect(mapStateToProps,{ createAddressBookCompany })(NewAddressBookCompanySliding);
