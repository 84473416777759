import React from "react";
import { connect } from "react-redux";
import { required, InputField, ReactSelect } from "modules/4_final_form/web";

import { FormGroup, Label, Row, Col, Button } from "reactstrap";

import { Field } from "react-final-form";
import _ from "lodash";

import AddressBookCompanyAddButton from "modules/address_book/companies/web/add_button.jsx";

import { getAddressBookCompanies } from "modules/address_book/companies/actions";

function mapStateToProps(state) {
  return {
    address_book_companies: state.address_book_companies,
  };
}
class AddressBookCompanyContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.props.getAddressBookCompanies();
  }

  render() {
    const { handleSubmit, formLabel, cancelFunction, address_book_companies } = this.props;

    return (
      <form>
        <FormGroup row>
          <Label md={2}>
            Company
            <AddressBookCompanyAddButton />
          </Label>
          <Col md={4}>
            <Field
              component={ReactSelect}
              name="company_id"
              options={_.map(address_book_companies, (adc) => {
                return { value: adc.id, label: adc.name };
              })}
              validate={required}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={2}>Name</Label>
          <Col md={4}>
            <Field component={InputField} name="name" type="string" parse={(value) => value} validate={required} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={2}>Email</Label>
          <Col md={4}>
            <Field component={InputField} name="email" type="string" parse={(value) => value} validate={required} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={2}>Phone Number</Label>
          <Col md={4}>
            <Field component={InputField} name="phone_number" type="string" parse={(value) => value} />
          </Col>
        </FormGroup>

        <Row>
          <Col>
            <Button color="success" onClick={handleSubmit}>
              {formLabel} Contacts
            </Button>
          </Col>
          <Col className="text-right">
            <Button onClick={cancelFunction} className="float-right" color="danger">
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}

export default connect(mapStateToProps, { getAddressBookCompanies })(AddressBookCompanyContactForm);
