import axios from "axios";

const prepareData = (data) => {return {paint_location:data}}

// Plural Actions
export const GET_PAINT_LOCATIONS = "get_paint_locations";
export const getPaintLocations = () => {
	const url = `/paint/locations.json`
	const request = axios.get(url);
	return {
		type: GET_PAINT_LOCATIONS,
		payload: request
	};
};


// Singular Actions
export const CREATE_PAINT_LOCATION = "create_paint_location";
export const createPaintLocation = (data) => {
	const url = `/paint/locations.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PAINT_LOCATION,
		payload: request
	};
};

export const GET_PAINT_LOCATION = "get_paint_location";
export const getPaintLocation = (id) => {
	const url = `/paint/locations/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PAINT_LOCATION,
		payload: request
	};
};

export const UPDATE_PAINT_LOCATION = "update_paint_location";
export const updatePaintLocation = (id,data) => {
	const url = `/paint/locations/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PAINT_LOCATION,
		payload: request
	};
};

export const DESTROY_PAINT_LOCATION = "destroy_paint_location";
export const destroyPaintLocation = (id) => {
	const url = `/paint/locations/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PAINT_LOCATION,
		payload: request
	};
};
