import React, { Fragment, useState } from "react";
import { connect } from "react-redux";

import ChipDisplay from "modules/5_view_helpers/web/ArrayFilter/ChipDisplay";
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import ArrayFilter from "./ArrayFilter";

import { toggleRightSidebarOpen } from "modules/3_reducers/web/ThemeOptions";

import { clearFilterValues } from "./ArrayFilter/actions";

// Bootstrap table related
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
const { ExportCSVButton } = CSVExport;
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./DefaultDataGrid.scss";

const mapStateToProps = (state) => ({
  rightSidebarOpen: state.ThemeOptions.rightSidebarOpen,
});

class DefaultDataGrid extends React.Component {
  constructor(props) {
    super(props);

    this.toggleFilterSidebar = this.toggleFilterSidebar.bind(this);

    this.tableRef = React.createRef();

    const { data } = this.props;

    this.state = {
      filterValues: {},
      filteredArray: data ? data : [],
    };
  }

  componentWillUnmount() {
    this.props.clearFilterValues();
  }

  componentDidUpdate(prevProps, prevState) {
    const { data } = this.props;

    // if there is a delay in getting the original data then set it when it comes in to the state
    if (data != prevProps.data) {
      this.setState({ filteredArray: data });
    }
  }

  toggleFilterSidebar() {
    let { rightSidebarOpen, toggleRightSidebarOpen } = this.props;
    toggleRightSidebarOpen(!rightSidebarOpen);
  }

  render() {
    const { columns, data, keyfield, filter, exportButton, expandRow, rowStyle, showCount, selectable, onSelect, disableTopRow, ...rest } =
      this.props;

    const { filteredArray } = this.state;

    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      hideSelectAll: true,
      bgColor: "#c7ffc2",
      onSelect: () => {
        this.forceUpdate(() => {
          const selectedIDs = this.tableRef.current?.selectionContext?.selected;
          const selectedRows = _.filter(data, (r) => _.includes(selectedIDs, r[keyfield]));
          if (onSelect) {
            onSelect(selectedRows);
          }
        });
      },
    };

    const columnsWithFilters = _.filter(columns, (c) => c.dataFilter);

    return (
      <ToolkitProvider
        keyField={keyfield}
        data={_.toArray(filteredArray)}
        columns={columns}
        exportCSV={{ onlyExportFiltered: true, exportAll: false }}
      >
        {(props) => (
          <div>
            {_.some(columns, "dataFilter") && (
              <ArrayFilter
                columns={columnsWithFilters}
                inputArray={data}
                outputArrayFunction={(filteredArray) => {
                  this.setState({ filteredArray });
                }}
              />
            )}
            {!disableTopRow && (
              <Row>
                <Col md={9} className="text-left" style={{ display: "block" }}>
                  <ChipDisplay />
                </Col>
                <Col md={3} className="text-right">
                  {showCount && (
                    <div
                      style={{
                        fontSize: "15px",
                        display: "inline",
                      }}
                    >
                      Count: <b>{_.size(filteredArray)}</b>
                    </div>
                  )}
                  {_.isNotEmpty(columnsWithFilters) && (
                    <Button color="primary" size="sm" onClick={this.toggleFilterSidebar} className="ml-3">
                      Filter <FontAwesomeIcon icon={faFilter} type="button" />
                    </Button>
                  )}
                  {exportButton && <ExportComponent {...props} {...this.props} />}
                </Col>
              </Row>
            )}

            <div className="mt-2">
              {selectable ? (
                <BootstrapTable
                  classes="table-sm table-hover table-header-text-top"
                  filter={filter}
                  {...props.baseProps}
                  expandRow={expandRow}
                  rowStyle={rowStyle}
                  selectRow={selectRow}
                  ref={this.tableRef}
                />
              ) : (
                <Fragment>
                  <BootstrapTable
                    classes="table-sm table-hover table-header-text-top"
                    filter={filter}
                    {...props.baseProps}
                    expandRow={expandRow}
                    rowStyle={rowStyle}
                  />
                </Fragment>
              )}
            </div>
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

DefaultDataGrid.defaultProps = {
  keyfield: "id",
  exportButton: true,
  showCount: true,
  disableTopRow: false,
};

export default connect(mapStateToProps, { toggleRightSidebarOpen, clearFilterValues })(DefaultDataGrid);

const ExportComponent = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { exportModal, exportPDF } = props;

  if (!exportModal) {
    return (
      <ExportCSVButton className="btn btn-primary btn-sm d-print-none ml-2" {...props.csvProps}>
        Export
      </ExportCSVButton>
    );
  } else {
    const exportPDFLink = _.isString(exportPDF) ? exportPDF : `${window.location.pathname}.pdf`;
    return (
      <Fragment>
        <Button
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          color="primary"
          size="sm"
          className="d-print-none ml-2"
        >
          Export
        </Button>

        <Modal
          isOpen={isOpen}
          toggle={() => {
            setIsOpen(!isOpen);
          }}
        >
          <ModalHeader>Export</ModalHeader>
          <ModalBody>
            {exportPDF && (
              <Button href={exportPDFLink} target="_blank" color="primary" size="sm" className="d-print-none ml-2">
                To PDF
              </Button>
            )}
            <ExportCSVButton className="btn btn-primary btn-sm d-print-none ml-2" {...props.csvProps}>
              To Excel
            </ExportCSVButton>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
};
