import React from 'react';

const ProjectCommunicationsCustomReferencesIndex = React.lazy(() => import("./index"))
const NewProjectCommunicationsCustomReferences = React.lazy(() => import("./new"))
const EditProjectCommunicationsCustomReferences = React.lazy(() => import("./edit"))

export default[
	{path:'/project/:project_id/communications/custom_references/:id/edit',component: EditProjectCommunicationsCustomReferences,name:'Edit ProjectCommunicationsCustomReference'},
	{path:'/project/:project_id/communications/custom_references/new',component: NewProjectCommunicationsCustomReferences,name:'New ProjectCommunicationsCustomReference'},  
]

// import ProjectCommunicationsCustomReferencesRoutes from "modules/project/communications/custom_references/web/routes"
// routes = _.concat(routes,ProjectCommunicationsCustomReferencesRoutes)
