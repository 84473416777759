import { 
	GET_DRAWING_OFFICE_DRAWING_TYPES, 
	GET_DRAWING_OFFICE_DRAWING_TYPE,
	CREATE_DRAWING_OFFICE_DRAWING_TYPE,
	UPDATE_DRAWING_OFFICE_DRAWING_TYPE,
	DESTROY_DRAWING_OFFICE_DRAWING_TYPE,
} from "./actions"

export function DrawingOfficeDrawingTypesReducer(state = {}, action) {
	switch (action.type) {
		case `${GET_DRAWING_OFFICE_DRAWING_TYPES}_FULFILLED`:{
			return _.mapKeys(action.payload.data, "id");
		}
		case `${GET_DRAWING_OFFICE_DRAWING_TYPE}_FULFILLED`:
		case `${CREATE_DRAWING_OFFICE_DRAWING_TYPE}_FULFILLED`:
		case `${UPDATE_DRAWING_OFFICE_DRAWING_TYPE}_FULFILLED`:{
			let newState = _.clone(state);
			const newItem = action.payload.data;
			const id = _.get(newItem,"id")
			newState[id] = newItem
			return newState;
		}		
		case `${DESTROY_DRAWING_OFFICE_DRAWING_TYPE}_FULFILLED`:{
			let newState = _.clone(state);
			const deletedItem = action.payload.data;
			const id = _.get(deletedItem,"id")
			newState = _.filter(newState,i => i.id != id)
			return newState;
		}
		default:
			return state;
	}
}

// Move this to the reducer page
// import { DrawingOfficeDrawingTypesReducer } from "modules/drawing_office/drawing_types/reducer"

// drawing_office_drawing_types:DrawingOfficeDrawingTypesReducer,
