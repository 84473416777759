import { 
	GET_PAINT_PRODUCT_CATEGORIES, 
	GET_PAINT_PRODUCT_CATEGORY,
	CREATE_PAINT_PRODUCT_CATEGORY,
	UPDATE_PAINT_PRODUCT_CATEGORY,
	DESTROY_PAINT_PRODUCT_CATEGORY,
} from "./actions"

export function PaintProductCategoriesReducer(state = {}, action) {
	switch (action.type) {
		case `${GET_PAINT_PRODUCT_CATEGORIES}_FULFILLED`:{
			return _.mapKeys(action.payload.data, "id");
		}
		case `${GET_PAINT_PRODUCT_CATEGORY}_FULFILLED`:
		case `${CREATE_PAINT_PRODUCT_CATEGORY}_FULFILLED`:
		case `${UPDATE_PAINT_PRODUCT_CATEGORY}_FULFILLED`:{
			let newState = _.clone(state);
			const newItem = action.payload.data;
			const id = _.get(newItem,"id")
			newState[id] = newItem
			return newState;
		}		
		case `${DESTROY_PAINT_PRODUCT_CATEGORY}_FULFILLED`:{
			let newState = _.clone(state);
			const deletedItem = action.payload.data;
			const id = _.get(deletedItem,"id")
			newState = _.filter(newState,i => i.id != id)
			return newState;
		}
		default:
			return state;
	}
}

// Move this to the reducer page
// import { PaintProductCategoriesReducer } from "modules/paint/product_categories/reducer"

// paint_product_categories:PaintProductCategoriesReducer,
