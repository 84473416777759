import React, { Fragment, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

// Helpers
import HasRole from "modules/5_view_helpers/HasRole";
import NewSliding from "./new_sliding";

const AddressBookCompanyContactAddButton = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const createRole = HasRole({ resource_type: "AddressBook::Company::Contact", name: ["create", "moderator"] });

  // Hide button if the user does not have the role
  if (!createRole) {
    return null;
  }
  return (
    <Fragment>
      <FontAwesomeIcon
        className="ml-2"
        icon={faPlus}
        style={{ cursor: "pointer" }}
        onClick={() => {
          setModalOpen(!modalOpen);
        }}
      />

      {modalOpen && (
        <NewSliding
          isOpen={modalOpen}
          toggle={() => {
            setModalOpen(!modalOpen);
          }}
        />
      )}
    </Fragment>
  );
};

export default AddressBookCompanyContactAddButton;
