import axios from "axios";

// Plural Actions
export const GET_PRODUCTION_STRUMIS_CONTRACTS = "GET_PRODUCTION_STRUMIS_CONTRACTS";
export const getProductionStrumisContracts = () => {
  const url = "/production/strumis/contracts.json";
  const request = axios.get(url);
  return {
    type: GET_PRODUCTION_STRUMIS_CONTRACTS,
    payload: request,
  };
};

export const GET_PRODUCTION_STRUMIS_CONTRACT = "GET_PRODUCTION_STRUMIS_CONTRACT";
export const getProductionStrumisContract = (strumis_contract_id,getStrumisData = false, groupBy) => {
  let url = `/production/strumis/contracts/${strumis_contract_id}.json`;

  if(getStrumisData && groupBy) {
    url += `?getStrumisData&groupBy=${groupBy}`;
  }
  const request = axios.get(url);
  return {
    type: GET_PRODUCTION_STRUMIS_CONTRACT,
    payload: request,
  };
};
