import axios from "axios";

const prepareData = (data) => {return {project_communications_outgoing_type:data}}

// Plural Actions
export const GET_PROJECT_COMMUNICATIONS_OUTGOING_TYPES = "get_project_communications_outgoing_types";
export const getProjectCommunicationsOutgoingTypes = () => {
	const url = `/project/communications/outgoing_types.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_COMMUNICATIONS_OUTGOING_TYPES,
		payload: request
	};
};


// Singular Actions
export const CREATE_PROJECT_COMMUNICATIONS_OUTGOING_TYPE = "create_project_communications_outgoing_type";
export const createProjectCommunicationsOutgoingType = (data) => {
	const url = `/project/communications/outgoing_types.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PROJECT_COMMUNICATIONS_OUTGOING_TYPE,
		payload: request
	};
};

export const GET_PROJECT_COMMUNICATIONS_OUTGOING_TYPE = "get_project_communications_outgoing_type";
export const getProjectCommunicationsOutgoingType = (id) => {
	const url = `/project/communications/outgoing_types/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_COMMUNICATIONS_OUTGOING_TYPE,
		payload: request
	};
};

export const UPDATE_PROJECT_COMMUNICATIONS_OUTGOING_TYPE = "update_project_communications_outgoing_type";
export const updateProjectCommunicationsOutgoingType = (id,data) => {
	const url = `/project/communications/outgoing_types/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PROJECT_COMMUNICATIONS_OUTGOING_TYPE,
		payload: request
	};
};

export const DESTROY_PROJECT_COMMUNICATIONS_OUTGOING_TYPE = "destroy_project_communications_outgoing_type";
export const destroyProjectCommunicationsOutgoingType = (id) => {
	const url = `/project/communications/outgoing_types/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PROJECT_COMMUNICATIONS_OUTGOING_TYPE,
		payload: request
	};
};
