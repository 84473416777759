import axios from "axios";

const prepareData = (data) => {return {store_bookout_item:data}}

// Plural Actions
export const GET_STORE_BOOKOUT_ITEMS = "get_store_bookout_items";
export const getStoreBookoutItems = () => {
	const url = `/store/bookout_items.json`
	const request = axios.get(url);
	return {
		type: GET_STORE_BOOKOUT_ITEMS,
		payload: request
	};
};


// Singular Actions
export const CREATE_STORE_BOOKOUT_ITEM = "create_store_bookout_item";
export const createStoreBookoutItem = (data) => {
	const url = `/store/bookout_items.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_STORE_BOOKOUT_ITEM,
		payload: request
	};
};
