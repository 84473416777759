import axios from "axios";

const prepareData = (data) => {
  return { training_nzqa_number: data };
};

// Plural Actions
export const GET_TRAINING_NZQA_NUMBERS = "get_training_nzqa_numbers";
export const getTrainingNzqaNumbers = () => {
  const url = `/training/nzqa_numbers.json`;
  const request = axios.get(url);
  return {
    type: GET_TRAINING_NZQA_NUMBERS,
    payload: request,
  };
};

// Singular Actions
export const CREATE_TRAINING_NZQA_NUMBER = "create_training_nzqa_number";
export const createTrainingNzqaNumber = (data) => {
  const url = `/training/nzqa_numbers.json`;
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_TRAINING_NZQA_NUMBER,
    payload: request,
  };
};

export const GET_TRAINING_NZQA_NUMBER = "get_training_nzqa_number";
export const getTrainingNzqaNumber = (id) => {
  const url = `/training/nzqa_numbers/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_TRAINING_NZQA_NUMBER,
    payload: request,
  };
};

export const UPDATE_TRAINING_NZQA_NUMBER = "update_training_nzqa_number";
export const updateTrainingNzqaNumber = (id, data) => {
  const url = `/training/nzqa_numbers/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_TRAINING_NZQA_NUMBER,
    payload: request,
  };
};

export const DESTROY_TRAINING_NZQA_NUMBER = "destroy_training_nzqa_number";
export const destroyTrainingNzqaNumber = (id) => {
  const url = `/training/nzqa_numbers/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_TRAINING_NZQA_NUMBER,
    payload: request,
  };
};
