import React, { Fragment } from "react";
import { Field, useFormState, useForm } from "react-final-form";
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Col, Row, UncontrolledTooltip } from "reactstrap";

import { required, InputField, ReactSelect, ToggleField, CreatableMultiselect } from "modules/4_final_form/web";
import _ from "lodash";
import { getColsAttributes } from "./shared";


const ColModal = (props) => {
  const {
    isOpen,
    toggle,
    fieldOpen: { fieldName, fieldIndex },
    fields: { value },
    column_types,
    fields,
  } = props;

  const allOtherFields = _.filter(fields.value, (f, i) => i != fieldIndex);
  const desktopWidthTotal = _.sumBy(allOtherFields, (f) => parseInt(f.desktop_width));
  const mobileWidthTotal = _.sumBy(allOtherFields, (f) => parseInt(f.mobile_width));
  // This aviods it updating
  const maxDesktopWidth = 12 - desktopWidthTotal;
  // const maxMobileWidth = 12 - mobileWidthTotal;

  const colValues = value?.[fieldIndex];
  const selectedColumnType = colValues?.column_type;
  const SelectedColumnTypeAttributes = column_types.find((c) => c.value === selectedColumnType)?.attributesComponent;

  const form = useForm();
  const formState = useFormState();
  const errors = formState.errors;
  const thisColErrors = _.get(errors, fieldName);
  const isDisabled = _.isNotEmpty(thisColErrors);

  const colsAttributes = getColsAttributes(formState.values);
  const filteredColsAttributes = _.filter(colsAttributes, (c) => c?.uid != colValues?.uid && !c?._destroy);
  const namesUsed = _.map(filteredColsAttributes, "name");

  const AllowedConditonalFields = _.filter(filteredColsAttributes, (c) => c.column_type != "text" && c.column_type != "attachments");

  const ConditionalField = _.get(colValues, "conditional_field");
  const ConditionalFieldObject = _.find(filteredColsAttributes, { name: ConditionalField });
  const ConditionalFieldSingleInput = ConditionalFieldObject?.column_type == "toggle" || ConditionalFieldObject?.column_type == "yesnona";

  let conditions = [
    { label: "Present", value: "present" }
  ]
  if (ConditionalFieldObject?.column_type == "toggle") {
    conditions = [
      { label: "True", value: "true" },
      { label: "False", value: "false" }
    ];
  }
  if (ConditionalFieldObject?.column_type == "yesnona") {
    conditions = [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
      { label: "N/A", value: "N/A" },
    ];
  }




  return (
    <div>
      <Modal isOpen={isOpen} toggle={isDisabled ? null : toggle} size="lg">
        <ModalHeader toggle={isDisabled ? null : toggle}>Add Col</ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label md={3}>Desktop Width</Label>
            <Col md={3}>
              <Field
                name={`${fieldName}.desktop_width`}
                component={InputField}
                validate={(value) => {
                  if (!value) return "is required";
                  if (value > maxDesktopWidth) return `must be less than ${maxDesktopWidth}`;
                }}
                type="number"
                max={maxDesktopWidth}
                min="1"
                defaultValue="6"
              />
            </Col>
          </FormGroup>
          <hr />
          <FormGroup row>
            <Label md={3}>Type</Label>
            <Col md={8}>
              <Field name={`${fieldName}.column_type`} component={ReactSelect} options={column_types} validate={required} />
            </Col>
          </FormGroup>
          <hr />

          {/* Render the form component for the selected column type */}
          {SelectedColumnTypeAttributes && <SelectedColumnTypeAttributes fieldName={fieldName} namesUsed={namesUsed} />}


          {selectedColumnType && (
            <Fragment>
              <hr />
              <FormGroup row>
                <Label md={3}>Conditionally Visible</Label>
                <Col md={3}>
                  <Field
                    name={`${fieldName}.conditionally_visible`}
                    component={ToggleField}
                  />
                </Col>
              </FormGroup>

              {colValues.conditionally_visible && (
                <Fragment>
                  <FormGroup row>
                    <Label md={3}>Condition Field</Label>
                    <Col md={8}>
                      <Field
                        name={`${fieldName}.conditional_field`}
                        component={ReactSelect}
                        options={_.map(AllowedConditonalFields, ca => {
                          return { label: ca.label, value: ca.name };
                        })}
                        validate={required}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3}>Condition Value</Label>
                    <Col md={8}>
                      <Field name={`${fieldName}.conditional_value`} component={ConditionalFieldSingleInput ? ReactSelect : CreatableMultiselect} options={conditions} validate={required} />
                    </Col>
                  </FormGroup>
                </Fragment>
              )}
            </Fragment>
          )}

          < hr />
          <Row>
            <Col>
              <Button
                color="success"
                id="successButton"
                onClick={() => {
                  isDisabled ? form.submit() : toggle();
                }}
              // disabled={isDisabled}
              >
                Save
              </Button>
              {isDisabled && (
                <UncontrolledTooltip placement="bottom" target="successButton">
                  Please fix errors before saving
                </UncontrolledTooltip>
              )}
            </Col>
            <Col className="text-right">
              <Button
                color="danger"
                onClick={() => {
                  if (colValues.id) {
                    let newRemovedFields = _.cloneDeep(formState.values.removed_fields) || [];
                    newRemovedFields.push(colValues.name);
                    newRemovedFields = _.uniq(newRemovedFields);
                    form.change("removed_fields", newRemovedFields);
                    props.fields.update(fieldIndex, { id: colValues.id, _destroy: true });
                  } else {
                    props.fields.remove(fieldIndex);
                  }
                  toggle();
                }}
              >
                Remove
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ColModal;
