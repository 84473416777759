import axios from "axios";

const prepareData = (data) => {
  return { internal_company: data };
};

// Plural Actions
export const GET_INTERNAL_COMPANIES = "get_internal_companies";
export const getInternalCompanies = () => {
  const url = "/internal_companies.json";
  const request = axios.get(url);
  return {
    type: GET_INTERNAL_COMPANIES,
    payload: request,
  };
};

// Singular Actions
export const CREATE_INTERNAL_COMPANY = "create_internal_company";
export const createInternalCompany = (data) => {
  const url = "/internal_companies.json";
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_INTERNAL_COMPANY,
    payload: request,
  };
};

export const GET_INTERNAL_COMPANY = "get_internal_company";
export const getInternalCompany = (id) => {
  const url = `/internal_companies/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_INTERNAL_COMPANY,
    payload: request,
  };
};

export const UPDATE_INTERNAL_COMPANY = "update_internal_company";
export const updateInternalCompany = (id, data) => {
  const url = `/internal_companies/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_INTERNAL_COMPANY,
    payload: request,
  };
};

export const DESTROY_INTERNAL_COMPANY = "destroy_internal_company";
export const destroyInternalCompany = (id) => {
  const url = `/internal_companies/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_INTERNAL_COMPANY,
    payload: request,
    meta: { id: id },
  };
};
