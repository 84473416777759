import React from 'react'
import { Navbar,NavLeft,NavTitle,Link } from "framework7-react"

const AppNavbar = (props) => {
  return (
      <Navbar>
        <NavLeft>
          <Link panelOpen="left" iconF7="bars" />
        </NavLeft>
        <NavTitle>{props.title}</NavTitle>
      </Navbar>
  );
};

export default AppNavbar;
