import localforage from "localforage-esm";
import { debounce } from "lodash";

const forageForm = localforage.createInstance({
  name: "LionV3",
  storeName: "FormData",
});

const persistDecorator = (options) => (form) => {
  const { name, debounceTime = 0, whitelist = [], blacklist = [] } = options;

  forageForm.getItem(name).then((persistedValues) => {
    const { initialValues } = form.getState();
    form.initialize({ ...persistedValues });
  });

  const unsubscribe = form.subscribe(
    debounce(
      ({ values, pristine }) => {
        let valuesKeys = Object.keys(values);
        if (whitelist.length > 0 && !blacklist.length) {
          valuesKeys = Object.keys(values).filter((value) => whitelist.includes(value));
        }
        if (blacklist.length > 0) {
          valuesKeys = Object.keys(values).filter((value) => !blacklist.includes(value));
        }
        const valuesObject = valuesKeys.reduce((acc, key) => {
          const value = values[key];
          return {
            ...acc,
            [key]: value,
          };
        }, {});

        if (!pristine) {
          forageForm.setItem(name, valuesObject);
        }
      },
      debounceTime,
      { leading: true, trailing: true }
    ),
    { values: true, pristine: true }
  );

  return unsubscribe;
};

export const createPersistDecorator = (options) => {
  const { name } = options;
  if (!name) {
    throw new Error('createPersistDecorator expects a "name" option');
  }

  return {
    persistDecorator: persistDecorator(options),
  };
};

export const clearPersisted = (name) => {
  return forageForm.removeItem(name);
};

export const isPersisted = (name) => {
  return forageForm.getItem(name);
};

export const getPersisted = isPersisted; // Just an alias
