export const Base64toFileObject = (base64String, filename, contentType) => {
  const byteString = atob(base64String);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }
  return new File([intArray], filename, { type: contentType });
};

export const MakeEMLFileObject = (mimeString, filename) => {
  const arrayBuffer = new ArrayBuffer(mimeString.length);
  const intArray = new Uint8Array(arrayBuffer);
  for (let i = 0; i < mimeString.length; i++) {
    intArray[i] = mimeString.charCodeAt(i);
  }
  const file = new File([intArray], filename, { type: "message/rfc822" });
  return file;
};
