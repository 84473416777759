import React, { Fragment, useEffect } from "react";
import { InputField, ToggleField, required } from "modules/4_final_form/mobile";

import { Field } from "react-final-form";

import { Block, List } from "framework7-react";

import { connect } from "react-redux";

import Tooltip from "modules/5_view_helpers/web/Tooltip";

import ElcometerReader from "./ElcometerReader/ElcometerReader";

function mapStateToProps(state, ownProps) {
  const {
    values: {
      assembly: { guid },
    },
  } = ownProps;
  const assembly_paint_inspections = _.filter(state.project_qa_paint_inspections, (pi) => pi.assembly?.guid == guid);
  return {
    assembly_paint_inspections: assembly_paint_inspections,
    pending: state.pending,
  };
}

const NormalReadings = (props) => {
  const {
    values: { average_dft, low_dft, paint_system_id, coat_id, isolated_low, id, use_dft_from_previous, complies,pi_readings_attributes },
    form: { change },
    assembly_paint_inspections,
    project_technical_details_paint_systems,
    pending,
    selectedPaintSystem,
    selectedCoat,
    parentSelectedPaintSystem,
  } = props;


  const filtered_pi_readings = _.filter(pi_readings_attributes,pi => !pi.user_deleted)
  const readOnlyReadings = _.isNotEmpty(filtered_pi_readings)


  // Filter paint systems to the project selected.
  const previousCoatNumber = selectedCoat?.number - 1;
  let findCompliantLastCoat;
  if (parentSelectedPaintSystem?.is_multi_system) {
    findCompliantLastCoat = _.findLast(
      assembly_paint_inspections,
      (pi) =>
        pi.complies &&
        pi.coat_number == previousCoatNumber &&
        pi.id != id &&
        pi.paint_system_id == parentSelectedPaintSystem.id &&
        pi.child_paint_system_id == selectedPaintSystem.id
    );
  } else {
    findCompliantLastCoat = _.findLast(
      assembly_paint_inspections,
      (pi) => pi.complies && pi.coat_number == previousCoatNumber && pi.id != id && pi.paint_system_id == selectedPaintSystem.id
    );
  }

  const requiredDFT = selectedCoat?.dft;

  const notFirstCoat = selectedCoat?.number > 1;

  const baseDFT = findCompliantLastCoat && use_dft_from_previous ? findCompliantLastCoat?.average_dft : selectedCoat?.sum_of_dfts;

  const useDFT = use_dft_from_previous && baseDFT && notFirstCoat ? baseDFT + requiredDFT : requiredDFT;
  const average_dft_check = average_dft >= useDFT;
  const low_dft_check = low_dft >= useDFT * (selectedCoat?.min_percentage / 100);

  // To check low is above the lowest and below the average
  const low_above_lowest_allowed = low_dft >= useDFT * (selectedCoat?.min_percentage / 100);
  const low_below_average = low_dft < useDFT;
  const low_between_low_and_avg = low_above_lowest_allowed && low_below_average;

  // Checks if it should be compliant or not
  useEffect(() => {
    if (paint_system_id && coat_id && pending == 0) {
      // Aviod doing it until the data has loaded
      // Calculate complies
      if (average_dft_check && (isolated_low ? isolated_low : low_dft_check)) {
        change("complies", true);
        change("failure_reason", null);
      } else {
        if (!average_dft_check) {
          change("failure_reason", "Non Compliant due to the average being lower than the required");
        } else if (!low_dft_check) {
          change("failure_reason", `Non Compliant due to the low dft being lower than ${selectedCoat?.min_percentage}% of the required dft`);
        }
        change("complies", false);
      }
      // Calculate complies
    }
  }, [average_dft, low_dft, project_technical_details_paint_systems, use_dft_from_previous, isolated_low]);

  let dftText;
  if (use_dft_from_previous && findCompliantLastCoat) {
    dftText = `From last compliant Coat #${selectedCoat?.number - 1} inspection`;
  } else {
    dftText = `From sum of required dfts up to Coat #${selectedCoat?.number}`;
  }


  return (
    <List>
      <ElcometerReader useDFT={useDFT} selectedCoat={selectedCoat} {...props} />

      <Block style={{ fontSize: "14px" }}>
        {selectedPaintSystem && selectedCoat && (
          <Fragment>
            <b>
              {selectedPaintSystem?.display_code} - Coat {selectedCoat?.display_name}
            </b>
            <br />
            Required dft : <b>{requiredDFT}</b>
            {notFirstCoat && (
              <Fragment>
                {" "}
                + <Tooltip id="Tooltip" label={baseDFT} text={dftText} /> = <b>{requiredDFT + baseDFT}</b>
              </Fragment>
            )}
          </Fragment>
        )}
      </Block>

      {notFirstCoat && baseDFT && findCompliantLastCoat && (
        <Field label="Use DFT from Previous Inspections" slot="list" component={ToggleField} name="use_dft_from_previous" />
      )}

      <Field
        label="Average dft"
        placeholder="Enter avg dft"
        slot="list"
        component={InputField}
        validate={required}
        postiveOnly
        name="average_dft"
        type="number"
        min="0"
        disabled={readOnlyReadings}
      />
      <Field
        slot="list"
        label="Low DFT"
        placeholder="Enter low dft"
        component={InputField}
        postiveOnly
        name="low_dft"
        type="number"
        min="0"
        validate={checkMax}
        disabled={readOnlyReadings}
      />
      {low_between_low_and_avg && <Field label="Isolated Low" slot="list" component={ToggleField} name="isolated_low" disabled={readOnlyReadings} />}
      <Field
        component={InputField}
        label="High DFT"
        placeholder="Enter high dft"
        name="high_dft"
        postiveOnly
        type="number"
        min="0"
        slot="list"
        validate={checkMin}
        disabled={readOnlyReadings}
      />
      <Field
        component={InputField}
        slot="list"
        validate={required}
        placeholder="Enter Readings #"
        min="0"
        label="Readings"
        name="readings"
        postiveOnly
        type="number"
        disabled={readOnlyReadings}
      />
      <Field label="Complies" disabled={!complies} slot="list" component={ToggleField} name="complies" type="boolean" />
      {!complies && (
        <Field
          slot="list"
          placeholder="Enter Failure reason"
          label="Failure Reason"
          component={InputField}
          type="textarea"
          name="failure_reason"
          rows="4"
        />
      )}
    </List>
  );
};

export default connect(mapStateToProps, {})(NormalReadings);

const checkMax = (value, allValues) => {
  const { average_dft } = allValues;
  return parseInt(value) > parseInt(average_dft) ? "Low DFT is too big" : value ? null : "is required";
};

const checkMin = (value, allValues) => {
  const { average_dft } = allValues;
  return parseInt(value) < parseInt(average_dft) ? "High DFT is too low" : value ? null : "is required";
};
