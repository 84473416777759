import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import AppLayout from "modules/1_layout/web/AppLayout";
import qs from "query-string";


const Router = (props) => {
  const query = qs.parse(location?.search);
  const { fullscreen } = query;

  return (
    <BrowserRouter>
      {/* This is to provide the route props to the applayout */}
      <Route path="/" name="Home" render={(props) => <AppLayout fullscreen={fullscreen} {...props} />} />
    </BrowserRouter>
  );
};

export default Router;