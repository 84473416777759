import React from "react";

import CustomFormRecordsIndex from "./index";
import NewCustomFormRecords from "./new";
import EditCustomFormRecords from "./edit";
// const OpenCustomFormRecords = React.lazy(() => import("./open"));

export default [
  //   { path: "/custom_form/:custom_form_id/records/:id/open", component: OpenCustomFormRecords, name: "OpenCustomFormRecords" },
  { path: "/custom_form/:custom_form_id/records/:id/edit/:parent_id?", component: EditCustomFormRecords, name: "Edit CustomFormRecord" },
  { path: "/custom_form/:custom_form_id/records/new/:parent_id?", component: NewCustomFormRecords, name: "New Custom Form Record" },
  { path: "/custom_form/:custom_form_id/records", component: CustomFormRecordsIndex, name: "CustomFormRecords" },
];

// import CustomFormRecordsRoutes from "modules/custom_form/records/web/routes"
// routes = _.concat(routes,CustomFormRecordsRoutes)
