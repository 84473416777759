import React from 'react';

const ProjectQaPaintInspectionsIndex = React.lazy(() => import("./index"))
const NewProjectQaPaintInspections = React.lazy(() => import("./new"))
const EditProjectQaPaintInspections = React.lazy(() => import("./edit"))
const ShowProjectQaPaintInspections = React.lazy(() => import("./show"))

export default[
	{path:'/project/:project_id/qa/paint_inspections/:id/edit',component: EditProjectQaPaintInspections,name:'Edit ProjectQaPaintInspection'},
	{path:'/project/:project_id/qa/paint_inspections/new',component: NewProjectQaPaintInspections,name:'New ProjectQaPaintInspection'},  
	{path:'/project/:project_id/qa/paint_inspections/:id',component: ShowProjectQaPaintInspections,name:'Show ProjectQaPaintInspection'},
	{path:'/project/:project_id/qa/paint_inspections',component: ProjectQaPaintInspectionsIndex,name:'ProjectQaPaintInspections'}, 
]

// import ProjectQaPaintInspectionsRoutes from "modules/project/qa/paint_inspections/web/routes"
// routes = _.concat(routes,ProjectQaPaintInspectionsRoutes)
