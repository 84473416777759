import { 
	GET_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASONS, 
	GET_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASON,
	CREATE_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASON,
	UPDATE_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASON,
	DESTROY_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASON,
} from "./actions"

export function ProjectQaWeldingInspectionsFailureReasonsReducer(state = {}, action) {
	switch (action.type) {
		case `${GET_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASONS}_FULFILLED`:{
			return _.mapKeys(action.payload.data, "id");
		}
		case `${GET_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASON}_FULFILLED`:
		case `${CREATE_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASON}_FULFILLED`:
		case `${UPDATE_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASON}_FULFILLED`:{
			let newState = _.clone(state);
			const newItem = action.payload.data;
			const id = _.get(newItem,"id")
			newState[id] = newItem
			return newState;
		}		
		case `${DESTROY_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASON}_FULFILLED`:{
			let newState = _.clone(state);
			const deletedItem = action.payload.data;
			const id = _.get(deletedItem,"id")
			newState = _.filter(newState,i => i.id != id)
			return newState;
		}
		default:
			return state;
	}
}

// Move this to the reducer page
// import { ProjectQaWeldingInspectionsFailureReasonsReducer } from "modules/project/qa/welding_inspections/failure_reasons/reducer"

// project_qa_welding_inspections_failure_reasons:ProjectQaWeldingInspectionsFailureReasonsReducer,
