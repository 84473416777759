import React from "react";
import { SingleDatePicker } from "react-dates";

import "react-dates/lib/css/_datepicker.css";

export default class DateField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { focused: false };
  }

  render() {
    const { id, onChange, showClearDate, enablePreviousDates, displayFormat, value, minDate, ...rest } = this.props;
    const { focused } = this.state;

    let isOutsideRange;

    if (minDate) {
      isOutsideRange = minDate ? (day) => day.isBefore(minDate) : undefined;
    } else {
      isOutsideRange = enablePreviousDates ? () => false : undefined;
    }

    return (
      <SingleDatePicker
        date={value} // momentPropTypes.momentObj or null
        onDateChange={(date) => {
          onChange(date);
        }} // PropTypes.func.isRequired
        focused={this.state.focused} // PropTypes.bool
        onFocusChange={({ focused }) => {
          this.setState({ focused });
        }} // PropTypes.func.isRequired
        id={`${id}`} // PropTypes.string.isRequired,
        firstDayOfWeek={1}
        displayFormat={displayFormat}
        isOutsideRange={isOutsideRange}
        showClearDate={showClearDate}
        hideKeyboardShortcutsPanel
        {...rest}
      />
    );
  }
}

DateField.defaultProps = {
  displayFormat: "DD/MM/YYYY",
  minDate: "",
};
