import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Table, Button, Card, CardHeader, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import _ from "lodash";

import { StatusTypes } from "modules/project/communications/shared.js";

// Helpers
import NotAuthorized from "modules/5_view_helpers/web/NotAuthorized";
import HasRole from "modules/5_view_helpers/HasRole";

// Actions
import { getProjectCommunicationsStatuses } from "../actions";

function mapStateToProps(state) {
  return {
    project_communications_statuses: state.project_communications_statuses,
  };
}

const ProjectCommunicationsStatusesIndex = (props) => {
  useEffect(() => {
    props.getProjectCommunicationsStatuses();
  }, []);

  const { project_communications_statuses } = props;

  const readRole = HasRole({ resource_type: "Project::Communications::Status", name: ["read", "moderator"] });
  const createRole = HasRole({ resource_type: "Project::Communications::Status", name: ["create", "moderator"] });
  const updateRole = HasRole({ resource_type: "Project::Communications::Status", name: ["update", "moderator"] });

  if (!readRole) {
    return <NotAuthorized />;
  } else {
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <h3>Statuses</h3>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
          {createRole && (
            <Row>
              <Col>
                <Link to={`/project/communications/statuses/new`}>
                  <Button color="primary" size="md">
                    <i className="fa fa-plus"> Create New Status</i>
                  </Button>
                </Link>
              </Col>
            </Row>
          )}

          {_.map(StatusTypes, (status_type, key) => (
            <StatusesTable
              statuses={_.filter(project_communications_statuses, (s) => s.type_string == status_type.value)}
              status_type={status_type.label}
              updateRole={updateRole}
            />
          ))}
        </CardBody>
      </Card>
    );
  }
};
export default connect(mapStateToProps, { getProjectCommunicationsStatuses })(ProjectCommunicationsStatusesIndex);

const StatusesTable = ({ statuses, status_type, updateRole }) => {
  return (
    <div className="mt-3">
      <h3>{status_type} Statuses</h3>
      <Table bordered hover>
        <thead>
          <th>Name</th>
          <th>Default</th>
          <th>Completes</th>
          <th>Voids</th>
          <th>Colour</th>
          {updateRole && <th>Edit</th>}
        </thead>
        {_.map(statuses, (status) => {
          return (
            <tr>
              <td>{status.name}</td>
              <td>{status.default ? "Yes" : "No"}</td>
              <td>{status.completes ? "Yes" : "No"}</td>
              <td>{status.voids ? "Yes" : "No"}</td>
              <td style={{backgroundColor:status.color}}>{status.color || "Not Set"}</td>
              {updateRole && (
                <td>
                  <Link to={`/project/communications/statuses/${status.id}/edit`}>Edit</Link>
                </td>
              )}
            </tr>
          );
        })}
      </Table>
    </div>
  );
};
