import React from 'react';

const Positions = React.lazy(() => import("./index"))
const NewPositions = React.lazy(() => import("./new"))
const EditPositions = React.lazy(() => import("./edit"))

export default[
	{path:'/positions/:id/edit',component: EditPositions,name:'Edit Position'},
	{path:'/positions/new',component: NewPositions,name:'New Position'},  
	{path:'/positions',component: Positions,name:'Positions'}, 
]

// import PositionsRoutes from "modules/positions/web/routes"
// routes = _.concat(routes,PositionsRoutes)
