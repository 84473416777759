import React from "react";

const ShowProductionSchedule = React.lazy(() => import("./show"));

export default [
  {
    path: "/production/schedule",
    component: ShowProductionSchedule,
    name: "Show Production Schedule",
  },
];

// import ProductionTransportBoardRoutes from "modules/production/transport_boards/web/routes"
// routes = _.concat(routes,ProductionTransportBoardRoutes)
