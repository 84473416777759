import React, { Fragment, Component } from "react";
import { ListItem, f7 } from "framework7-react";
import _ from "lodash";

export default class SmartSelect extends Component {
  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.renderOption = this.renderOption.bind(this);
    this.getValue = this.getValue.bind(this);
    this.getLabel = this.getLabel.bind(this);

    this.f7Ref = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.input.value !== prevProps.input.value) {
      const smartSelect = this.f7Ref.f7SmartSelect;
      if (_.isFunction(smartSelect)) {
        if (smartSelect()) {
          const label = this.getLabel();
          smartSelect().setValueText(label);
        }
      }
    }
  }

  renderOption(option, i) {
    const { labelName, valueName } = this.props;

    if (option.options) {
      return (
        <optgroup key={i} label={option.label}>
          {_.map(option.options, (o) => this.renderOption(o, `${option.label}-${i}`))}
        </optgroup>
      );
    } else {
      return (
        <option value={option[valueName]} key={i} disabled={option.isDisabled}>
          {option[labelName]}
        </option>
      );
    }
  }

  onChangeHandler(event) {
    const {
      options,
      valueName,
      input: { onChange },
      multi,
    } = this.props;

    if (multi) {
      const selectedOptions = Array.from(event.target.selectedOptions);
      const newSelectedValues = _.map(selectedOptions, (o) => o.value);

      let newFieldValues = _.map(newSelectedValues, (v) => _.find(options, (o) => o[valueName] == v));

      onChange(newFieldValues);
    } else {
      onChange(event.target.value);
    }
  }

  getValue() {
    const {
      input: { value },
      valueName,
      multi,
    } = this.props;
    let transformedValue = value;
    if (multi) {
      transformedValue = _.map(value, (v) => v[valueName]);
    }
    return transformedValue;
  }

  getLabel() {
    const { options, labelName, valueName, multi } = this.props;
    const value = this.getValue();
    if (multi) {
      return _.map(value, (v) => _.find(options, (o) => o[valueName] == v)[labelName]).join(", ");
    } else {
      return _.get(
        _.find(options, (o) => o[valueName] == value),
        labelName
      );
    }
  }

  render() {
    const {
      input: { value, onBlur, name },
      meta: { touched, error },
      label,
      placeholder,
      options,
      openIn,
      searchbar,
      searchbarPlaceholder,
      closeOnSelect,
      multi,
      after,
      virtualList,
    } = this.props;

    const transformedValue = this.getValue();
    let errorDisplay;
    if (_.isObject(error)) {
      errorDisplay = _.get(error, name);
    } else {
      errorDisplay = error;
    }

    return (
      <Fragment>
        {_.isEmpty(options) && <ListItem>No {label} options</ListItem>}
        {_.isNotEmpty(options) && (
          <ListItem
            title={label}
            after={after}
            smartSelect
            smartSelectParams={{
              openIn: openIn,
              searchbar: searchbar,
              searchbarPlaceholder: searchbarPlaceholder,
              closeOnSelect: closeOnSelect,
              on: {
                smartSelectClosed: onBlur,
              },
              virtualList: virtualList,
            }}
            ref={(el) => {
              this.f7Ref = el;
            }}
          >
            <select multiple={multi} name={name} onChange={this.onChangeHandler} value={transformedValue}>
              {placeholder && !multi && <option disabled={value}>{placeholder}</option>}
              {_.map(options, (o, i) => {
                return this.renderOption(o, i);
              })}
            </select>
          </ListItem>
        )}
        {touched && errorDisplay ? (
          <div className="margin-left text-color-red">
            <small>{errorDisplay}</small>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

SmartSelect.defaultProps = {
  openIn: "popup",
  searchbar: false,
  searchbarPlaceholder: null,
  closeOnSelect: true,
  placeholder: "Select",
  label: "",
  multi: false,
  valueName: "id",
  labelName: "name",
};
