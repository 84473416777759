import React, { Fragment } from "react";
import { Field, useForm } from "react-final-form";
import { OnChange } from "react-final-form-listeners";

// Web Final Form Components
import { FormGroup, Label, Col } from "reactstrap";
import { InputField, ToggleField, required } from "modules/4_final_form/web";

// Mobile Final Form Components
import { InputField as MobileInputField } from "modules/4_final_form/mobile";

export const InputFieldAttributes = ({ fieldName, namesUsed }) => {
  const form = useForm();

  return (
    <Fragment>
      <FormGroup row>
        <Label md={3}>Label</Label>
        <Col md={8}>
          <Field name={`${fieldName}.label`} component={InputField} />
          <OnChange name={`${fieldName}.label`}>
            {(value, previous) => {
              if (value) {
                const name = _.snakeCase(value);
                form.change(`${fieldName}.name`, name);
              }
            }}
          </OnChange>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>Name</Label>
        <Col md={8}>
          <Field
            name={`${fieldName}.name`}
            component={InputField}
            validate={(value, values) => {
              if (!value) {
                return "is required";
              }
              if (namesUsed.includes(value)) {
                return "must be unique";
              }
            }}
            convertToLowerCase
            lettersOnly
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>Placeholder</Label>
        <Col md={8}>
          <Field name={`${fieldName}.placeholder`} component={InputField} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>Required Field</Label>
        <Col md={8}>
          <Field name={`${fieldName}.required`} component={ToggleField} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>On Dashboard</Label>
        <Col md={8}>
          <Field name={`${fieldName}.on_dashboard`} component={ToggleField} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>On Report</Label>
        <Col md={8}>
          <Field name={`${fieldName}.on_report`} component={ToggleField} />
        </Col>
      </FormGroup>
    </Fragment>
  );
};

export const InputFieldForm = (props) => {
  const { label, placeholder, required: isRequired, preview, name, prefixName } = props;
  return (
    <FormGroup row>
      {label && (
        <Label md={2}>
          {label}
          {required && "*"}
        </Label>
      )}
      <Col md={10}>
        <Field
          name={`${prefixName}.${name}`}
          disabled={preview}
          component={InputField}
          placeholder={placeholder}
          validate={isRequired && !preview ? required : null}
        />
      </Col>
    </FormGroup>
  );
};
InputFieldForm.defaultProps = {
  prefixName: "data",
};


export const MobileInputFieldForm = (props) => {
  const { label, placeholder, required: isRequired, preview, name, prefixName } = props;
  return (
    <Field
      label={label}
      name={`${prefixName}.${name}`}
      disabled={preview}
      component={MobileInputField}
      placeholder={placeholder}
      validate={isRequired && !preview ? required : null}
    />
  );
};
MobileInputFieldForm.defaultProps = {
  prefixName: "data",
};
