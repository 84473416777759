import React from "react";
import { Page,Block } from "framework7-react"
import AppNavbar from "modules/1_layout/mobile/AppNavbar"

const Home = (props) => {
	return (
    <Page>
      <AppNavbar title="Lion V3"/>
      <Block>
        Welcome to LionV3 Mobile App, please use the menu on the left
      </Block>
    </Page>
  );
};

export default Home;
