export const isNotEmpty = (value) => {
  let newValue = _.clone(value);

  if (_.isArray(newValue)) {
    newValue = _.filter(newValue, (t) => !t._destroy);
  }

  return !_.isEmpty(newValue) ? undefined : "at least one is required";
};

export default isNotEmpty;
