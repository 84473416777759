import React from 'react';

const HireagesCategory = React.lazy(() => import("./index"))
const NewHireagesCategory = React.lazy(() => import("./new"))
const EditHireagesCategory = React.lazy(() => import("./edit"))

export default[
	{path:'/hireages/categories/:id/edit',component: EditHireagesCategory,name:'Edit HireagesCategory'},
	{path:'/hireages/categories/new',component: NewHireagesCategory,name:'New HireagesCategory'},  
	{path:'/hireages/categories',component: HireagesCategory,name:'HireagesCategory'}, 
]

// import HireagesCategoryRoutes from "modules/hireages/categories/web/routes"
// routes = _.concat(routes,HireagesCategoryRoutes)
