import { Card, CardBody } from "reactstrap";

const StoreHome = (props) => {
  return (
    <Card>
      <CardBody>Store Module, use the menu on the left</CardBody>
    </Card>
  );
};

export default StoreHome;
