import {
  GET_PRODUCTION_JOB_BOARDS,
  GET_PRODUCTION_JOB_BOARD,
  CREATE_PRODUCTION_JOB_BOARD,
  UPDATE_PRODUCTION_JOB_BOARD,
  DESTROY_PRODUCTION_JOB_BOARD,
} from "./actions";

export function ProductionJobBoardReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_PRODUCTION_JOB_BOARDS}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    case `${GET_PRODUCTION_JOB_BOARD}_FULFILLED`:
    case `${CREATE_PRODUCTION_JOB_BOARD}_FULFILLED`:
    case `${UPDATE_PRODUCTION_JOB_BOARD}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_PRODUCTION_JOB_BOARD}_FULFILLED`: {
      let newState = _.clone(state);
      const {
        meta: { id },
      } = action;
      _.unset(newState, id);
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { ProductionJobBoardReducer } from "modules/production/job_boards/reducer"

// production_job_boards:ProductionJobBoardReducer,
