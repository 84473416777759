import React from 'react';

const TrainingCourse = React.lazy(() => import("./index"))
const NewTrainingCourse = React.lazy(() => import("./new"))
const EditTrainingCourse = React.lazy(() => import("./edit"))

export default[
	{path:'/training/courses/:id/edit',component: EditTrainingCourse,name:'Edit TrainingCourse'},
	{path:'/training/courses/new',component: NewTrainingCourse,name:'New TrainingCourse'},  
	{path:'/training/courses',component: TrainingCourse,name:'TrainingCourse'}, 
]

// import TrainingCourseRoutes from "modules/training/courses/web/routes"
// routes = _.concat(routes,TrainingCourseRoutes)
