import React, { Fragment, useEffect, useState } from "react";
import { InputField, ToggleField, required } from "modules/4_final_form/mobile";
import classnames from "classnames";

import { Field } from "react-final-form";
import { connect } from "react-redux";
import { Block, List, Badge, Navbar, Tabs, Tab,BlockTitle } from "framework7-react";

import Tooltip from "modules/5_view_helpers/web/Tooltip";
import ElcometerReader from "./ElcometerReader/ElcometerReader";

function mapStateToProps(state, ownProps) {
  const {
    project,
    values: {
      assembly: { guid },
    },
  } = ownProps;

  const assembly_paint_inspections = _.filter(state.project_qa_paint_inspections, (pi) => pi.assembly?.guid == guid);
  let production_strumis_contract_mark_instances = _.filter(
    state.production_strumis_contract_mark_instances,
    (mi) => mi.contract_id == project?.strumis_contract_id
  );

  return {
    assembly_paint_inspections: assembly_paint_inspections,
    production_strumis_contract_mark_instances,
    pending: state.pending,
  };
}

const SectionSizeReadings = (props) => {
  const {
    selectedPaintSystem,
    selectedCoat,
    firstCoatWithSections,
    values: { assembly, section_readings_attributes },
    production_strumis_contract_mark_instances,
    errors,
    meta: { error },
    submitFailed,
    project,
  } = props;

  const [activeTab, setActiveTab] = useState("1");

  const selectedAssembly = _.find(production_strumis_contract_mark_instances, (mi) => mi.guid == assembly?.guid);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  let displaySections = [];

  if (selectedCoat) {
    const coatToUse = !selectedCoat.dft_by_sections && firstCoatWithSections?.dft_by_sections ? firstCoatWithSections : selectedCoat;

    // Check if each paint system section type is in this assembly and then display it.
    _.each(coatToUse.sections_attributes, (section_in_paint_system) => {
      if (project?.strumis_contract_id && selectedAssembly) {
        const findInStrumisAssembly = _.find(
          selectedAssembly.sections,
          (strumis_section) =>
            strumis_section.section_size == section_in_paint_system.strumis_section_size &&
            strumis_section.section_type == section_in_paint_system.strumis_section_type
        );
        if (findInStrumisAssembly) {
          displaySections.push(section_in_paint_system);
        }
      } else {
        displaySections.push(section_in_paint_system);
      }
    });
  }

  return (
    <div>
      <hr />
      <BlockTitle>Sections</BlockTitle>
      <div className="grid grid-cols-5">
        {_.map(displaySections, (section, i) => {
          let thisSectionHasErrors = false;
          const findSectionKey = _.findIndex(section_readings_attributes, (sr) => sr.section_id == section.id);

          if (findSectionKey != -1) {
            const thisSectionErrors = _.get(errors, `section_readings_attributes[${findSectionKey}]`, {});
            thisSectionHasErrors = _.isNotEmpty(thisSectionErrors);
          }

          return (
            <div
              key={i}
              onClick={() => {
                toggle(section.strumis_section_size);
              }}
              slot="default"
              className="padding"
            >
              {activeTab == section.strumis_section_size ? <b>{section.strumis_section_size}</b> : section.strumis_section_size}
              {submitFailed && thisSectionHasErrors && <Badge color="danger">!</Badge>}
            </div>
          );
        })}
      </div>
      <hr />
      <Tabs>
        {_.map(displaySections, (section, i) => (
          <Tab key={i} tabActive={activeTab == section.strumis_section_size}>
            <Readings section={section} selectedPaintSystem={selectedPaintSystem} index={i} selectedCoat={selectedCoat} {...props} />
          </Tab>
        ))}
      </Tabs>

      {error && _.isEmpty(displaySections) && "At least one matching paint system section with strumis sections is needed"}
    </div>
  );
};

export default connect(mapStateToProps, {})(SectionSizeReadings);

const Readings = (props) => {
  const {
    section: { id: section_id, dft: section_dft, strumis_section_size },
    selectedPaintSystem,
    selectedCoat,
    previousCoats,
    form: { change },
    values,
    values: { id, section_readings_attributes },
    index,
    assembly_paint_inspections,
    parentSelectedPaintSystem,
    pending,
  } = props;

  useEffect(() => {
    change(`${fieldName}.section_id`, section_id);
    change(`${fieldName}.use_dft_from_previous`, true);
  }, []);

  const indexToUse = _.findIndex(section_readings_attributes, (sr) => sr.section_id == section_id);
  const fieldName = `section_readings_attributes[${indexToUse == -1 ? index : indexToUse}]`;
  const thisField = _.get(values, fieldName);
  const average_dft = thisField?.average_dft;
  const low_dft = thisField?.low_dft;
  const high_dft = thisField?.high_dft;
  const isolated_low = thisField?.isolated_low;
  const complies = thisField?.complies;
  const readings = thisField?.readings;
  const use_dft_from_previous = thisField?.use_dft_from_previous;
  const notFirstCoat = selectedCoat?.number > 1;

  // Calculate the sum_of_dfts to potentially use.
  const sum_of_dfts = _.sumBy(previousCoats, (coat) => {
    if (coat.dft_by_sections) {
      // Find this section in the paint system coat if its by sections
      const findSection = _.find(coat.sections_attributes, (csect) => csect.strumis_section_size == strumis_section_size);
      return findSection ? findSection.dft : 0;
    } else {
      // Get the normal dft from the coat if its not by section
      return coat.dft;
    }
  });

  let dftText = null;
  let baseDFT = null;

  // Find the previous inspection
  const coatNumberToSearchFor = selectedCoat?.number - 1;

  let findPreviousInspection;
  if (parentSelectedPaintSystem.is_multi_system) {
    findPreviousInspection = _.findLast(
      assembly_paint_inspections,
      (pi) =>
        pi.coat_number == coatNumberToSearchFor &&
        pi.id != id &&
        pi.paint_system_id == parentSelectedPaintSystem.id &&
        pi.child_paint_system_id == selectedPaintSystem.id
    );
  } else {
    findPreviousInspection = _.findLast(
      assembly_paint_inspections,
      (pi) => pi.coat_number == coatNumberToSearchFor && pi.id != id && pi.paint_system_id == selectedPaintSystem.id
    );
  }

  let findCompliantLastCoat = false;
  // If the toggle is set to use previous dfts get the dft from the last inspection and use that for the baseDFT
  if (findPreviousInspection) {
    // If the previous inspection had sections find the section inspection dfts
    if (findPreviousInspection?.inspect_by_sections) {
      const findThisSectionInPreviousInspection = _.find(
        findPreviousInspection?.section_readings_attributes,
        (psect) => psect.complies && psect.section_size == strumis_section_size
      );
      // If the section exisits in the previous inspection set the baseDFT to this
      findCompliantLastCoat = findThisSectionInPreviousInspection;
    }
    // Otherwise just get the average dft from the previous compliant inspection
    else if (findPreviousInspection.complies) {
      findCompliantLastCoat = true;
    }
  }

  // If the toggle is set to use previous dfts get the dft from the last inspection and use that for the baseDFT
  if (use_dft_from_previous && findPreviousInspection) {
    // If the previous inspection had sections find the section inspection dfts
    if (findPreviousInspection?.inspect_by_sections) {
      const findThisSectionInPreviousInspection = _.find(
        findPreviousInspection?.section_readings_attributes,
        (psect) => psect.complies && psect.section_size == strumis_section_size
      );
      // If the section exisits in the previous inspection set the baseDFT to this
      baseDFT = findThisSectionInPreviousInspection ? findThisSectionInPreviousInspection.average_dft : sum_of_dfts;
      dftText = `From last compliant Coat #${selectedCoat.number - 1} inspection - Section ${strumis_section_size}`;
    }
    // Otherwise just get the average dft from the previous compliant inspection
    else if (findPreviousInspection.complies) {
      baseDFT = findPreviousInspection?.average_dft;
      dftText = `From last compliant Coat #${selectedCoat.number - 1} inspection`;
    }
  } else {
    dftText = `From sum of dfts up to Coat #${selectedCoat.number - 1}`;
    baseDFT = sum_of_dfts;
  }

  // DFT Calculations
  const requiredDFT = selectedCoat?.dft_by_sections ? section_dft : selectedCoat?.dft;
  const useDFT = use_dft_from_previous && baseDFT && notFirstCoat ? baseDFT + requiredDFT : requiredDFT;
  const average_dft_check = average_dft >= useDFT;
  const low_dft_check = low_dft >= useDFT * (selectedCoat?.min_percentage / 100);

  useEffect(() => {
    if (pending == 0) {
      // Aviod doing it until the data has loaded
      // Calculate complies
      if (average_dft_check && (isolated_low ? isolated_low : low_dft_check)) {
        change(`${fieldName}.complies`, true);
        change(`${fieldName}.failure_reason`, null);
      } else {
        if (!average_dft_check) {
          change(`${fieldName}.failure_reason`, "Non Compliant due to the average being lower than the required");
        } else if (!low_dft_check) {
          change(
            `${fieldName}.failure_reason`,
            `Non Compliant due to the low dft being lower than ${selectedCoat?.min_percentage}% of the required dft`
          );
        }
        change(`${fieldName}.complies`, false);
      }
      // Calculate complies
    }
  }, [average_dft, low_dft, high_dft, readings, isolated_low, use_dft_from_previous]);

  return (
    <Fragment>
      {window.elco && (
        <ElcometerReader
          average_dft_name={`${fieldName}.average_dft`}
          low_dft_name={`${fieldName}.low_dft`}
          high_dft_name={`${fieldName}.high_dft`}
          readings_name={`${fieldName}.readings`}
          form_name={`${fieldName}.pi_readings_attributes`}
          useDFT={useDFT}
          selectedCoat={selectedCoat}
          {...props}
        />
      )}

      <List>
        <Block style={{ fontSize: "14px" }}>
          {selectedPaintSystem && selectedCoat && (
            <Fragment>
              <b>
                {selectedPaintSystem?.display_code} - Coat {selectedCoat?.display_name}
              </b>
              <br />
              Required dft : <b>{requiredDFT}</b>
              {notFirstCoat && (
                <Fragment>
                  {" "}
                  + <Tooltip id="Tooltip" label={baseDFT} text={dftText} /> = <b>{requiredDFT + baseDFT}</b>
                </Fragment>
              )}
            </Fragment>
          )}
        </Block>
        {notFirstCoat && baseDFT && findCompliantLastCoat && (
          <Field label="Use DFT from Previous Inspections" slot="list" component={ToggleField} name={`${fieldName}.use_dft_from_previous`} />
        )}
        <Field
          label="Average dft"
          placeholder="Enter avg dft"
          slot="list"
          component={InputField}
          validate={required}
          name={`${fieldName}.average_dft`}
          postiveOnly
          type="number"
          min="0"
        />
        <Field
          slot="list"
          label="Low DFT"
          placeholder="Enter low dft"
          component={InputField}
          name={`${fieldName}.low_dft`}
          postiveOnly
          type="number"
          validate={checkMax}
          min="0"
        />
        {!low_dft_check && low_dft && <Field label="Isolated Low" slot="list" component={ToggleField} name={`${fieldName}.isolated_low`} />}
        <Field
          component={InputField}
          label="High DFT"
          placeholder="Enter high dft"
          name={`${fieldName}.high_dft`}
          postiveOnly
          type="number"
          slot="list"
          validate={checkMin}
          min="0"
        />
        <Field
          component={InputField}
          slot="list"
          validate={required}
          placeholder="Enter Readings #"
          label="Readings"
          name={`${fieldName}.readings`}
          postiveOnly
          type="number"
          min="0"
        />
        <Field label="Complies" disabled={!complies} slot="list" component={ToggleField} name={`${fieldName}.complies`} type="boolean" />
        {!complies && (
          <Field
            slot="list"
            placeholder="Enter Failure reason"
            label="Failure Reason"
            component={InputField}
            type="textarea"
            name={`${fieldName}.failure_reason`}
            rows="4"
          />
        )}
      </List>
    </Fragment>
  );
};

const checkMax = (value, allValues) => {
  const { average_dft } = allValues;
  return parseInt(value) > parseInt(average_dft) ? "Low DFT is too big" : value ? null : "is required";
};

const checkMin = (value, allValues) => {
  const { average_dft } = allValues;
  return parseInt(value) < parseInt(average_dft) ? "High DFT is too low" : value ? null : "is required";
};
