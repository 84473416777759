import React from "react";
import EmployeeQualificationsRoutes from "modules/employee/qualifications/web/routes";

const Employees = React.lazy(() => import("./index"));
const NewEmployees = React.lazy(() => import("./new"));
const EditEmployees = React.lazy(() => import("./edit"));
const ShowEmployees = React.lazy(() => import("./show"));

const EmployeeRoutes = [
  {
    path: "/employees/:id/show",
    component: ShowEmployees,
    name: "Show Employee",
  },
  {
    path: "/employees/:id/edit",
    component: EditEmployees,
    name: "Edit Employee",
  },
  { path: "/employees/new", component: NewEmployees, name: "New Employee" },
  { path: "/employees", component: Employees, name: "Employees" },
];

let routes = [];

routes = _.concat(routes, EmployeeQualificationsRoutes);
routes = _.concat(routes, EmployeeRoutes);

export default routes;
