import { ADD_REACTDROPZONE_FILE_UPLOADING, REMOVE_REACTDROPZONE_FILE_UPLOADING } from "./actions";

export function ReactDropzoneStatus(state = 0, action) {
  switch (action.type) {
    case ADD_REACTDROPZONE_FILE_UPLOADING: {
      return state + 1;
    }
    case REMOVE_REACTDROPZONE_FILE_UPLOADING: {
      return state - 1;
    }
    default:
      return state;
  }
}
