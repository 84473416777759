import React from "react";

import AddressBookIndex from "./index";
import AddressBookCompaniesRoutes from "modules/address_book/companies/web/routes";
import AddressBookCompanyContactsRoutes from "modules/address_book/company/contacts/web/routes"

let routes = [];
routes = _.concat(routes, AddressBookCompaniesRoutes);
routes = _.concat(routes,AddressBookCompanyContactsRoutes)

routes.push({
  path: "/address_book",
  component: AddressBookIndex,
  name: "Address Book",
});

export default routes;
