import React from 'react';

const ProjectSiteDocumentReleasesIndex = React.lazy(() => import("./index"))
const NewProjectSiteDocumentReleases = React.lazy(() => import("./new"))
const ShowProjectSiteDocumentReleases = React.lazy(() => import("./show"))

export default[
	{path:'/project/:project_id/site_document_releases/new',component: NewProjectSiteDocumentReleases,name:'New ProjectSiteDocumentRelease'},  
	{path:'/project/:project_id/site_document_releases/:id',component: ShowProjectSiteDocumentReleases,name:'Show ProjectSiteDocumentRelease'},
	{path:'/project/:project_id/site_document_releases',component: ProjectSiteDocumentReleasesIndex,name:'ProjectSiteDocumentReleases'}, 
]

// import ProjectSiteDocumentReleasesRoutes from "modules/project/site_document_releases/web/routes"
// routes = _.concat(routes,ProjectSiteDocumentReleasesRoutes)
