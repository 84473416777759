import HasRole from "modules/5_view_helpers/HasRole";

const getStoreNav = () => {
  let items = [];

  items.push({
    icon: "fa fa-home",
    label: "Home",
    to: `/store`,
  });

  items.push({
    icon: "fa fa-arrow-right",
    label: "Bookout Items",
    to: `/store/bookout_items`,
  });

  items.push({
    icon: "fa fa-arrow-left",
    label: "Back To Lion",
    to: `/`,
    external: true,
  });

  return items;
};

export default getStoreNav;
