import React from 'react';

const UserGroupsIndex = React.lazy(() => import("./index"))
const NewUserGroups = React.lazy(() => import("./new"))
const EditUserGroups = React.lazy(() => import("./edit"))

export default[
	{path:'/user_groups/:id/edit',component: EditUserGroups,name:'Edit UserGroup'},
	{path:'/user_groups/new',component: NewUserGroups,name:'New UserGroup'},  
	{path:'/user_groups',component: UserGroupsIndex,name:'UserGroups'}, 
]

// import UserGroupsRoutes from "modules/user_groups/web/routes"
// routes = _.concat(routes,UserGroupsRoutes)
