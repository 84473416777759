import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";

import { Page, Fab, List, ListItem, BlockTitle, Icon, AccordionContent, Button, Block, Preloader, Searchbar, f7 } from "framework7-react";

// Helpers
import HasRole from "modules/5_view_helpers/HasRole";
import AppNavbar from "modules/1_layout/mobile/AppNavbar";

import SelectProject from "modules/projects/mobile/select";
import { setCurrentProject } from "modules/current_user/actions";

// Actions
import { getProjectQaPaintInspections } from "../actions";
import NotAuthorized from "modules/5_view_helpers/mobile/NotAuthorized";

import { handleNewClick, ResumePersistedButton } from "modules/5_view_helpers/mobile/PersistedHelpers.jsx";
import { persistedName } from "./vairables";

import _ from "lodash";

function mapStateToProps(state, ownProps) {
  const current_user = state.current_user;
  const project_id = current_user?.current_project_id;

  return {
    projects: state.projects,
    current_user: current_user,
    this_project_qa_paint_inspections: _.filter(state.project_qa_paint_inspections, (pi) => pi.project_id == project_id),
  };
}

const ProjectQaPaintInspectionsIndex = (props) => {
  const [loading, setLoading] = useState(false);
  const { this_project_qa_paint_inspections, current_user, projects, f7router, f7route } = props;
  const current_project_id = _.get(current_user, "current_project_id");
  const project = projects[current_project_id];

  const PaintInspectionsByGuid = _.groupBy(this_project_qa_paint_inspections, "assembly.guid");
  let items = _.map(PaintInspectionsByGuid, (paint_inspections, guid) => {
    const assembly_display_name_full = paint_inspections[0]?.assembly_display_name_full;
    return { assembly_display_name_full, guid, paint_inspections };
  });
  items = _.sortBy(items, "assembly_display_name_full");

  const newUrl = `/project/${project?.id}/qa/paint_inspections/new`;

  // Virtual List Stuff START
  const [vlData, setVlData] = useState({
    items: [],
  });
  const searchAll = (query, searchItems) => {
    const found = [];
    for (let i = 0; i < searchItems.length; i += 1) {
      if (searchItems[i]?.assembly_display_name_full.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === "") found.push(i);
    }
    return found; // return array with mathced indexes
  };
  const renderExternal = (vl, newData) => {
    setVlData({ ...newData });
  };
  // Virtual List Stuff END

  const readRole = HasRole(
    {
      resource_type: "Project::Qa::PaintInspection",
      name: ["read", "moderator"],
    },
    true
  );

  const createRole = HasRole(
    {
      resource_type: "Project::Qa::PaintInspection",
      name: ["create", "moderator"],
    },
    true
  );

  const updateRole = HasRole(
    {
      resource_type: "Project::Qa::PaintInspection",
      name: ["update", "moderator"],
    },
    true
  );

  const hideMenu = f7route?.query?.hideMenu == "true";

  const getData = () => {
    setLoading(true);
    props.getProjectQaPaintInspections(current_project_id).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (current_project_id && _.isEmpty(this_project_qa_paint_inspections) && readRole) {
      getData();
    }
  }, [current_project_id]);

  if (!readRole) {
    return <NotAuthorized />;
  }

  return (
    <Page>
      {!hideMenu && <AppNavbar title="Paint Inspections" />}

      <SelectProject
        onChange={({ target: { value } }) => {
          props.setCurrentProject(value);
        }}
        value={current_project_id}
      />
      <ResumePersistedButton name={persistedName} url={newUrl} />

      {loading && <Preloader />}
      {!loading && (
        <Fragment>
          <Searchbar searchContainer=".virtual-list" searchItem="li" searchIn=".item-title" />

          <BlockTitle>{project?.display_name} - Paint Inspections</BlockTitle>
          <Block>
            <Button fill onClick={getData} size="sm">
              Refresh
            </Button>
          </Block>
          <List
            strong
            className="searchbar-found"
            virtualList
            virtualListParams={{
              items,
              searchAll,
              renderExternal,
              height: 48,
            }}
          >
            <ul>
              {vlData.items.map((item, index) => {
                const { paint_inspections } = item;
                const assembly = paint_inspections[0].assembly;
                const virtualListIndex = _.findIndex(items, { guid: item.guid });

                return (
                  <ListItem
                    slot="list"
                    accordionItem
                    title={assembly?.display_name_full}
                    virtualListIndex={virtualListIndex}
                    style={{ top: `${vlData.topPosition}px` }}
                  >
                    <AccordionContent>
                      {createRole && (
                        <Block>
                          <Button fill href={`/project/${assembly.project_id}/qa/paint_inspections/new?selectedGUID=${assembly.guid}`}>
                            Inspect
                          </Button>
                        </Block>
                      )}
                      <List accordionList>
                        {_.map(paint_inspections, (paint_inspection, i) => (
                          <PaintInspection key={i} project_id={assembly.project_id} paint_inspection={paint_inspection} updateRole={updateRole} />
                        ))}
                      </List>
                    </AccordionContent>
                  </ListItem>
                );
              })}
            </ul>
          </List>
        </Fragment>
      )}

      {createRole && (
        <Fab
          position="right-bottom"
          slot="fixed"
          text="New"
          href=""
          onClick={() => {
            handleNewClick(f7router, persistedName, newUrl);
          }}
        >
          <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
        </Fab>
      )}
    </Page>
  );
};
export default connect(mapStateToProps, { getProjectQaPaintInspections, setCurrentProject })(ProjectQaPaintInspectionsIndex);

const PaintInspection = (props) => {
  const {
    paint_inspection,
    paint_inspection: { section_readings_attributes },
    project_id,
  } = props;

  const label = `Inspection #${paint_inspection.number} - ${paint_inspection.paint_system_display_code} ${
    paint_inspection.paint_system_is_multi_system ? ` -> ${paint_inspection.child_paint_system_display_code}` : ""
  } Coat #${paint_inspection.coat_number}`;

  return (
    <ListItem className="padding-left" accordionItem title={label}>
      <AccordionContent>
        {!paint_inspection.inspect_by_sections && (
          <div className="padding-left">
            <div className="grid grid-cols-1">
              <div>Complies : {<b>{paint_inspection.complies ? "Yes" : "No"}</b>}</div>
            </div>
            <div className="grid grid-cols-1">
              <div>
                Paint System: <b>{paint_inspection.paint_system_display_code}</b>
                {paint_inspection.paint_system_is_multi_system && ` -> ${paint_inspection.child_paint_system_display_code}`}
              </div>
            </div>
            <div className="grid grid-cols-1">
              <div>
                Coat: <b>{paint_inspection.coat_display_name}</b>
              </div>
            </div>
            <div className="grid grid-cols-1">
              <div>
                Inspection Location: <b>{paint_inspection.location_name}</b>
              </div>
            </div>
            <div className="grid grid-cols-1">
              <div>
                Average DFT: <b>{paint_inspection.average_dft}</b>
              </div>
            </div>
            <div className="grid grid-cols-1">
              <div>
                Low DFT: <b>{paint_inspection.low_dft}</b>
              </div>
            </div>
            <div className="grid grid-cols-1">
              <div>
                High DFT: <b>{paint_inspection.high_dft}</b>
              </div>
            </div>
            <div className="grid grid-cols-1">
              <div>
                Readings: <b>{paint_inspection.readings}</b>
              </div>
            </div>

            {paint_inspection.isolated_low && (
              <div className="grid grid-cols-1">
                <div>
                  Isolated Low: <b>{paint_inspection.isolated_low ? "Yes" : "No"}</b>
                </div>
              </div>
            )}

            <div className="grid grid-cols-1">
              <div>
                Failure Reason: <b>{paint_inspection.complies ? "N/A" : paint_inspection.failure_reason}</b>
              </div>
            </div>
          </div>
        )}

        {paint_inspection.inspect_by_sections && (
          <List accordionList className="padding-left">
            <Block className="padding-left" style={{ fontSize: "14px" }}>
              <div className="grid grid-cols-1">
                <div>
                  Paint System: <b>{paint_inspection.paint_system_display_code}</b>
                  {paint_inspection.paint_system_is_multi_system && ` -> ${paint_inspection.child_paint_system_display_code}`}
                </div>
              </div>
              <div className="grid grid-cols-1">
                <div>
                  Coat: <b>{paint_inspection.coat_display_name}</b>
                </div>
              </div>
              <div className="grid grid-cols-1">
                <div>
                  Inspection Location: <b>{paint_inspection.location_name}</b>
                </div>
              </div>
            </Block>

            {_.map(section_readings_attributes, (section_reading, i) => (
              <ListItem accordionItem key={i} title={`Section : ${section_reading.section_size}`}>
                <AccordionContent className="padding-left">
                  <div className="grid grid-cols-1">
                    <div>Complies: {<b>{section_reading.complies ? "Yes" : "No"}</b>}</div>
                  </div>
                  <div className="grid grid-cols-1">
                    <div>
                      Average DFT: <b>{section_reading.average_dft}</b>
                    </div>
                  </div>
                  <div className="grid grid-cols-1">
                    <div>
                      Low DFT: <b>{section_reading.low_dft}</b>
                    </div>
                  </div>
                  <div className="grid grid-cols-1">
                    <div>
                      High DFT: <b>{section_reading.high_dft}</b>
                    </div>
                  </div>
                  <div className="grid grid-cols-1">
                    <div>
                      Readings: <b>{section_reading.readings}</b>
                    </div>
                  </div>
                  <div className="grid grid-cols-1">
                    <div>
                      Failure Reason: <b>{section_reading.complies ? "N/A" : section_reading.failure_reason}</b>
                    </div>
                  </div>
                </AccordionContent>
              </ListItem>
            ))}
          </List>
        )}

        <Block>
          <Button color="green" external fill href={`/project/${project_id}/qa/paint_inspections/${paint_inspection.id}.pdf`} target="_blank">
            Open PDF
          </Button>
        </Block>
        {/* <Block className="margin-top">
          <Button fill href={`/project/${project_id}/qa/paint_inspections/${paint_inspection.id}/edit`}>
            Edit
          </Button>
        </Block> */}
      </AccordionContent>
    </ListItem>
  );
};
