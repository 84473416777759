// Plural Actions
export const GET_MW_JOBS = "GET_MW_JOBS";
export const getMWJobs = () => {
  const url = "/moneyworks/jobs.json";
  const request = axios.get(url);
  return {
    type: GET_MW_JOBS,
    payload: request,
  };
};
