import { 
	GET_PAINT_LOCATIONS, 
	GET_PAINT_LOCATION,
	CREATE_PAINT_LOCATION,
	UPDATE_PAINT_LOCATION,
	DESTROY_PAINT_LOCATION,
} from "./actions"

export function PaintLocationsReducer(state = {}, action) {
	switch (action.type) {
		case `${GET_PAINT_LOCATIONS}_FULFILLED`:{
			return _.mapKeys(action.payload.data, "id");
		}
		case `${GET_PAINT_LOCATION}_FULFILLED`:
		case `${CREATE_PAINT_LOCATION}_FULFILLED`:
		case `${UPDATE_PAINT_LOCATION}_FULFILLED`:{
			let newState = _.clone(state);
			const newItem = action.payload.data;
			const id = _.get(newItem,"id")
			newState[id] = newItem
			return newState;
		}		
		case `${DESTROY_PAINT_LOCATION}_FULFILLED`:{
			let newState = _.clone(state);
			const deletedItem = action.payload.data;
			const id = _.get(deletedItem,"id")
			newState = _.filter(newState,i => i.id != id)
			return newState;
		}
		default:
			return state;
	}
}

// Move this to the reducer page
// import { PaintLocationsReducer } from "modules/paint/locations/reducer"

// paint_locations:PaintLocationsReducer,
