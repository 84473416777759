import {
  GET_PRODUCTION_STRUMIS_CONTRACT_MARK_ITEM_INSTANCES_BY_LOTS,
} from "./actions";

export function ProductionStrumisContractMarkItemInstancesReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_PRODUCTION_STRUMIS_CONTRACT_MARK_ITEM_INSTANCES_BY_LOTS}_FULFILLED`: {
      const newState = _.clone(state);
      return _.merge(newState, _.mapKeys(action.payload.data, "id"));
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { ProductionStrumisContractMarkInstancesReducer } from "modules/production/strumis/contracts/reducer"

// production_strumis_contract_mark_instances:ProductionStrumisContractMarkInstancesReducer,
