import axios from "axios";

const prepareData = (data) => {return {address_book_company_contact:data}}

// Plural Actions
export const GET_ADDRESS_BOOK_COMPANY_CONTACTS = "get_address_book_company_contacts";
export const getAddressBookCompanyContacts = () => {
	const url = `/address_book/company/contacts.json`
	const request = axios.get(url);
	return {
		type: GET_ADDRESS_BOOK_COMPANY_CONTACTS,
		payload: request
	};
};


// Singular Actions
export const CREATE_ADDRESS_BOOK_COMPANY_CONTACT = "create_address_book_company_contact";
export const createAddressBookCompanyContact = (data) => {
	const url = `/address_book/company/contacts.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_ADDRESS_BOOK_COMPANY_CONTACT,
		payload: request
	};
};

export const GET_ADDRESS_BOOK_COMPANY_CONTACT = "get_address_book_company_contact";
export const getAddressBookCompanyContact = (id) => {
	const url = `/address_book/company/contacts/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_ADDRESS_BOOK_COMPANY_CONTACT,
		payload: request
	};
};

export const UPDATE_ADDRESS_BOOK_COMPANY_CONTACT = "update_address_book_company_contact";
export const updateAddressBookCompanyContact = (id,data) => {
	const url = `/address_book/company/contacts/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_ADDRESS_BOOK_COMPANY_CONTACT,
		payload: request
	};
};

export const DESTROY_ADDRESS_BOOK_COMPANY_CONTACT = "destroy_address_book_company_contact";
export const destroyAddressBookCompanyContact = (id) => {
	const url = `/address_book/company/contacts/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_ADDRESS_BOOK_COMPANY_CONTACT,
		payload: request
	};
};
