import React from "react";

import ProjectQaSiteSurveillancesIndex from "./index";
import NewProjectQaSiteSurveillance from "./new";
import EditProjectQaSiteSurveillance from "./edit";

export default [
  {
    path: "/project/:project_id/qa/site/surveillances/:id/edit",
    component: EditProjectQaSiteSurveillance,
    name: "Edit ProjectQaSiteSiteInstallation",
  },
  {
    path: "/project/:project_id/qa/site/surveillances/new",
    component: NewProjectQaSiteSurveillance,
    name: "New NewProjectQaSiteSurveillance",
  },
  {
    path: "/project/qa/site/surveillances",
    component: ProjectQaSiteSurveillancesIndex,
    name: "Project QA Surveillance Reports",
  },
];

// import ProjectsRoutes from "modules/projects/web/routes"
// routes = _.concat(routes,ProjectsRoutes)
