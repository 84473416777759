import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Row, Col, CardHeader, CardBody } from "reactstrap";
import classnames from "classnames";

import HasRole from "modules/5_view_helpers/HasRole";

import qs from "query-string";

import AddressBookCompaniesIndex from "modules/address_book/companies/web/index.jsx";
import AddressBookCompanyContactsIndex from "modules/address_book/company/contacts/web/index.jsx";

const AddressBookIndex = (props) => {
  const query = qs.parse(props.location.search);
  const { tab } = query;
  const [activeTab, setActiveTab] = useState(tab || "companies");

  const CompanyRoles = HasRole({ resource_type: "AddressBook::Company" });
  const CompanyContactRoles = HasRole({ resource_type: "AddressBook::Company::Contact" });

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Card>
      <CardHeader>Address Book</CardHeader>
      <CardBody>
        <Nav tabs>
          {CompanyRoles && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "companies" })}
                onClick={() => {
                  toggle("companies");
                }}
              >
                Companies
              </NavLink>
            </NavItem>
          )}
          {CompanyContactRoles && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "contacts" })}
                onClick={() => {
                  toggle("contacts");
                }}
              >
                Contacts
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={activeTab}>
          {CompanyRoles && (
            <TabPane tabId="companies">
              <AddressBookCompaniesIndex {...props} />
            </TabPane>
          )}
          {CompanyContactRoles && (
            <TabPane tabId="contacts">
              <AddressBookCompanyContactsIndex {...props} />
            </TabPane>
          )}
        </TabContent>
      </CardBody>
    </Card>
  );
};

export default AddressBookIndex;
