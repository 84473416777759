import React from 'react';

const ProjectQaWeldingInspectionsSurfaceConditionsIndex = React.lazy(() => import("./index"))
const NewProjectQaWeldingInspectionsSurfaceConditions = React.lazy(() => import("./new"))
const EditProjectQaWeldingInspectionsSurfaceConditions = React.lazy(() => import("./edit"))

export default[
	{path:'/project/qa/welding_inspections/surface_conditions/:id/edit',component: EditProjectQaWeldingInspectionsSurfaceConditions,name:'Edit ProjectQaWeldingInspectionsSurfaceCondition'},
	{path:'/project/qa/welding_inspections/surface_conditions/new',component: NewProjectQaWeldingInspectionsSurfaceConditions,name:'New ProjectQaWeldingInspectionsSurfaceCondition'},  
	{path:'/project/qa/welding_inspections/surface_conditions',component: ProjectQaWeldingInspectionsSurfaceConditionsIndex,name:'ProjectQaWeldingInspectionsSurfaceConditions'}, 
]

// import ProjectQaWeldingInspectionsSurfaceConditionsRoutes from "modules/project/qa/welding_inspections/surface_conditions/web/routes"
// routes = _.concat(routes,ProjectQaWeldingInspectionsSurfaceConditionsRoutes)
