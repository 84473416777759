// Plural Actions
export const GET_ROLES = "get_roles";
export const getRoles = () => {
	const url = "/roles.json"
	const request = axios.get(url);
	return {
		type: GET_ROLES,
		payload: request
	};
};

