import React from "react";

import ProjectsQaSiteChecklistItemRoutes from "../checklist_items/web/routes";
import SiteQADashboard from "./dashboard";

let routes = [
  {
    path: "/projects/qa/site/dashboard",
    component: SiteQADashboard,
    name: "Site QA Dashboard",
  },
];
routes = _.concat(routes, ProjectsQaSiteChecklistItemRoutes);

export default routes;
