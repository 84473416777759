import axios from "axios";

const prepareData = (data) => {return {projects_qa_site_checklist_item:data}}

// Plural Actions
export const GET_PROJECTS_QA_SITE_CHECKLIST_ITEMS = "get_projects_qa_site_checklist_items";
export const getProjectsQaSiteChecklistItems = () => {
	const url = "/projects/qa/site/checklist_items.json"
	const request = axios.get(url);
	return {
		type: GET_PROJECTS_QA_SITE_CHECKLIST_ITEMS,
		payload: request
	};
};


// Singular Actions
export const CREATE_PROJECTS_QA_SITE_CHECKLIST_ITEM = "create_projects_qa_site_checklist_item";
export const createProjectsQaSiteChecklistItem = (data) => {
	const url = "/projects/qa/site/checklist_items.json"
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PROJECTS_QA_SITE_CHECKLIST_ITEM,
		payload: request
	};
};

export const GET_PROJECTS_QA_SITE_CHECKLIST_ITEM = "get_projects_qa_site_checklist_item";
export const getProjectsQaSiteChecklistItem = (id) => {
	const url = `/projects/qa/site/checklist_items/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECTS_QA_SITE_CHECKLIST_ITEM,
		payload: request
	};
};

export const UPDATE_PROJECTS_QA_SITE_CHECKLIST_ITEM = "update_projects_qa_site_checklist_item";
export const updateProjectsQaSiteChecklistItem = (id,data) => {
	const url = `/projects/qa/site/checklist_items/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PROJECTS_QA_SITE_CHECKLIST_ITEM,
		payload: request
	};
};

// export const DESTROY_PROJECTS_QA_SITE_CHECKLIST_ITEM = "destroy_projects_qa_site_checklist_item";
// export const destroyProjectsQaSiteChecklistItem = (id) => {
// 	const url = `/projects/qa/site/checklist_items/${id}.json`
// 	const request = axios({method: "delete",url: url});
// 	return {
// 		type: DESTROY_PROJECTS_QA_SITE_CHECKLIST_ITEM,
// 		payload: request
// 	};
// };
