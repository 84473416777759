import axios from "axios";
import jsonFormData from "json-form-data";

const prepareData = (data) => {
  return jsonFormData({ project_qa_site_surveillance: data }, { showLeafArrayIndexes: true });
};

// Plural Actions
export const GET_PROJECT_QA_SITE_SURVEILLANCES = "get_project_qa_site_surveillances";
export const getProjectQaSiteSurveillances = (project_id) => {
  const url = `/project/${project_id}/qa/site/surveillances.json`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_QA_SITE_SURVEILLANCES,
    payload: request,
  };
};

// Singular Actions
export const CREATE_PROJECT_QA_SITE_SURVEILLANCE = "create_project_qa_site_surveillance";
export const createProjectQaSiteSurveillance = (project_id, data, notify) => {
  let url = `/project/${project_id}/qa/site/surveillances.json`;
  if (notify) {
    url += "?notify";
  }

  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_PROJECT_QA_SITE_SURVEILLANCE,
    payload: request,
  };
};

export const GET_PROJECT_QA_SITE_SURVEILLANCE = "get_project_qa_site_surveillance";
export const getProjectQaSiteSurveillance = (project_id, id) => {
  const url = `/project/${project_id}/qa/site/surveillances/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_QA_SITE_SURVEILLANCE,
    payload: request,
  };
};

export const UPDATE_PROJECT_QA_SITE_SURVEILLANCE = "update_project_qa_site_surveillance";
export const updateProjectQaSiteSurveillance = (project_id, id, data, notify) => {
  let url = `/project/${project_id}/qa/site/surveillances/${id}.json`;
  if (notify) {
    url += "?notify";
  }

  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_PROJECT_QA_SITE_SURVEILLANCE,
    payload: request,
  };
};

export const DESTROY_PROJECT_QA_SITE_SURVEILLANCE = "destroy_project_qa_site_surveillance";
export const destroyProjectQaSiteSurveillance = (project_id, id) => {
  const url = `/project/${project_id}/qa/site/surveillances/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_PROJECT_QA_SITE_SURVEILLANCE,
    payload: request,
  };
};
