import React from 'react';

const DrawingOfficeDrawingTypesIndex = React.lazy(() => import("./index"))
const NewDrawingOfficeDrawingTypes = React.lazy(() => import("./new"))
const EditDrawingOfficeDrawingTypes = React.lazy(() => import("./edit"))

export default[
	{path:'/drawing_office/drawing_types/:id/edit',component: EditDrawingOfficeDrawingTypes,name:'Edit DrawingOfficeDrawingType'},
	{path:'/drawing_office/drawing_types/new',component: NewDrawingOfficeDrawingTypes,name:'New DrawingOfficeDrawingType'},  
	// {path:'/drawing_office/drawing_types',component: DrawingOfficeDrawingTypesIndex,name:'DrawingOfficeDrawingTypes'}, 
]

// import DrawingOfficeDrawingTypesRoutes from "modules/drawing_office/drawing_types/web/routes"
// routes = _.concat(routes,DrawingOfficeDrawingTypesRoutes)
