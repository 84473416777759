import React from 'react';

const PaintLocationsIndex = React.lazy(() => import("./index"))
const NewPaintLocations = React.lazy(() => import("./new"))
const EditPaintLocations = React.lazy(() => import("./edit"))

export default[
	{path:'/paint/locations/:id/edit',component: EditPaintLocations,name:'Edit PaintLocation'},
	{path:'/paint/locations/new',component: NewPaintLocations,name:'New PaintLocation'},  
	{path:'/paint/locations',component: PaintLocationsIndex,name:'PaintLocations'}, 
]

// import PaintLocationsRoutes from "modules/paint/locations/web/routes"
// routes = _.concat(routes,PaintLocationsRoutes)
