import axios from "axios";

const prepareData = (data) => {
  return { position: data };
};

// Plural Actions
export const GET_POSITIONS = "get_positions";
export const getPositions = () => {
  const url = "/positions.json";
  const request = axios.get(url);
  return {
    type: GET_POSITIONS,
    payload: request,
  };
};

// Singular Actions
export const CREATE_POSITION = "create_position";
export const createPosition = (data) => {
  const url = "/positions.json";
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_POSITION,
    payload: request,
  };
};

export const GET_POSITION = "get_position";
export const getPosition = (id) => {
  const url = `/positions/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_POSITION,
    payload: request,
  };
};

export const UPDATE_POSITION = "update_position";
export const updatePosition = (id, data) => {
  const url = `/positions/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_POSITION,
    payload: request,
  };
};

export const DESTROY_POSITION = "destroy_position";
export const destroyPosition = (id) => {
  const url = `/positions/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_POSITION,
    payload: request,
    meta: { id: id },
  };
};
