import axios from "axios";

const prepareData = (data) => {return {project_qa_welding_inspections_welding_standard:data}}

// Plural Actions
export const GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STANDARDS = "get_project_qa_welding_inspections_welding_standards";
export const getProjectQaWeldingInspectionsWeldingStandards = () => {
	const url = `/project/qa/welding_inspections/welding_standards.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STANDARDS,
		payload: request
	};
};


// Singular Actions
export const CREATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STANDARD = "create_project_qa_welding_inspections_welding_standard";
export const createProjectQaWeldingInspectionsWeldingStandard = (data) => {
	const url = `/project/qa/welding_inspections/welding_standards.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STANDARD,
		payload: request
	};
};

export const GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STANDARD = "get_project_qa_welding_inspections_welding_standard";
export const getProjectQaWeldingInspectionsWeldingStandard = (id) => {
	const url = `/project/qa/welding_inspections/welding_standards/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STANDARD,
		payload: request
	};
};

export const UPDATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STANDARD = "update_project_qa_welding_inspections_welding_standard";
export const updateProjectQaWeldingInspectionsWeldingStandard = (id,data) => {
	const url = `/project/qa/welding_inspections/welding_standards/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STANDARD,
		payload: request
	};
};

export const DESTROY_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STANDARD = "destroy_project_qa_welding_inspections_welding_standard";
export const destroyProjectQaWeldingInspectionsWeldingStandard = (id) => {
	const url = `/project/qa/welding_inspections/welding_standards/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STANDARD,
		payload: request
	};
};
