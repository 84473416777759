import React from 'react';

const ProjectQaWeldingInspectionsFilletSizesIndex = React.lazy(() => import("./index"))
const NewProjectQaWeldingInspectionsFilletSizes = React.lazy(() => import("./new"))
const EditProjectQaWeldingInspectionsFilletSizes = React.lazy(() => import("./edit"))

export default[
	{path:'/project/qa/welding_inspections/fillet_sizes/:id/edit',component: EditProjectQaWeldingInspectionsFilletSizes,name:'Edit ProjectQaWeldingInspectionsFilletSize'},
	{path:'/project/qa/welding_inspections/fillet_sizes/new',component: NewProjectQaWeldingInspectionsFilletSizes,name:'New ProjectQaWeldingInspectionsFilletSize'},  
	{path:'/project/qa/welding_inspections/fillet_sizes',component: ProjectQaWeldingInspectionsFilletSizesIndex,name:'ProjectQaWeldingInspectionsFilletSizes'}, 
]

// import ProjectQaWeldingInspectionsFilletSizesRoutes from "modules/project/qa/welding_inspections/fillet_sizes/web/routes"
// routes = _.concat(routes,ProjectQaWeldingInspectionsFilletSizesRoutes)
