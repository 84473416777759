export const hasNewActionItems = (action_items_array) => {
  let actionItems = _.compact(action_items_array); // remove nulls/undefined which will exist because of keyed array
  actionItems = _.filter(actionItems, (ai) => !_.get(ai, "_destroy")); // ignores items marked for deletion

  return _.isNotEmpty(actionItems);
};

export const hasNewOrUpdatedActionItems = (original_action_items,new_action_items) => {
  const compacted_original_action_items = _.compact(original_action_items);
  const compacted_new_action_items = _.compact(new_action_items);

  const items_to_remove = _.map(
    _.filter(compacted_new_action_items, (ai) => ai?._destroy),
    "id"
  );


  const filered_original_action_items = _.filter(compacted_original_action_items, (ai) => !_.includes(items_to_remove, ai.id));
  const filered_new_action_items = _.filter(compacted_new_action_items, (ai) => !_.includes(items_to_remove, ai.id));

  return !_.isEqual(filered_original_action_items, filered_new_action_items);
}