import React from "react";

const TrainingNzqaNumbersIndex = React.lazy(() => import("./index"));
const NewTrainingNzqaNumbers = React.lazy(() => import("./new"));
const EditTrainingNzqaNumbers = React.lazy(() => import("./edit"));

export default [
  { path: "/training/nzqa_numbers/:id/edit", component: EditTrainingNzqaNumbers, name: "Edit TrainingNzqaNumber" },
  { path: "/training/nzqa_numbers/new", component: NewTrainingNzqaNumbers, name: "New TrainingNzqaNumber" },
  { path: "/training/nzqa_numbers", component: TrainingNzqaNumbersIndex, name: "TrainingNzqaNumbers" },
];

// import TrainingNzqaNumbersRoutes from "modules/training/nzqa_numbers/web/routes"
// routes = _.concat(routes,TrainingNzqaNumbersRoutes)
