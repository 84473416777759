import { List, ListItem } from "framework7-react";
import React, { Fragment } from "react";

const SmartSelect = (props) => {
  const {
    label,
    placeholder,
    options,
    openIn,
    searchbar,
    searchbarPlaceholder,
    closeOnSelect,
    onChange,
    onBlur,
    multi,
    labelName,
    valueName,
    value,
  } = props;


  const renderOption = (option, i) => {
    if (option.options) {
      return (
        <optgroup key={i} label={option.label}>
          {_.map(option.options, (o) => this.renderOption(o, `${option.label}-${i}`))}
        </optgroup>
      );
    } else {
      return (
        <option value={option[valueName]} key={i} disabled={option.isDisabled}>
          {option[labelName]}
        </option>
      );
    }
  };

  return (
    <List>
      {_.isEmpty(options) && <ListItem>No {label} options</ListItem>}
      {_.isNotEmpty(options) && (
        <ListItem
          title={label}
          smartSelect
          smartSelectParams={{
            openIn: openIn,
            searchbar: searchbar,
            searchbarPlaceholder: searchbarPlaceholder,
            closeOnSelect: closeOnSelect,
            on: {
              smartSelectClosed: onBlur,
            },
          }}
        >
          <select multiple={multi} name={name} onChange={onChange} value={value}>
            {placeholder && <option disabled={value}>{placeholder}</option>}
            {_.map(options, (o, i) => {
              return renderOption(o, i);
            })}
          </select>
        </ListItem>
      )}
    </List>
  );
};

export default SmartSelect;

SmartSelect.defaultProps = {
  openIn: "popup",
  searchbar: false,
  searchbarPlaceholder: null,
  closeOnSelect: true,
  label: "",
  multi: false,
  valueName: "value",
  labelName: "label",
};
