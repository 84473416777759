import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Table, Button, Card, CardHeader, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import _ from "lodash";

import DefaultDataGrid from "modules/5_view_helpers/web/DefaultDataGrid";
import columns from "./columns";

// Helpers
import NotAuthorized from "modules/5_view_helpers/web/NotAuthorized";
import HasRole from "modules/5_view_helpers/HasRole";

// Actions
import { getAddressBookCompanies } from "../actions";

function mapStateToProps(state) {
  return {
    address_book_companies: state.address_book_companies,
  };
}

const AddressBookCompaniesIndex = (props) => {
  useEffect(() => {
    props.getAddressBookCompanies();
  }, []);

  const { address_book_companies } = props;

  const readRole = HasRole({ resource_type: "AddressBook::Company", name: ["read", "moderator"] });
  const createRole = HasRole({ resource_type: "AddressBook::Company", name: ["create", "moderator"] });
  const updateRole = HasRole({ resource_type: "AddressBook::Company", name: ["update", "moderator"] });

  if (!readRole) {
    return <NotAuthorized />;
  } else {
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <h3>Companies</h3>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
          {createRole && (
            <Row>
              <Col>
                <Link to={`/address_book/companies/new`}>
                  <Button color="primary" size="md">
                    <i className="fa fa-plus"> Create New Company</i>
                  </Button>
                </Link>
              </Col>
            </Row>
          )}

          <Row className="mt-2">
            <Col>
              <DefaultDataGrid columns={columns(updateRole)} data={address_book_companies} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
};
export default connect(mapStateToProps, { getAddressBookCompanies })(AddressBookCompaniesIndex);
