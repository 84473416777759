import React from 'react';

import HireagesCategoryRoutes from "../categories/web/routes"
import HireagesExternalRoutes from "../externals/web/routes"

let routes = []
routes = _.concat(routes, HireagesCategoryRoutes);
routes = _.concat(routes, HireagesExternalRoutes)


export default routes

// import HireageRoutes from "modules/hireages/web/routes"
// routes = _.concat(routes,HireageRoutes)
