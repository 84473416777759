import {
  GET_MW_NAMES,
} from "./actions";

export function MWNamesReducer(state = [], action) {
  switch (action.type) {
    case `${GET_MW_NAMES}_FULFILLED`: {
      return action.payload.data;
    }
    default:
      return state;
  }
}

