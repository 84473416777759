import React from 'react';

const SiteQAIndex = React.lazy(() => import("./index"))

export default[
	{path:'/project/:project_id/site_qa',component: SiteQAIndex,name:'Site QA Index'},
]

// import ProjectsRoutes from "modules/projects/web/routes"
// routes = _.concat(routes,ProjectsRoutes)
