import React from "react";

const PaintTreatmentGradesIndex = React.lazy(() => import("./index"));
const NewPaintTreatmentGrades = React.lazy(() => import("./new"));
const EditPaintTreatmentGrades = React.lazy(() => import("./edit"));

export default [
  { path: "/paint/treatment_grades/:id/edit", component: EditPaintTreatmentGrades, name: "Edit PaintTreatmentGrade" },
  { path: "/paint/treatment_grades/new", component: NewPaintTreatmentGrades, name: "New PaintTreatmentGrade" },
  { path: "/paint/treatment_grades", component: PaintTreatmentGradesIndex, name: "PaintTreatmentGrades" },
];

// import PaintTreatmentGradesRoutes from "modules/paint/treatment_grades/web/routes"
// routes = _.concat(routes,PaintTreatmentGradesRoutes)
