import React from 'react';

const HireagesExternal = React.lazy(() => import("./index"))
const NewHireagesExternal = React.lazy(() => import("./new"))
const EditHireagesExternal = React.lazy(() => import("./edit"))

export default[
	{path:'/hireages/externals/:id/edit',component: EditHireagesExternal,name:'Edit HireagesExternal'},
	{path:'/hireages/externals/new',component: NewHireagesExternal,name:'New HireagesExternal'},  
	{path:'/hireages/externals',component: HireagesExternal,name:'HireagesExternal'}, 
]

// import HireagesExternalRoutes from "modules/hireages/externals/web/routes"
// routes = _.concat(routes,HireagesExternalRoutes)
