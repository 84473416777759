import axios from "axios";

const prepareData = (data) => {return {project_qa_welding_inspections_surface_condition:data}}

// Plural Actions
export const GET_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITIONS = "get_project_qa_welding_inspections_surface_conditions";
export const getProjectQaWeldingInspectionsSurfaceConditions = () => {
	const url = `/project/qa/welding_inspections/surface_conditions.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITIONS,
		payload: request
	};
};


// Singular Actions
export const CREATE_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITION = "create_project_qa_welding_inspections_surface_condition";
export const createProjectQaWeldingInspectionsSurfaceCondition = (data) => {
	const url = `/project/qa/welding_inspections/surface_conditions.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITION,
		payload: request
	};
};

export const GET_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITION = "get_project_qa_welding_inspections_surface_condition";
export const getProjectQaWeldingInspectionsSurfaceCondition = (id) => {
	const url = `/project/qa/welding_inspections/surface_conditions/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITION,
		payload: request
	};
};

export const UPDATE_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITION = "update_project_qa_welding_inspections_surface_condition";
export const updateProjectQaWeldingInspectionsSurfaceCondition = (id,data) => {
	const url = `/project/qa/welding_inspections/surface_conditions/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITION,
		payload: request
	};
};

export const DESTROY_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITION = "destroy_project_qa_welding_inspections_surface_condition";
export const destroyProjectQaWeldingInspectionsSurfaceCondition = (id) => {
	const url = `/project/qa/welding_inspections/surface_conditions/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITION,
		payload: request
	};
};
