import React, { Fragment } from "react";
import { Button, ButtonGroup } from "reactstrap";

const RadioButtons = (props) => {
  const {
    options,
    input: { value, onChange, name },
    meta: { touched, error, warning },
    label,
    errorName,
    disabled,
  } = props;
  return (
    <Fragment>
      <ButtonGroup>
        {_.map(options, (option, i) => {
          return (
            <Button key={i} disabled={disabled} color="primary" onClick={() => onChange(option.value)} active={value == option.value}>
              {option.label}
            </Button>
          );
        })}
      </ButtonGroup>
      {props.children}
        <div>
          {touched &&
            ((error && (
              <span className="text-danger">
                {label ? label : errorName ? errorName : name} {error}
              </span>
            )) ||
              (warning && <span className="text-warning">{warning}</span>))}
        </div>
    </Fragment>
  );
};

export default RadioButtons;
