import React from 'react';

const ProductionJobBoard = React.lazy(() => import("./index"))
const SelectProductionJobBoard = React.lazy(() => import("./select"));
const NewProductionJobBoard = React.lazy(() => import("./new"))
const EditProductionJobBoard = React.lazy(() => import("./edit"))
const ShowProductionJobBoard = React.lazy(() => import("./show"))

export default [
  { path: "/production/job_boards/:id/show", component: ShowProductionJobBoard, name: "Show ProductionJobBoard" },
  { path: "/production/job_boards/:id/edit", component: EditProductionJobBoard, name: "Edit ProductionJobBoard" },
  { path: "/production/job_boards/select", component: SelectProductionJobBoard, name: "Select ProductionJobBoard" },
  { path: "/production/job_boards/new", component: NewProductionJobBoard, name: "New ProductionJobBoard" },
  { path: "/production/job_boards", component: ProductionJobBoard, name: "ProductionJobBoard" },
];

// import ProductionJobBoardRoutes from "modules/production/job_boards/web/routes"
// routes = _.concat(routes,ProductionJobBoardRoutes)
