import React from 'react';

const Departments = React.lazy(() => import("./index"))
const NewDepartments = React.lazy(() => import("./new"))
const EditDepartments = React.lazy(() => import("./edit"))

export default[
	{path:'/departments/:id/edit',component: EditDepartments,name:'Edit Department'},
	{path:'/departments/new',component: NewDepartments,name:'New Department'},  
	{path:'/departments',component: Departments,name:'Departments'}, 
]

// import DepartmentsRoutes from "modules/departments/web/routes"
// routes = _.concat(routes,DepartmentsRoutes)
