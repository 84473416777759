import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Page, Fab, List, ListItem, BlockTitle, Icon, Preloader } from "framework7-react";

import _ from "lodash";

// Helpers
import HasRole from "modules/5_view_helpers/HasRole";
import AppNavbar from "modules/1_layout/mobile/AppNavbar";

import { setCurrentProject } from "modules/current_user/actions";

// Actions
import { getStoreBookoutItems } from "../actions";
import NotAuthorized from "modules/5_view_helpers/mobile/NotAuthorized";
import { getMWJobs } from "modules/moneyworks/jobs/actions";
import { getMWProducts } from "modules/moneyworks/products/actions";

function mapStateToProps(state) {
  return {
    projects: state.projects,
    current_user: state.current_user,
    store_bookout_items: state.store_bookout_items,
    mw_products: state.mw_products,
    mw_jobs: state.mw_jobs
  };
}

const StoreBookoutItemsIndex = (props) => {
  const [loading, setLoading] = useState(false);
  const { store_bookout_items } = props;

  const readRole = HasRole(
    {
      resource_type: "Store::BookoutItem",
      name: ["read", "moderator"],
    },
    true
  );

  useEffect(() => {
    if (readRole) {
      setLoading(true);
      props.getStoreBookoutItems().then(() => {
        setLoading(false);
      });
    }
  }, []);

  const createRole = HasRole(
    {
      resource_type: "Store::BookoutItem",
      name: ["create", "moderator"],
    },
    true
  );

  const updateRole = HasRole(
    {
      resource_type: "Store::BookoutItem",
      name: ["update", "moderator"],
    },
    true
  );

  if (!readRole) {
    return <NotAuthorized />;
  }
  return (
    <Page>
      <AppNavbar title="Store - Bookout Items" />

      {loading && <Preloader />}
      {!loading && (
        <Fragment>
          <BlockTitle>Last 10 booked out items</BlockTitle>
          <List>
            {_.map(store_bookout_items, (item, i) => (
              <ListItem key={i} title={`${item?.job} - ${item.memo} - ${item.qty}No`} />
            ))}
          </List>
        </Fragment>
      )}

      {createRole && (
        <Fab position="right-bottom" slot="fixed" text="New" href="/store/bookout_items/new">
          <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
        </Fab>
      )}
    </Page>
  );
};
export default connect(mapStateToProps, { getStoreBookoutItems, setCurrentProject, getMWJobs, getMWProducts })(StoreBookoutItemsIndex);
