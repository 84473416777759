import React, { Fragment } from "react";
import { Label, Input, Button, InputGroupAddon, InputGroup } from "reactstrap";

const TimeField = (props) => {
  const {
    input: { value, onChange, onBlur, name },
    meta: { touched, error, warning },
    label,
    type,
    placeholder,
    readonly,
    disabled,
    bgColor,
    className,
    errorName,
    showNow,
    ...rest
  } = props;

  return (
    <Fragment>
      {label && <Label>{label}</Label>}
      <InputGroup>
        <Input
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          readOnly={readonly}
          disabled={disabled}
          style={{ backgroundColor: bgColor }}
          className={className}
          name={name}
          value={value}
          type="time"
          {...rest}
        />

        {showNow && _.isEmpty(value) && (<InputGroupAddon addonType="append">
          <Button
            color="success"
            onClick={() => {
              onChange(moment().format("HH:mm"));
            }}
          >
            Now
          </Button>
        </InputGroupAddon>)}

        <InputGroupAddon addonType="append">
          <Button
            color="danger"
            onClick={() => {
              onChange(null);
            }}
          >
            Clear
          </Button>
        </InputGroupAddon>
      </InputGroup>
      {touched &&
        ((error && (
          <span className="text-danger">
            {label ? label : errorName ? errorName : name} {error}
          </span>
        )) ||
          (warning && <span className="text-warning">{warning}</span>))}
    </Fragment>
  );
};

export default TimeField;
