import React, { useState, Fragment } from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import { Button, Popup, Page, Navbar, NavRight, Link, Block, List, ListItem, AccordionContent } from "framework7-react";
import { Label } from "reactstrap";

const ListArray = (props) => {
  const [popupOpened, setPopupOpened] = useState(false);
  const { label, fields, filterAddProps, filterBy } = props;

  const closePopup = () => {
    const {
      meta: { error },
      handleSubmit,
    } = props;
    if (_.isEmpty(error)) {
      setPopupOpened(false);
    } else {
      handleSubmit();
    }
  };

  let fieldCount = 0;
  fields.forEach((f, i) => {
    const thisField = fields.value[i];
    const markedForDestruction = _.get(thisField, "_destroy");
    let inFilter = true;
    if (filterBy) {
      inFilter = _.isEqual(thisField?.fieldName, filterBy);
    }
    if (inFilter && !markedForDestruction) {
      fieldCount++;
    }
  });

  return (
    <div>
      <Button
        fill
        onClick={() => {
          setPopupOpened(true);
        }}
      >
        {fieldCount > 0 ? `${fieldCount} No - ` : ""} {label}
      </Button>

      <Popup className="demo-popup" opened={popupOpened}>
        <ListPopupPage closePopup={closePopup} {...props} />
      </Popup>
    </div>
  );
};

export default ListArray;

ListArray.defaultProps = {
  buttonLabel: "Add Item",
  addProps: {},
  showHeaders: true,
};

const ListPopupPage = (props) => {
  const { fields, label, buttonLabel, addProps, itemLabel, filterBy, columns, meta } = props;

  const removeItem = (i) => {
    const thisField = fields.value[i];
    if (thisField?.id) {
      fields.update(i, { id: thisField.id, _destroy: "1" });
    } else {
      fields.remove(i);
    }
  };

  let fieldNumber = 0;

  return (
    <Page>
      <Navbar title={label}>
        <NavRight>
          <Link
            onClick={() => {
              props.closePopup(false);
            }}
          >
            Close
          </Link>
        </NavRight>
      </Navbar>

      <List accordionList>
        {fields.map((field, i) => {
          const thisField = fields.value[i];
          const markedForDestruction = _.get(thisField, "_destroy");
          const showItem = _.isEmpty(filterBy) || _.isEqual(thisField?.fieldName, filterBy);

          if (!markedForDestruction && showItem) {
            fieldNumber++;
            return (
              <Item
                field={field}
                key={i}
                i={i}
                removeItem={() => {
                  removeItem(i);
                }}
                fieldNumber={fieldNumber}
                meta={meta}
                columns={columns}
                itemLabel={itemLabel}
                slot="list"
              />
            );
          }
        })}
      </List>

      <Block>
        <Button
          small
          fill
          onClick={() => {
            fields.push({ ...addProps });
          }}
        >
          {buttonLabel}
        </Button>
      </Block>
    </Page>
  );
};

const Item = (props) => {
  const {
    field,
    columns,
    i,
    removeItem,
    fieldNumber,
    itemLabel,
    meta: { touched, error },
  } = props;

  const findErrorForThisField = _.isArray(error) ? error[i] : null;

  return (
    <ListItem
      accordionItem
      title={`${itemLabel} ${fieldNumber}`}
      badge={touched && _.isNotEmpty(findErrorForThisField) ? "Error" : null}
      badgeColor="red"
    >
      <AccordionContent>
        <List>
          {_.map(columns, (column, n) => {
            const { name, fieldArray, ...rest } = column;
            const fieldName = `${field}.${column.name}`;
            return fieldArray ? (
              <Block className="padding">
                <Label>{column.label}</Label>
                <FieldArray key={n} name={fieldName} {...rest} />
              </Block>
            ) : (
              <Field key={n} name={fieldName} {...rest} />
            );
          })}
        </List>
        <Block>
          <Button small fill color="red" onClick={removeItem}>
            Remove
          </Button>
        </Block>
      </AccordionContent>
    </ListItem>
  );
};
