import React, { Component } from "react";
import { connect } from "react-redux";

import { Page, Navbar } from "framework7-react";

// Helpers
import HasRole from "modules/5_view_helpers/HasRole";
import NotAuthorized from "modules/5_view_helpers/mobile/NotAuthorized";

import { createProjectQaPaintInspection } from "../actions";
import { getProjectAssemblies } from "modules/project/assemblies/actions";
import ProjectQaPaintInspectionForm from "./form";
import arrayMutators from "final-form-arrays";

// Persisted Stuff
import Form from "modules/4_final_form/PersistedForm";
import { clearPersisted } from "modules/4_final_form/PersistDecorator";
import { persistedName } from "./vairables.js";

function mapStateToProps(state, props) {
  const {
    f7route: {
      params: { project_id },
      query: { selectedGUID },
    },
  } = props;

  let project_assembly = null;
  if (selectedGUID) {
    project_assembly = _.find(state.project_assemblies, (pa) => pa.guid == selectedGUID);
  }

  return {
    paint_locations: state.paint_locations,
    project: state.projects[project_id],
    pending: state.pending,
    project_assembly: project_assembly,
  };
}

class NewProjectQaPaintInspection extends Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.successfullySubmitted = this.successfullySubmitted.bind(this);
    const {
      f7route: {
        params: { project_id },
        query: { selectedGUID },
      },
    } = this.props;

    const createRole = HasRole(
      {
        resource_type: "Project::Qa::ActionItem",
        name: ["create", "moderator"],
      },
      true
    );

    const returnUrl = `/project/qa/paint_inspections`;

    // If the assembly isnt selected then change to the guid in the url
    if (selectedGUID) {
      props.getProjectAssemblies(project_id, selectedGUID);
    }

    this.state = {
      returnUrl: returnUrl,
      createRole: createRole,
    };
  }

  submitForm(values) {
    const {
      f7router: { app },
      project_id,
    } = this.props;

    this.preloader = app.preloader;
    this.preloader.show();
    const createRequest = this.props.createProjectQaPaintInspection(project_id, values, true);
    createRequest.then(this.successfullySubmitted);
  }

  successfullySubmitted() {
    const {
      f7router,
      f7router: { app },
    } = this.props;

    const { returnUrl } = this.state;

    this.preloader.hide();
    clearPersisted(persistedName).then(() => {
      app.toast
        .create({
          icon: '<i class="material-icons">check</i>',
          text: "Created New Paint inspection!",
          position: "center",
          closeTimeout: 2000,
          on: {
            opened: () => {
              f7router.navigate(returnUrl);
            },
          },
        })
        .open();
    });
  }

  render() {
    const { history, project, project_assembly, paint_locations } = this.props;
    const { returnUrl, createRole } = this.state;
    const findDefaultPaintLocation = _.find(paint_locations, (pl) => pl.default);

    if (!createRole) {
      return <NotAuthorized />;
    }

    return (
      <Page>
        <Navbar title="New Paint Inspection" backLink />
        <Form
          persist={{ name: persistedName }}
          component={ProjectQaPaintInspectionForm}
          onSubmit={this.submitForm}
          formLabel="Create"
          mutators={{
            ...arrayMutators,
          }}
          initialValues={{ assembly: project_assembly, use_dft_from_previous: true, location_id: findDefaultPaintLocation?.id }}
          {...this.props}
        />
      </Page>
    );
  }
}
export default connect(mapStateToProps, { createProjectQaPaintInspection, getProjectAssemblies })(NewProjectQaPaintInspection);
