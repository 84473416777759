import React from "react";

const EmployeeTrainingRequirementsIndex = React.lazy(() => import("./index"));
const NewEmployeeTrainingRequirements = React.lazy(() => import("./new"));
const EditEmployeeTrainingRequirements = React.lazy(() => import("./edit"));

export default [
  {
    path: "/employee/:employee_id/training_requirements/:id/edit",
    component: EditEmployeeTrainingRequirements,
    name: "Edit EmployeeTrainingRequirement",
  },
  {
    path: "/employee/:employee_id/training_requirements/new",
    component: NewEmployeeTrainingRequirements,
    name: "New EmployeeTrainingRequirement",
  },
  {
    path: "/employee/:employee_id/training_requirements",
    component: EmployeeTrainingRequirementsIndex,
    name: "EmployeeTrainingRequirements",
  },
];
