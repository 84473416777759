import HasRole from "modules/5_view_helpers/HasRole";

const getProductionNav = (production_job_boards, production_pp_boards) => {
  let items = [];

  const ProductionModuleRole = HasRole({ name: "Production_Module_Access" });
  if (ProductionModuleRole) {
    items.push({
      icon: "fa fa-building",
      label: "Home",
      to: `/production`,
      external: true,
    });
  }

  const JobBoardRoles = HasRole({ resource_type: "Production::JobBoard" });

  if (JobBoardRoles) {
    items.push({
      icon: "fa fa-tv",
      label: "Select Job Board",
      to: `/production/job_boards/select`,
      external: true,
    });

    let JobBoardsMenu = {};
    _.set(JobBoardsMenu, "icon", "fa fa-tv");
    _.set(JobBoardsMenu, "label", "Job Boards");

    _.set(JobBoardsMenu, "content", []);
    let content = _.get(JobBoardsMenu, "content", []);
    _.each(production_job_boards, (jb) => {
      content.push({ label: jb.name, to: `/production/job_boards/${jb.id}/show`, external: true });
    });

    items.push(JobBoardsMenu);
  }

  const PPBoardRoles = HasRole({ resource_type: "Production::PpBoard" });
  if (PPBoardRoles) {
    let PPBoardsMenu = {};
    _.set(PPBoardsMenu, "icon", "fa fa-table");
    _.set(PPBoardsMenu, "label", "PP Boards");

    _.set(PPBoardsMenu, "content", []);
    let content = _.get(PPBoardsMenu, "content", []);
    _.each(production_pp_boards, (pp) => {
      content.push({
        label: pp.name,
        to: `/production/pp_boards/${pp.id}/show`,
        external: true,
      });
    });

    items.push(PPBoardsMenu);
  }

  items.push({
    icon: "fa fa-car",
    label: "Transport Boards",
    to: `/production/transport_boards/show`,
    external: true,
  });

  items.push({
    icon: "fa fa-paint-brush",
    label: "Paint Board",
    to: `/production/paint_boards/show`,
    external: true,
  });

  if (JobBoardRoles) {
    items.push({
      icon: "fa fa-calendar",
      label: "Schedule",
      to: `/production/schedule`,
      external: true,
    });
  }
  const SettingsRoles = HasRole({ category: "Production" });
  if (SettingsRoles) {
    let settingsMenu = {};
    _.set(settingsMenu, "icon", "fa fa-cog");
    _.set(settingsMenu, "label", "Settings");

    _.set(settingsMenu, "content", []);
    let content = _.get(settingsMenu, "content", []);

    if (JobBoardRoles) {
      content.push({ label: "Job Boards", to: "/production/job_boards", external: true });
    }
    if (PPBoardRoles) {
      content.push({ label: "PP Boards", to: "/production/pp_boards", external: true });
    }

    items.push(settingsMenu);
  }

  items.push({
    icon: "fa fa-arrow-left",
    label: "Back To Lion",
    external: true,
    to: `/`,
  });

  return items;
};

export default getProductionNav;
