import axios from "axios";

const prepareData = (data) => {
  return { project: data };
};

// Plural Actions
export const GET_PROJECTS = "get_projects";
export const getProjects = (simpleOnly = false) => {
  let url = "/projects.json";
  if (simpleOnly) {
    url += "?simpleOnly=true";
  }

  const request = axios.get(url);
  return {
    type: GET_PROJECTS,
    payload: request,
  };
};

// Singular Actions
export const CREATE_PROJECT = "create_project";
export const createProject = (data) => {
  const url = "/projects.json";
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_PROJECT,
    payload: request,
  };
};

export const GET_PROJECT = "get_project";
export const getProject = (id) => {
  const url = `/projects/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT,
    payload: request,
  };
};

export const UPDATE_PROJECT = "update_project";
export const updateProject = (id, data) => {
  const url = `/projects/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_PROJECT,
    payload: request,
  };
};

export const DESTROY_PROJECT = "destroy_project";
export const destroyProject = (id) => {
  const url = `/projects/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_PROJECT,
    payload: request,
    meta: { id: id },
  };
};
