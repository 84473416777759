import axios from "axios";

// Plural Actions
export const GET_PRODUCTION_STRUMIS_WORKSTATIONS = "get_production_strumis_workstations";
export const getProductionStrumisWorkstations = () => {
  const url = "/production/strumis/workstations.json";
  const request = axios.get(url);
  return {
    type: GET_PRODUCTION_STRUMIS_WORKSTATIONS,
    payload: request,
  };
};

