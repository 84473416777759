import axios from "axios";

const prepareData = (data) => {
  return { project_area: data };
};

// Plural Actions
export const GET_ALL_PROJECT_AREAS = "get_all_project_areas";
export const getAllProjectAreas = () => {
  const url = `/projects/all_areas.json`;
  const request = axios.get(url);
  return {
    type: GET_ALL_PROJECT_AREAS,
    payload: request,
  };
};


// Plural Actions
export const GET_PROJECT_AREAS = "get_project_areas";
export const getProjectAreas = (project_id) => {
  const url = `/project/${project_id}/areas.json`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_AREAS,
    payload: request,
  };
};

// Singular Actions
export const CREATE_PROJECT_AREA = "create_project_area";
export const createProjectArea = (project_id, data) => {
  const url = `/project/${project_id}/areas.json`;
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_PROJECT_AREA,
    payload: request,
  };
};

export const GET_PROJECT_AREA = "get_project_area";
export const getProjectArea = (project_id, id) => {
  const url = `/project/${project_id}/areas/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_AREA,
    payload: request,
  };
};

export const UPDATE_PROJECT_AREA = "update_project_area";
export const updateProjectArea = (project_id, id, data) => {
  const url = `/project/${project_id}/areas/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_PROJECT_AREA,
    payload: request,
  };
};

export const DESTROY_PROJECT_AREA = "destroy_project_area";
export const destroyProjectArea = (project_id, id) => {
  const url = `/project/${project_id}/areas/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_PROJECT_AREA,
    payload: request,
    meta: { id: id },
  };
};
