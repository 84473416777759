import React from "react";
import { connect } from "react-redux";

import ReactDOM from "react-dom";
import { toggleRightSidebarOpen } from "modules/3_reducers/web/ThemeOptions";

const mapStateToProps = (state) => ({
  rightSidebarOpen: state.ThemeOptions.rightSidebarOpen,
});

class RightSidebar extends React.Component {
  constructor(props) {
    super(props);
    // Create a div that we'll render the modal into. Because each
    // Modal component has its own element, we can render multiple
    // modal components into the modal container.
    this.el = document.createElement("div");
  }

  componentDidMount() {
    // Append the element into the DOM on mount. We'll render
    // into the modal container element (see the HTML tab).

    const RightSidebarRoot = document.getElementById("RightSidebarRoot");
    RightSidebarRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    let { rightSidebarOpen, toggleRightSidebarOpen } = this.props;

    // Remove the element from the DOM when we unmount
    const RightSidebarRoot = document.getElementById("RightSidebarRoot");
    RightSidebarRoot.removeChild(this.el);
    toggleRightSidebarOpen(!rightSidebarOpen);
  }

  render() {
    const componentToRender = (
      <div className="app-opened-right-sidebar">
        <div className="sidebar-card">
          <div className="sidebar-header">{this.props.header}</div>
          <div className="sidebar-content">{this.props.children}</div>
        </div>
      </div>
    );

    // Use a portal to render the children into the element
    return ReactDOM.createPortal(
      // Any valid React child: JSX, strings, arrays, etc.
      componentToRender,
      // A DOM element
      this.el
    );
  }
}

export default connect(mapStateToProps, { toggleRightSidebarOpen })(RightSidebar);
