import { GET_CURRENT_USER, SET_CURRENT_PROJECT, READ_NOTIFICATION, ADD_NOTIFICATION } from "./actions";

export function CurrentUserReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_CURRENT_USER}_FULFILLED`:
    case `${SET_CURRENT_PROJECT}_FULFILLED`:
    case `${READ_NOTIFICATION}_FULFILLED`:
      return action.payload.data;
    case `${ADD_NOTIFICATION}`: {
      let newState = _.cloneDeep(state);
      newState.notifications.unshift(action.data);
      return newState;
    }
    default:
      return state;
  }
}
