import axios from "axios";

const prepareData = (data) => {return {project_communications_status:data}}

// Plural Actions
export const GET_PROJECT_COMMUNICATIONS_STATUSES = "get_project_communications_statuses";
export const getProjectCommunicationsStatuses = () => {
	const url = `/project/communications/statuses.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_COMMUNICATIONS_STATUSES,
		payload: request
	};
};


// Singular Actions
export const CREATE_PROJECT_COMMUNICATIONS_STATUS = "create_project_communications_status";
export const createProjectCommunicationsStatus = (data) => {
	const url = `/project/communications/statuses.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PROJECT_COMMUNICATIONS_STATUS,
		payload: request
	};
};

export const GET_PROJECT_COMMUNICATIONS_STATUS = "get_project_communications_status";
export const getProjectCommunicationsStatus = (id) => {
	const url = `/project/communications/statuses/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_COMMUNICATIONS_STATUS,
		payload: request
	};
};

export const UPDATE_PROJECT_COMMUNICATIONS_STATUS = "update_project_communications_status";
export const updateProjectCommunicationsStatus = (id,data) => {
	const url = `/project/communications/statuses/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PROJECT_COMMUNICATIONS_STATUS,
		payload: request
	};
};

export const DESTROY_PROJECT_COMMUNICATIONS_STATUS = "destroy_project_communications_status";
export const destroyProjectCommunicationsStatus = (id) => {
	const url = `/project/communications/statuses/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PROJECT_COMMUNICATIONS_STATUS,
		payload: request
	};
};
