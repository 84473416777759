import React, { useEffect } from "react";
import { connect } from 'react-redux'
import { Row,Col,Table,Button,Card,CardHeader,CardBody } from "reactstrap"
import { Link } from 'react-router-dom'
import _ from "lodash"

import DefaultDataGrid from "modules/5_view_helpers/web/DefaultDataGrid"
import columns from "./columns"

// Helpers
import NotAuthorized from "modules/5_view_helpers/web/NotAuthorized"
import HasRole from "modules/5_view_helpers/HasRole"

// Actions
import { getProjectQaWeldingInspectionsViewingConditions } from "../actions"



function mapStateToProps(state) {
	return {
		project_qa_welding_inspections_viewing_conditions:state.project_qa_welding_inspections_viewing_conditions,
	}
}

const ProjectQaWeldingInspectionsViewingConditionsIndex = (props) => {
	useEffect(() => {
		props.getProjectQaWeldingInspectionsViewingConditions()
	},[])

	const { project_qa_welding_inspections_viewing_conditions } = props

	const readRole = HasRole({resource_type:"Project::Qa::WeldingInspections::ViewingCondition",name:["read","moderator"]})
	const createRole = HasRole({resource_type:"Project::Qa::WeldingInspections::ViewingCondition",name:["create","moderator"]})
	const updateRole = HasRole({resource_type:"Project::Qa::WeldingInspections::ViewingCondition",name:["update","moderator"]})

	


	if (!readRole) {
		return <NotAuthorized />;
	}
	else{
		return (
			<Card>
				<CardHeader>
					<Row>
						<Col><h3>Viewing conditions</h3></Col>
					</Row>
				</CardHeader>

				<CardBody>
					
					{createRole && (<Row>
						<Col>
							<Link to={`/project/qa/welding_inspections/viewing_conditions/new`}>
								<Button color="primary" size="md"><i className="fa fa-plus"> Create New Viewing condition</i></Button>
							</Link>
						</Col>
					</Row>)}


					<Row className="mt-2">
						<Col>
							<DefaultDataGrid 
								columns={columns(updateRole)}
								data={project_qa_welding_inspections_viewing_conditions}
							/>
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	}
}
export default connect(mapStateToProps,{getProjectQaWeldingInspectionsViewingConditions})(ProjectQaWeldingInspectionsViewingConditionsIndex)

