import { GET_MW_PRODUCTS } from "./actions";

export function MWProductsReducer(state = [], action) {
  switch (action.type) {
    case `${GET_MW_PRODUCTS}_FULFILLED`: {
      return action.payload.data;
    }
    default:
      return state;
  }
}
