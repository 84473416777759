import OutlookHome from "modules/outlook/Home.jsx";

import NewProjectCommunicationOutlook from "modules/project/communications/outlook/new";
import NewCustomFormRecordsOutlook from "modules/custom_form/records/outlook/new";

let routes = [
  { path: "/custom_form/:custom_form_id/records/new", component: NewCustomFormRecordsOutlook, name: "New CustomFormRecord" },
  { path: "/project/communications/new", component: NewProjectCommunicationOutlook, name: "New Project Communication" },
  { path: "/", component: OutlookHome, name: "Outlook Home" },
];

export default routes;
