import axios from "axios";
import jsonFormData from "json-form-data";


const prepareData = (data) => {
  return jsonFormData({ employee_task: data }, { showLeafArrayIndexes: true });
};


// Plural Actions
export const GET_EMPLOYEE_TASKS = "get_employee_tasks";
export const getEmployeeTasks = (employee_id) => {
	const url = `/employee/${employee_id}/tasks.json`
	const request = axios.get(url);
	return {
		type: GET_EMPLOYEE_TASKS,
		payload: request
	};
};


// Singular Actions
export const CREATE_EMPLOYEE_TASK = "create_employee_task";
export const createEmployeeTask = (employee_id,data) => {
	const url = `/employee/${employee_id}/tasks.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_EMPLOYEE_TASK,
		payload: request
	};
};

export const GET_EMPLOYEE_TASK = "get_employee_task";
export const getEmployeeTask = (employee_id,id) => {
	const url = `/employee/${employee_id}/tasks/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_EMPLOYEE_TASK,
		payload: request
	};
};

export const UPDATE_EMPLOYEE_TASK = "update_employee_task";
export const updateEmployeeTask = (employee_id,id,data) => {
	const url = `/employee/${employee_id}/tasks/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_EMPLOYEE_TASK,
		payload: request
	};
};

export const DESTROY_EMPLOYEE_TASK = "destroy_employee_task";
export const destroyEmployeeTask = (employee_id,id) => {
	const url = `/employee/${employee_id}/tasks/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_EMPLOYEE_TASK,
		payload: request
	};
};
