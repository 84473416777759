import React, { Fragment, useEffect } from "react";

import Interweave from "interweave";

import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import { InputField, CheckboxField, ListArray, SmartSelect, ReactDropzone, required } from "modules/4_final_form/mobile";
import { Block, List, Button, Tabs, Tab } from "framework7-react";

const ChecklistItemsAttributes = (props) => {
  const { fields, sorted_checklist_items, initialValues } = props;

  useEffect(() => {
    const current_checklist_items = fields.value || [];
    const current_checklist_item_ids = _.map(current_checklist_items, "checklist_item_id");

    _.each(sorted_checklist_items, (checklist_item) => {
      // Check if the new sorted checklist item is in the field array array based off its checklist_item_id
      if (!current_checklist_item_ids.includes(checklist_item.id)) {
        fields.push({ checklist_item_id: checklist_item.id });
      }
    });
  }, [sorted_checklist_items, initialValues]);

  return (
    <Fragment>
      {fields.map((field_array_name, index) => {
        const fieldValues = fields?.value[index];
        const findChecklistItem = _.find(sorted_checklist_items, (sci) => sci.id == fieldValues?.checklist_item_id);

        return (
          <Tab id={`tab-${fieldValues?.checklist_item_id}`}>
            <ChecklistItemTab field_array_name={field_array_name} checklist_item={findChecklistItem} {...props} />
          </Tab>
        );
      })}
    </Fragment>
  );
};
export default ChecklistItemsAttributes;

const ChecklistItemTab = (props) => {
  const {
    field_array_name,
    checklist_item,
    project_id,
    project_area_id,
    current_user,
    users,
    project_qa_action_items_statuses,
    handleSubmit,
    isLast,
  } = props;

  let addProps = {
    project_id: project_id,
    project_area_links_attributes: [{ project_area_id: project_area_id }],
    author_id: current_user.id,
    date: moment(),
  };

  return (
    <Block>
      <Interweave content={checklist_item?.description} />

      {checklist_item?.extra_description && (
        <Fragment>
          <hr />
          <Interweave content={checklist_item?.extra_description} />
        </Fragment>
      )}
      <hr />

      <List>
        <Field slot="list" label={checklist_item?.name} component={CheckboxField} type="checkbox" name={`${field_array_name}.toggle_value`} />
        <Field
          slot="list"
          label="Comments"
          placeholder="Enter here..."
          component={InputField}
          name={`${field_array_name}.comments`}
          type="textarea"
        />
      </List>

      <List>
        <FieldArray
          component={ListArray}
          name={`${field_array_name}.action_items_attributes`}
          columns={[
            {
              name: "description",
              label: "Description",
              type: "textarea",
              placeholder: "Type description here...",
              component: InputField,
              validate: required,
            },
            {
              name: "user_links_attributes",
              label: "User/s Responsible",
              component: SmartSelect,
              type: "select",
              multi: true,
              labelName: "label",
              valueName: "value",
              options: _.map(users, (user) => {
                return {
                  label: user.name,
                  value: user.id,
                  user_id: user.id,
                };
              }),
            },
            {
              name: "status_id",
              label: "Status",
              placeholder: "Select status",
              component: SmartSelect,
              type: "select",
              labelName: "label",
              valueName: "value",
              validate: required,
              options: _.map(project_qa_action_items_statuses, (status) => {
                return {
                  label: status.name,
                  value: status.id,
                };
              }),
            },
            {
              name: "attachments_attributes",
              label: "Attachments",
              fieldArray: true,
              component: ReactDropzone,
              array: true,
            },
          ]}
          label="Action Items"
          itemLabel="Action Item"
          buttonLabel="Add Action Item"
          handleSubmit={handleSubmit}
          addProps={addProps}
        />
      </List>
      <FieldArray component={ReactDropzone} name={`${field_array_name}.attachments_attributes`} popupLabel="Photos" popup />

      <hr />
      <Button onClick={handleSubmit} color="green" fill>
        Save
      </Button>
    </Block>
  );
};
