import axios from "axios";

const prepareData = (data) => {return {address_book_company:data}}

// Plural Actions
export const GET_ADDRESS_BOOK_COMPANIES = "get_address_book_companies";
export const getAddressBookCompanies = () => {
	const url = `/address_book/companies.json`
	const request = axios.get(url);
	return {
		type: GET_ADDRESS_BOOK_COMPANIES,
		payload: request
	};
};


// Singular Actions
export const CREATE_ADDRESS_BOOK_COMPANY = "create_address_book_company";
export const createAddressBookCompany = (data) => {
	const url = `/address_book/companies.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_ADDRESS_BOOK_COMPANY,
		payload: request
	};
};

export const GET_ADDRESS_BOOK_COMPANY = "get_address_book_company";
export const getAddressBookCompany = (id) => {
	const url = `/address_book/companies/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_ADDRESS_BOOK_COMPANY,
		payload: request
	};
};

export const UPDATE_ADDRESS_BOOK_COMPANY = "update_address_book_company";
export const updateAddressBookCompany = (id,data) => {
	const url = `/address_book/companies/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_ADDRESS_BOOK_COMPANY,
		payload: request
	};
};

export const DESTROY_ADDRESS_BOOK_COMPANY = "destroy_address_book_company";
export const destroyAddressBookCompany = (id) => {
	const url = `/address_book/companies/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_ADDRESS_BOOK_COMPANY,
		payload: request
	};
};
