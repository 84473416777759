import React from "react";

const ShowPaintBoard = React.lazy(() => import("./show"));

export default [
  {
    path: "/production/paint_boards/show",
    component: ShowPaintBoard,
    name: "Show Paint Board",
  },
];

// import ProductionPaintBoardRoutes from "modules/production/paint_boards/web/routes"
// routes = _.concat(routes,ProductionPaintBoardRoutes)
