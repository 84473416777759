import React, { useEffect } from "react";
import { ListInput, Input } from "framework7-react";

export default function InputField(props) {
  const {
    input: { value, onChange, onBlur, type },
    meta: { touched, error, warning },
    tabIndex,
    listInput,
    postiveOnly,
    convertToUpperCase,
    ...rest
  } = props;

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key == "Enter") {
        e.preventDefault();
        e.target.blur()
      }
    })
  }, [])


  const handleChange = (e) => {
    let newValue = e.target.value;

    if (convertToUpperCase) {
      newValue = newValue.toUpperCase();
    }
    if (postiveOnly && type == "number" && newValue.includes("-")) {
      newValue = Math.abs(newValue);
    }
    onChange(newValue);
  };

  let InputType;
  if (listInput) {
    InputType = ListInput;
  } else {
    InputType = Input;
  }

  return (
    <InputType
      onChange={handleChange}
      onBlur={onBlur}
      value={value}
      errorMessage={touched && error ? error : undefined}
      errorMessageForce={touched && error ? true : false}
      tabindex={tabIndex}
      type={type}
      {...rest}
    />
  );
}

InputField.defaultProps = {
  inlineLabel: false,
  listInput: true,
};
