import axios from "axios";
import jsonFormData from "json-form-data";

const prepareData = (data) => {
  return jsonFormData(
    { project_qa_action_item: data },
    { showLeafArrayIndexes: true }
  );
};

// Plural Actions
export const GET_ALL_PROJECT_QA_ACTION_ITEMS =
  "get_all_project_qa_action_items";
export const getAllProjectQaActionItems = () => {
  const url = `/project/qa/action_items/get_all.json`;
  const request = axios.get(url);
  return {
    type: GET_ALL_PROJECT_QA_ACTION_ITEMS,
    payload: request,
  };
};

// Plural Actions
export const GET_PROJECT_QA_ACTION_ITEMS = "get_project_qa_action_items";
export const getProjectQaActionItems = (project_id) => {
  const url = `/project/${project_id}/qa/action_items.json`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_QA_ACTION_ITEMS,
    payload: request,
  };
};

// Singular Actions
export const CREATE_PROJECT_QA_ACTION_ITEM = "create_project_qa_action_item";
export const createProjectQaActionItem = (project_id, data, notify) => {
  let url = `/project/${project_id}/qa/action_items.json`;
  if (notify) {
    url += "?notify";
  }

  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_PROJECT_QA_ACTION_ITEM,
    payload: request,
  };
};

export const GET_PROJECT_QA_ACTION_ITEM = "get_project_qa_action_item";
export const getProjectQaActionItem = (project_id, id) => {
  const url = `/project/${project_id}/qa/action_items/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_QA_ACTION_ITEM,
    payload: request,
  };
};

export const UPDATE_PROJECT_QA_ACTION_ITEM = "update_project_qa_action_item";
export const updateProjectQaActionItem = (project_id, id, data,notify) => {
  let url = `/project/${project_id}/qa/action_items/${id}.json`;
  if (notify) {
    url += "?notify";
  }

  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_PROJECT_QA_ACTION_ITEM,
    payload: request,
  };
};

export const DESTROY_PROJECT_QA_ACTION_ITEM = "destroy_project_qa_action_item";
export const destroyProjectQaActionItem = (project_id, id) => {
  const url = `/project/${project_id}/qa/action_items/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_PROJECT_QA_ACTION_ITEM,
    payload: request,
  };
};
