import { GET_STORE_BOOKOUT_ITEMS, CREATE_STORE_BOOKOUT_ITEM } from "./actions";

export function StoreBookoutItemsReducer(state = {}, action) {
  switch (action.type) {
    case `${CREATE_STORE_BOOKOUT_ITEM}_FULFILLED`:
    case `${GET_STORE_BOOKOUT_ITEMS}_FULFILLED`: {
      let returnValues = _.mapKeys(action.payload.data, "id");
      returnValues = _.reverse(_.sortBy(returnValues, "id"));
      return returnValues;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { StoreBookoutItemsReducer } from "modules/store/bookout_items/reducer"

// store_bookout_items:StoreBookoutItemsReducer,
