import React, { Component } from "react";
import { connect } from "react-redux";

import ProjectQaActionItemForm from "./form";
import { createProjectQaActionItem } from "../actions";

import { Page, Navbar } from "framework7-react";

// Persisted Stuff
import Form from "modules/4_final_form/PersistedForm";
import { clearPersisted } from "modules/4_final_form/PersistDecorator";
import { persistedName } from "./vairables.js";

// Helpers
import HasRole from "modules/5_view_helpers/HasRole";
import arrayMutators from "final-form-arrays";
import NotAuthorized from "modules/5_view_helpers/mobile/NotAuthorized";

function mapStateToProps(state) {
  return {};
}

class NewProjectQaActionItem extends Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.successfullySubmitted = this.successfullySubmitted.bind(this);

    const createRole = HasRole(
      {
        resource_type: "Project::Qa::ActionItem",
        name: ["create", "moderator"],
      },
      true
    );

    const returnUrl = `/project/qa/action_items`;

    this.state = {
      returnUrl: returnUrl,
      createRole,
    };
  }

  submitForm(values) {
    const {
      f7router: { app },
      project_id,
    } = this.props;

    app.dialog.confirm(
      "Send Email Notification to Owner/Person Responsible?",
      () => {
        // When clicked okay
        this.preloader = app.preloader;
        this.preloader.show();
        const createRequest = this.props.createProjectQaActionItem(project_id, values, true);
        createRequest.then(this.successfullySubmitted);
      },
      () => {
        // When clicked cancel
        this.preloader = app.preloader;
        this.preloader.show();
        const createRequest = this.props.createProjectQaActionItem(project_id, values, false);
        createRequest.then(this.successfullySubmitted);
      }
    );
  }

  successfullySubmitted() {
    const {
      f7router,
      f7router: { app },
    } = this.props;

    const { returnUrl } = this.state;

    this.preloader.hide();
    clearPersisted(persistedName).then(() => {
      app.toast
        .create({
          icon: '<i class="material-icons">check</i>',
          text: "Created Action Item!",
          position: "center",
          closeTimeout: 2000,
          on: {
            opened: () => {
              f7router.navigate(returnUrl);
            },
          },
        })
        .open();
    });
  }

  render() {
    const { history } = this.props;
    const { returnUrl, createRole } = this.state;

    if (!createRole) {
      return <NotAuthorized />;
    }

    return (
      <Page>
        <Navbar title="New Action Item" backLink />
        <Form
          persist={{ name: persistedName }}
          component={ProjectQaActionItemForm}
          onSubmit={this.submitForm}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={{
            date: moment().format("YYYY-MM-DD"),
          }}
          formLabel="Create"
          cancelFunction={() => {
            history.push(returnUrl);
          }}
          {...this.props}
        />
      </Page>
    );
  }
}

export default connect(mapStateToProps, { createProjectQaActionItem })(NewProjectQaActionItem);
