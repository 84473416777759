import axios from "axios";

const prepareData = (data) => {return {user_group:data}}

// Plural Actions
export const GET_USER_GROUPS = "get_user_groups";
export const getUserGroups = () => {
	const url = `/user_groups.json`
	const request = axios.get(url);
	return {
		type: GET_USER_GROUPS,
		payload: request
	};
};


// Singular Actions
export const CREATE_USER_GROUP = "create_user_group";
export const createUserGroup = (data) => {
	const url = `/user_groups.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_USER_GROUP,
		payload: request
	};
};

export const GET_USER_GROUP = "get_user_group";
export const getUserGroup = (id) => {
	const url = `/user_groups/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_USER_GROUP,
		payload: request
	};
};

export const UPDATE_USER_GROUP = "update_user_group";
export const updateUserGroup = (id,data) => {
	const url = `/user_groups/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_USER_GROUP,
		payload: request
	};
};

export const DESTROY_USER_GROUP = "destroy_user_group";
export const destroyUserGroup = (id) => {
	const url = `/user_groups/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_USER_GROUP,
		payload: request
	};
};
