import {
  GET_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEMS,
  GET_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM,
  CREATE_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM,
  UPDATE_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM,
  APPROVE_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM,
  UNAPPROVE_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM,
  DESTROY_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM,
} from "./actions";

export function ProjectTechnicalDetailsPaintSystemsReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEMS}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    case `${APPROVE_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM}_FULFILLED`:
    case `${UNAPPROVE_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM}_FULFILLED`:
    case `${GET_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM}_FULFILLED`:
    case `${CREATE_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM}_FULFILLED`:
    case `${UPDATE_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_PROJECT_TECHNICAL_DETAILS_PAINT_SYSTEM}_FULFILLED`: {
      let newState = _.clone(state);
      const deletedItem = action.payload.data;
      const id = _.get(deletedItem, "id");
      newState = _.filter(newState, (i) => i.id != id);
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { ProjectTechnicalDetailsPaintSystemsReducer } from "modules/project/technical_details/paint_systems/reducer"

// project_technical_details_paint_systems:ProjectTechnicalDetailsPaintSystemsReducer,
