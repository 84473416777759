const SET_FILTER_VALUES = "set_filter_values";
const REMOVE_FILTER_VALUE = "remove_filter_value";
const CLEAR_FILTER_VALUES = "clear_filter_values";

export const setFilterValues = (filterValues) => ({
  type: SET_FILTER_VALUES,
  filterValues,
});

export const removeFilterValue = (column, value) => {
  return { type: REMOVE_FILTER_VALUE, column, value };
};

export const clearFilterValues = () => {
  return { type: CLEAR_FILTER_VALUES };
};

export default function reducer(state = {}, action) {
  switch (action.type) {
    case SET_FILTER_VALUES: {
      return action.filterValues;
    }
    case REMOVE_FILTER_VALUE: {
      const column = action.column;
      const value = action.value;
      let newState = _.clone(state);

      if (column.dataFilter == "select") {
        newState[column.dataField].value = "";
      }
      if (column.dataFilter == "text") {
        newState[column.dataField].value = "";
      }
      if (column.dataFilter == "multiselect") {
        let columnFilterValues = newState[column.dataField];
        columnFilterValues = _.filter(newState[column.dataField].value, (v) => v !== action.value);

        newState[column.dataField].value = columnFilterValues;
      }
      if (column.dataFilter == "date") {
        newState[column.dataField] = "";
      }

      return newState;
    }
    case CLEAR_FILTER_VALUES: {
      return {};
    }
    default:
  }
  return state;
}
