import React, { Fragment } from "react";
import Select from "react-select";
import { Row, Col } from "reactstrap";
// import 'react-select/dist/react-select.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import WindowedSelect from "react-windowed-select";

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 100, // set to whatever value you need
  }),
};

export default function ReactSelect(props) {
  const {
    input,
    meta,
    options,
    multi,
    modalToggle,
    placeholder,
    isClearable,
    instanceId,
    sendDestroy,
    saveObject,
    disabled,
    classNamePrefix,
    components,
    maxOptionsSelected,
  } = props;
  const { name, value, onBlur, onChange, onFocus } = input;
  const { touched, error, warning } = meta;
  const transformedValue = transformValue(value, options, multi, saveObject);

  let errorDisplay;
  if (_.isObject(error)) {
    errorDisplay = _.get(error, name);
  } else {
    errorDisplay = error;
  }

  return (
    <Fragment>
      <Row>
        <Col sm={modalToggle || props.children ? 10 : 12}>
          {props.virtualList ? (
            <WindowedSelect
              classNamePrefix={classNamePrefix ? `react-select-${classNamePrefix}` : "react-select"}
              isClearable={isClearable}
              valueKey="value"
              name={name}
              value={transformedValue}
              multi={multi}
              isMulti={multi}
              options={options}
              instanceId={instanceId}
              onChange={multi ? multiChangeHandler(onChange, sendDestroy, value) : singleChangeHandler(onChange, saveObject)}
              onBlur={() => onBlur(value)}
              onFocus={onFocus}
              placeholder={placeholder}
              isDisabled={disabled}
              id={name}
              styles={customStyles}
              components={components}
              isOptionDisabled={maxOptionsSelected && multi ? () => value?.length >= maxOptionsSelected : () => false}
            />
          ) : (
            <Select
              classNamePrefix={classNamePrefix ? `react-select-${classNamePrefix}` : "react-select"}
              isClearable={isClearable}
              valueKey="value"
              name={name}
              value={transformedValue}
              multi={multi}
              isMulti={multi}
              options={options}
              instanceId={instanceId}
              onChange={multi ? multiChangeHandler(onChange, sendDestroy, value) : singleChangeHandler(onChange, saveObject)}
              onBlur={() => onBlur(value)}
              onFocus={onFocus}
              placeholder={placeholder}
              isDisabled={disabled}
              id={name}
              styles={customStyles}
              components={components}
              isOptionDisabled={maxOptionsSelected && multi ? () => value?.length >= maxOptionsSelected : () => false}
            />
          )}
        </Col>
        {modalToggle && (
          <Col sm={2} style={{ cursor: "pointer" }} onClick={modalToggle}>
            <FontAwesomeIcon icon={faPlus} />
          </Col>
        )}
        {props.children && <Col sm={2}>{props.children}</Col>}
      </Row>

      <Row>
        <Col>
          {touched &&
            ((errorDisplay && (
              <span className="text-danger">
                {name} {errorDisplay}
              </span>
            )) ||
              (warning && <span className="text-warning">{warning}</span>))}
        </Col>
      </Row>
    </Fragment>
  );
}

ReactSelect.defaultProps = {
  sendDestroy: false,
  saveObject: false,
};

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler(func, saveObject) {
  return function handleSingleChange(value) {
    if (saveObject) {
      func(value ? value : null);
    } else {
      func(value ? value.value : null);
    }
  };
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler(finalFormOnChange, sendDestroy, finalFormValues) {
  return function handleMultiHandler(reactSelectValues, reactSelectAction) {
    if (reactSelectAction.action == "remove-value" && sendDestroy && reactSelectAction.removedValue?.id) {
      let newArray = _.clone(finalFormValues ? finalFormValues : []);
      const findArrayKey = _.findKey(newArray, (item) => item.id == reactSelectAction.removedValue.id);
      if (findArrayKey != -1) {
        newArray[findArrayKey]._destroy = "1";
      }

      finalFormOnChange(newArray);
    } else if (reactSelectAction.action == "select-option" && sendDestroy) {
      let newArray = _.clone(finalFormValues ? finalFormValues : []);
      const findTheOption = _.findKey(finalFormValues, (ffv) => ffv.value == reactSelectAction.option.value);
      if (findTheOption && reactSelectAction.option?.id) {
        delete newArray[findTheOption]._destroy; // Deletes the destroy key
        finalFormOnChange(newArray);
      } else {
        newArray.push(reactSelectAction.option);
        finalFormOnChange(newArray);
      }
    } else {
      finalFormOnChange(reactSelectValues);
    }
  };
}

/**
 * For single select, Final Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Final Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue(value, options, multi, saveObject) {
  if (multi && typeof value === "string") return [];

  let filteredOptions;
  if (multi) {
    filteredOptions = _.difference(
      _.filter(value, (o) => !o._destroy),
      options
    );
  } else if (saveObject) {
    return value;
  } else if (value) {
    let clonedOptions = _.clone(options);
    const hasGroupedOptions = _.some(options, (o) => o.options);
    if (hasGroupedOptions) {
      clonedOptions = _.flatten(_.map(clonedOptions, (o) => (o.options ? o.options : o)));
    }
    filteredOptions = clonedOptions.find((option) => option.value === value);
  } else {
    filteredOptions = null;
  }

  return filteredOptions;
}
