import axios from "axios";
import jsonFormData from "json-form-data";

const prepareData = (data) => {
  return jsonFormData({ employee: data }, { showLeafArrayIndexes: true });
};

// Plural Actions
export const GET_EMPLOYEES = "get_employees";
export const getEmployees = (get_all = false, show_all_details = false) => {
  let url = "/employees.json";
  if (get_all) {
    url += "?get_all";
  }
  if (show_all_details) {
    if (get_all) {
      url += "&";
    } else {
      url += "?";
    }
    url += "show_all_details";
  }

  const request = axios.get(url);
  return {
    type: GET_EMPLOYEES,
    payload: request,
  };
};

// Singular Actions
export const CREATE_EMPLOYEE = "create_employee";
export const createEmployee = (data) => {
  const url = "/employees.json";
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_EMPLOYEE,
    payload: request,
  };
};

export const GET_EMPLOYEE = "get_employee";
export const getEmployee = (id) => {
  const url = `/employees/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_EMPLOYEE,
    payload: request,
  };
};

export const UPDATE_EMPLOYEE = "update_employee";
export const updateEmployee = (id, data) => {
  const url = `/employees/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_EMPLOYEE,
    payload: request,
  };
};
