import NewStoreBookoutItem from "../bookout_items/mobile/new";
import StoreHome from "./home";
import StoreBookoutItemsIndex from "modules/store/bookout_items/mobile/index.jsx";

let routes = [];

routes.push({
  path: "/store/bookout_items/new",
  component: NewStoreBookoutItem,
  name: "New Bookout",
});
routes.push({
  path: "/store/bookout_items",
  component: StoreBookoutItemsIndex,
  name: "Store Bookout Items",
});

routes.push({
  path: "/store",
  component: StoreHome,
  name: "Store Home",
});

export default routes;
