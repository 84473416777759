import React from 'react';

const ProjectQaWeldingInspectionsWeldingProcessesIndex = React.lazy(() => import("./index"))
const NewProjectQaWeldingInspectionsWeldingProcesses = React.lazy(() => import("./new"))
const EditProjectQaWeldingInspectionsWeldingProcesses = React.lazy(() => import("./edit"))

export default[
	{path:'/project/qa/welding_inspections/welding_processes/:id/edit',component: EditProjectQaWeldingInspectionsWeldingProcesses,name:'Edit ProjectQaWeldingInspectionsWeldingProcess'},
	{path:'/project/qa/welding_inspections/welding_processes/new',component: NewProjectQaWeldingInspectionsWeldingProcesses,name:'New ProjectQaWeldingInspectionsWeldingProcess'},  
	{path:'/project/qa/welding_inspections/welding_processes',component: ProjectQaWeldingInspectionsWeldingProcessesIndex,name:'ProjectQaWeldingInspectionsWeldingProcesses'}, 
]

// import ProjectQaWeldingInspectionsWeldingProcessesRoutes from "modules/project/qa/welding_inspections/welding_processes/web/routes"
// routes = _.concat(routes,ProjectQaWeldingInspectionsWeldingProcessesRoutes)
