import axios from "axios";

const prepareData = (data) => { return { user_management: data } }

// Plural Actions
export const GET_USER_MANAGEMENTS = "get_user_managements";
export const getUserManagements = (basic = false) => {
	let url = "/user_managements.json";
	if (basic) {
		url += "?basic=true";
	}
	const request = axios.get(url);
	return {
		type: GET_USER_MANAGEMENTS,
		payload: request
	};
};


// Singular Actions
export const CREATE_USER_MANAGEMENT = "create_user_management";
export const createUserManagement = (data) => {
	const url = "/user_managements.json"
	const request = axios({ method: "post", url: url, data: prepareData(data) });
	return {
		type: CREATE_USER_MANAGEMENT,
		payload: request
	};
};

export const GET_USER_MANAGEMENT = "get_user_management";
export const getUserManagement = (id) => {
	const url = `/user_managements/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_USER_MANAGEMENT,
		payload: request
	};
};

export const UPDATE_USER_MANAGEMENT = "update_user_management";
export const updateUserManagement = (id, data) => {
	const url = `/user_managements/${id}.json`
	const request = axios({ method: "patch", url: url, data: prepareData(data) });
	return {
		type: UPDATE_USER_MANAGEMENT,
		payload: request
	};
};
