import React, { useEffect } from "react";
import { connect } from "react-redux";

import { App, View, Panel } from "framework7-react";

import routes from "modules/2_routes/mobile";
import { getCurrentUser } from "../current_user/actions";

const mapStateToProps = (state) => ({
  current_user: state.current_user,
});

const f7params = {
  name: "LionV3",
  id: "lionv3.app",
  theme: "md",
  routes: routes,
};

const Router = (props) => {
  const { current_user } = props;

  const [rerenderKey, setRerenderKey] = React.useState(Date.now());

  useEffect(() => {
    props.getCurrentUser();
  }, []);

  if (_.isNotEmpty(current_user)) {
    return (
      <App {...f7params}>
        <Panel
          left
          cover
          resizable
          onPanelOpen={() => {
            setRerenderKey(Date.now());
          }}
        >
          <View key={rerenderKey} url="/panel-left/" linksView=".view-main" reloadPages />
        </Panel>
        <View main url="/" browserHistory browserHistorySeparator="" />
      </App>
    );
  } else {
    return <div>Loading user...</div>;
  }
};

export default connect(mapStateToProps, { getCurrentUser })(Router);
