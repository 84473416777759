import React from "react";
import { required, InputField } from "modules/4_final_form/web";

import { FormGroup, Label, Row, Col, Button } from "reactstrap";

import { Field } from "react-final-form";

export default class AddressBookCompanyForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { handleSubmit, formLabel, cancelFunction } = this.props;

    return (
      <form>
        <FormGroup row>
          <Label md={2}>Name</Label>
          <Col md={4}>
            <Field component={InputField} name="name" type="string" parse={(value) => value} validate={required} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={2}>Address</Label>
          <Col md={4}>
            <Field component={InputField} name="address" type="string" parse={(value) => value} />
          </Col>
        </FormGroup>

        <Row>
          <Col>
            <Button color="success" onClick={handleSubmit}>
              {formLabel} Companies
            </Button>
          </Col>
          <Col className="text-right">
            <Button onClick={cancelFunction} className="float-right" color="danger">
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}
