import React, { Fragment, useEffect, useState } from "react";
import { Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { getProjectQaPaintInspectionsByAssemblyGuid } from "modules/project/qa/paint_inspections/actions.js";
import {
  getProductionStrumisContractMarkInstancesView,
  getProductionStrumisContractMarkInstancesWithStage,
} from "modules/production/strumis/contract/mark_instances/actions.js";
import { connect } from "react-redux";
import { required, InputField, SmartSelect, ToggleField } from "modules/4_final_form/mobile";

import { List, ListItem } from "framework7-react";

function mapStateToProps(state, ownProps) {
  const { project } = ownProps;
  const strumis_contracts_ids = project?.strumis_contracts_attributes.map((sc) => sc.strumis_contract_id);

  // Filter the mark instances to only include the ones that are part of the project
  let production_strumis_contract_mark_instances = _.filter(state.production_strumis_contract_mark_instances, (mi) =>
    _.includes(strumis_contracts_ids, mi.contract_id)
  );

  production_strumis_contract_mark_instances = _.mapKeys(production_strumis_contract_mark_instances, "id"); // Convert back to object with id keys after the filter for easy searching...

  return { production_strumis_contract_mark_instances: production_strumis_contract_mark_instances };
}

const AssemblyDetailsTab = (props) => {
  const [loadingMarkInstances, setLoadingMarkInstances] = useState(false);

  const {
    project,
    values: { assembly },
    project_technical_details_paint_systems,
    edit,
  } = props;

  const guid = assembly?.guid;

  const searchInStrumis = project?.strumis_connected;
  const paint_systems = _.filter(project_technical_details_paint_systems, (ps) => ps.project_id == project?.id);

  const strumis_mark_instance_id = assembly?.strumis_mark_instance_id;
  const assemblyReadOnly = edit || strumis_mark_instance_id;

  useEffect(() => {
    if (project?.strumis_connected) {
      setLoadingMarkInstances(true);
      // const req = props.getProductionStrumisContractMarkInstancesWithStage(project?.id, "surface_status");
      const req = props.getProductionStrumisContractMarkInstancesView(project?.id);
      req.then(() => {
        setLoadingMarkInstances(false);
      });
    }
  }, [project]);

  useEffect(() => {
    if (guid && project) {
      props.getProjectQaPaintInspectionsByAssemblyGuid(project?.id, guid);
    }
  }, [guid, project]);

  return (
    <Fragment>
      <List>
        {searchInStrumis && (
          <StrumisAssemblyFields slot="list" loadingMarkInstances={loadingMarkInstances} paint_systems={paint_systems} {...props} />
        )}
        <ManualAssemblyFields slot="list" assemblyReadOnly={assemblyReadOnly} {...props} />
        <PaintSystemFields slot="list" paint_systems={paint_systems} {...props} />
      </List>
    </Fragment>
  );
};

export default connect(mapStateToProps, { getProjectQaPaintInspectionsByAssemblyGuid, getProductionStrumisContractMarkInstancesView })(
  AssemblyDetailsTab
);

const StrumisAssemblyFields = (props) => {
  const {
    production_strumis_contract_mark_instances,
    form: { change },
    paint_systems,
    values,
    edit,
    loadingMarkInstances,
  } = props;

  const paint_system_id = values?.paint_system_id;

  let mark_instances = _.map(production_strumis_contract_mark_instances, (mi) => {
    const assembly_params = _.omit(mi, "id");
    return {
      label: `${mi.mark} - Phase ${mi.phase} - Lot ${mi.lot} - ${mi.guid.substring(0, 4)} - ${mi.paint_finish}`,
      value: mi.id,
      strumis_mark_instance_id: mi.id,
      ...assembly_params,
    };
  });

  mark_instances = _.sortBy(mark_instances, "label");

  return (
    <Fragment>
      {loadingMarkInstances ? (
        <ListItem>Loading Strumis Marks...</ListItem>
      ) : (
        <Fragment>
          <Field
            label="Search Strumis"
            component={SmartSelect}
            name="assembly.strumis_mark_instance_id"
            placeholder="Select Strumis Mark"
            labelName="label"
            valueName="value"
            searchbar
            searchbarPlaceholder="Search marks"
            options={mark_instances}
            virtualList
          />
          <OnChange name="assembly.strumis_mark_instance_id">
            {(value) => {
              const selectedStrumisMark = production_strumis_contract_mark_instances[value];
              if (selectedStrumisMark) {
                // Copies assembly selected to other assembly fields START
                change("assembly.mark", selectedStrumisMark.mark);
                change("assembly.phase", selectedStrumisMark.phase);
                change("assembly.lot", selectedStrumisMark.lot);
                change("assembly.guid", selectedStrumisMark.guid);
                change("assembly.paint_finish", selectedStrumisMark.paint_finish);
                // Copies assembly selected to other assembly fields END

                // If the there is a paint finish search for the paint system and select it START
                if (!edit && selectedStrumisMark && !paint_system_id) {
                  let paint_finish = selectedStrumisMark.paint_finish; // Get the paint finish
                  paint_finish = paint_finish.substr(-1) == "X" ? paint_finish.slice(0, -1) : paint_finish; // remove X if there is one on the end
                  const findPaintSystem = _.find(paint_systems, (lps) => lps.display_code == paint_finish);
                  if (findPaintSystem && findPaintSystem.approved) {
                    // Select the paint system if you can find it
                    change("paint_system_id", findPaintSystem.id);
                    // If there is only one coat select it START
                    if (findPaintSystem.coats_attributes.length == 1) {
                      change("coat_id", findPaintSystem.coats_attributes[0].id);
                    }
                    // If there is only one coat select it END
                  }
                }
                // If the there is a paint finish search for the paint system and select it END
              }
            }}
          </OnChange>
        </Fragment>
      )}

      <hr />
    </Fragment>
  );
};

const ManualAssemblyFields = (props) => {
  const { assemblyReadOnly, values } = props;

  const multiple = values?.assembly?.multiple;

  return (
    <Fragment>
      <Field
        label="Mark"
        disabled={assemblyReadOnly}
        placeholder="Enter Mark Here..."
        component={InputField}
        name="assembly.mark"
        validate={required}
        convertToUpperCase
      />
      <Field label="Multiple Assemblies" slot="list" component={ToggleField} name="assembly.multiple" />

      <div className="grid grid-cols-2">
        <div>
          <Field
            disabled={assemblyReadOnly}
            component={InputField}
            label="Phase"
            postiveOnly
            placeholder="Enter phase..."
            name="assembly.phase"
            type="number"
            validate={required}
          />
        </div>
        {!multiple && (
          <div>
            <Field
              disabled={assemblyReadOnly}
              component={InputField}
              label="Lot"
              postiveOnly
              placeholder="Enter lot..."
              name="assembly.lot"
              type="number"
              validate={required}
            />
          </div>
        )}
      </div>

      {multiple && (
        <div className="grid grid-cols-1">
          <div>
            <Field component={InputField} label="Description" placeholder="Enter description..." name="assembly.description" validate={required} />
          </div>
        </div>
      )}

      {/* <Field
        disabled={assemblyReadOnly}
        component={InputField}
        label="GUID"
        placeholder="Enter guid..."
        name="assembly.guid"
        validate={required}
        convertToUpperCase
      /> */}
    </Fragment>
  );
};

const PaintSystemFields = (props) => {
  const {
    paint_systems,
    values: { assembly },
    form: { change },
    selectedPaintSystem,
    parentSelectedPaintSystem,
    paint_locations,
  } = props;

  const coats_attributes = selectedPaintSystem?.coats_attributes;

  let display_child_paint_systems = [];
  if (parentSelectedPaintSystem?.is_multi_system) {
    const parentPaintSystemChildrenIDS = _.map(parentSelectedPaintSystem.children_attributes, "value");
    display_child_paint_systems = _.filter(paint_systems, (ps) => _.includes(parentPaintSystemChildrenIDS, ps.id));
  }

  return (
    <Fragment>
      <Field
        component={SmartSelect}
        label="Paint System"
        name="paint_system_id"
        validate={required}
        placeholder="Select Paint System"
        labelName="label"
        valueName="value"
        openIn="popover"
        options={_.map(paint_systems, (ps) => {
          const label = ps.approved ? ps.display_code : `${ps.display_code} - Not Approved`;
          return { label: label, value: ps.id, isDisabled: !ps.approved };
        })}
      />
      <OnChange name="paint_system_id">
        {(value, previous) => {
          if (coats_attributes?.length == 1) {
            change("coat_id", coats_attributes[0].id);
          } else {
            change("coat_id", null);
          }
          change("section_readings_attributes", null);
        }}
      </OnChange>
      {/* <Col>{assembly?.paint_finish && `Actual : ${assembly?.paint_finish}`}</Col> */}

      {parentSelectedPaintSystem?.is_multi_system && (
        <Fragment>
          <Field
            component={SmartSelect}
            label="Child Paint System"
            name="child_paint_system_id"
            validate={required}
            placeholder="Select Paint System"
            labelName="label"
            valueName="value"
            options={_.map(display_child_paint_systems, (ps) => {
              const label = ps.approved ? ps.display_code : `${ps.display_code} - Not Approved`;
              return { label: label, value: ps.id, isDisabled: !ps.approved };
            })}
          />
          <OnChange name="child_paint_system_id">
            {(value, previous) => {
              if (coats_attributes?.length == 1) {
                change("coat_id", coats_attributes[0].id);
              } else {
                change("coat_id", null);
              }
              change("section_readings_attributes", null);
            }}
          </OnChange>
        </Fragment>
      )}

      <Field
        component={SmartSelect}
        name="coat_id"
        validate={required}
        label="Coat"
        placeholder="Select Coat"
        labelName="label"
        valueName="value"
        openIn="popover"
        options={_.map(coats_attributes, (coat) => {
          return { label: coat.display_name, value: coat.id };
        })}
      />
      <OnChange name="coat_id">
        {(value, previous) => {
          change("failure_reason", null);
        }}
      </OnChange>

      <Field
        component={SmartSelect}
        name="location_id"
        validate={required}
        label="Inspection Location"
        placeholder="Select Inspection Location"
        labelName="label"
        valueName="value"
        openIn="popover"
        options={_.map(paint_locations, (pl) => ({ value: pl.id, label: pl.name }))}
      />
    </Fragment>
  );
};
