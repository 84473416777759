import axios from "axios";

const prepareData = (data) => {
  return { training_course_category: data };
};

// Plural Actions
export const GET_TRAINING_COURSE_CATEGORIES = "get_training_course_categories";
export const getTrainingCourseCategories = () => {
  const url = "/training/course_categories.json";
  const request = axios.get(url);
  return {
    type: GET_TRAINING_COURSE_CATEGORIES,
    payload: request,
  };
};

// Singular Actions
export const CREATE_TRAINING_COURSE_CATEGORY =
  "create_training_course_category";
export const createTrainingCourseCategory = (data) => {
  const url = "/training/course_categories.json";
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_TRAINING_COURSE_CATEGORY,
    payload: request,
  };
};

export const GET_TRAINING_COURSE_CATEGORY = "get_training_course_category";
export const getTrainingCourseCategory = (id) => {
  const url = `/training/course_categories/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_TRAINING_COURSE_CATEGORY,
    payload: request,
  };
};

export const UPDATE_TRAINING_COURSE_CATEGORY =
  "update_training_course_category";
export const updateTrainingCourseCategory = (id, data) => {
  const url = `/training/course_categories/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_TRAINING_COURSE_CATEGORY,
    payload: request,
  };
};

export const DESTROY_TRAINING_COURSE_CATEGORY =
  "destroy_training_course_category";
export const destroyTrainingCourseCategory = (id) => {
  const url = `/training/course_categories/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_TRAINING_COURSE_CATEGORY,
    payload: request,
    meta: { id: id },
  };
};
