import React from 'react';

const ProjectQaWeldingInspectionsIndex = React.lazy(() => import("./index"))
const NewProjectQaWeldingInspections = React.lazy(() => import("./new"))
const EditProjectQaWeldingInspections = React.lazy(() => import("./edit"))

export default[
	{path:'/project/:project_id/qa/welding_inspections/:id/edit',component: EditProjectQaWeldingInspections,name:'Edit ProjectQaWeldingInspection'},
	{path:'/project/:project_id/qa/welding_inspections/new',component: NewProjectQaWeldingInspections,name:'New ProjectQaWeldingInspection'},  
	{path:'/project/:project_id/qa/welding_inspections',component: ProjectQaWeldingInspectionsIndex,name:'ProjectQaWeldingInspections'}, 
]

// import ProjectQaWeldingInspectionsRoutes from "modules/project/qa/welding_inspections/web/routes"
// routes = _.concat(routes,ProjectQaWeldingInspectionsRoutes)
