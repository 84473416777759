import React from "react"
import { Page, BlockTitle } from "framework7-react"
import AppNavbar from "modules/1_layout/mobile/AppNavbar";

const NotAuthorized = () => {
  return (
    <Page>
      <AppNavbar title="Not Authorized" />
      <BlockTitle>You do not have permission for this page.</BlockTitle>
    </Page>
  );
};

export default NotAuthorized;
