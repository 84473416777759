import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Page, Navbar, Fab, List, ListItem, BlockTitle, Icon, AccordionContent, Block, Button, Preloader, Toggle } from "framework7-react";

import _ from "lodash";

// Helpers
import HasRole from "modules/5_view_helpers/HasRole";
import AppNavbar from "modules/1_layout/mobile/AppNavbar";

import SelectProject from "modules/projects/mobile/select";
import { setCurrentProject } from "modules/current_user/actions";

// Actions
import { getProjectQaActionItems } from "../actions";
import moment from "moment";
import NotAuthorized from "modules/5_view_helpers/mobile/NotAuthorized";

import { handleNewClick, ResumePersistedButton } from "modules/5_view_helpers/mobile/PersistedHelpers.jsx";
import { persistedName } from "./vairables";

function mapStateToProps(state) {
  return {
    projects: state.projects,
    current_user: state.current_user,
    project_qa_action_items: state.project_qa_action_items,
  };
}

const ProjectQaActionItemsIndex = (props) => {
  const [loading, setLoading] = useState(false);
  const [showComplete, setShowComplete] = useState(false);
  const { project_qa_action_items, current_user, projects, f7router } = props;
  const current_project_id = _.get(current_user, "current_project_id");
  const project = projects[current_project_id];

  const newUrl = `/project/${project?.id}/qa/action_items/new`;

  let display_project_qa_action_items = _.toArray(project_qa_action_items);
  if (!showComplete) {
    display_project_qa_action_items = _.filter(display_project_qa_action_items, (action_item) => !action_item.complete);
  }


  const readRole = HasRole(
    {
      resource_type: "Project::Qa::ActionItem",
      name: ["read", "moderator"],
    },
    true
  );

  useEffect(() => {
    if (current_project_id && readRole) {
      setLoading(true);
      props.getProjectQaActionItems(current_project_id).then(() => {
        setLoading(false);
      });
    }
  }, [current_project_id]);

  const createRole = HasRole(
    {
      resource_type: "Project::Qa::ActionItem",
      name: ["create", "moderator"],
    },
    true
  );

  const updateRole = HasRole(
    {
      resource_type: "Project::Qa::ActionItem",
      name: ["update", "moderator"],
    },
    true
  );

  if (!readRole) {
    return <NotAuthorized />;
  }
  return (
    <Page>
      <AppNavbar title="Action Items" />

      <SelectProject
        onChange={({ target: { value } }) => {
          props.setCurrentProject(value);
        }}
        value={current_project_id}
      />
      <ResumePersistedButton name={persistedName} url={newUrl} />

      <List>
        <ListItem>
          Show Complete
          <Toggle
            checked={showComplete}
            onChange={() => {
              setShowComplete(!showComplete);
            }}
          />
        </ListItem>
      </List>

      {loading && <Preloader />}
      {!loading && (
        <Fragment>
          <BlockTitle>{project?.display_name} - Action Items - Count : {display_project_qa_action_items.length}</BlockTitle>
          <List accordionList>
            {_.map(display_project_qa_action_items, (action_item, i) => (
              <ActionItemRow action_item={action_item} slot="list" key={i} updateRole={updateRole} />
            ))}
          </List>
          {_.isEmpty(display_project_qa_action_items) && (
            <List>
              <ListItem>No Action Items to do</ListItem>
            </List>
          )}
        </Fragment>
      )}

      {createRole && (
        <Fab
          position="right-bottom"
          slot="fixed"
          text="New"
          href=""
          onClick={() => {
            handleNewClick(f7router, persistedName, newUrl);
          }}
        >
          <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
        </Fab>
      )}
    </Page>
  );
};
export default connect(mapStateToProps, { getProjectQaActionItems, setCurrentProject })(ProjectQaActionItemsIndex);

const ActionItemRow = (props) => {
  const { action_item, updateRole } = props;

  return (
    <ListItem accordionItem title={action_item?.description} style={{ backgroundColor: action_item?.complete && "green" }}>
      <AccordionContent>
        <Block>
          <dl>
            <dt>Date :</dt>
            <dd>{action_item?.date && moment(action_item.date).format("DD/MM/YYYY")}</dd>
            <dt>Project Area/s :</dt>
            <dd>{action_item?.project_areas ? action_item.project_areas : "None set"}</dd>

            <dt>User/s Responsible :</dt>
            <dd>{action_item?.users}</dd>

            <dt>Description :</dt>
            <dd>{action_item?.description}</dd>

            <dt>Status :</dt>
            <dd>{action_item?.status_name}</dd>
          </dl>
          {updateRole && (
            <Button href={`/project/${action_item?.project_id}/qa/action_items/${action_item?.id}/edit`} fill>
              Edit
            </Button>
          )}
        </Block>
      </AccordionContent>
    </ListItem>
  );
};
