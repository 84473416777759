import React from 'react';

const NewProjectQaActionItemsStatuses = React.lazy(() => import("./new"))
const EditProjectQaActionItemsStatuses = React.lazy(() => import("./edit"))

export default[
	{path:'/project/qa/action_items/statuses/:id/edit',component: EditProjectQaActionItemsStatuses,name:'Edit ProjectQaActionItemsStatus'},
	{path:'/project/qa/action_items/statuses/new',component: NewProjectQaActionItemsStatuses,name:'New ProjectQaActionItemsStatus'},  
]

// import ProjectQaActionItemsStatusesRoutes from "modules/project/qa/action_items/statuses/web/routes"
// routes = _.concat(routes,ProjectQaActionItemsStatusesRoutes)
