import React from 'react';

const ProjectQaWeldingInspectionsInspectionCriteriasIndex = React.lazy(() => import("./index"))
const NewProjectQaWeldingInspectionsInspectionCriterias = React.lazy(() => import("./new"))
const EditProjectQaWeldingInspectionsInspectionCriterias = React.lazy(() => import("./edit"))

export default[
	{path:'/project/qa/welding_inspections/inspection_criterias/:id/edit',component: EditProjectQaWeldingInspectionsInspectionCriterias,name:'Edit ProjectQaWeldingInspectionsInspectionCriteria'},
	{path:'/project/qa/welding_inspections/inspection_criterias/new',component: NewProjectQaWeldingInspectionsInspectionCriterias,name:'New ProjectQaWeldingInspectionsInspectionCriteria'},  
	{path:'/project/qa/welding_inspections/inspection_criterias',component: ProjectQaWeldingInspectionsInspectionCriteriasIndex,name:'ProjectQaWeldingInspectionsInspectionCriterias'}, 
]

// import ProjectQaWeldingInspectionsInspectionCriteriasRoutes from "modules/project/qa/welding_inspections/inspection_criterias/web/routes"
// routes = _.concat(routes,ProjectQaWeldingInspectionsInspectionCriteriasRoutes)
