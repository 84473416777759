import { 
	GET_PROJECT_SITE_DOCUMENT_RELEASES, 
	GET_PROJECT_SITE_DOCUMENT_RELEASE,
	CREATE_PROJECT_SITE_DOCUMENT_RELEASE,
	UPDATE_PROJECT_SITE_DOCUMENT_RELEASE,
	DESTROY_PROJECT_SITE_DOCUMENT_RELEASE,
} from "./actions"

export function ProjectSiteDocumentReleasesReducer(state = {}, action) {
	switch (action.type) {
		case `${GET_PROJECT_SITE_DOCUMENT_RELEASES}_FULFILLED`:{
			return _.mapKeys(action.payload.data, "id");
		}
		case `${GET_PROJECT_SITE_DOCUMENT_RELEASE}_FULFILLED`:
		case `${CREATE_PROJECT_SITE_DOCUMENT_RELEASE}_FULFILLED`:
		case `${UPDATE_PROJECT_SITE_DOCUMENT_RELEASE}_FULFILLED`:{
			let newState = _.clone(state);
			const newItem = action.payload.data;
			const id = _.get(newItem,"id")
			newState[id] = newItem
			return newState;
		}		
		case `${DESTROY_PROJECT_SITE_DOCUMENT_RELEASE}_FULFILLED`:{
			let newState = _.clone(state);
			const deletedItem = action.payload.data;
			const id = _.get(deletedItem,"id")
			newState = _.filter(newState,i => i.id != id)
			return newState;
		}
		default:
			return state;
	}
}

// Move this to the reducer page
// import { ProjectSiteDocumentReleasesReducer } from "modules/project/site_document_releases/reducer"

// project_site_document_releases:ProjectSiteDocumentReleasesReducer,
