import React from "react";

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { createPromise } from "redux-promise-middleware";

import Router from "./2_router";
import Reducers from "modules/3_reducers";

const createStoreWithMiddleware = applyMiddleware(createPromise())(createStore);
export const mobileStore = createStoreWithMiddleware(
  Reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const Entry = (props) => {
  return (
    <Provider store={mobileStore}>
      <Router {...props} />
    </Provider>
  );
};

export default Entry;
