import { GET_PRODUCTION_STRUMIS_BUNDLE } from "./actions";

export function ProductionStrumisContractBundlesReducer(state = [], action) {
  switch (action.type) {
    case `${GET_PRODUCTION_STRUMIS_BUNDLE}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { ProductionStrumisBundle } from "modules/production/strumis/production/delivery_notes/reducer"

// production_strumis_production_delivery_notes:ProductionStrumisContractsReducer,
