import React, { Fragment } from "react";
import { Field, useForm } from "react-final-form";
import { FormGroup, Label, Col, Input } from "reactstrap";

import { SingleDateField, InputField, ToggleField, required } from "modules/4_final_form/web";
import { OnChange } from "react-final-form-listeners";
import { DateField } from "modules/4_final_form/mobile";

export const DateFieldAttributes = ({ fieldName, namesUsed }) => {
  const form = useForm();

  return (
    <Fragment>
      <FormGroup row>
        <Label md={3}>Label</Label>
        <Col md={8}>
          <Field name={`${fieldName}.label`} component={InputField} parse={(value) => value} />
          <OnChange name={`${fieldName}.label`}>
            {(value, previous) => {
              if (value) {
                const name = _.snakeCase(value);
                form.change(`${fieldName}.name`, name);
              }
            }}
          </OnChange>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>Name</Label>
        <Col md={8}>
          <Field
            name={`${fieldName}.name`}
            component={InputField}
            validate={(value, values) => {
              if (!value) {
                return "is required";
              }
              if (namesUsed.includes(value)) {
                return "must be unique";
              }
            }}
            convertToLowerCase
            lettersOnly
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>Placeholder</Label>
        <Col md={8}>
          <Field name={`${fieldName}.placeholder`} component={InputField} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>Required Field</Label>
        <Col md={8}>
          <Field name={`${fieldName}.required`} component={ToggleField} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>On Dashboard</Label>
        <Col md={8}>
          <Field name={`${fieldName}.on_dashboard`} component={ToggleField} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>On Report</Label>
        <Col md={8}>
          <Field name={`${fieldName}.on_report`} component={ToggleField} />
        </Col>
      </FormGroup>
    </Fragment>
  );
};

export const DateFieldForm = (props) => {
  const { label, placeholder, required: isRequired, preview, name, prefixName } = props;
  return (
    <FormGroup row>
      {label && (
        <Label md={2}>
          {label}
          {required && "*"}
        </Label>
      )}
      <Col md={4}>
        <Field
          name={`${prefixName}.${name}`}
          disabled={preview}
          component={SingleDateField}
          placeholder={placeholder}
          enablePreviousDates
          validate={isRequired && !preview ? required : null}
        />
      </Col>
    </FormGroup>
  );
};

DateFieldForm.defaultProps = {
  prefixName: "data",
};

export const MobileDateFieldForm = (props) => {
  const { label, placeholder, required: isRequired, preview, name, prefixName } = props;
  return (
    <Field
      slot="list"
      label={label}
      name={`${prefixName}.${name}`}
      disabled={preview}
      component={DateField}
      placeholder={placeholder}
      validate={isRequired && !preview ? required : null}
    />
  );
};
MobileDateFieldForm.defaultProps = {
  prefixName: "data",
};
