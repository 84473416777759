import React from 'react';

const TrainingCompany = React.lazy(() => import("./index"))
const NewTrainingCompany = React.lazy(() => import("./new"))
const EditTrainingCompany = React.lazy(() => import("./edit"))

export default[
	{path:'/training/companies/:id/edit',component: EditTrainingCompany,name:'Edit TrainingCompany'},
	{path:'/training/companies/new',component: NewTrainingCompany,name:'New TrainingCompany'},  
	{path:'/training/companies',component: TrainingCompany,name:'TrainingCompany'}, 
]

// import TrainingCompanyRoutes from "modules/training/companies/web/routes"
// routes = _.concat(routes,TrainingCompanyRoutes)
