import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Page, BlockTitle,Block, List, ListItem } from "framework7-react";
import AppNavbar from "modules/1_layout/mobile/AppNavbar";


const QAIndex = (props) => {
  return (
    <Page>
      <AppNavbar title="Quality Assurance" />
      <Block>
        Quality Assurance Module - Please use the menu in the top left.
      </Block>
    </Page>
  );
};

export default QAIndex