import React, { useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

var Block = Quill.import("blots/block");
Block.tagName = "DIV";
Quill.register(Block, true);

const TextField = ({ input, meta, placeholder, height, toolbar }) => {
  const { onChange, value } = input;

  return (
    <div style={{ height: height }}>
      <ReactQuill
        theme="snow"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        modules={{
          toolbar: toolbar,
        }}
        style={{ height: "80%" }}
      />
    </div>
  );
};

TextField.defaultProps = {
  height: "300px",
  toolbar: ["bold", { color: [] }],
};

export default TextField;
