import React, { useState, useEffect } from "react";
import { CKEditorField, ReactSelect, SingleDateField, required } from "modules/4_final_form/web";
import { Field } from "react-final-form";
import { Button, Modal, ModalBody, ModalFooter, Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown, faTimes } from "@fortawesome/free-solid-svg-icons";

import momentBusinessDays from "moment-business-days";
import { stripHtml } from "string-strip-html";

import _ from "lodash";

const QuestionsAttributes = (props) => {
  const { default_question_status, disabled, current_user } = props;
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [questionToRemove, setQuestionToRemove] = useState(null);

  const {
    fields,
    statuses,
    meta: { error, touched },
    hideAdd,
  } = props;

  const toggleRemoveModal = () => {
    setRemoveModalOpen(!removeModalOpen);
  };

  return (
    <div>
      {error && !_.isArray(error) && <div className="text-danger">Questions : {error}</div>}
      {fields.map((fieldName, index) => {
        const thisField = fields?.value[index];
        const thisError = error?.[index];
        const thisTouched = touched?.[index];

        return (
          <QuestionForm
            key={index}
            fieldName={fieldName}
            thisField={thisField}
            thisError={thisError}
            index={index}
            opened={thisField.opened}
            statuses={statuses}
            current_user={current_user}
            default_question_status={default_question_status}
            removeQuestion={() => {
              setQuestionToRemove(index);
              toggleRemoveModal();
            }}
            disabled={disabled}
          />
        );
      })}
      {!hideAdd && !disabled && (
        <Button
          className="mt-2"
          onClick={() => {
            // Close all opened questions
            fields.forEach((fieldName, index) => {
              fields.update(index, { ...fields.value[index], opened: false });
            });
            fields.push({ opened: true, date_required: moment().businessAdd(5).format("YYYY-MM-DD") });
          }}
        >
          Add Question
        </Button>
      )}
      <hr />

      <Modal isOpen={removeModalOpen} toggle={toggleRemoveModal}>
        <ModalBody>Are you sure you want to remove this question?</ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              fields.remove(questionToRemove);
              toggleRemoveModal();
            }}
          >
            Remove
          </Button>
          <Button onClick={toggleRemoveModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default QuestionsAttributes;

QuestionsAttributes.defaultProps = {
  hideAdd: false,
};

const QuestionForm = (props) => {
  const { fieldName, thisField, opened, index, removeQuestion, statuses, thisError, default_question_status, disabled, current_user } = props;
  const [itemOpen, setItemOpen] = useState(opened ? true : false);

  useEffect(() => {
    setItemOpen(opened);
  }, [opened]);

  return (
    <table className="questions_table">
      <tr>
        <td
          style={{ width: "5%" }}
          onClick={() => {
            setItemOpen(!itemOpen);
          }}
        >
          <FontAwesomeIcon icon={itemOpen ? faAngleUp : faAngleDown} size="lg" />
        </td>
        <td
          onClick={() => {
            setItemOpen(!itemOpen);
          }}
        >
          {_.isNotEmpty(thisError) && (
            <Badge color="danger" className="mr-2">
              !
            </Badge>
          )}
          <b>Question {index + 1}</b> : {thisField?.body && stripHtml(thisField?.body)?.result}
        </td>
        <td style={{ width: "20%", textAlign: "left" }}>
          <Field name={`${fieldName}.date_required`} component={SingleDateField} validate={required} placeholder="Date Required" />
        </td>
        <td style={{ width: "20%", textAlign: "left" }}>
          <Field
            // Creating an array with one element which then creates a new status record
            // On edit we receive an array with one element which is the existing status record
            // Because that element has the id of the status record, it will not create a new record
            // But when you change the status, it will create a new record because it looses the id because it sets it based from the options
            // Cheeky...
            name={`${fieldName}.statuses_attributes[0]`}
            saveObject
            component={ReactSelect}
            options={_.map(statuses, (status) => {
              return { label: status.name, value: status.id, status_id: status.id, user_id: current_user?.id };
            })}
            validate={required}
            defaultValue={default_question_status}
            placeholder="Select status..."
          />
        </td>
        {!disabled && (
          <td style={{ width: "5%", textAlign: "center", cursor: "" }} onClick={!disabled && removeQuestion}>
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </td>
        )}
      </tr>
      <tr className={itemOpen ? null : "d-none"}>
        <td colSpan="4" style={{ paddingBottom: "20px" }}>
          <Field name={`${fieldName}.body`} component={CKEditorField} height="350px" validate={required} />
        </td>
      </tr>
    </table>
  );
};
