import React from 'react';

const ProjectQaWeldingInspectionsWeldingStandardsIndex = React.lazy(() => import("./index"))
const NewProjectQaWeldingInspectionsWeldingStandards = React.lazy(() => import("./new"))
const EditProjectQaWeldingInspectionsWeldingStandards = React.lazy(() => import("./edit"))

export default[
	{path:'/project/qa/welding_inspections/welding_standards/:id/edit',component: EditProjectQaWeldingInspectionsWeldingStandards,name:'Edit ProjectQaWeldingInspectionsWeldingStandard'},
	{path:'/project/qa/welding_inspections/welding_standards/new',component: NewProjectQaWeldingInspectionsWeldingStandards,name:'New ProjectQaWeldingInspectionsWeldingStandard'},  
	{path:'/project/qa/welding_inspections/welding_standards',component: ProjectQaWeldingInspectionsWeldingStandardsIndex,name:'ProjectQaWeldingInspectionsWeldingStandards'}, 
]

// import ProjectQaWeldingInspectionsWeldingStandardsRoutes from "modules/project/qa/welding_inspections/welding_standards/web/routes"
// routes = _.concat(routes,ProjectQaWeldingInspectionsWeldingStandardsRoutes)
