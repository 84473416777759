import {
  GET_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITIONS,
  GET_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITION,
  CREATE_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITION,
  UPDATE_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITION,
  DESTROY_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITION,
} from "./actions";

export function ProjectQaWeldingInspectionsSurfaceConditionsReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITIONS}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    case `${GET_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITION}_FULFILLED`:
    case `${CREATE_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITION}_FULFILLED`:
    case `${UPDATE_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITION}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_PROJECT_QA_WELDING_INSPECTIONS_SURFACE_CONDITION}_FULFILLED`: {
      let newState = _.clone(state);
      const deletedItem = action.payload.data;
      const id = _.get(deletedItem, "id");
      newState = _.filter(newState, (i) => i.id != id);
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { ProjectQaWeldingInspectionsSurfaceConditionsReducer } from "modules/project/qa/welding_inspections/surface_conditions/reducer"

// project_qa_welding_inspections_surface_conditions:ProjectQaWeldingInspectionsSurfaceConditionsReducer,
