import React from 'react';

const CustomFormsIndex = React.lazy(() => import("./index"))
const NewCustomForms = React.lazy(() => import("./new"))
const EditCustomForms = React.lazy(() => import("./edit"))

export default[
	{path:'/custom_forms/:id/edit',component: EditCustomForms,name:'Edit CustomForm'},
	{path:'/custom_forms/new',component: NewCustomForms,name:'New CustomForm'},  
	{path:'/custom_forms',component: CustomFormsIndex,name:'CustomForms'}, 
]

// import CustomFormsRoutes from "modules/custom_forms/web/routes"
// routes = _.concat(routes,CustomFormsRoutes)
