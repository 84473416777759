import React from "react";
import { connect } from "react-redux";

import { List,ListItem } from "framework7-react"

import { getProjects } from "../actions";

function mapStateToProps(state) {
  return {
    projects: state.projects,
  };
}

export class SelectProject extends React.Component {
  constructor(props) {
    super(props);

    this.props.getProjects();
  }

  render() {
    const { projects, value, onChange } = this.props;

    return (
      <List>
        {_.isNotEmpty(projects) && (
          <ListItem
            title="Project"
            smartSelect
            smartSelectParams={{
              openIn: "popup",
              searchbar: true,
              searchbarPlaceholder: "Search project",
              closeOnSelect: true,
            }}
          >
            <select
              name="projects"
              value={value ? parseInt(value) : ""}
              onChange={onChange}
            >
              {_.map(projects, (p) => (
                <option key={p.id} value={p.id}>
                  {p.display_name}
                </option>
              ))}
            </select>
          </ListItem>
        )}
      </List>
    );
  }
}

export default connect(mapStateToProps, { getProjects })(SelectProject);
