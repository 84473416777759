import {
  GET_DEPARTMENTS,
  GET_DEPARTMENT,
  CREATE_DEPARTMENT,
  UPDATE_DEPARTMENT,
  DESTROY_DEPARTMENT,
} from "./actions";

export function DepartmentsReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_DEPARTMENTS}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    case `${GET_DEPARTMENT}_FULFILLED`:
    case `${CREATE_DEPARTMENT}_FULFILLED`:
    case `${UPDATE_DEPARTMENT}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_DEPARTMENT}_FULFILLED`: {
      let newState = _.clone(state);
      const {
        meta: { id },
      } = action;
      _.unset(newState,id)
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { DepartmentsReducer } from "modules/departments/reducer"

// departments:DepartmentsReducer,
