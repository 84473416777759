import axios from "axios";

// Plural Actions
export const GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES_BY_LOTS = "GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES_BY_LOTS";
export const getProductionStrumisContractMarkInstancesByLots = (strumis_contract_id, lots_from, lots_to) => {
  let url;
  if (lots_from && lots_to) {
    url = `/production/strumis/contracts/${strumis_contract_id}/mark_instances/by_lots.json?lots_from=${lots_from}&lots_to=${lots_to}`;
  } else {
    url = `/production/strumis/contracts/${strumis_contract_id}/mark_instances/by_lots.json`;
  }
  const request = axios.get(url);
  return {
    type: GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES_BY_LOTS,
    payload: request,
  };
};

export const GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES = "GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES";
export const getProductionStrumisContractMarkInstances = (strumis_contract_id, name, groupBy, job_board_id) => {
  let url = `/production/strumis/contracts/${strumis_contract_id}/mark_instances/index.json?name=${name}&groupBy=${groupBy}`;
  if (job_board_id) {
    url += `&job_board_id=${job_board_id}`;
  }

  const request = axios.get(url);
  return {
    type: GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES,
    payload: request,
  };
};

export const GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES_INCOMPLETE_PAINT = "get_production_strumis_contract_mark_instances_incomplete_paint";
export const getProductionStrumisContractMarkInstancesIncompletePaint = () => {
  const url = `/production/strumis/contract/mark_instances/incomplete_paint.json`;
  const request = axios.get(url);

  return {
    type: GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES_INCOMPLETE_PAINT,
    payload: request,
  };
};

export const GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES_WITH_STAGE = "get_production_strumis_contract_mark_instances_with_stage";
export const getProductionStrumisContractMarkInstancesWithStage = (project_id, stage) => {
  const url = `/project/${project_id}/production/strumis/contracts/mark_instances/with_stage.json?stage=${stage}`;
  const request = axios.get(url);
  return {
    type: GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES_WITH_STAGE,
    payload: request,
  };
};

export const GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES_VIEW = "get_production_strumis_contract_mark_instances_view";
export const getProductionStrumisContractMarkInstancesView = (project_id) => {
  const url = `/project/${project_id}/production/strumis/contracts/mark_instances/view.json`;
  const request = axios.get(url);
  return {
    type: GET_PRODUCTION_STRUMIS_CONTRACT_MARK_INSTANCES_VIEW,
    payload: request,
  };
};
