import React from 'react';

const ProjectVariationNumbersIndex = React.lazy(() => import("./index"))
const NewProjectVariationNumbers = React.lazy(() => import("./new"))
const EditProjectVariationNumbers = React.lazy(() => import("./edit"))

export default[
	{path:'/project/:project_id/variation_numbers/:id/edit',component: EditProjectVariationNumbers,name:'Edit ProjectVariationNumber'},
	{path:'/project/:project_id/variation_numbers/new',component: NewProjectVariationNumbers,name:'New ProjectVariationNumber'},  
	{path:'/project/:project_id/variation_numbers',component: ProjectVariationNumbersIndex,name:'ProjectVariationNumbers'}, 
]

// import ProjectVariationNumbersRoutes from "modules/project/variation_numbers/web/routes"
// routes = _.concat(routes,ProjectVariationNumbersRoutes)
