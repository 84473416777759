import {
  GET_TRAINING_COURSES,
  GET_TRAINING_COURSE,
  CREATE_TRAINING_COURSE,
  UPDATE_TRAINING_COURSE,
  DESTROY_TRAINING_COURSE,
} from "./actions";

export function TrainingCourseReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_TRAINING_COURSES}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    case `${GET_TRAINING_COURSE}_FULFILLED`:
    case `${CREATE_TRAINING_COURSE}_FULFILLED`:
    case `${UPDATE_TRAINING_COURSE}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_TRAINING_COURSE}_FULFILLED`: {
      let newState = _.clone(state);
      const {
        meta: { id },
      } = action;
      _.unset(newState, id);
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { TrainingCourseReducer } from "modules/training/courses/reducer"

// training_courses:TrainingCourseReducer,
