import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Table, Button, Card, CardHeader, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import _ from "lodash";

// Helpers
import NotAuthorized from "modules/5_view_helpers/web/NotAuthorized";
import HasRole from "modules/5_view_helpers/HasRole";

// Actions
import { getProjectCommunicationsCustomReferences } from "../actions";

import { StatusTypes } from "modules/project/communications/shared.js";

function mapStateToProps(state, ownProps) {
  const { project_id } = ownProps;

  return {
    project: state.projects[project_id],
    project_communications_custom_references: state.project_communications_custom_references,
  };
}

const ProjectCommunicationsCustomReferencesIndex = (props) => {
  const { project_id, project, project_communications_custom_references } = props;

  useEffect(() => {
    if (project_id) props.getProjectCommunicationsCustomReferences(project_id);
  }, [project_id]);

  const readRole = HasRole({ resource_type: "Project::Communications::CustomReference", name: ["read", "moderator"] });
  const createRole = HasRole({ resource_type: "Project::Communications::CustomReference", name: ["create", "moderator"] });
  const updateRole = HasRole({ resource_type: "Project::Communications::CustomReference", name: ["update", "moderator"] });

  if (!readRole) {
    return <NotAuthorized />;
  } else {
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <h3>Custom references</h3>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
          {createRole && (
            <Row>
              <Col>
                <Link to={`/project/${project_id}/communications/custom_references/new`}>
                  <Button color="primary" size="md">
                    <i className="fa fa-plus"> Create New Custom reference</i>
                  </Button>
                </Link>
              </Col>
            </Row>
          )}

          {_.map(StatusTypes, (status_type, key) => (
            <CustomReferenceTable
              custom_references={_.filter(project_communications_custom_references, (s) => s.type_string == status_type.value)}
              project_id={project_id}
              status_type={status_type.label}
              updateRole={updateRole}
              project={project}
            />
          ))}
        </CardBody>
      </Card>
    );
  }
};
export default connect(mapStateToProps, { getProjectCommunicationsCustomReferences })(ProjectCommunicationsCustomReferencesIndex);

const CustomReferenceTable = ({ custom_references, project_id, status_type, updateRole, project }) => {
  return (
    <div className="mt-5">
      <h5>
        {project?.display_name} - {status_type} References
      </h5>
      <Table bordered hover>
        <thead>
          <th>Title</th>
          {updateRole && <th>Edit</th>}
        </thead>
        {_.map(custom_references, (custom_reference) => {
          return (
            <tr>
              <td>{custom_reference.title}</td>
              {updateRole && (
                <td>
                  <Link to={`/project/${project_id}/communications/statuses/${custom_reference.id}/edit`}>Edit</Link>
                </td>
              )}
            </tr>
          );
        })}
      </Table>
    </div>
  );
};
