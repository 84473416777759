import React from "react";
import { Page, BlockTitle, List, ListItem } from "framework7-react";
import HasRole from "modules/5_view_helpers/HasRole";

export const StoreSidebar = (props) => {
  const [, forceRerender] = React.useState();

  return (
    <Page>
      <BlockTitle>Store Menu</BlockTitle>
      <List>
        <ListItem title="Bookout Items" link="/store/bookout_items" panelClose onClick={forceRerender}></ListItem>
        <ListItem title="Back to Lion" link="/" panelClose onClick={forceRerender}></ListItem>
      </List>
    </Page>
  );
};

export default StoreSidebar;
