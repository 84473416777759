import { UPDATE_TEMP_DRAWINGS } from "./actions";

export function TempDrawingsReducer(state = [], action) {
  switch (action.type) {
    case `${UPDATE_TEMP_DRAWINGS}`: {
      return action.data;
    }
    default:
      return state;
  }
}
