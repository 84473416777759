import React from "react";

import { Link } from 'react-router-dom'

const columns = (updateRole = false) => {
	let returnArray = [
		
			{
				text: 'Name',
				dataField: 'name',
				
			},
		
	];

	if (updateRole) {
		returnArray.push({
			text: 'Edit',
			dataField: 'edit',
      		csvExport: false,
			formatter:(cell,row) => <Link to={`/project/qa/welding_inspections/fillet_sizes/${row.id}/edit`}>Edit</Link>
		});
	}

	return returnArray
};

export default columns
