import React from 'react';

const ProjectQaWeldingInspectionsFailureReasonsIndex = React.lazy(() => import("./index"))
const NewProjectQaWeldingInspectionsFailureReasons = React.lazy(() => import("./new"))
const EditProjectQaWeldingInspectionsFailureReasons = React.lazy(() => import("./edit"))

export default[
	{path:'/project/qa/welding_inspections/failure_reasons/:id/edit',component: EditProjectQaWeldingInspectionsFailureReasons,name:'Edit ProjectQaWeldingInspectionsFailureReason'},
	{path:'/project/qa/welding_inspections/failure_reasons/new',component: NewProjectQaWeldingInspectionsFailureReasons,name:'New ProjectQaWeldingInspectionsFailureReason'},  
	{path:'/project/qa/welding_inspections/failure_reasons',component: ProjectQaWeldingInspectionsFailureReasonsIndex,name:'ProjectQaWeldingInspectionsFailureReasons'}, 
]

// import ProjectQaWeldingInspectionsFailureReasonsRoutes from "modules/project/qa/welding_inspections/failure_reasons/web/routes"
// routes = _.concat(routes,ProjectQaWeldingInspectionsFailureReasonsRoutes)
