import React from "react";

import { Link } from "react-router-dom";

const columns = (updateRole = false) => {
  let returnArray = [
    {
      text: "Name",
      dataField: "name",
    },
    {
      text: "Details",
      dataField: "details_string",
    },
  ];

  if (updateRole) {
    returnArray.push({
      text: "Edit",
      dataField: "edit",
      csvExport: false,
      formatter: (cell, row) => <Link to={`/project/qa/welding_inspections/failure_reasons/${row.id}/edit`}>Edit</Link>,
    });
  }

  return returnArray;
};

export default columns;
