import HasRole from "modules/5_view_helpers/HasRole";

const getHireageNav = () => {
  const HireageRoles = HasRole({ category: "Hireages" });




  if (HireageRoles) {
    let hireageMenu = {};
    _.set(hireageMenu, "icon", "fa fa-calendar");
    _.set(hireageMenu, "label", "Hireages");
    _.set(hireageMenu, "content", []);
    let content = _.get(hireageMenu, "content", []);



    const HireExternalRoles = HasRole({
      resource_type: "Hireages::External",
    });
    if (HireExternalRoles) {
      content.push({
        label: "External",
        to: "/hireages/externals",
      });
    }

    const HireCategoryRoles = HasRole({
      resource_type: "Hireages::Category",
    });
    if (HireCategoryRoles) {
      content.push({
        label: "Categories",
        to: "/hireages/categories",
      });
    }

    return hireageMenu;
  }
};

export default getHireageNav;
