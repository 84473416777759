import axios from "axios";

const prepareData = (data) => {return {project_drawing:data}}

// Plural Actions
export const GET_PROJECT_DRAWINGS = "get_project_drawings";
export const getProjectDrawings = (project_id) => {
	const url = `/project/${project_id}/drawings.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_DRAWINGS,
		payload: request
	};
};


// Singular Actions
export const CREATE_PROJECT_DRAWING = "create_project_drawing";
export const createProjectDrawing = (project_id,data) => {
	const url = `/project/${project_id}/drawings.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PROJECT_DRAWING,
		payload: request
	};
};

export const GET_PROJECT_DRAWING = "get_project_drawing";
export const getProjectDrawing = (project_id,id) => {
	const url = `/project/${project_id}/drawings/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_DRAWING,
		payload: request
	};
};

export const UPDATE_PROJECT_DRAWING = "update_project_drawing";
export const updateProjectDrawing = (project_id,id,data) => {
	const url = `/project/${project_id}/drawings/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PROJECT_DRAWING,
		payload: request
	};
};

export const DESTROY_PROJECT_DRAWING = "destroy_project_drawing";
export const destroyProjectDrawing = (project_id,id) => {
	const url = `/project/${project_id}/drawings/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PROJECT_DRAWING,
		payload: request
	};
};
