import {
  GET_TRAINING_COMPANIES,
  GET_TRAINING_COMPANY,
  CREATE_TRAINING_COMPANY,
  UPDATE_TRAINING_COMPANY,
  DESTROY_TRAINING_COMPANY,
} from "./actions";

export function TrainingCompanyReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_TRAINING_COMPANIES}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    case `${GET_TRAINING_COMPANY}_FULFILLED`:
    case `${CREATE_TRAINING_COMPANY}_FULFILLED`:
    case `${UPDATE_TRAINING_COMPANY}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_TRAINING_COMPANY}_FULFILLED`: {
      let newState = _.clone(state);
      const {
        meta: { id },
      } = action;
      _.unset(newState, id);
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { TrainingCompanyReducer } from "modules/training/companies/reducer"

// training_companies:TrainingCompanyReducer,
