import { 
	GET_V2_MAINTENANCE_ITEMS, 
} from "./actions"

export function V2MaintenanceItemsReducer(state = [], action) {
	switch (action.type) {
		case `${GET_V2_MAINTENANCE_ITEMS}_FULFILLED`:{
			return _.mapKeys(action.payload.data, "id");
		}
		default:
			return state;
	}
}

