import React from 'react';

const PaintSurfacePrepsIndex = React.lazy(() => import("./index"))
const NewPaintSurfacePreps = React.lazy(() => import("./new"))
const EditPaintSurfacePreps = React.lazy(() => import("./edit"))

export default[
	{path:'/paint/surface_preps/:id/edit',component: EditPaintSurfacePreps,name:'Edit PaintSurfacePrep'},
	{path:'/paint/surface_preps/new',component: NewPaintSurfacePreps,name:'New PaintSurfacePrep'},  
	{path:'/paint/surface_preps',component: PaintSurfacePrepsIndex,name:'PaintSurfacePreps'}, 
]

// import PaintSurfacePrepsRoutes from "modules/paint/surface_preps/web/routes"
// routes = _.concat(routes,PaintSurfacePrepsRoutes)
