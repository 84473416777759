import React from "react";

import QAIndex from "./index";
import ProjectQaSiteSiteInstallationRoutes from "../site/site_installations/mobile/routes";
import ProjectQaActionItemsRoutes from "modules/project/qa/action_items/mobile/routes";
import ProjectQaSiteSurveillancesRoutes from "modules/project/qa/site/surveillances/mobile/routes.js";
import ProjectQaPaintInspectionRoutes from "modules/project/qa/paint_inspections/mobile/routes";

let routes = [{ path: "/project/qa", component: QAIndex, name: "QA Index" }];
routes = _.concat(routes, ProjectQaSiteSiteInstallationRoutes);
routes = _.concat(routes, ProjectQaActionItemsRoutes);
routes = _.concat(routes, ProjectQaSiteSurveillancesRoutes);
routes = _.concat(routes, ProjectQaPaintInspectionRoutes);

export default routes;
