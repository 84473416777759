import React from 'react';

const AddressBookCompanyContactsIndex = React.lazy(() => import("./index"))
const NewAddressBookCompanyContacts = React.lazy(() => import("./new"))
const EditAddressBookCompanyContacts = React.lazy(() => import("./edit"))

export default[
	{path:'/address_book/company/contacts/:id/edit',component: EditAddressBookCompanyContacts,name:'Edit AddressBookCompanyContact'},
	{path:'/address_book/company/contacts/new',component: NewAddressBookCompanyContacts,name:'New AddressBookCompanyContact'},  
	{path:'/address_book/company/contacts',component: AddressBookCompanyContactsIndex,name:'AddressBookCompanyContacts'}, 
]

// import AddressBookCompanyContactsRoutes from "modules/address_book/company/contacts/web/routes"
// routes = _.concat(routes,AddressBookCompanyContactsRoutes)
