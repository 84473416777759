import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, CardHeader } from "reactstrap";
import classnames from "classnames";

import AllProjectsSiteQADashboard from "./dashboard/all_projects";
const CurrentProjectSiteQA = React.lazy(() => import("modules/project/qa/site/web"));
import qs from "query-string";

const SiteQADashboard = (props) => {
  const query = qs.parse(props.location.search);
  const { tab } = query;

  const {
    history: { push },
  } = props;
  const [activeTab, setActiveTab] = useState(tab ? tab : "current_project");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Card>
      <CardHeader>Site QA Dashboard</CardHeader>

      <CardBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "current_project",
              })}
              onClick={() => {
                toggle("current_project");
                push("/projects/qa/site/dashboard?tab=current_project");
              }}
            >
              Current Project
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "all_projects" })}
              onClick={() => {
                toggle("all_projects");
                push("/projects/qa/site/dashboard?tab=all_projects");
              }}
            >
              All Projects
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="current_project">{activeTab == "current_project" && <CurrentProjectSiteQA {...props} />}</TabPane>
          <TabPane tabId="all_projects">{activeTab == "all_projects" && <AllProjectsSiteQADashboard {...props} />}</TabPane>
        </TabContent>
      </CardBody>
    </Card>
  );
};

export default SiteQADashboard;
