import React, { Fragment, useState } from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFilePdf, faFileWord, faFileExcel, faFilePowerpoint } from "@fortawesome/free-regular-svg-icons";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import "./ReactDropzone.scss";
import { BarLoader } from "react-spinners";
import jsonFormData from "json-form-data";
import Dropzone from "react-dropzone";
import { useFormState } from "react-final-form";
import _ from "lodash";
import { connect } from "react-redux";

import { addReactDropzoneFileUploading, removeReactDropzoneFileUploading } from "../ReactDropzone/actions";

const imageExt = ["png", "jpg", "jpeg", "gif", "bmp"];

function mapStateToProps(state) {
  return {
    react_dropzone_status: state.react_dropzone_status,
  };
}

const ReactDropzone = (props) => {
  const {
    placeholder,
    filesAccepted,
    maxSize,
    maxFiles,
    fields,
    attachmentName,
    imageOnly,
    disabled,
    meta: { error },
    addProps,
    filterBy,
    uploadNow,
  } = props;

  const filtered_values = filterBy
    ? _.filter(fields.value, (file) => file[filterBy] == props?.field_name && !file._destroy)
    : _.filter(fields.value, (file) => !file._destroy);
  const [fileToRemove, setFileToRemove] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [state, setState] = useState(0);
  const toggleRemoveModal = () => setDeleteModal(!deleteModal);

  const removeFile = () => {
    const thisField = fields.value[fileToRemove];

    if (thisField?.id) {
      fields.update(fileToRemove, { id: thisField.id, _destroy: "1" });
    } else {
      fields.remove(fileToRemove);
    }
  };

  const addFile = (fileIo, fileAddedIndex) => {
    let fileObject = {
      name: fileIo.name,
      size: fileIo.size,
      type: fileIo.type,
      url: URL.createObjectURL(fileIo),
      ...addProps,
    };

    if (uploadNow) {
      // Calculate the index of where the file will be added into fields
      // If there are multiple files being uploaded, the index will be the length of the fields + the index of the file being added
      const fileIndex = fields.value?.length + fileAddedIndex || 0 + fileAddedIndex;
      fields.push({ uploading: true, ...fileObject });
      props.addReactDropzoneFileUploading();

      // Upload the fileAccepted
      const data = jsonFormData({ attachment: fileIo }, { showLeafArrayIndexes: true });
      axios.post("/attachments/create", data).then((response) => {
        // Once the file is uploaded, update the file object and remove the loading and set uploaded to true
        fileObject.blob_signed_id = response.data.blob_signed_id;
        fields.update(fileIndex, { uploading: false, uploaded: true, ...fileObject });
        props.removeReactDropzoneFileUploading();
      });
    } else {
      fileObject[attachmentName] = fileIo;
      fields.push(fileObject);
    }
  };

  const onDrop = (acceptedFiles) => {
    _.each(acceptedFiles, (fileAccepted, i) => {
      addFile(fileAccepted, i);
    });
  };

  const validator = (newFile) => {
    if (_.some(filtered_values, (file) => newFile.name == file.name && newFile.size == file.size)) {
      return {
        code: "duplicate-file",
        message: `File already exists`,
      };
    }
  };

  const onDropRejected = (errorArray) => {
    _.each(errorArray, (errorObject) => {
      _.each(errorObject.errors, (e) => {
        if (e.message == "File type must be image/*") {
          toastr.error(`${errorObject.file.name} File must be an image`);
        } else {
          toastr.error(`${errorObject.file.name} ${e.message}`);
        }
      });
    });
  };

  // Dropzone
  return (
    <Fragment>
      <Dropzone
        onDrop={onDrop}
        disabled={disabled}
        accept={imageOnly ? "image/*" : filesAccepted}
        maxSize={maxSize}
        maxFiles={maxFiles}
        validator={validator}
        onDropRejected={onDropRejected}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            {_.isEmpty(filtered_values) && <p className="placeholder-text">{placeholder}</p>}
            <aside className="thumbsContainer">
              <Gallery withDownloadButton>
                {fields.map((field, i) => {
                  const thisField = fields.value[i];
                  const markedForDestruction = _.get(thisField, "_destroy");
                  if (props?.field_name && filterBy && thisField[filterBy] != props?.field_name) return null;
                  if (!markedForDestruction) {
                    return (
                      <FilePreview
                        file={thisField}
                        key={i}
                        i={i}
                        setFileToRemove={setFileToRemove}
                        toggleRemoveModal={toggleRemoveModal}
                        disabled={disabled}
                      />
                    );
                  }
                })}
              </Gallery>
            </aside>
          </div>
        )}
      </Dropzone>
      <RemoveModal
        removeFile={removeFile}
        toggleRemoveModal={toggleRemoveModal}
        deleteModal={deleteModal}
        fields={fields}
        fileToRemove={fileToRemove}
      />
      {error && <div className="text-danger">{error}</div>}
    </Fragment>
  );
};

ReactDropzone.defaultProps = {
  placeholder: "Drag 'n' drop some files here, or click to select files",
  maxSize: 20000000,
  attachmentName: "attachment",
  uploadNow:false
};

export default connect(mapStateToProps, { addReactDropzoneFileUploading, removeReactDropzoneFileUploading })(ReactDropzone);

const FilePreview = (props) => {
  let { file, setFileToRemove, toggleRemoveModal, i } = props;

  // Find file extension for preview image
  let ext = file?.name?.split(".").pop().toLowerCase();

  const displayFileSize = file.size < 1000000 ? `${(file.size / 1000).toFixed(2)} kB` : `${(file.size / 1000000).toFixed(2)} MB`;
  const isImage = _.includes(imageExt, ext);

  const style = file?.uploaded ? { borderColor: "green", borderWidth: "2px" } : {};

  return (
    <div className="preview-container">
      <button
        type="button"
        className="remove-file"
        // e.stopPropagation to prevent dropzone opening
        onClick={(e) => {
          e.stopPropagation();
          setFileToRemove(i);
          toggleRemoveModal();
        }}
      />
      {file.uploading && <BarLoader loading={true} />}
      {!file.uploading && <>{isImage ? <ImagePreview file={file} style={style} /> : <OtherFilesPreview style={style} ext={ext} />}</>}
      <div
        className="file-name"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <a href={file.url} target="_blank" title={file.name}>
          {file.name}
        </a>
      </div>
      <div className="file-size">{displayFileSize}</div>
    </div>
  );
};

const ImagePreview = ({ file, style }) => {
  let imageData = new Image();
  imageData.src = file.url;
  return (
    // For images
    <a
      href={file.url}
      target="_blank"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <div className="thumb" style={style}>
        <div className="thumbInner">
          <Item original={file.url} width={imageData.width} height={imageData.height}>
            {({ ref, open }) => <img className="img" ref={ref} onClick={open} src={file.url} />}
          </Item>
        </div>
      </div>
    </a>
  );
};

const OtherFilesPreview = ({ style, ext }) => {
  let icon;
  let iconColor;

  // Set the icon and color depending on the file type
  if (_.includes(ext, "pdf")) {
    icon = faFilePdf;
    iconColor = "#ff1b0e";
  } else if (_.includes(ext, "doc" || "docx")) {
    icon = faFileWord;
    iconColor = "#185abd";
  } else if (_.includes(ext, "xls")) {
    icon = faFileExcel;
    iconColor = "#107c40";
  } else if (_.includes(ext, "ppt")) {
    icon = faFilePowerpoint;
    iconColor = "#c53e1c";
  } else {
    icon = faFile;
  }

  return (
    // For other files
    <div
      className="thumb"
      style={{ cursor: "default", ...style }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="thumbInnerNoImg">
        <FontAwesomeIcon icon={icon} size="3x" color={iconColor} />
      </div>
    </div>
  );
};

const RemoveModal = (props) => {
  const { deleteModal, toggleRemoveModal, removeFile, fields, fileToRemove } = props;

  let fileName;

  // Did this because 0 was causing it to not change the fileName
  if (_.isNotEmpty(fields.value)) {
    fileName = fields.value[fileToRemove]?.name;
  }

  return (
    <div>
      <Modal isOpen={deleteModal} toggle={toggleRemoveModal}>
        <ModalHeader toggle={toggleRemoveModal}>Remove File</ModalHeader>
        <ModalBody>Are you sure you want to remove {fileName}?</ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={() => {
              removeFile();
              toggleRemoveModal();
            }}
          >
            Yes
          </Button>{" "}
          <Button color="secondary" onClick={toggleRemoveModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
