// Plural Actions
export const GET_V2_MAINTENANCE_CATEGORIES = "GET_V2_MAINTENANCE_CATEGORIES";
export const getV2MaintenanceCategories = () => {
  // Fetch categories from LionV2 API
  const request = axios.get("/v2/maintenance/categories.json")

  return {
    type: GET_V2_MAINTENANCE_CATEGORIES,
    payload: request,
  };
};
