import axios from "axios";

const prepareData = (data) => {return {project_qa_welding_inspections_failure_reason:data}}

// Plural Actions
export const GET_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASONS = "get_project_qa_welding_inspections_failure_reasons";
export const getProjectQaWeldingInspectionsFailureReasons = () => {
	const url = `/project/qa/welding_inspections/failure_reasons.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASONS,
		payload: request
	};
};


// Singular Actions
export const CREATE_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASON = "create_project_qa_welding_inspections_failure_reason";
export const createProjectQaWeldingInspectionsFailureReason = (data) => {
	const url = `/project/qa/welding_inspections/failure_reasons.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASON,
		payload: request
	};
};

export const GET_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASON = "get_project_qa_welding_inspections_failure_reason";
export const getProjectQaWeldingInspectionsFailureReason = (id) => {
	const url = `/project/qa/welding_inspections/failure_reasons/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASON,
		payload: request
	};
};

export const UPDATE_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASON = "update_project_qa_welding_inspections_failure_reason";
export const updateProjectQaWeldingInspectionsFailureReason = (id,data) => {
	const url = `/project/qa/welding_inspections/failure_reasons/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASON,
		payload: request
	};
};

export const DESTROY_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASON = "destroy_project_qa_welding_inspections_failure_reason";
export const destroyProjectQaWeldingInspectionsFailureReason = (id) => {
	const url = `/project/qa/welding_inspections/failure_reasons/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PROJECT_QA_WELDING_INSPECTIONS_FAILURE_REASON,
		payload: request
	};
};
