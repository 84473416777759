import axios from "axios";

const prepareData = (data) => {return {project_qa_welding_inspections_welding_procedure:data}}

// Plural Actions
export const GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCEDURES = "get_project_qa_welding_inspections_welding_procedures";
export const getProjectQaWeldingInspectionsWeldingProcedures = () => {
	const url = `/project/qa/welding_inspections/welding_procedures.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCEDURES,
		payload: request
	};
};


// Singular Actions
export const CREATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCEDURE = "create_project_qa_welding_inspections_welding_procedure";
export const createProjectQaWeldingInspectionsWeldingProcedure = (data) => {
	const url = `/project/qa/welding_inspections/welding_procedures.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCEDURE,
		payload: request
	};
};

export const GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCEDURE = "get_project_qa_welding_inspections_welding_procedure";
export const getProjectQaWeldingInspectionsWeldingProcedure = (id) => {
	const url = `/project/qa/welding_inspections/welding_procedures/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCEDURE,
		payload: request
	};
};

export const UPDATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCEDURE = "update_project_qa_welding_inspections_welding_procedure";
export const updateProjectQaWeldingInspectionsWeldingProcedure = (id,data) => {
	const url = `/project/qa/welding_inspections/welding_procedures/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCEDURE,
		payload: request
	};
};

export const DESTROY_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCEDURE = "destroy_project_qa_welding_inspections_welding_procedure";
export const destroyProjectQaWeldingInspectionsWeldingProcedure = (id) => {
	const url = `/project/qa/welding_inspections/welding_procedures/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PROJECT_QA_WELDING_INSPECTIONS_WELDING_PROCEDURE,
		payload: request
	};
};
