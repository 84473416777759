import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Card, CardHeader, CardBody, Table, UncontrolledPopover, PopoverBody, PopoverHeader } from "reactstrap";

import { getAllProjectAreas } from "modules/project/areas/actions";

const mapStateToProps = (state) => {
  return {
    project_areas: state.project_areas,
  };
};

export class SiteQADashboard extends Component {
  constructor(props) {
    super(props);

    this.props.getAllProjectAreas();
  }

  render() {
    const { project_areas } = this.props;

    const ordered_project_areas = _.orderBy(project_areas, "site_installation_qa_date");

    return (
      <Card>
        <CardBody>
          <h3>Site QA Dashboard - All Projects</h3>
          <hr />

          <Table hover size="sm">
            <thead>
              <tr>
                <th>Project</th>
                <th>Area</th>
                <th>Site Installation QA Estimated Date</th>
              </tr>
            </thead>
            <tbody>
              {_.map(ordered_project_areas, (area, i) => (
                <tr key={i}>
                  <td>{area.project_display_name}</td>
                  <td>{area.name}</td>
                  {area.site_installation_qa ? <SiteInstallationQARow area={area} /> : <td>Not Required</td>}
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}

export default connect(mapStateToProps, { getAllProjectAreas })(SiteQADashboard);

const SiteInstallationQARow = (props) => {
  const { area } = props;

  return (
    <td>
      {_.isEmpty(area?.qa_site_site_installations) && <div>Not Started Due {moment(area?.site_installation_qa_date).format("DD/MM/YYYY")}</div>}
      {_.isNotEmpty(area?.qa_site_site_installations) && <SiteInstallPopover area={area} />}
    </td>
  );
};

const SiteInstallPopover = (props) => {
  const {
    area,
    area: { qa_site_site_installations, checklist_items },
  } = props;

  const sorted_checklist_items = _.sortBy(checklist_items, "order");

  return (
    <Fragment>
      <a
        style={{ cursor: "pointer", color: area.site_installation_qa_complete ? "green" : area.site_installation_qa_color }}
        id={`Popover-${area.id}`}
      >
        {moment(area.site_installation_qa_date).format("DD/MM/YYYY")}
        {qa_site_site_installations.length == 1 && ` - ${qa_site_site_installations[0].sections_complete}/${area.total_sections} Sections Complete`}
        {qa_site_site_installations.length > 1 && ` - ${qa_site_site_installations.length} QA Documents`}
      </a>
      <UncontrolledPopover placement="right" trigger="legacy" fade={false} target={`Popover-${area.id}`}>
        <PopoverHeader>
          <b>{area.name} Site Installation QA</b>
        </PopoverHeader>
        <PopoverBody>
          <Table size="sm">
            <thead>
              <tr>
                <th>Date Done</th>
                {_.map(qa_site_site_installations, (si, i) => (
                  <th key={i}>{si.display_name_without_area}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {_.map(sorted_checklist_items, (cl_item, i) => (
                <tr key={i}>
                  <td>{cl_item.name}</td>
                  {_.map(qa_site_site_installations, (si, i) => (
                    <td key={i}>
                      <Link to={`/project/${area.project_id}/qa/site/site_installations/${si.id}/edit?tab=${cl_item.id}`} target="_blank">
                        {_.find(si.checklist_item_links, (siclil) => siclil.checklist_item_id == cl_item.id)?.toggle_value
                          ? "Complete"
                          : "Not Complete"}
                      </Link>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </PopoverBody>
      </UncontrolledPopover>
    </Fragment>
  );
};
