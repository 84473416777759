import React from "react";

import { Link } from 'react-router-dom'

const columns = (updateRole = false) => {
	let returnArray = [
		
			{
				text: 'Name',
				dataField: 'name',
				
			},
		
			{
				text: 'Address',
				dataField: 'address',
				
			},
		
	];

	if (updateRole) {
		returnArray.push({
			text: 'Edit',
			dataField: 'edit',
      		csvExport: false,
			formatter:(cell,row) => <Link to={`/address_book/companies/${row.id}/edit`}>Edit</Link>
		});
	}

	return returnArray
};

export default columns
