import React from "react";

import ProjectCommunicationsStatusesRoutes from "modules/project/communications/statuses/web/routes";
import ProjectCommunicationsOutgoingTypesRoutes from "modules/project/communications/outgoing_types/web/routes";

const ProjectCommunicationsIndex = React.lazy(() => import("./index"));
const NewProjectCommunications = React.lazy(() => import("./new"));
const ShowProjectCommunications = React.lazy(() => import("./show"));
const EditProjectCommunications = React.lazy(() => import("./edit"));

let routes = [
  { path: "/project/:project_id/communications/:id/edit", component: EditProjectCommunications, name: "Edit ProjectCommunication" },
  { path: "/project/:project_id/communications/new", component: NewProjectCommunications, name: "New ProjectCommunication" },
  { path: "/project/:project_id/communications/:id", component: ShowProjectCommunications, name: "Show ProjectCommunication" },
  { path: "/project/:project_id/communications", component: ProjectCommunicationsIndex, name: "ProjectCommunications" },
];

routes = _.concat(routes, ProjectCommunicationsStatusesRoutes);
routes = _.concat(routes, ProjectCommunicationsOutgoingTypesRoutes);

export default routes;
// import ProjectCommunicationsRoutes from "modules/project/communications/web/routes"
// routes = _.concat(routes,ProjectCommunicationsRoutes)
