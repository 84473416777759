import axios from "axios";

const prepareData = (data) => {
  return { employee_training_requirement: data };
};

// Plural Actions
export const GET_ALL_EMPLOYEE_TRAINING_REQUIREMENTS =
  "get_all_employee_training_requirements";
export const getAllTrainingRequirements = () => {
  const url = `/employee/training_requirements/get_all.json`;
  const request = axios.get(url);
  return {
    type: GET_ALL_EMPLOYEE_TRAINING_REQUIREMENTS,
    payload: request,
  };
};

export const GET_EMPLOYEE_TRAINING_REQUIREMENTS =
  "get_employee_training_requirements";
export const getEmployeeTrainingRequirements = (employee_id) => {
  const url = `/employee/${employee_id}/training_requirements.json`;
  const request = axios.get(url);
  return {
    type: GET_EMPLOYEE_TRAINING_REQUIREMENTS,
    payload: request,
  };
};

// Singular Actions
export const CREATE_EMPLOYEE_TRAINING_REQUIREMENT =
  "create_employee_training_requirement";
export const createEmployeeTrainingRequirement = (employee_id, data) => {
  const url = `/employee/${employee_id}/training_requirements.json`;
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_EMPLOYEE_TRAINING_REQUIREMENT,
    payload: request,
  };
};

export const GET_EMPLOYEE_TRAINING_REQUIREMENT =
  "get_employee_training_requirement";
export const getEmployeeTrainingRequirement = (employee_id, id) => {
  const url = `/employee/${employee_id}/training_requirements/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_EMPLOYEE_TRAINING_REQUIREMENT,
    payload: request,
  };
};

export const UPDATE_EMPLOYEE_TRAINING_REQUIREMENT =
  "update_employee_training_requirement";
export const updateEmployeeTrainingRequirement = (employee_id, id, data) => {
  const url = `/employee/${employee_id}/training_requirements/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_EMPLOYEE_TRAINING_REQUIREMENT,
    payload: request,
  };
};

export const DESTROY_EMPLOYEE_TRAINING_REQUIREMENT =
  "destroy_employee_training_requirement";
export const destroyEmployeeTrainingRequirement = (employee_id, id) => {
  const url = `/employee/${employee_id}/training_requirements/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_EMPLOYEE_TRAINING_REQUIREMENT,
    payload: request,
    meta: { id: id },
  };
};
