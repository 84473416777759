import axios from "axios";

// Plural Actions
export const GET_PRODUCTION_STRUMIS_CONTRACT_MARK_ITEM_INSTANCES_BY_LOTS = "GET_PRODUCTION_STRUMIS_CONTRACT_MARK_ITEM_INSTANCES_BY_LOTS";
export const getProductionStrumisContractMarkItemInstancesByLots = (
  strumis_contract_id,lots_from,lots_to
) => {
  let url;
  if (lots_from && lots_to) {
    url = `/production/strumis/contracts/${strumis_contract_id}/mark_item_instances/by_lots.json?lots_from=${lots_from}&lots_to=${lots_to}`;
  } else {
    url = `/production/strumis/contracts/${strumis_contract_id}/mark_item_instances/by_lots.json`;
  }

  const request = axios.get(url);
  return {
    type: GET_PRODUCTION_STRUMIS_CONTRACT_MARK_ITEM_INSTANCES_BY_LOTS,
    payload: request,
  };
};
