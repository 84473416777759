import React, { useState } from "react";
import { connect } from "react-redux";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody } from "reactstrap";
import classnames from "classnames";
import HasRole from "modules/5_view_helpers/HasRole";

import DrawingOfficeDrawingTypesIndex from "modules/drawing_office/drawing_types/web/index";
import ProjectCommunicationsStatusesIndex from "modules/project/communications/statuses/web/index";
import ProjectCommunicationsOutgoingTypesIndex from "modules/project/communications/outgoing_types/web/index.jsx";
import ProjectCommunicationsCustomReferencesIndex from "modules/project/communications/custom_references/web/index.jsx";
import qs from "query-string";

function mapStateToProps(state) {
  return {
    current_user: state.current_user,
  };
}

const DrawingOfficeSettings = (props) => {
  const query = qs.parse(props.location.search);
  const tab = query.tab || "drawing_types";

  const { current_user } = props;

  const [activeTab, setActiveTab] = useState(tab);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const drawingTypesRoles = HasRole({ resource_type: "DrawingOffice::DrawingType", name: ["read", "moderator"] });
  const communicationStatusesRoles = HasRole({ resource_type: "Project::Communications::Status", name: ["read", "moderator"] });
  const outgoingTypesRoles = HasRole({ resource_type: "Project::Communications::OutgoingType", name: ["read", "moderator"] });
  const customReferencesRoles = HasRole({ resource_type: "Project::Communications::CustomReference", name: ["read", "moderator"] });

  return (
    <Card>
      <CardBody>
        <Nav tabs>
          {drawingTypesRoles && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "drawing_types" })}
                onClick={() => {
                  toggle("drawing_types");
                }}
              >
                Drawing Types
              </NavLink>
            </NavItem>
          )}
          {communicationStatusesRoles && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "communication_statuses" })}
                onClick={() => {
                  toggle("communication_statuses");
                }}
              >
                Communication Statuses
              </NavLink>
            </NavItem>
          )}
          {outgoingTypesRoles && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "outgoing_types" })}
                onClick={() => {
                  toggle("outgoing_types");
                }}
              >
                Outgoing Types
              </NavLink>
            </NavItem>
          )}
          {customReferencesRoles && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "custom_references" })}
                onClick={() => {
                  toggle("custom_references");
                }}
              >
                Custom References
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={activeTab}>
          {drawingTypesRoles && (
            <TabPane tabId="drawing_types">
              <DrawingOfficeDrawingTypesIndex {...props} />
            </TabPane>
          )}
          {communicationStatusesRoles && (
            <TabPane tabId="communication_statuses">
              <ProjectCommunicationsStatusesIndex {...props} />
            </TabPane>
          )}
          {outgoingTypesRoles && (
            <TabPane tabId="outgoing_types">
              <ProjectCommunicationsOutgoingTypesIndex {...props} />
            </TabPane>
          )}
          {customReferencesRoles && (
            <TabPane tabId="custom_references">
              <ProjectCommunicationsCustomReferencesIndex project_id={current_user?.current_project_id} {...props} />
            </TabPane>
          )}
        </TabContent>
      </CardBody>
    </Card>
  );
};

export default connect(mapStateToProps, {})(DrawingOfficeSettings);
