import {
  GET_ALL_EMPLOYEE_QUALIFICATIONS,
  GET_EMPLOYEE_QUALIFICATIONS,
  GET_EMPLOYEE_QUALIFICATION,
  CREATE_EMPLOYEE_QUALIFICATION,
  UPDATE_EMPLOYEE_QUALIFICATION,
  DESTROY_EMPLOYEE_QUALIFICATION,
} from "./actions";

export function EmployeeQualificationsReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_ALL_EMPLOYEE_QUALIFICATIONS}_FULFILLED`:
    case `${GET_EMPLOYEE_QUALIFICATIONS}_FULFILLED`: {
      if (_.isNotEmpty(state)) {
        const newState = _.clone(state);
        return _.merge(newState, _.mapKeys(action.payload.data, "id"));
      } else {
        return _.mapKeys(action.payload.data, "id");
      }
    }
    case `${GET_EMPLOYEE_QUALIFICATION}_FULFILLED`:
    case `${CREATE_EMPLOYEE_QUALIFICATION}_FULFILLED`:
    case `${UPDATE_EMPLOYEE_QUALIFICATION}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_EMPLOYEE_QUALIFICATION}_FULFILLED`: {
      let newState = _.clone(state);
      const {
        meta: { id },
      } = action;
      _.unset(newState, id);
      return newState;
    }
    default:
      return state;
  }
}
