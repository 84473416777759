import {
  GET_ALL_PROJECT_AREAS,
  GET_PROJECT_AREAS,
  GET_PROJECT_AREA,
  CREATE_PROJECT_AREA,
  UPDATE_PROJECT_AREA,
  DESTROY_PROJECT_AREA,
} from "./actions";

export function ProjectAreaReducer(state = {}, action) {
  switch (action.type) {
    case `${GET_ALL_PROJECT_AREAS}_FULFILLED`:
    case `${GET_PROJECT_AREAS}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    case `${GET_PROJECT_AREA}_FULFILLED`:
    case `${CREATE_PROJECT_AREA}_FULFILLED`:
    case `${UPDATE_PROJECT_AREA}_FULFILLED`: {
      let newState = _.clone(state);
      const newItem = action.payload.data;
      const id = _.get(newItem, "id");
      newState[id] = newItem;
      return newState;
    }
    case `${DESTROY_PROJECT_AREA}_FULFILLED`: {
      let newState = _.clone(state);
      const {
        meta: { id },
      } = action;
      _.unset(newState, id);
      return newState;
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { ProjectAreaReducer } from "modules/project/areas/reducer"

// project_areas:ProjectAreaReducer,
