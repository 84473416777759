import React, { Fragment, useEffect } from "react";
import { Field } from "react-final-form";

import { InputField, ListArray, required, SmartSelect, ReactDropzone } from "modules/4_final_form/mobile";
import { FieldArray } from "react-final-form-arrays";

import { Block, BlockTitle, List } from "framework7-react";

const ProjectAreaTab = (props) => {
  const {
    project_area,
    form: { change },
    values: { area_links_attributes },
    users,
    project_qa_action_items_statuses,
    current_user,
    project_id,
    handleSubmit,
    index,
  } = props;

  let arrayKey = _.findKey(area_links_attributes, (al) => al.value == project_area?.id);
  const findKey = arrayKey ? arrayKey : index;
  const fieldArrayName = `area_links_attributes[${findKey}]`;

  useEffect(() => {
    if (project_area) {
      change(`${fieldArrayName}.project_area_id`, project_area.id);
    }
  }, []);

  let addProps = {
    project_id: project_id,
    project_area_links_attributes: [{ project_area_id: project_area?.id }],
    author_id: current_user.id,
  };

  return (
    <Block>
      <List>
        <BlockTitle slot="list">{project_area?.name}</BlockTitle>
        <Field label="Comments" component={InputField} type="textarea" name={`${fieldArrayName}.comments`} slot="list" />
        <BlockTitle slot="list">Area Surveillance Attachments</BlockTitle>
        <FieldArray component={ReactDropzone} name={`${fieldArrayName}.attachments_attributes`} slot="list" uploadNow />
        <Block slot="list">
          <FieldArray
            component={ListArray}
            name={`${fieldArrayName}.action_items_attributes`}
            slot="list"
            columns={[
              {
                name: "description",
                label: "Description",
                type: "textarea",
                placeholder: "Type description here...",
                component: InputField,
                validate: required,
              },
              {
                name: "user_links_attributes",
                label: "User/s Responsible",
                component: SmartSelect,
                type: "select",
                multi: true,
                labelName: "label",
                valueName: "value",
                options: _.map(users, (user) => {
                  return {
                    label: user.name,
                    value: user.id,
                    user_id: user.id,
                  };
                }),
              },
              {
                name: "status_id",
                label: "Status",
                placeholder: "Select status",
                component: SmartSelect,
                type: "select",
                labelName: "label",
                valueName: "value",
                validate: required,
                openIn: "popover",
                options: _.map(project_qa_action_items_statuses, (status) => {
                  return {
                    label: status.name,
                    value: status.id,
                  };
                }),
              },
              {
                name: "attachments_attributes",
                label: "Attachments",
                fieldArray: true,
                component: ReactDropzone,
                array: true,
                uploadNow: true,
              },
            ]}
            label="Action Items"
            itemLabel="Action Item"
            buttonLabel="Add Action Item"
            handleSubmit={handleSubmit}
            addProps={addProps}
          />
        </Block>
      </List>
    </Block>
  );
};

export default ProjectAreaTab;
