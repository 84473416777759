import NotAuthorized from "modules/5_view_helpers/web/NotAuthorized"

const logger = store => next => action => {
	const split = action.type.split("_");
	const type = split.slice(-1)[0];
	if(type == "REJECTED"){
		const status = _.get(action,"payload.response.status")
		if(status == 403){
			NotAuthorized()
		}
	}
	let result = next(action)
	return result
}

export default logger