import { 
	GET_PAINT_CODE_DETAILS, 
	GET_PAINT_CODE_DETAIL,
	CREATE_PAINT_CODE_DETAIL,
	UPDATE_PAINT_CODE_DETAIL,
	DESTROY_PAINT_CODE_DETAIL,
} from "./actions"

export function PaintCodeDetailsReducer(state = {}, action) {
	switch (action.type) {
		case `${GET_PAINT_CODE_DETAILS}_FULFILLED`:{
			return _.mapKeys(action.payload.data, "id");
		}
		case `${GET_PAINT_CODE_DETAIL}_FULFILLED`:
		case `${CREATE_PAINT_CODE_DETAIL}_FULFILLED`:
		case `${UPDATE_PAINT_CODE_DETAIL}_FULFILLED`:{
			let newState = _.clone(state);
			const newItem = action.payload.data;
			const id = _.get(newItem,"id")
			newState[id] = newItem
			return newState;
		}		
		case `${DESTROY_PAINT_CODE_DETAIL}_FULFILLED`:{
			let newState = _.clone(state);
			const deletedItem = action.payload.data;
			const id = _.get(deletedItem,"id")
			newState = _.filter(newState,i => i.id != id)
			return newState;
		}
		default:
			return state;
	}
}

// Move this to the reducer page
// import { PaintCodeDetailsReducer } from "modules/paint/code_details/reducer"

// paint_code_details:PaintCodeDetailsReducer,
