export const ADD_REACTDROPZONE_FILE_UPLOADING = "ADD_REACTDROPZONE_FILE_UPLOADING";
export const REMOVE_REACTDROPZONE_FILE_UPLOADING = "REMOVE_REACTDROPZONE_FILE_UPLOADING";

export const addReactDropzoneFileUploading = () => {
  return {
    type: ADD_REACTDROPZONE_FILE_UPLOADING,
  };
};

export const removeReactDropzoneFileUploading = () => {
  return {
    type: REMOVE_REACTDROPZONE_FILE_UPLOADING,
  };
};
