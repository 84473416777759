import { GET_PRODUCTION_STRUMIS_SECTION_SIZES } from "./actions";

export function ProductionStrumisSectionSizesReducer(state = [], action) {
  switch (action.type) {
    case `${GET_PRODUCTION_STRUMIS_SECTION_SIZES}_FULFILLED`: {
      return _.mapKeys(action.payload.data, "id");
    }
    default:
      return state;
  }
}

// Move this to the reducer page
// import { ProductionStrumisSectionSizesReducer } from "modules/production/strumis/section_sizes/reducer"

// production_strumis_section_sizes:ProductionStrumisSectionSizesReducer,
