import React, { useState, Fragment } from "react";

import { Button, Table } from "reactstrap";
import { ReactSelect, required } from "modules/4_final_form/web";

import { Field } from "react-final-form";
import _ from "lodash";
import { OnChange } from "react-final-form-listeners";

const ParentsAttributes = (props) => {
  const {
    fields,
    project_communications,
    project_communication_id,
    disabled,
    meta: { error },
  } = props;

  let filtered_project_communications = _.toArray(project_communications);
  if (project_communication_id) {
    filtered_project_communications = _.filter(project_communications, (pc) => pc.id != project_communication_id);
  }

  const removeParent = (i) => {
    const thisField = fields.value[i];
    if (thisField?.id) {
      return fields.update(i, { ...thisField, _destroy: "1" });
    } else {
      return fields.remove(i);
    }
  };

  return (
    <Fragment>
      <hr />

      <Table hover bordered style={{ tableLayout: "fixed" }}>
        <thead>
          <tr>
            <th>Communication Link</th>
            <th>Question</th>
            <th>New Communication Status</th>
            <th style={{ width: "5%" }}></th>
          </tr>
        </thead>
        <tbody>
          {fields.map((fieldName, index) => {
            const thisField = fields.value[index];
            const markedForDestruction = _.get(thisField, "_destroy");
            if (!markedForDestruction) {
              return (
                <ParentForm
                  fieldName={fieldName}
                  key={index}
                  thisField={thisField}
                  removeParent={() => {
                    return removeParent(index);
                  }}
                  index={index}
                  fields={fields}
                  show_project_communications={filtered_project_communications}
                  {...props}
                />
              );
            }
          })}
          <tr>
            {!disabled && _.isEmpty(error) && (
              <td colSpan="3">
                <div
                  className="link"
                  onClick={() => {
                    fields.push({});
                  }}
                >
                  <b>Add Communication Link</b>
                </div>
              </td>
            )}
          </tr>
        </tbody>
      </Table>
    </Fragment>
  );
};

export default ParentsAttributes;

const ParentForm = (props) => {
  const {
    fieldName,
    thisField,
    project_communications_statuses,
    removeParent,
    project_communications,
    show_project_communications,
    fields: { value },
    fields,
    index,
  } = props;

  let newValue = _.cloneDeep(value || []);
  newValue.splice(index, 1);

  const selected_parent_communication_ids = _.map(newValue, "parent_communication_id");
  const selected_parent_communication_question_ids = _.map(newValue, "parent_communication_question_id");

  let display_project_communications = _.clone(show_project_communications);
  display_project_communications = _.filter(display_project_communications, (pc) => {
    if (pc.has_questions) {
      const checkAllQuestionsSelected = _.every(pc?.questions_attributes, (q) => _.includes(selected_parent_communication_question_ids, q.id));
      return !checkAllQuestionsSelected;
    } else if (_.includes(selected_parent_communication_ids, pc.id)) {
      return false;
    } else {
      return true;
    }
  });

  const selected_project_communication = _.find(project_communications, { id: thisField?.parent_communication_id });
  let questions = [];
  let statuses = [];
  if (selected_project_communication?.outgoing_type_questions) {
    statuses = _.filter(project_communications_statuses, { type_string: "question" });

    // If no questions are selected, add an option to select all questions
    if (!_.some(selected_project_communication?.questions_attributes, (q) => _.includes(selected_parent_communication_question_ids, q.id))) {
      questions.push({ label: `${selected_project_communication.display_name} - All Questions`, value: "all" });
    }

    _.map(selected_project_communication?.questions_attributes, (q) => {
      if (!_.includes(selected_parent_communication_question_ids, q.id)) {
        questions.push({ label: q.display_name, value: q.id });
      }
    });
  } else if (selected_project_communication?.direction == "incoming") {
    statuses = _.filter(project_communications_statuses, { type_string: "incoming" });
  } else if (selected_project_communication?.direction == "outgoing") {
    statuses = _.filter(project_communications_statuses, { type_string: "outgoing" });
  }

  return (
    <tr>
      <td>
        <Field
          name={`${fieldName}.parent_communication_id`}
          component={ReactSelect}
          options={_.map(display_project_communications, (pc) => {
            return { label: pc.display_name, value: pc.id };
          })}
          validate={required}
        />
      </td>
      <td>
        {selected_project_communication?.outgoing_type_questions && questions.length > 0 ? (
          <Fragment>
            <Field name={`${fieldName}.parent_communication_question_id`} component={ReactSelect} options={questions} validate={required} />
            <OnChange name={`${fieldName}.parent_communication_question_id`}>
              {/* Have to use a settimeout :(, not great. But must move on. */}
              {(value) => {
                if (value == "all") {
                  const removedParent = removeParent();
                  setTimeout(() => {
                    const parent_communication_id = thisField?.parent_communication_id;
                    const allQuestions = _.map(selected_project_communication?.questions_attributes, (q) => {
                      return { parent_communication_id: parent_communication_id, parent_communication_question_id: q.id, status_id: null };
                    });
                    _.each(allQuestions, (q) => {
                      fields.push(q);
                    });
                  }, 50);
                }
              }}
            </OnChange>
          </Fragment>
        ) : (
          "N/A"
        )}
      </td>
      <td>
        <Field
          name={`${fieldName}.status_id`}
          component={ReactSelect}
          options={_.map(statuses, (status) => {
            return { label: status.name, value: status.id };
          })}
          isClearable
          placeholder="No new status"
        />
      </td>
      <td>
        <Button color="danger" onClick={removeParent}>
          X
        </Button>
      </td>
    </tr>
  );
};
