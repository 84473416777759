import axios from "axios";

const prepareData = (data) => {return {paint_product_category:data}}

// Plural Actions
export const GET_PAINT_PRODUCT_CATEGORIES = "get_paint_product_categories";
export const getPaintProductCategories = () => {
	const url = `/paint/product_categories.json`
	const request = axios.get(url);
	return {
		type: GET_PAINT_PRODUCT_CATEGORIES,
		payload: request
	};
};


// Singular Actions
export const CREATE_PAINT_PRODUCT_CATEGORY = "create_paint_product_category";
export const createPaintProductCategory = (data) => {
	const url = `/paint/product_categories.json`
	const request = axios({method: "post",url: url,data: prepareData(data)});
	return {
		type: CREATE_PAINT_PRODUCT_CATEGORY,
		payload: request
	};
};

export const GET_PAINT_PRODUCT_CATEGORY = "get_paint_product_category";
export const getPaintProductCategory = (id) => {
	const url = `/paint/product_categories/${id}.json`
	const request = axios.get(url);
	return {
		type: GET_PAINT_PRODUCT_CATEGORY,
		payload: request
	};
};

export const UPDATE_PAINT_PRODUCT_CATEGORY = "update_paint_product_category";
export const updatePaintProductCategory = (id,data) => {
	const url = `/paint/product_categories/${id}.json`
	const request = axios({method: "patch",url: url,data: prepareData(data)});
	return {
		type: UPDATE_PAINT_PRODUCT_CATEGORY,
		payload: request
	};
};

export const DESTROY_PAINT_PRODUCT_CATEGORY = "destroy_paint_product_category";
export const destroyPaintProductCategory = (id) => {
	const url = `/paint/product_categories/${id}.json`
	const request = axios({method: "delete",url: url});
	return {
		type: DESTROY_PAINT_PRODUCT_CATEGORY,
		payload: request
	};
};
