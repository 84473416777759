import React from "react";

const Production = React.lazy(() => import("./index"));

import ProductionJobBoardRoutes from "modules/production/job_boards/web/routes";
import ProductionTransportBoardRoutes from "modules/production/transport_boards/web/routes";
import ProductionPpBoardRoutes from "modules/production/pp_boards/web/routes";
import ProductionPaintBoardRoutes from "modules/production/paint_boards/web/routes";
import ProductionScheduleRoutes from "modules/production/schedule/web/routes";

let routes = [];
routes = _.concat(routes, ProductionJobBoardRoutes);
routes = _.concat(routes, ProductionPpBoardRoutes);

// in dev
routes = _.concat(routes, ProductionTransportBoardRoutes);
routes = _.concat(routes, ProductionPaintBoardRoutes);

routes = _.concat(routes, ProductionScheduleRoutes);

routes.push({ path: "/production", component: Production, name: "Production" });

export default routes;
