import React from 'react';

const ProjectQaWeldingInspectionsWeldingTypesIndex = React.lazy(() => import("./index"))
const NewProjectQaWeldingInspectionsWeldingTypes = React.lazy(() => import("./new"))
const EditProjectQaWeldingInspectionsWeldingTypes = React.lazy(() => import("./edit"))

export default[
	{path:'/project/qa/welding_inspections/welding_types/:id/edit',component: EditProjectQaWeldingInspectionsWeldingTypes,name:'Edit ProjectQaWeldingInspectionsWeldingType'},
	{path:'/project/qa/welding_inspections/welding_types/new',component: NewProjectQaWeldingInspectionsWeldingTypes,name:'New ProjectQaWeldingInspectionsWeldingType'},  
	{path:'/project/qa/welding_inspections/welding_types',component: ProjectQaWeldingInspectionsWeldingTypesIndex,name:'ProjectQaWeldingInspectionsWeldingTypes'}, 
]

// import ProjectQaWeldingInspectionsWeldingTypesRoutes from "modules/project/qa/welding_inspections/welding_types/web/routes"
// routes = _.concat(routes,ProjectQaWeldingInspectionsWeldingTypesRoutes)
