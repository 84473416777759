import React, { Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Chip from "@mui/material/Chip";

import { removeFilterValue } from "./actions";

function mapStateToProps(state) {
  return {
    filterValues: state.filterValues,
  };
}

function ChipDisplay(props) {
  const { filterValues } = props;

  return (
    <Fragment>
      {_.map(filterValues, (filterObject, v) => {
        const value = filterObject?.value;
        const column = filterObject?.column;

        const chipLabel = (
          <Fragment>
            <b>{column?.text}</b>: {value}
          </Fragment>
        );

        // Multiselect Chips
        if (column?.dataFilter == "multiselect") {
          return _.map(filterValues[column.dataField]?.value, (v) => (
            <Chip
              variant="outlined"
              key={v}
              label={v}
              onDelete={() => {
                props.removeFilterValue(column, v);
              }}
              className="mr-2"
              style={{
                color: "#545CD8",
                borderColor: "#545CD8",
                border: "2px solid",
                fontWeight: "bold",
              }}
            />
          ));
          // Date chips
        } else if (column?.dataFilter == "date" && value?.startDate) {
          let dateLabel = moment(value?.startDate).format("DD/MM/YYYY");

          // Conditionally add end date to chip label
          if (value.endDate) {
            dateLabel = (
              <Fragment>
                {moment(value?.startDate).format("DD/MM/YYYY")} to {moment(value?.endDate).format("DD/MM/YYYY")}
              </Fragment>
            );
          }
          return (
            <Chip
              variant="outlined"
              key={v}
              label={dateLabel}
              onDelete={() => {
                props.removeFilterValue(column);
              }}
              className="mr-2"
              style={{
                color: "#545CD8",
                borderColor: "#545CD8",
                border: "2px solid",
                fontWeight: "bold",
              }}
            />
          );
          // Default chips
        } else if (value) {
          return (
            <Chip
              variant="outlined"
              key={v}
              label={chipLabel}
              onDelete={() => {
                props.removeFilterValue(column);
              }}
              className="mr-2"
              style={{
                color: "#545CD8",
                borderColor: "#545CD8",
                border: "2px solid",
                fontWeight: "bold",
              }}
            />
          );
        }
      })}
    </Fragment>
  );
}

export default connect(mapStateToProps, { removeFilterValue })(ChipDisplay);
