import axios from "axios";

const prepareData = (data) => {
  return { project_qa_welding_inspections_inspection_criteria: data };
};

// Plural Actions
export const GET_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIAS = "get_project_qa_welding_inspections_inspection_criterias";
export const getProjectQaWeldingInspectionsInspectionCriterias = () => {
  const url = `/project/qa/welding_inspections/inspection_criteria.json`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIAS,
    payload: request,
  };
};

// Singular Actions
export const CREATE_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIA = "create_project_qa_welding_inspections_inspection_criteria";
export const createProjectQaWeldingInspectionsInspectionCriteria = (data) => {
  const url = `/project/qa/welding_inspections/inspection_criteria.json`;
  const request = axios({ method: "post", url: url, data: prepareData(data) });
  return {
    type: CREATE_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIA,
    payload: request,
  };
};

export const GET_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIA = "get_project_qa_welding_inspections_inspection_criteria";
export const getProjectQaWeldingInspectionsInspectionCriteria = (id) => {
  const url = `/project/qa/welding_inspections/inspection_criteria/${id}.json`;
  const request = axios.get(url);
  return {
    type: GET_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIA,
    payload: request,
  };
};

export const UPDATE_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIA = "update_project_qa_welding_inspections_inspection_criteria";
export const updateProjectQaWeldingInspectionsInspectionCriteria = (id, data) => {
  const url = `/project/qa/welding_inspections/inspection_criteria/${id}.json`;
  const request = axios({ method: "patch", url: url, data: prepareData(data) });
  return {
    type: UPDATE_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIA,
    payload: request,
  };
};

export const DESTROY_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIA = "destroy_project_qa_welding_inspections_inspection_criteria";
export const destroyProjectQaWeldingInspectionsInspectionCriteria = (id) => {
  const url = `/project/qa/welding_inspections/inspection_criteria/${id}.json`;
  const request = axios({ method: "delete", url: url });
  return {
    type: DESTROY_PROJECT_QA_WELDING_INSPECTIONS_INSPECTION_CRITERIA,
    payload: request,
  };
};
