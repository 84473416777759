import React, { Fragment } from "react";
import { Field, useForm } from "react-final-form";
import { FormGroup, Label, Col, Input } from "reactstrap";

import { InputField, ToggleField, required } from "modules/4_final_form/web";

import { ToggleField as MobileToggleField } from "modules/4_final_form/mobile";
import { OnChange } from "react-final-form-listeners";

export const ToggleFieldAttributes = ({ fieldName, namesUsed }) => {
  const form = useForm();

  return (
    <Fragment>
      <FormGroup row>
        <Label md={3}>Label</Label>
        <Col md={8}>
          <Field name={`${fieldName}.label`} component={InputField} />
          <OnChange name={`${fieldName}.label`}>
            {(value, previous) => {
              if (value) {
                const name = _.snakeCase(value);
                form.change(`${fieldName}.name`, name);
              }
            }}
          </OnChange>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>Name</Label>
        <Col md={8}>
          <Field
            name={`${fieldName}.name`}
            component={InputField}
            validate={(value, values) => {
              if (!value) {
                return "is required";
              }
              if (namesUsed.includes(value)) {
                return "must be unique";
              }
            }}
            convertToLowerCase
            lettersOnly
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>On Dashboard</Label>
        <Col md={8}>
          <Field name={`${fieldName}.on_dashboard`} component={ToggleField} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={3}>On Report</Label>
        <Col md={8}>
          <Field name={`${fieldName}.on_report`} component={ToggleField} />
        </Col>
      </FormGroup>
    </Fragment>
  );
};

export const ToggleFieldForm = (props) => {
  const { label, placeholder, required: isRequired, preview, name, prefixName } = props;
  return (
    <FormGroup row>
      <Col md={2}>
        <Field
          name={`${prefixName}.${name}`}
          disabled={preview}
          component={ToggleField}
          placeholder={placeholder}
          validate={isRequired && !preview ? required : null}
          format={(value) => (value == "1" ? true : false)} // Got to format based on a text value
        />
      </Col>
      {label && (
        <Label md={9}>
          {label}
          {required && "*"}
        </Label>
      )}
    </FormGroup>
  );
};
ToggleFieldForm.defaultProps = {
  prefixName: "data",
};


export const MobileToggleFieldForm = (props) => {
  const { label, placeholder, required: isRequired, preview, name, prefixName } = props;
  return (
    <Field
      name={`${prefixName}.${name}`}
      disabled={preview}
      label={label}
      component={MobileToggleField}
      placeholder={placeholder}
      validate={isRequired && !preview ? required : null}
      format={(value) => (value == "1" ? true : false)} // Got to format based on a text value
    />
  );
};
MobileToggleFieldForm.defaultProps = {
  prefixName: "data",
};
