import { 
	GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STAGES, 
	GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STAGE,
	CREATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STAGE,
	UPDATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STAGE,
	DESTROY_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STAGE,
} from "./actions"

export function ProjectQaWeldingInspectionsWeldingStagesReducer(state = {}, action) {
	switch (action.type) {
		case `${GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STAGES}_FULFILLED`:{
			return _.mapKeys(action.payload.data, "id");
		}
		case `${GET_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STAGE}_FULFILLED`:
		case `${CREATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STAGE}_FULFILLED`:
		case `${UPDATE_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STAGE}_FULFILLED`:{
			let newState = _.clone(state);
			const newItem = action.payload.data;
			const id = _.get(newItem,"id")
			newState[id] = newItem
			return newState;
		}		
		case `${DESTROY_PROJECT_QA_WELDING_INSPECTIONS_WELDING_STAGE}_FULFILLED`:{
			let newState = _.clone(state);
			const deletedItem = action.payload.data;
			const id = _.get(deletedItem,"id")
			newState = _.filter(newState,i => i.id != id)
			return newState;
		}
		default:
			return state;
	}
}

// Move this to the reducer page
// import { ProjectQaWeldingInspectionsWeldingStagesReducer } from "modules/project/qa/welding_inspections/welding_stages/reducer"

// project_qa_welding_inspections_welding_stages:ProjectQaWeldingInspectionsWeldingStagesReducer,
