import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { useParams } from "react-router-dom";

import CustomFormRecordForm from "modules/custom_form/records/web/form.jsx";
import { createCustomFormRecord, getCustomFormRecords } from "../actions";

import { Card, CardHeader, CardBody, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Form } from "react-final-form";
import { getCustomForm, getCustomForms } from "modules/custom_forms/actions.js";

import { getProjects } from "modules/projects/actions";
import { getEmployees } from "modules/employees/actions";

// Helpers
import NotAuthorized from "modules/5_view_helpers/web/NotAuthorized";
import HasRole from "modules/5_view_helpers/HasRole";

import getReturnAndBreadcrumbs from "../shared";

import arrayMutators from "final-form-arrays";
import { PacmanLoader } from "react-spinners";

function mapStateToProps(state, ownProps) {
  return {
    projects: state.projects,
    employees: state.employees,
    custom_forms: state.custom_forms,
    custom_form_records: state.custom_form_records,
  };
}

const NewCustomFormRecordOutlook = (props) => {
  const [loadingString, setLoadingString] = useState(null);

  const { history, custom_forms } = props;
  const { custom_form_id, parent_id } = useParams();

  const { projects, employees, custom_form_records } = props;

  const custom_form = custom_forms?.[custom_form_id];

  useEffect(() => {
    props.getCustomForms();
    props.getCustomForm(custom_form_id);
  }, [custom_form_id]);

  useEffect(() => {
    if (custom_form) {
      if (custom_form?.parent_type === "Project") {
        props.getProjects();
      }
      if (custom_form?.parent_type === "Employee") {
        props.getEmployees();
      }
      if (custom_form?.parent_type == "CustomForm") {
        props.getCustomFormRecords(custom_form?.parent_id);
      }
    }
  }, [custom_form]);

  const initialValues = { parent_type: custom_form?.parent_type }; // Set the parent type to the parent type of the custom form.
  // So it will save it against the parent selected

  // Get an array of possible parents to pass to the form since we didnt select the parent. This will make a select box at the top.
  // Only used for the outlook module.
  let parentSelectLabel = "";
  let parents = null;
  if (custom_form?.parent_type === "Project") {
    parentSelectLabel = "Project";
    parents = _.map(projects, (project) => {
      return {
        value: project.id,
        label: project.display_name,
      };
    });
  }
  if (custom_form?.parent_type === "Employee") {
    parentSelectLabel = "Employee";
    parents = _.map(employees, (employee) => {
      return {
        value: employee.id,
        label: employee.display_name,
      };
    });
  }
  if (custom_form?.parent_type === "CustomForm") {
    parentSelectLabel = custom_forms[custom_form?.parent_id]?.name; // Get the name of the parent custom form
    const parent_custom_form_records = _.filter(custom_form_records, { custom_form_id: custom_form?.parent_id });
    parents = _.map(parent_custom_form_records, (custom_form_record) => {
      return {
        value: custom_form_record.id,
        label: custom_form_record.display_name,
      };
    });
  }

  const returnUrl = "/outlook";

  const createRole = HasRole({ resource_type: "CustomForm::Record", name: ["create", "moderator"] }, false, true);

  const submitForm = (values) => {
    setLoadingString(`Saving new ${custom_form?.name}`);
    const createRequest = props.createCustomFormRecord(custom_form_id, values);
    createRequest.then(successfullySubmitted);
  };

  const successfullySubmitted = () => {
    setLoadingString(null);
    props.history.push(returnUrl);
    toastr.success("Created New Record");
  };

  if (loadingString) {
    return (
      <div className="text-center">
        <PacmanLoader />
        {loadingString}
      </div>
    );
  }
  if (!createRole) {
    return <NotAuthorized />;
  } else {
    return (
      <Card>
        <CardHeader>
          <h3>New {custom_form?.name}</h3>
        </CardHeader>
        <CardBody>
          <Form
            component={CustomFormRecordForm}
            onSubmit={submitForm}
            formLabel="Create"
            cancelFunction={() => {
              history.push(returnUrl);
            }}
            mutators={{
              ...arrayMutators,
            }}
            initialValues={initialValues}
            custom_form={custom_form}
            parents={parents} // Outlook pass the parents to the form so it can be used in the select box
            parentSelectLabel={parentSelectLabel} // Pass parent name to the form
            outlookMode // Outlook mode on for the form
            {...props}
          />
        </CardBody>
      </Card>
    );
  }
};

export default connect(mapStateToProps, { createCustomFormRecord, getCustomForm, getProjects, getEmployees, getCustomForms, getCustomFormRecords })(
  NewCustomFormRecordOutlook
);
